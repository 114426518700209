import React, { ReactNode } from 'react'

interface RideSharingDisplayBlockProps {
  imageUrl: string
  description1: string
  description2: string
  description3: string
  icon?: ReactNode
  buttonText?: string          // Button
  onButtonClick?: () => void   // Button
}

const RideSharingDisplayBlock: React.FC<RideSharingDisplayBlockProps & { className?: string }> = ({ imageUrl, description1, description2, description3, className, icon, buttonText, onButtonClick }) => {
  return (
    <div className={`flex flex-col items-start p-4 bg-blue-50 shadow-md rounded-md ${className}`}>
      {/* Icon */}
      {icon && <div className="mb-4">{icon}</div>}
      
      <div className="flex flex-grow w-full">
        {/* Left content: Description, Image, and Extra Description */}
        <div className="flex flex-col flex-grow">
          {/* Description1 & Description2 */}
          <div className="flex">  {/* Make description3 and description4 inline */}
            <div className="mr-2 text-xs font-bold">{description1}</div>
            <div className="text-xs font-normal">{description2}</div>
          </div>
          {/* Image */}
          <img src={imageUrl} alt="Description Image" className="object-cover h-40 max-w-xl mt-4 mb-4 rounded-md" />
          {/* Description3 */}
          {description3 && <p className="mb-2 text-xs text-black-600">{description3}</p>}
        </div>
      </div>

      {/* Button */}
      {buttonText && 
          <div className="flex justify-end w-full">
            <button onClick={onButtonClick} className="self-end px-4 py-2 text-xs font-normal text-white bg-blue-500 rounded hover:bg-blue-700">
              {buttonText}
            </button>
          </div>
        }
    </div>
  )
}

export default RideSharingDisplayBlock
