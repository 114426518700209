import { BookingRoute } from 'src/consts/route'
import { Edit } from 'src/types'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function isNil(input: any): boolean {
  return [null, undefined, 'null', 'undefined'].includes(input)
}

export function formatInputPhoneText(value: string) {
  const s = value.split('').filter((x) => x !== '-')
  let text = ''
  for (let i = 0; i < s.length; i++) {
    if (i === 3 && s[i] !== '-') {
      text = text + '-'
    }
    if (i === 6 && s[i] !== '-') {
      text = text + '-'
    }
    text = text + s[i]
  }
  return text
}

export function formatPhoneNumber(value: string) {
  return value.replace(/-/g, '')
}

export function decoratePhoneNumber(phone: string) {
  return phone.length === 10
    ? phone
        .replace(/-/g, '')
        .split('')
        .map((v, i) => (i === 2 || i === 5 ? `${v}-` : v))
        .join('')
    : phone
}

export function convertDuration(s: string) {
  if (s === '') return { h: 0, m: 0 }
  else {
    const time = s.split(' ')
    if (time.length === 2) return { h: 0, m: parseInt(time[1]) }
    else return { h: parseInt(time[0]), m: parseInt(time[2]) }
  }
}

export function padZero(t: number, length = 2): string {
  return t.toString().padStart(length, '0')
}

export function editRouteCheck(edit: Edit, page: string) {
  if (edit.byConfirm) return BookingRoute.Confirm
  else if (edit.byView) return `${BookingRoute.View}/?bn=${edit.bn}`
  else return page
}

export function resolveAddressName(
  address_components: google.maps.GeocoderAddressComponent[],
  formatted_address: string
) {
  let name = `${address_components[0].short_name} ${address_components[1].short_name} ${address_components[2].short_name}`
  const regex = /[+]/g
  if (regex.test(formatted_address.split(' ')[0])) {
    name = name.replace(`${formatted_address.split(' ')[0]} `, '')
    formatted_address = formatted_address.replace(`${formatted_address.split(' ')[0]} `, '')
  }
  return { name, formatted_address }
}

export const isDev = process.env.NODE_ENV === 'development'
