import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import {
  // BufferedTimeDay,
  BufferedTravelTime,
  EndHour,
  ERelation,
  intitialPlace,
  intitialType,
  // IS_REALTIME_ENABLE,
  StartHour,
} from 'src/consts'
import { dayjstz } from 'src/helpers/datetime'
import {
  Baggage,
  BookingVehicle,
  CustomerCNMIRecoilProps,
  CustomerBookingInfoCNMIInterface,
  Customer,
  Edit,
  Person,
  Place,
  PublicBooking,
  Route,
  RouteType,
  TimePackage,
  Vehicle,
} from 'src/types'
import dayjs from 'dayjs'
import { UserCNMIFetchingStateValue, Languages, BeforeMapPage } from 'src/enums'

const { persistAtom } = recoilPersist()

export interface DepartureState {
  date: Date
  goTime: TimePackage
  backTime?: TimePackage
  vehicle?: Vehicle
  carModelId?: number
  followers: number
  baggages: Baggage
  note: string
  requestId?: number
  isFree?: boolean
  goPrice: number
  backPrice: number | undefined
}

export interface LocationState {
  routeType: RouteType
  routeGo: Route
  routeBack: Route
}

export const hospitalOnBotState = atom<boolean>({
  key: 'hospitalOnBot',
  default: true
})

export const otherLocationState = atom<{ place?: Place }>({
  key: 'otherLocation',
  default: { place: undefined }
})

export const bookingLocationState = atom<LocationState>({
  key: 'bookingLocation',
  default: {
    routeType: intitialType,
    routeGo: { origin: intitialPlace, destination: intitialPlace },
    routeBack: { origin: intitialPlace, destination: intitialPlace },
  },
  effects_UNSTABLE: [persistAtom],
})

export const viewLocationState = atom<LocationState>({
  key: 'viewBookingLocation',
  default: {
    routeType: intitialType,
    routeGo: { origin: intitialPlace, destination: intitialPlace },
    routeBack: { origin: intitialPlace, destination: intitialPlace },
  },
})

export const bookingLocationSearchState = atom<Place>({
  key: 'bookingLocationSearch',
  default: intitialPlace,
  effects_UNSTABLE: [persistAtom],
})

export const isOutOfServiceTime = dayjstz().isAfter(dayjstz().hour(EndHour).minute(0).second(0))
// const additionalOurOfServeiceDay = IS_REALTIME_ENABLE ? 2 : 1
// const additionalOurOfServeiceDay = 2
// const day = dayjstz()
//   .add(isOutOfServiceTime ? BufferedTimeDay + additionalOurOfServeiceDay : BufferedTimeDay, 'day')
//   .toDate()

const defaultRealTime = dayjstz().add(5, 'minutes')

export const bookingDepartureState = atom<DepartureState>({
  key: 'bookingDeparture',
  default: {
    date: dayjstz().add(2, 'day').toDate(),
    goTime: {
      departure: isOutOfServiceTime
        ? { hour: StartHour, minute: 0 }
        : { hour: defaultRealTime.get('hour'), minute: defaultRealTime.get('minute') },
      arrival: { hour: StartHour, minute: BufferedTravelTime },
    },
    baggages: { items: [], note: '' },
    followers: 0,
    note: '',
    isFree: false,
    goPrice: 0,
    backPrice: 0,
  },
  effects_UNSTABLE: [persistAtom],
})

export const viewDepartureState = atom<DepartureState>({
  key: 'viewBookingDeparture',
  default: {
    date: dayjstz().add(2, 'day').toDate(),
    goTime: {
      departure: { hour: StartHour, minute: 0 },
      arrival: { hour: StartHour, minute: BufferedTravelTime },
    },
    baggages: { items: [], note: '' },
    followers: 0,
    note: '',
    isFree: false,
    goPrice: 0,
    backPrice: 0,
  },
})

export const bookingCustomerState = atom<Customer>({
  key: 'bookingCustomer',
  default: {
    booker: { name: '', phone: '', lineId: '' },
    passenger: { name: '', phone: '', lineId: '' },
    emergencyContact: { name: '', phone: '', relation: ERelation.Child.toString() },
    note: '',
    checked: 0,
  },
  effects_UNSTABLE: [persistAtom],
})

export const viewCustomerState = atom<Customer>({
  key: 'viewBookingCustomer',
  default: {
    booker: { name: '', phone: '', lineId: '' },
    passenger: { name: '', phone: '', lineId: '' },
    emergencyContact: { name: '', phone: '', relation: ERelation.Child.toString() },
    note: '',
    checked: 0,
  },
  effects_UNSTABLE: [persistAtom],
})

export const editState = atom<Edit>({
  key: 'editState',
  default: {
    byConfirm: false,
    byView: false,
    bn: '',
  },
})

export const customerContacts = atom<Person[]>({
  key: 'customerContacts',
  default: [],
})

export const bookingNumberState = atom({
  key: 'bookingNumber',
  default: '',
})

export const publicBookingState = atom<PublicBooking | null>({
  key: 'publicBooking',
  default: null,
})

export const bookingState = atom<BookingVehicle | null>({
  key: 'booking',
  default: null,
})

export const tempBooking = atom<BookingVehicle | null>({
  key: 'tempBooking',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const lineState = atom({
  key: 'lineState',
  default: { userId: '', displayName: '', pictureURL: ''}, // Add pictureURL for user's line profile picture
  effects_UNSTABLE: [persistAtom],
})

export const pathState = atom({
  key: 'pathState',
  default: { fromMenu: false, prvePath: '' },
})

export const domainState = atom({
  key: 'domainState',
  default: '',
})

export const isRealtimeState = atom({
  key: 'isRealtimeState',
  default: true,
})

export const cnmiCustomerState = atom<CustomerCNMIRecoilProps>({
  key: 'cnmiCustomerState',
  default: {
    'fetchState': UserCNMIFetchingStateValue.NOT_FETCH,
    'lineId': undefined,
    'phone': undefined,
    'name':undefined,
    'lat': undefined,
    'lng': undefined,
    'distance': undefined,
    'duration': undefined,
    'postCode': undefined,
    'id': undefined,
    'addressName': undefined,
    'address': undefined,
    'pickUpPoint': undefined
  }
})

export const cnmiPassengerState = atom<CustomerBookingInfoCNMIInterface>({
  key: 'cnmiPassengerState',
  default: {
    'lineId': '',
    'phone': '',
    'name':'',
    'lat': '',
    'lng': '',
    'distance': 0,
    'duration': 0,
  }
})

export const currentMonthYearState = atom({
  key: 'currentMonthYearState',
  default: dayjs(),
})

export const languageState = atom<Languages>({
  key: 'languageState',
  default: localStorage.getItem('userLanguage') as Languages || Languages.EN,
})

export const totalPriceState = atom({
  key: 'totalPriceState',
  default: 0,
})

export const pendingBookingState = atom({
  key: 'pendingBookingState',
  default: 0,
})

export const beforeMapPage = atom<BeforeMapPage>({
  key: 'beforeMapPage',
  default: BeforeMapPage.AddressAndPriceCNMI,
})