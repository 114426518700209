import liff from '@line/liff/dist/lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import ViewShowCarCard from 'src/components/ViewShowCarCard'
import { EndHour, PLACE_TYPE } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BookingStatusCNMI, BookingStatusNewCNMI, RouteType as RouteTypes } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { decoratePhoneNumber } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import useInterval from 'src/hooks/useInterval'
import DoubleButtonCard from 'src/pageviews/Booking/DoubleButtonCard'
import DriverCard from 'src/pageviews/Booking/DriverCard'
import Loading from 'src/pageviews/Booking/Loading'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import NotificationCard from 'src/pageviews/Booking/NotificationCard'
import Page404 from 'src/pageviews/Booking/Page404'
import RouteCard from 'src/pageviews/Booking/RouteCard'
import ShowInfoCNMI from 'src/pageviews/Booking/ShowInfoCNMI'
import ShowScheduleCNMI from 'src/pageviews/Booking/ShowScheduleCNMI'
import { formatPhoneNumber } from 'src/helpers/utils'
import shareTargetPicker from 'src/hooks/useTargetPicker'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'
import { bookingState, editState, lineState, pathState, viewDepartureState, viewLocationState,viewCustomerState } from 'src/states'
import { BookingVehicle, Driver, DriverLocation, Route, RouteType, Staff } from 'src/types'
import Map from 'src/pageviews/Booking/Map'
import Tracking from 'src/pageviews/Booking/Tracking'
import Duration from 'src/pageviews/Booking/Duration'



const inProgressStatuses = [BookingStatusNewCNMI.PENDING, BookingStatusNewCNMI.START, BookingStatusNewCNMI.DEPART, BookingStatusNewCNMI.CONFIRM_DRIVER, BookingStatusNewCNMI.CONFIRM_PICK_UP, BookingStatusNewCNMI.ARRIVE, BookingStatusNewCNMI.PICK_UP_POINT]
const DisplayMapState = [
  BookingStatusNewCNMI.PICK_UP_POINT,
  BookingStatusNewCNMI.CONFIRM_PICK_UP,
  BookingStatusNewCNMI.CONFIRM_DRIVER,
  BookingStatusNewCNMI.DEPART
]

export default function PrivateView() {
  const [open, setOpen] = useState(false)
  const [openContact, setOpenContact] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [booking, setBooking] = useState<BookingVehicle | null>()
  const [routeType, setRouteType] = useState<RouteType | undefined>()
  const [routeGo, setRouteGo] = useState<Route | undefined>()
  const [routeBack, setRouteBack] = useState<Route | undefined>()
  const [bookingStatus, setBookingStatus] = useState<number | null>(null)
  const [staff, setStaff] = useState<Staff | undefined>()
  const [loading, setLoading] = useState(false)
  const [onProcess, setOnProcess] = useState(false)
  const [processState, setProcessState] = useState<number | undefined>(undefined)
  const [driverLocation, setDriverLocation] = useState<DriverLocation | undefined>()
  const [driver, setDriver] = useState<Driver | undefined>()
  const [isShowDateCar, setIsShowDateCar] = useState(false)
  const [isShowIconEdit, setIsShowIconEdit] = useState(false)
  const [isShowNotificationCard, setIsShowNotificationCard] = useState(false)
  const [isHistory, setIsHistory] = useState(false)
  const [openShareTargetPicker, setOpenShareTargetPicker] = useState(false)
  const { t } = useTranslation()
  const [domain, setDomain] = useState('')
  const [customer, setCustomer] = useRecoilState(viewCustomerState)
  const [departure, setDeparture] = useRecoilState(viewDepartureState)
  const [path] = useRecoilState(pathState)
  const [edit, setEdit] = useRecoilState(editState)
  const [tripType, setTriptype] = useState(3)
  const [location, setLocation] = useRecoilState(viewLocationState)
  const bookingVehicle = useRecoilValue(bookingState)
  const { userId } = useRecoilValue(lineState)
  const [isShowNotification, setIsShowNotification] = useState(false)
  const [passengerLanguage, setPassengerLanguage] = useState('en')
  const {
    updateCustomer,
    getBooking,
    cancelBooking,
    getStaff,
    getDriverById,
    getDomain,
    getDriverLocation,
  } = useCallApi()
  const navigate = useNavigate()
  const defaultLocation = { lat: '13.595549', lng: '100.8770063' }

  const queryParams = new URLSearchParams(window.location.search)
  const bn = queryParams.get('bn')

  const checkViewState = () => {
    if (!path.fromMenu && bn) {
      navigate(`/view/?bn=${bn}`)
    } else if (path.fromMenu && path.prvePath) {
      navigate(path.prvePath)
    }
  }
  window.addEventListener('popstate', checkViewState)

  const OnProcessState = [
    BookingStatusNewCNMI.PENDING,
    BookingStatusNewCNMI.START,
    BookingStatusNewCNMI.PICK_UP_POINT,
    BookingStatusNewCNMI.CONFIRM_PICK_UP,
    BookingStatusNewCNMI.CONFIRM_DRIVER,
    BookingStatusNewCNMI.DEPART,
    BookingStatusNewCNMI.ARRIVE,
    BookingStatusNewCNMI.FINISH,
    BookingStatusNewCNMI.CANCEL,
    BookingStatusNewCNMI.ABSENT
  ]

  useInterval(() => {
    checkState()
  }, 10000)

  useEffect(() => {
    checkState()
  }, [booking])

  useEffect(() => {
    (async () => {
      setDomain(await getDomain())
    })()
  }, [domain])

  async function checkState() {
    if (!booking) return

    if (booking.driverId && booking.carId && driver === undefined) {
      fetchDriver(booking.driverId)
    }

    const dateNow = dayjstz(new Date())
    const beforeStartDate = dayjstz(booking.scheduledDate).subtract(1, 'day').hour(EndHour).minute(0).second(0)

    if (booking.driverId && booking.carId) {
      setIsShowDateCar(true)
    }

    if (
      dateNow.isSameOrBefore(beforeStartDate) &&
      userId === booking.bookerLineId &&
      // ![BookingStatus.CANCELED, BookingStatus.COMPLETED].includes(booking.status)
      ![BookingStatusNewCNMI.FINISH, BookingStatusNewCNMI.CANCEL, BookingStatusNewCNMI.ABSENT].includes(booking.status)
    ) {
      setIsShowIconEdit(true)
    }
  }

  async function fetchDriver(driverId: string) {
    const driver = await getDriverById(driverId)
    setDriver(driver)
  }

  async function fetchBooking() {
    const queryParams = new URLSearchParams(window.location.search)
    const bn = queryParams.get('bn')
    if (bn) {
      const b = bookingVehicle ? bookingVehicle : await getBooking(bn)
      if (!b) return
      // setIsHistory([BookingStatus.CANCELED, BookingStatus.COMPLETED].includes(b.status))
      setIsHistory([BookingStatusNewCNMI.FINISH, BookingStatusNewCNMI.CANCEL, BookingStatusNewCNMI.ABSENT].includes(b.status))
      // setIsShowNotificationCard(b.status === BookingStatus.NEW)
      setIsShowNotificationCard(b.status === BookingStatusNewCNMI.PENDING)

      setLocation({
        routeType: { isOneWay: !b.trip2, placeType: PLACE_TYPE.ORIGIN1, showRoute: true },
        routeGo: b.trip,
        routeBack: b.trip2 ? b.trip2 : location.routeBack,
      })

      setEdit({ ...edit, bn: b.bookingNumber })
      setTriptype(b.tripType)
      setCustomer({
        booker: { name: b.bookerName, phone: decoratePhoneNumber(b.bookerPhone), lineId: b.bookerLineId },
        passenger: { name: b.passengerName, phone: decoratePhoneNumber(b.passengerPhone), lineId: b.passengerLineId },
        emergencyContact: {
          name: b.emergencyContactName,
          phone: decoratePhoneNumber(b.emergencyContactPhone),
          relation: b.emergencyContactRelation,
        },
        note: b.note ?? '',
        checked: 0,
      })

      const departureTime = dayjstz(b.departureTime)
      const arrivalTime = dayjstz(b.arrivalTime)
      const departureTime2 = dayjstz(b.departureTime2)
      const arrivalTime2 = dayjstz(b.arrivalTime2)

      const goPrice = b.payments.find((p) => p.routeType === RouteTypes.GO)?.amount ?? 0
      const backPrice = b.payments.find((p) => p.routeType === RouteTypes.BACK)?.amount ?? 0
      setDeparture({
        date: b.scheduledDate,
        goTime: {
          departure: { hour: departureTime.hour(), minute: departureTime.minute() },
          arrival: { hour: arrivalTime.hour(), minute: arrivalTime.minute() },
        },
        backTime: {
          departure: { hour: departureTime2.hour(), minute: departureTime2.minute() },
          arrival: { hour: arrivalTime2.hour(), minute: arrivalTime2.minute() },
        },
        vehicle: {
          id: b.vehicleId,
          date: `${dayjstz(b.scheduledDate)}`,
          carId: b.carId,
          carModelId: b.modelId,
          car: {
            id: b.carId,
            modelId: b.modelId,
            model: b.carModel,
            color: b.carColor,
            licensePlateId: b.carLicensePlateId,
            seat: b.seat,
            imageUrl: b.carImageUrl,
          },
        },

        baggages: b.baggages,
        followers: b.followers ?? 0,
        note: b.note ?? '',
        goPrice: Number(goPrice),
        backPrice: Number(backPrice),

      })

      setBooking(b)

      setRouteType({
        isOneWay: !b.trip2,
        placeType: PLACE_TYPE.ORIGIN1,
        showRoute: true,
      })
      setRouteGo(b.trip)
      if (b.trip2) {
        setRouteBack(b.trip2)
      }

      /*
      Status
      1 -> Cancel
      2 -> Complete
      3 -> Wait Car
      4 -> Wait Payment
      5 -> Wait Trip Start
      6 -> In progress
      */

      if (b.status == 8 || b.status == 9) {
        setBookingStatus(BookingStatusCNMI.CANCELED)
      } else if ('carId' in b && b.carId === null) {
        setBookingStatus(BookingStatusCNMI.WAIT_CONFIRM_CAR)
      } else if (b.payments[0].paidStatus != 2) {
        setBookingStatus(BookingStatusCNMI.WAIT_PAYMENT)
      } else if (b.status == 0) {
        setBookingStatus(BookingStatusCNMI.WAIT)
      } else if (b.status > 0 && b.status < 7) {
        setBookingStatus(BookingStatusCNMI.IN_PROGRESS)
      } else if (b.status == 7) {
        setBookingStatus(BookingStatusCNMI.COMPLETED)
      }
    }
  }

  const getStatusIcon = () => {
    if (bookingStatus === BookingStatusCNMI.CANCELED) {
      return "🛑"
    } else if (bookingStatus === BookingStatusCNMI.COMPLETED) {
      return "✔️"
    } else if (bookingStatus === BookingStatusCNMI.WAIT_CONFIRM_CAR) {
      return "⌛"
    } else if (bookingStatus === BookingStatusCNMI.WAIT_PAYMENT) {
      return "💲"
    } else if (bookingStatus === BookingStatusCNMI.WAIT) {
      return "💼"
    } else if (bookingStatus === BookingStatusCNMI.IN_PROGRESS) {
      return "🚐"
    }
    return null
  }

  async function fetchStaff() {
    const domain = await getDomain()
    setStaff(await getStaff(domain))
  }

  async function fetchVihecleState() {
    if (!booking?.vehicleId) return
    if ([BookingStatusNewCNMI.PICK_UP_POINT,
    BookingStatusNewCNMI.CONFIRM_PICK_UP,
    BookingStatusNewCNMI.CONFIRM_DRIVER,
    BookingStatusNewCNMI.DEPART,].includes(booking.status)) {

      const { lat, lng } = handleTracking()
      const _driverLocation = await getDriverLocation(booking.bookingNumber, `${lat},${lng}`)
      console.log("Set Driver Location: ", _driverLocation)
      console.log("Driver Location State: ", _driverLocation.state)
      console.log("Booking Status: ", booking.status)
      setDriverLocation(_driverLocation)
      setProcessState(booking.status)
      if (OnProcessState.includes(booking.status)) {
        setOnProcess(true)
      } else {
        setOnProcess(false)
      }
    }
  }

  function handleTracking() {
    if (!processState || !routeGo) {
      console.log("Driver Location: ", driverLocation)
      console.log("Route Go: ", routeGo)
      console.log("Default Location LAT Debug: ", defaultLocation.lat)
      console.log("Default Location LON Debug: ", defaultLocation.lng)
      return {
        lat: parseFloat(defaultLocation.lat),
        lng: parseFloat(defaultLocation.lng),
      }
    }

    if (
      [
        BookingStatusNewCNMI.PICK_UP_POINT,
        BookingStatusNewCNMI.CONFIRM_PICK_UP,
        BookingStatusNewCNMI.CONFIRM_DRIVER,
      ].includes(processState)
    ) {
      console.log("Route Go Origin LAT: ", routeGo.origin.lat)
      console.log("Route Go Origin LON: ", routeGo.origin.lng)
      console.log("Default Location LAT: ", defaultLocation.lat)
      console.log("Default Location LON: ", defaultLocation.lng)
      return {
        lat: parseFloat(routeGo.origin.lat ?? defaultLocation.lat),
        lng: parseFloat(routeGo.origin.lng ?? defaultLocation.lng),
      }
    } else if ([BookingStatusNewCNMI.DEPART].includes(processState)) {
      console.log("Route Go Destination LAT: ", routeGo.destination.lat)
      console.log("Route Go Destination LON: ", routeGo.destination.lng)
      console.log("Default Location LAT: ", defaultLocation.lat)
      console.log("Default Location LON: ", defaultLocation.lng)
      return {
        lat: parseFloat(routeGo.destination.lat ?? defaultLocation.lat),
        lng: parseFloat(routeGo.destination.lng ?? defaultLocation.lng),
      }
    }

    return {
      lat: parseFloat(defaultLocation.lat),
      lng: parseFloat(defaultLocation.lng),
    }
  }

  function cancel() {
    if (!booking) return
    (async () => {
      try {
        const res = await cancelBooking(booking.bookingNumber)
        if (res.success === true) {
          navigate(BookingRoute.Canceled)
        } else {
          toast.error(t('Failed'))
        }
      } catch (err) {
        toast.error(t('Request failed'))
      }
    })()
    setOpen(false)
  }

  function contactCustomer() {
    if (liff.getOS() === 'ios') {
      const a = document.createElement('a')
      a.href = `tel:${staff?.phone}`
      a.click()
    } else {
      setOpenContact(true)
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true)
      if (userId) {
        await fetchStaff()
        await fetchBooking()
      }
      setLoading(false)
    })()
  }, [userId])

  useInterval(() => {
    (async () => {
      const isBetween = dayjstz(new Date()).isBetween(
        dayjstz(booking?.departureTime).subtract(1, 'hour'),
        dayjstz(booking?.departureTime).add(1, 'hour')
      )
      if (isBetween || inProgressStatuses.includes(booking?.status || BookingStatusNewCNMI.ABSENT)) {
        if (!inProgressStatuses.includes(booking?.status || BookingStatusNewCNMI.ABSENT)) {
          await fetchBooking()
        } else {
          await fetchVihecleState()
          await fetchBooking()

          if (
            processState &&
            [BookingStatusNewCNMI.ARRIVE, BookingStatusNewCNMI.FINISH].includes(
              processState
            )
          ) {
            await fetchBooking()
          }
        }
      }
    })()
  }, 10000)

  useEffect(() => {
    (async () => {
      if (routeGo?.origin.id) fetchVihecleState()
    })()
  }, [routeGo?.origin.id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function handleCheckCancel() {
    if (!booking) return
    setOpenCancel(false)
    setOpen(true)
  }

  useEffect(() => {
    setIsShowNotification(dayjstz(departure.date).isToday())
  }, [departure.date])

  const isDisplayMap = booking && DisplayMapState.includes(booking.status)

  return loading ? (
    <Loading />
  ) : !booking || !routeType ? (
    <Page404
      description={t('The system is not working properly, please try again.')}
      textButton={t('Try again')}
      onClick={() => navigate(0)}
    />
  ) : (
    <div>
      <ToastContainer position="top-center" hideProgressBar={false} pauseOnFocusLoss draggable pauseOnHover />
      {isShowNotificationCard && userId === booking.bookerLineId && !isShowNotification && (
        <NotificationCard departureData={departure} />
      )}
      <div className="p-3 mb-2 text-black bg-gray-300">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3>{t('Reservaion Information')}</h3>

          <div className="absolute flex items-center right-3">
            {' '}

            {bookingStatus === BookingStatusCNMI.CANCELED ? (
              <div className="font-semibold text-red-500" style={{ fontSize: '0.7rem' }}>
                {t('Status.Canceled')}
              </div>
            ) : bookingStatus === BookingStatusCNMI.COMPLETED ? (
              <div className="font-semibold text-green-400" style={{ fontSize: '0.7rem' }}>
                {t('Status.Completed')}
              </div>
            ) : bookingStatus === BookingStatusCNMI.WAIT_CONFIRM_CAR ? (
              <div className="font-semibold text-orange-500" style={{ fontSize: '0.7rem' }}>
                {t('Wait Confirm Car')}
              </div>
            ) : bookingStatus === BookingStatusCNMI.WAIT_PAYMENT ? (
              <div className="font-semibold text-orange-500" style={{ fontSize: '0.7rem' }}>
                {t('Wait Payment')}
              </div>
            ) : bookingStatus === BookingStatusCNMI.WAIT ? (
              <div className="font-semibold text-blue-500" style={{ fontSize: '0.7rem' }}>
                {t('Status.Wait')}
              </div>
            ) : bookingStatus === BookingStatusCNMI.IN_PROGRESS ? (
              <div className="font-semibold text-blue-500" style={{ fontSize: '0.7rem' }}>
                {t('Status.In progress')}
              </div>
            ) : (
              <></>
            )}

            &nbsp;{getStatusIcon()}
          </div>
        </div>
      </div>

      {isDisplayMap ? (
        <>
          {!onProcess
            ? routeGo && routeType && <Map isView key={routeGo.origin.id} height={'60vh'} />
            : routeGo &&
            driverLocation && (
              <Tracking
                key={dayjstz(driverLocation.time).valueOf()}
                height={'60vh'}
                destination={handleTracking()}
                driverLocation={{
                  lat: parseFloat(driverLocation.lat ?? '0'),
                  lng: parseFloat(driverLocation.lng ?? '0'),
                }}
                state={booking.status}
              />
            )}
          {location.routeGo.origin.id && (
            <Duration
              departure={departure}
              bookingStatus={booking.status}
              vehicleState={booking.status}
              duration={driverLocation?.duration}
            />
          )}
        </>
      ) : null}

      {routeType.isOneWay
        ? routeGo && <RouteCard routeGo={routeGo} readonly={true} />
        : routeGo && <RouteCard routeBack={routeBack} routeGo={routeGo} readonly={true} />}
      <div className="bg-gray-200 gap-y-1">
        <ShowScheduleCNMI
          routeType={routeType}
          date={departure.date}
          departureTime={departure.goTime.departure}
          arrivalTime={departure.goTime.arrival}
          departureTimeTwo={departure.backTime?.departure ?? { hour: 0, minute: 0 }}
          arrivalTimeTwo={departure.backTime?.arrival ?? { hour: 0, minute: 0 }}
          tripType={tripType}
          showTime={isShowDateCar}
        />
        {isShowDateCar && (
          <>
            <div className="pt-2 bg-gray-200" />
            <DriverCard driver={driver} />
          </>
        )}
        <ViewShowCarCard
          booking={booking}
          departure={departure}
          isShowDateCar={isShowDateCar}
          onChange={() => { }}
          onEdit={() => {
            setEdit({ ...edit, byView: true })
            navigate(BookingRoute.Departure)
          }}
        ></ViewShowCarCard>
        <ShowInfoCNMI
          booker={customer.booker}
          passenger={customer.passenger}
          emergencyContact={customer.emergencyContact}
          note={customer.note}
          onEdit={() => {
            setEdit({ ...edit, byView: true })
            navigate(BookingRoute.Customer)
          }}
          isEdit={isShowIconEdit}
          booking={booking}
        />
        <ModalCard
          title={t('Are you sure to cancel booking?')}
          detail={t(
            'Your booking information will be deleted after cancel booking. If you want to edit please booking again.'
          )}
          status={open}
          duoButton={{
            left: { name: t('Yes'), type: 'confirm', style: 'btn-danger' },
            right: { name: t('No'), type: 'cancel', style: 'btn-outline-primary' },
          }}
          onClick={(val) => (val ? (cancel(), setOpen(false)) : setOpen(val))}
          haveCheck
        />
        <ModalCard
          title={t('Reservation cannot be canceled.')}
          detail={t('You must cancel your booking at least 3 hours before the departure time.')}
          status={openCancel}
          singleButton={{ name: t('Close'), type: 'cancel', style: 'btn-primary' }}
          onClick={(val) => setOpenCancel(val)}
        />
        <ModalCard
          title={t('Are you want to contact customer service ?')}
          detail={''}
          status={openContact}
          duoButton={{
            left: { name: t('No'), type: 'cancel', style: 'btn-outline-primary' },
            right: { name: t('Yes'), type: 'confirm', style: 'btn-primary' },
          }}
          onClick={(val) => {
            setOpenContact(false)
            if (val) {
              window.open(`tel:${staff?.phone}`)
            }
          }}
        />
        <ModalCard
          selectLanguage={true}
          loading={false}
          title={t('Send notification to passenger')}
          detail={t('Please select a passenger contact for notification.')}
          status={openShareTargetPicker}
          singleButton={{ name: t('Select passenger'), type: 'cancel', style: 'btn-primary' }}
          onClick={async () => {
            await updateCustomer({
              phone: formatPhoneNumber(booking.bookerPhone),
              language: passengerLanguage,
            })
            if (booking) {
              await shareTargetPicker(
                booking.bookingNumber,
                booking.type,
                booking.bookerName,
                passengerLanguage,
                domain
              )
            }
            setOpenShareTargetPicker(false)
          }}
          languageChange={(val) => {
            setPassengerLanguage(val)
          }}
        />
        {!isHistory && (
          <>
            <div className="h-5"></div>
            <div className="border-b border-gray-300">
              <SingleButtonCard
                style={`btn btn-primary `}
                //disabled={isReserveButtonClick === true}
                onClick={() => setOpenShareTargetPicker(true)}>
                {/* style= "btn btn-primary"
                  onClick={submit}> */}
                {t('Send notification to passenger')}
              </SingleButtonCard>
            </div>
            <DoubleButtonCard
              vertical
              shuffle
              style="btn-outline-danger"
              textCancel={t('Cancel booking')}
              textConfirm={t('Contact customer service')}
              onCancel={() => handleCheckCancel()}
              onConfirm={() => contactCustomer()}
              isHideCancelButton={!isShowIconEdit}
            />
          </>
        )}
      </div>
    </div>
  )
}
