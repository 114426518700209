import { Dialog } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/Button/Base'
import Header from 'src/components/Header'
// import i18n from 'src/i18n'

type Props = {
  visible: boolean
  onCancel: () => void
  onOk: () => void
}

export default function ModalTermConditionCNMISignup({ visible, onCancel, onOk }: Props) {
  const { t } = useTranslation()

  return (
    <Dialog open={visible} fullWidth>
      <div>
        <Header icon={'null'} title={t('Terms and Conditions')} reload={true} />
        <iframe
          // src={`${process.env.REACT_APP_AWS_S3_PUBLIC_URL}/consent/cnmi/consent_${i18n.language}.html`}
          src={`${process.env.REACT_APP_AWS_S3_PUBLIC_URL}/consent/cnmi/consent_th.html`}
          height="100%"
          width="100%"
          className=" bg-[#ebf4fe] h-[70vh]"
        />
        <div className="flex justify-between row p-5">
          <Button type="btn btn-outline-danger w-2/5" onClick={() => onCancel()}>
            {t('Reject')}
          </Button>
          <Button type="btn btn-primary w-2/5" onClick={() => onOk()}>
            {t('Accept')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
