import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cutOffTimeHourInit, cutOffTimeMinuteInit } from 'src/consts'
import { useCallApi } from 'src/hooks/useCallApi'
import logo from '../../assets/icons/notification.svg'
import { DepartureState } from 'src/states'
import { dayjstz } from 'src/helpers/datetime'
import i18n from 'src/i18n'
import { language } from 'src/i18n/langs'
  /** 
  * NotificationCard
  * @params departureData : Get date to show on notification card
  * 
  * */
type Props = { departureData: DepartureState }

export default function NotificationCard({ departureData }: Props) {
  const { t } = useTranslation()
  const { getCutOffAdvanceBookingTime } = useCallApi()
  const [cutOffTimeHour, setCutOffTimeHour] = useState(cutOffTimeHourInit)
  const [cutOffTimeMinute, setCutOffTimeMinute] = useState(cutOffTimeMinuteInit)

  useEffect(() => {
    async function fetchCarServiceTime() {
      const { cutOffAdvanceBookingTime } = await getCutOffAdvanceBookingTime()
      let [cutOffHours, cutOffMinute] = ['', '']
      if (cutOffAdvanceBookingTime) {
        ;[cutOffHours, cutOffMinute] = cutOffAdvanceBookingTime.split(':')
      }
      setCutOffTimeHour(cutOffHours == '' ? cutOffTimeHourInit : cutOffHours)
      setCutOffTimeMinute(cutOffMinute == '' ? cutOffTimeMinuteInit : cutOffMinute)
    }
    fetchCarServiceTime()
  }, [getCutOffAdvanceBookingTime])

  const shownTime = useMemo(() => {
    // Add departureData.date for notify customer to edit 1 day before service date
    return dayjstz(departureData.date).locale(i18n.language).subtract(1, 'day')
  }, [i18n.language]) // Add trigger for reset shownTime when language is changed.

  return (
    <div className="block p-4 space-y-2 font-semibold bg-white">
      <div className="flex">
        <div className="flex w-44">
          <img src={logo} />
        </div>
        <div className="text-sm opacity-60">
          <br />
          {t('You can edit/cancel your booking before ')}
          {cutOffTimeHour}:{cutOffTimeMinute}
          {t('on ')}
          {i18n.language === language.TH
            ? shownTime.format('DD MMM BBBB')
            : shownTime.format('MMM, DD YYYY')}
          {t(
            ' (1 day before the departure date).\n If you want to edit/cancel after that period,\nplease contact customer service.'
          )}
        </div>
      </div>
    </div>
  )
}
