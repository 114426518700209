import { useTranslation } from 'react-i18next'
import { BookingStatus } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import i18n from 'src/i18n'
import { language } from 'src/i18n/langs'
import { Trip } from 'src/types'
import Clock from '../../assets/icons/clock.svg'
import Flag from '../../assets/icons/flag.svg'

type Props = {
  trip?: Trip
  time?: Date
  date: Date
  status?: number
  vehicleId?: number
}
const TripCard: React.FC<Props> = ({ trip, time, date, status, vehicleId }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-row justify-start mb-2">
        <img className="w-5 mr-1" src={Flag}></img>
        <div className="text-sm text-[#3E6ADF]">{trip?.destination.name}</div>
      </div>
      <div className="flex flex-row justify-between mb-2">
        <div className="flex flex-row justify-start">
          <img className="w-5 mr-1" src={Clock}></img>
          <div className="text-sm">
            {`${dayjstz(time).locale('th').format('HH:mm')} • ${dayjstz(date)
              .locale(i18n.language)
              .format(`DD MMMM ${i18n.language === language.TH ? `BBBB` : `YYYY`}`)}`}
          </div>
        </div>
        {status === BookingStatus.IN_PROGRESS ? (
          <div className="text-sm font-semibold text-orange-500">{t('Status.In progress')}</div>
        ) : status === BookingStatus.NEW ? (
          <>
            {vehicleId ? (
              <div className="text-sm font-semibold text-blue-400">{t('Status.Wait')}</div>
            ) : (
              <div className="text-sm font-semibold text-blue-400">{t('Status.Waiting for driver')}</div>
            )}
          </>
        ) : status === BookingStatus.COMPLETED ? (
          <div className="text-sm font-semibold text-green-500">{t('Status.Completed')}</div>
        ) : status === BookingStatus.CANCELED ? (
          <div className="text-sm font-semibold text-red-500">{t('Status.Canceled')}</div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default TripCard
