import liff from '@line/liff/dist/lib'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/Button/Base'
import { RECOIL_PERSIST } from 'src/consts'
import IconFind from '../../assets/icons/icon-find.svg'
import IconSuccess from '../../assets/icons/icon-success.svg'

export default function Success() {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const isRealtimeBooking = queryParams.get('RealtimeBooking')

  useEffect(() => {
    localStorage.removeItem(RECOIL_PERSIST)
  }, [])

  return (
    <>
      <div className="flex flex-col items-center w-full h-screen text-white bg-blue-500">
        <div className="h-40"></div>
        <div className="text-9xl ">
          {isRealtimeBooking ? <img src={IconFind}></img> : <img src={IconSuccess}></img>}
        </div>
        <strong className="py-5">{isRealtimeBooking ? t('Searching for driver') : t('Booking complete')}</strong>
        <p className="w-10/12 py-3 text-center">
          {isRealtimeBooking
            ? t(
                'Check the details of booking via Line again. If undetectable the driver in 15 minutes system is going to cancel your booking.'
              )
            : t('Check the details of booking via Line again.')}
        </p>
      </div>
      <div className="fixed bottom-0 w-full p-5 mb-5 space-y-3">
        <Button type={'btn btn-light block'} onClick={() => liff.closeWindow()}>
          {t('Back to chat')}
        </Button>
      </div>
    </>
  )
}
