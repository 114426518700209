import dayjs from 'dayjs'
import th from 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { t } from 'i18next'
import { BufferedTimeDay, DefaultTimezone } from '../consts'
import { StartStopTime, TimeUnit } from '../types'


dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(buddhistEra)
dayjs.tz.setDefault('Asia/Bangkok')
dayjs.locale(th)

export function dayjstz(d?: string | number | dayjs.Dayjs | Date | null): dayjs.Dayjs {
  return dayjs(d).tz(DefaultTimezone)
}
  /**
   * isRouteTimeValid : Check that return time is valid or not
   * @param date - Date type : Selected date from date card
   * @param departure - TimeUnit type : Departure time of Return trip 
   * @param arrival - TimeUnit type : Arrival time of Return trip
   * @param startStopTime - StartStopTime type : start, end, cutoff time separately
   * @param cutOffTime - const type { cutOffAdvanceBookingTime: string }: Cutoff time
   * @param minTime - [?]Date type : Arrival time of Going trip
   * @param goTimeDeparture - [?]Timeunit type : Departure time of Going trip
   * @param goTimeArrival - [?]Timeunit type : Arrival time of Going trip
   * @param now - [?]Date type : Current date when open app
   * @param hasNoCarOrVehicleActive - boolean type 
   * @returns ฺBoolean : True = Departure time of return trip is valid, can set time to card
   * 
   * @remarks
   * 
   * Using datetime method
   * - dayjstz
   * */
export function isRouteTimeValid(
  date: Date,
  departure: TimeUnit,
  arrival: TimeUnit,
  startStopTime:StartStopTime,
  minTime?: Date,
  goTimeDeparture?: TimeUnit,
  goTimeArrival?: TimeUnit,
  now?: Date,
  hasNoCarOrVehicleActive?: boolean
): boolean {

  /* Check that current day 
  * Return : _now is current date
  */
  const _now = now ? dayjstz(now) : dayjstz()

  /* The departure day must be Date.now() +1 day (If have now value)
  * Target : User must not choose yesterday
  * True = Today (Now) is after selected day
  * False = Today (Now) is before or same with selected day
  * @remarks
  * REVISE_NEED
  */
  const isTodayAfterSelectedDate = hasNoCarOrVehicleActive
    ? _now.hour(0).minute(0).second(0).isSameOrAfter(dayjstz(date))
    : _now.hour(0).minute(0).second(0).isAfter(dayjstz(date))

  // Departure day is today
  const isSelectToday = now ? dayjstz(now).isSame(date, 'day') : dayjstz(date).isToday()
  const isTooLateBooking =
    isSelectToday && _now.hour(departure.hour).minute(departure.minute).second(0).isBefore(dayjstz())
  // Date.now() is After EndHour (over cutoutTimeHour) -> tomorrow can not booking
  const isOverBookingTime = _now.isAfter(dayjstz().hour(parseInt(startStopTime.cutOffTimeHour)).minute(parseInt(startStopTime.cutOffTimeMinute)).second(0))
  // Date.now() is Before +2 departure day
  const isDepartureDate2DaysAfterToday = dayjstz(date)
    .hour(departure.hour)
    .minute(departure.minute)
    .second(0)
    .isBefore(
      _now
        .hour(0)
        .minute(0)
        .second(0)
        .add(BufferedTimeDay + 1, 'day')

        )

  // The departure day must be Date.now() +1 day (If don't have now value)
  if (
    isTodayAfterSelectedDate ||
    (!hasNoCarOrVehicleActive && isSelectToday) ||
    isTooLateBooking ||
    // isOverBookingTime||
    (isOverBookingTime && isDepartureDate2DaysAfterToday)
  ) {
    return false
  }

  // Must be between startAvailable - endAvailable
  if (departure.hour < parseInt(startStopTime.startAvailableHour) 
     || departure.hour > parseInt(startStopTime.endAvailableHour) 
     || (departure.hour === parseInt(startStopTime.endAvailableHour) 
        && departure.minute > parseInt(startStopTime.endAvailableMinute))) {
    return false
  }

  if (departure.hour<parseInt(startStopTime.endAvailableHour) 
     &&  departure.hour === parseInt(startStopTime.startAvailableHour) 
     && departure.minute < parseInt(startStopTime.startAvailableMinute)){
    return false
  }

  // The departure time must be before the arrival time
  // if (arrival.hour < departure.hour || (arrival.hour === departure.hour && arrival.minute <= departure.minute)) {
  //   return false
  // }

  // RoundTrip: The route go time must be before the route back time
  if (minTime && dayjstz(date).hour(departure.hour).minute(departure.minute).second(0).isBefore(dayjstz(minTime))) {
    return false
  }

  // Arrival over booking time goTimeDeparture: route go arrival
  if(goTimeArrival&&goTimeDeparture){
    if (goTimeArrival.hour < goTimeDeparture.hour  ||(goTimeArrival.hour ===  goTimeDeparture.hour  && goTimeArrival.minute <  goTimeDeparture.minute )) {
      return false
    }
  }

  return true
}

export function buildTimestamp(date: Date, hour: number, minute: number): Date {
  return dayjstz(date).hour(hour).minute(minute).toDate()
}

export function getTimeUTC(): { hour: number; minute: number } {
  const t = new Date().toISOString().split('T')[1].split(':')
  return { hour: Number(t[0]), minute: Number(t[1]) }
}

export function getDateCardError(
  departure: TimeUnit,
  isDepartureTimeBeforeOrSameCurrentTime: boolean,
  isCarAvailable: boolean,
  startStopTime: StartStopTime 
) {
  return departure.hour < parseInt(startStopTime.startAvailableHour) 
  || departure.hour > parseInt(startStopTime.endAvailableHour) 
  || (departure.hour === parseInt(startStopTime.endAvailableHour) && departure.minute > parseInt(startStopTime.endAvailableMinute)) 
  || (departure.hour < parseInt(startStopTime.endAvailableHour) &&  departure.hour === parseInt(startStopTime.startAvailableHour) && departure.minute < parseInt(startStopTime.startAvailableMinute))
    ? t('Please select service time ')+startStopTime.startAvailableHour+':'+startStopTime.startAvailableMinute +' - '+startStopTime.endAvailableHour+':'+startStopTime.endAvailableMinute
    : isDepartureTimeBeforeOrSameCurrentTime
    ? t('Time occurs in the past please select new time')
    : !isCarAvailable
    ? t('Sorry, our service is not available on selected time. Please select another time.')
    : t('Invalid time')
}

