import Calendar from '../../assets/icons/calendar.svg'

type Props = {
  description: string
  buttonText?: string          // Button
  onButtonClick?: () => void   // Button
}

  export default function PageEmpty({ description, buttonText, onButtonClick }: Props) {
    return (
      <div className="flex w-full max-w-xl h-24 bg-gray-100 rounded-lg shadow-md shadow-blue-600/20">  {/* rounded-l-lg -> round left side */}
          {/* Left side - Picture */}
          <div className="w-1/4 flex flex-col bg-white rounded-l-lg items-center justify-center">
              <img className="w-auto h-12 mt-2" src={Calendar} alt=""/>
          </div>
          {/* Middle - Details */}
          <div className="flex flex-col ml-4 flex-grow"> {/* Add flex-grow */}
              <p className="font-bold text-gray-800 mt-4" style={{ fontSize: '0.7rem'}}>{description}</p>
          </div>
          {/* Right side - Button */}
          {buttonText && 
            <div className="flex-none flex flex-col justify-end mr-3 mb-3" style={{ fontSize: '0.7rem'}}> 
              <button onClick={onButtonClick} className="bg-blue-500 hover:bg-blue-700 text-white font-notmal py-1 px-3 rounded self-end">
                  {buttonText}
              </button>
            </div>
          }
      </div>
    )
  }