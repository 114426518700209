import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import Header from 'src/components/Header'
import { PAYMENT_METHOD, PAYMENT_STATUS } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BookingStatusNewCNMI, BookingType, RouteType, TripType, VehicleState } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { useCallApi } from 'src/hooks/useCallApi'
import i18n from 'src/i18n'

import {
  bookingCustomerState,
  cnmiCustomerState,
  cnmiPassengerState,
  bookingDepartureState,
  bookingLocationState,
  editState,
  tempBooking,
  pendingBookingState
} from 'src/states'
import { BookingVehicle, Payment, Timeslot } from 'src/types'
import dayjs, { Dayjs } from 'dayjs'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Calendar from 'src/components/Calendar'
import { BsFillTrash3Fill, BsPencilSquare } from 'react-icons/bs'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'
import TimeSlotCard from 'src/pageviews/Booking/TimeSlotCard'
import Modal from 'react-modal'
import Loading from 'src/pageviews/Booking/Loading'
import { currentMonthYearState, languageState } from 'src/states'
import loadingAnimation from 'src/assets/icons/loading.svg'

export interface TimeUnit {
  hour: number
  minute: number
}
export interface trip {
  pickup?: TimeUnit
  arrive?: TimeUnit
}

export interface TimeSlotInterface {
  goingTime: Timeslot | null
  backTime: Timeslot | null
}

interface Row {
  name: string
  phone: number
  lineId: string
  bookingDate: Dayjs
  gotrip?: trip
  backtrip?: trip
  tripType?: string
  tripTime?: trip
}

export default function DepartureCNMI() {
  const [loading, setLoading] = useState(false)
  const { routeGo, routeType } = useRecoilValue(bookingLocationState)
  const { date, backTime, vehicle, baggages, followers, isFree, goPrice, backPrice } =
    useRecoilValue(bookingDepartureState)
  const { emergencyContact, note } = useRecoilValue(bookingCustomerState)
  const [edit] = useRecoilState(editState)
  const setBooking = useSetRecoilState(tempBooking)
  const { createRealtimeBooking, createBooking, getBookings } = useCallApi()
  const isTodayBooking = useMemo(() => dayjstz(date).hour(0).minute(0).isToday(), [date])
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null)
  const currentMonthYear = useRecoilValue(currentMonthYearState)
  const [scheduledDate, setScheduledDate] = useState<(Date | null)[]>([])
  const [bookingStatus, setBookingStatus] = useState<number[]>([])
  const [isLoadingBookings, setIsLoadingBookings] = useState(true)
  const [updateIndex, setUpdateIndex] = useState<number | undefined>(undefined)
  const [cnmiCustomer] = useRecoilState(cnmiCustomerState)
  const [cnmiPassenger] = useRecoilState(cnmiPassengerState)
  const currentLanguage = useRecoilValue(languageState)

  const [isReserveButtonClick, setIsReserveButtonClick] = useState(false);

  const setPendingBookingState = useSetRecoilState(pendingBookingState)

  const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlotInterface>({
    goingTime: null,
    backTime: null,
  })
  const [editTripType, setEditTripType] = useState<string | undefined>(undefined)

  const [rows, setRows] = useState<Row[]>([
    // {
    //   name: 'Frozen yoghurt',
    //   phone: 159,
    //   lineId: '6.0',
    //   bookingDate: dayjstz('2023-11-17'),
    //   gotrip: {
    //     pickup: { hour: 8, minute: 45 },
    //     arrive: { hour: 8, minute: 45 },
    //   },
    //   backtrip: {
    //     pickup: { hour: 10, minute: 20 },
    //     arrive: { hour: 8, minute: 45 },
    //   },
    // },
  ])

  const [medData, setMedData] = useState<{
    appointmentDates?: number[]
  }>({
    appointmentDates: [],
  })

  const { getAppointment } = useCallApi()

  async function fetchGetBookings() {
    try {
      console.log(1)

      // setIsLoadingBookings(true)
      const res = await getBookings({})

      if (!res) {
        setIsLoadingBookings(false)
        return
      }

      /////////////////////////////////// Get booking data of each month ///////////////////////////////////
      // Save Month&Year Keys and their Booking Numbers //
      const monthYearBookings: Record<string, Date[]> = {}

      // Map Booking Numbers of each month //
      res.bookings.forEach((booking) => {
        const date = dayjs(booking.scheduledDate)
        const monthYearKey = `${date.month() + 1}-${date.year()}` // Format: MM-YYYY

        if (!monthYearBookings[monthYearKey]) {
          monthYearBookings[monthYearKey] = []
        }

        monthYearBookings[monthYearKey].push(booking.scheduledDate)
      })

      // Extract schedule date for the current month
      const currentMonthKey = `${currentMonthYear.month() + 1}-${currentMonthYear.year()}`
      const currentMonthScheduleDates = monthYearBookings[currentMonthKey] || []

      setScheduledDate(currentMonthScheduleDates)
      /////////////////////////////////// End: Get booking data of each month ///////////////////////////////////

      //////// Not show booked circle when already cancel it ////////
      const BookingData = res.bookings as BookingVehicle[]
      console.log("Booking Data at BookingCardCalendar page: ", BookingData)
      // Filter Booking of each month //
      const bookingsInCurrentMonth = BookingData.filter(
        (booking) =>
          dayjs(booking.scheduledDate).month() === currentMonthYear.month() &&
          dayjs(booking.scheduledDate).year() === currentMonthYear.year()
      )

      // Set booking status for bookings in the current month
      const bookingStatusInCurrentMonth = bookingsInCurrentMonth.map(booking => booking.status)
      setBookingStatus(bookingStatusInCurrentMonth)
    }
    catch (e) {
      console.log("error")
    }
    finally {
      setIsLoadingBookings(false)
    }
  }

  // useEffect(()=>{
  //   console.log('recoil state: ', cnmiCustomerState)
  // },[])

  useEffect(() => {
    console.log(0)
    fetchGetBookings()
    getAppointment().then((res) => {
      console.log(0.1)
      setMedData(res)
      // setIsLoadingBookings(true)
    })
  }, [currentMonthYear])

  const payments: Payment[] = useMemo(
    () =>
      routeType.isOneWay
        ? [
          {
            routeType: RouteType.GO,
            method: 0,
            amount: goPrice,
            paidStatus: isFree ? PAYMENT_STATUS.NOT_PAID : PAYMENT_STATUS.UNPAID,
          },
        ]
        : [
          {
            routeType: RouteType.GO,
            method: 0,
            amount: goPrice,
            paidStatus: isFree ? PAYMENT_STATUS.NOT_PAID : PAYMENT_STATUS.UNPAID,
          },
          {
            routeType: RouteType.BACK,
            method: 0,
            amount: backPrice ?? 0,
            paidStatus: isFree ? PAYMENT_STATUS.NOT_PAID : PAYMENT_STATUS.UNPAID,
          },
        ],
    [isFree, goPrice, backPrice]
  )

  console.log("Route Go at BookingCardCalendar Page: ", routeGo)

  function prepareBookingCnmi(date: Dayjs, start: TimeUnit, rowtripType?: string): BookingVehicle {
    const formattedVehicleId = dayjstz(date).hour(start.hour).format('YYYYMMDDHH')
    // const dateObject = dayjstz(date);
    // const lastDigitOfYear = dateObject.year() % 10;
    // const formattedVehicleId = `${lastDigitOfYear}${dayjstz(date).hour(start.hour).minute(start.minute).format('MMDDHHmm')}`
    const DateTimeNumber = parseInt(formattedVehicleId, 10)
    const booking: BookingVehicle = {
      bookingNumber: edit.bn ?? '',
      type: dayjstz(date).hour(0).minute(0).isToday() ? BookingType.REAL_TIME : BookingType.NORMAL,
      // tripType: routeType.isOneWay ? TripType.ONE_WAY : TripType.ROUND_TRIP,
      tripType: rowtripType === 'go' ? TripType.GO_TRIP : TripType.BACK_TRIP,
      trip: {
        origin: rowtripType === 'go' ? routeGo.origin : routeGo.destination,
        destination: rowtripType === 'go' ? routeGo.destination : routeGo.origin,
        distance: routeGo.distance || 0,
        duration: routeGo.duration || 0,
        price: goPrice ?? 0,
      },
      trip2: undefined,

      scheduledDate: dayjstz(date).hour(0).minute(0).toDate(),
      // departureTime: dayjstz(date).hour(start.hour).minute(start.minute).toDate(),

      //departureTime: rowtripType ==='go' ? undefined :  dayjstz(date).hour(start.hour).minute(start.minute).toDate(),
      //arrivalTime: rowtripType ==='go' ?  dayjstz(date).hour(start.hour).minute(start.minute).toDate() : undefined ,
      departureTime: dayjstz(date).hour(start.hour).minute(start.minute).second(0).toDate(),
      arrivalTime: dayjstz(date).hour(start.hour).minute(start.minute).second(0).toDate(),
      departureTime2:
        backTime && !routeType.isOneWay
          ? dayjstz(date).hour(backTime.departure.hour).minute(backTime.departure.minute).toDate()
          : undefined,
      arrivalTime2:
        backTime && !routeType.isOneWay
          ? dayjstz(date).hour(backTime.arrival.hour).minute(backTime.arrival.minute).toDate()
          : undefined,
      vehicleId: vehicle ? vehicle.id : DateTimeNumber,
      carModelId: vehicle ? vehicle.carModelId : 1,
      followers,
      baggages,
      note,
      //price: goPrice + (backPrice ?? 0),
      price: goPrice ?? 0,

      status: BookingStatusNewCNMI.PENDING,

      bookerName: cnmiCustomer.name ?? '',
      bookerPhone: cnmiCustomer.phone ?? '',
      bookerLineId: cnmiCustomer.lineId ?? '',
      passengerName: cnmiPassenger.name ?? '',
      passengerPhone: cnmiPassenger.phone ?? '',
      passengerLineId: cnmiCustomer.lineId ?? '',
      emergencyContactName: cnmiCustomer.name ?? '',
      emergencyContactPhone: cnmiCustomer.phone ?? '',
      emergencyContactRelation: emergencyContact.relation || '',
      language: i18n.language,
      carId: 0,
      carModel: '',
      modelId: 0,
      carLicensePlateId: '',
      carColor: '',
      carImageUrl: '',
      driverId: '',
      driverName: '',
      driverPhone: '',
      driverEmail: '',
      driverInfo: { cpr: 0, firstaid: 0 },
      state: VehicleState.PENDING,
      seat: 0,
      payments: payments,
    }
    console.log("booking [Send]: ", booking)
    return booking
  }
  async function submit() {
    console.log("CLICK!!!!")
    setIsReserveButtonClick(true);

    setPendingBookingState(currentValue => currentValue + rows.length)

    for (const row of rows) {
      let bookTime = { hour: 8, minute: 0o0 }

      if (row.backtrip?.pickup !== undefined) {
        bookTime = row.backtrip.pickup
      } else if (row.gotrip?.arrive !== undefined) {
        bookTime = row.gotrip.arrive
      }

      // When no phone number, won't continue to Payment page
      if (cnmiCustomer.phone == undefined) {
        console.log("GOT KICKED!!!!")
        return
      }
      const booking: BookingVehicle = prepareBookingCnmi(row.bookingDate, bookTime, row.tripType)
      try {
        setBooking(booking)
        if (!booking.bookingNumber) {
          const { booking: createdBooking } = isTodayBooking
            ? await createRealtimeBooking({
              ...booking,
              paymentMethod: PAYMENT_METHOD.CASH,
            })
            : await createBooking({
              ...booking,
              paymentMethod: PAYMENT_METHOD.CASH,
            })

          if (!createdBooking) {
            return
          }
          console.log(111)
        }
        console.log("Debug1 isTodayBooking: ", isTodayBooking)
        setLoading(false)
        // navigate(BookingRoute.Payment)
        navigate(BookingRoute.PaymentSummary)
      } catch {
        setLoading(false)
      } finally {
        setPendingBookingState(currentValue => currentValue - 1)
      }
    }
  }

  const OnAddDate = (addDate: Dayjs) => {
    const today = dayjs() // Get today's date
    console.log("Day selected:", addDate.format("DD-MM-YYYY"));

    // Check if 'addDate' is not before today
    if (!addDate.isBefore(today, 'day')) {
      setSelectedDate(addDate)
    }
  }

  // Function to delete a row based on its date
  const onDelete = (dateBooking: Dayjs) => {
    const updatedRows = rows.filter((row) => row.bookingDate !== dateBooking)
    setRows(updatedRows)
  }

  const onEdit = (row: Row, index: number) => {
    setSelectedDate(row.bookingDate)
    setUpdateIndex(index)
    console.log('row.tripType', row.tripType)

    const defaultTime: TimeUnit = { hour: 0, minute: 0 }

    // Set selected time slots for the modal
    const goingTime = row.gotrip ? { isGoing: true, isAvailable: true, time: row.gotrip.arrive ?? defaultTime } : null
    const backTime = row.backtrip
      ? { isGoing: false, isAvailable: true, time: row.backtrip.pickup ?? defaultTime }
      : null

    row.tripTime
    setSelectedTimeSlots({ goingTime, backTime })
    setEditTripType(row.tripType)
  }

  const handleCloseModal = () => {
    setSelectedDate(null)
    setUpdateIndex(undefined)
  }

  const handleConfirmTimeSlot = (newTimeSlot: TimeSlotInterface) => {
    setSelectedTimeSlots({ goingTime: null, backTime: null })
    // // Check if either goingTime or backTime is null
    if (!newTimeSlot.goingTime && !newTimeSlot.backTime) {
      // Handle the case where both times are null (this is just an example, replace it with your logic)
      console.log('Both goingTime and backTime are null')
      return // You may want to return or handle this case accordingly
    }

    const temp = rows
    if (newTimeSlot.goingTime) {
      temp.push({
        name: 'Tonakorn1',
        phone: 159,
        lineId: '6.0',
        bookingDate: selectedDate,
        gotrip: {
          pickup: undefined,
          arrive: newTimeSlot?.goingTime?.time,
        },
        backtrip: {
          pickup: undefined,
          arrive: undefined,
        },
        tripType: 'go',
      } as Row)
    }
    if (newTimeSlot.backTime) {
      temp.push({
        name: 'Tonakorn1',
        phone: 159,
        lineId: '6.0',
        bookingDate: selectedDate,
        gotrip: {
          pickup: undefined,
          arrive: undefined,
        },
        backtrip: {
          pickup: newTimeSlot?.backTime?.time,
          arrive: undefined,
        },
        tripType: 'back',
      } as Row)
    }

    setRows(temp)
    setSelectedDate(null)
  }

  const handleUpdateTimeSlot = (Slot: Timeslot, tripType: TripType, updateIndex: number) => {
    setSelectedTimeSlots({ goingTime: null, backTime: null })
    setEditTripType(undefined)
    // Check if either goingTime or backTime is null
    if (Slot === null) {
      // Handle the case where both times are null (this is just an example, replace it with your logic)
      console.log('Both goingTime and backTime are null')
      return
    }

    const tempRow = rows
    if (tripType === TripType.GO_TRIP) {
      tempRow[updateIndex] = {
        ...tempRow[updateIndex],
        gotrip: {
          pickup: undefined,
          arrive: Slot?.time,
        },
        backtrip: undefined,
      }
    } else {
      tempRow[updateIndex] = {
        ...tempRow[updateIndex],
        gotrip: undefined,
        backtrip: {
          pickup: Slot?.time,
          arrive: undefined,
        },
      }
    }
    setRows(tempRow)
    setSelectedDate(null)
  }

  return loading ? (
    <div className="h-screen bg-powderblue">
      <Loading />
    </div>
  ) : (
    <div>
      <Header
        icon="back"
        title={t('Choose departure date')}
        reload={true}
        onBack={() => {
          navigate(BookingRoute.AddressAndPriceCNMI)
          // navigate(BookingRoute.PaymentSummary)
        }}
      ></Header>
      <div className="h-screen min-h-screen p-2 bg-gray-300">
        <div className="p-1 text-lg font-bold bg-slate-300"> {t('Choose date-time')} </div>
        <div className="flex flex-col justify-between max-h-screen bg-white shadow-md">
          <Modal isOpen={!!selectedDate} onRequestClose={handleCloseModal} contentLabel="Time Slot Modal">
            <div className="flex justify-end">
              <button className="close-button" onClick={handleCloseModal}>
                &times;
              </button>
            </div>
            {selectedDate && (
              <TimeSlotCard
                date={selectedDate}
                updateIndex={updateIndex}
                onClose={handleCloseModal}
                onConfirm={handleConfirmTimeSlot}
                onUpdate={handleUpdateTimeSlot}
                selectedTimeEdit={selectedTimeSlots}
                editTripType={editTripType}
              />
            )}
          </Modal>
          {/* <div className="grid divide-y md:divide-y-8"> */}
          <div className="">
            <div className="">
              {/* <!-- Cart Icon --> */}
              <div className="flex items-center justify-center p-2 font-bold text-black">
                <span>📅 {t('Car Booking Date')}</span>
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    //height: '100vh',
                  }}
                ></div>
                <div className="flex items-center justify-center overflow-hidden font-sans bg-gray-300 ">
                  <div className="w-full lg:w-1/2">
                    <div className="my-6 bg-white rounded shadow-md">
                      {isLoadingBookings ? (
                        <div className="flex items-center justify-center pt-10 pb-10">
                          <img src={loadingAnimation} width={'41px'} height={'41px'} alt="Loading" />
                        </div>
                      ) : (
                        medData !== undefined && (
                          <Calendar
                            key={`${currentMonthYear.month()}-${currentMonthYear.year()}`}
                            // appointmentDates={medData.appointmentDates}
                            appointmentDates={[]}
                            scheduleddates={scheduledDate}
                            bookingStatus={bookingStatus}
                            onClick={(d) => OnAddDate(d)}
                            bookinglist={false}
                            locale={currentLanguage}
                          />
                        )
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        //height: '100vh',
                      }}
                    >
                      <div className="flex items-center justify-center pb-1 text-gray-400">
                        {rows.length < 1 ? <span> {t('Select date in Calendar')} </span> : <span> </span>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-center overflow-hidden font-sans bg-gray-100 ">
                  <div className="w-full lg:w-full">
                    <div className="my-6 bg-white rounded shadow-md">
                      <div className="flex items-center justify-center p-1 text-gray-400">
                        <span>📋 {t('Reservaion Information')}</span>
                      </div>
                      <TableContainer component={Paper} sx={{
                        '.MuiTableCell-sizeSmall': {
                          padding: '4px 0px',
                        },
                      }}>
                        <Table sx={{ minWidth: 50 }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell className="whitespace-nowrap" align="center" rowSpan={2}> {t('DD MM YY')} </TableCell>
                              <TableCell align="center" className="whitespace-nowrap"> {t('Trip Type')} </TableCell>
                              <TableCell align="center" className="whitespace-nowrap"> {t('Pick-up Time')} </TableCell>
                              <TableCell align="center" className="whitespace-nowrap"> {t('Arrival Time')} </TableCell>
                              <TableCell align="center" className="whitespace-nowrap"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows
                              .sort((a, b) => a.bookingDate.unix() - b.bookingDate.unix()) // Sort rows by bookingDate
                              .map((row, index) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &::last-child th': { border: 0 } }}>
                                  <TableCell align="center" component="th" scope="row" padding="none">
                                    {/* {row.bookingDate.format('DD MMM YYYY').toString()} */}
                                    {i18n.language === 'th'
                                      ? row.bookingDate.locale('th').format('DD MMM YY')
                                      : row.bookingDate.format('DD MMM YY')}
                                  </TableCell>
                                  <TableCell align="center">{row.gotrip?.arrive ? t('Go Trip') : t('Back Trip')}</TableCell>
                                  <TableCell align="center" padding="none">
                                    {row.backtrip?.pickup
                                      ? `${row.backtrip.pickup.hour.toString().padStart(2, '0')} 
                                          : ${row.backtrip.pickup.minute.toString().padStart(2, '0')}`
                                      : t('TBC')}
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    {row.gotrip?.arrive
                                      ? `${row.gotrip.arrive.hour.toString().padStart(2, '0')}
                                          : ${row.gotrip.arrive.minute.toString().padStart(2, '0')}`
                                      : t('TBC')}
                                  </TableCell>
                                  <TableCell align="center" padding="none">
                                    <div className="flex">
                                      <BsPencilSquare className="mr-3" onClick={() => onEdit(row, index)} />
                                      <BsFillTrash3Fill onClick={() => onDelete(row.bookingDate)} />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
                <SingleButtonCard
                  style={`btn btn-primary ${isReserveButtonClick ? 'disabled' : ''}`}
                  disabled={isReserveButtonClick === true}
                  onClick={() => submit()}>
                  {/* style= "btn btn-primary"
                  onClick={submit}> */}
                  {t('Reserve')}
                </SingleButtonCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
