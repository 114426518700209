import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { dayjstz } from 'src/helpers/datetime'
import { useCallApi } from 'src/hooks/useCallApi'
import CarSelection from 'src/pageviews/Booking/CarSelection'
import { DepartureState } from 'src/states'
import { BookingVehicle } from 'src/types'

interface Props {
  booking: BookingVehicle
  departure: DepartureState
  onChange: () => void
  onEdit: () => void
  isShowDateCar: boolean
}

export default function ViewShowCarCard({ booking, departure, onChange, onEdit, isShowDateCar }: Props) {
  const [serviceTime, setServiceTime] = useState({ startCarServiceTime: '01:00', stopCarServiceTime: '23:00' })
  const {
    getStartStopCarServiceTime,
  } = useCallApi()

  useEffect(() => {
    getStartStopCarServiceTime().then(res => {
      setServiceTime(res)
    })
  }, [])
  
  return booking.vehicleId ? (
    <CarSelection
      vehicles={[]}
      serviceTime={serviceTime}
      selectedCar={departure.vehicle?.car}
      goDepartureTime={dayjstz(departure.date)
        .hour(departure.goTime.departure.hour)
        .minute(departure.goTime.departure.minute)
        .second(0)
        .toDate()}
      backDepartureTime={
        departure.backTime
          ? dayjstz(departure.date)
              .hour(departure.backTime?.departure.hour)
              .minute(departure.backTime.departure.minute)
              .second(0)
              .toDate()
          : undefined
      }
      readonly
      onChange={onChange}
      onEdit={onEdit}
      isShowCarNumber={isShowDateCar}
    />
  ) : (
    <>
      <div className="h-2 bg-gray-200" />
      <div className="card">
        <div className="flex justify-between border-b border-gray-200 card-title">
          <h3>{t('Vehicle')}</h3>
        </div>
        <div className="my-3">
          <div className="flex justify-center py-4 border-b">
            <img alt="car" src={booking.carImageUrl}></img>
          </div>
          <div className="flex flex-col px-4 pt-4">
            <div className="font-semibold">{booking.carModel}</div>
          </div>
        </div>
      </div>
    </>
  )
}
