import QuestionChoice from 'src/components/questions/QuestionChoice'
import QuestionChoiceImage from 'src/components/questions/QuestionChoiceImage'
import QuestionVote from 'src/components/questions/QuestionVote'
import { QuestionType, Vote } from 'src/enums'
import i18n from 'src/i18n'
import { FeedbackStatus, TopicQuestion } from 'src/types'

type PropsAnswer = {
  id: number
  like: Vote | null
  note: string | null
  choice: string | null
}

type Props = {
  onClick: ({ id, like, note, choice }: PropsAnswer) => void
  data: TopicQuestion | undefined
  driverImg?: string | undefined
  active: FeedbackStatus[]
}

const FeedBackCard = ({ data, onClick, driverImg, active }: Props) => {
  const handlerAnswer = (answer: PropsAnswer) => {
    onClick(answer)
  }
  return (
    <div className="flex flex-col p-4 border-b-8">
      <div className="flex flex-row justify-between">
        <div className="pb-2 text-base font-bold text-[#000000]">
          {i18n.language === 'th' ? data?.bodyTh : data?.bodyEn}
        </div>
        {driverImg ? <img className="w-6 h-6 rounded-full" src={driverImg}></img> : <></>}
      </div>
      {data?.questions?.data.map((question, index) =>
        question.type === QuestionType.Vote ? (
          <QuestionVote key={index} active={active} question={question} setAnswer={(answer) => handlerAnswer(answer)} />
        ) : question.type === QuestionType.Choice ? (
          <QuestionChoice key={index} question={question} answer={(answer) => handlerAnswer(answer)} />
        ) : question.type === QuestionType.ChoiceImg ? (
          <QuestionChoiceImage key={index} question={question} answer={(answer) => handlerAnswer(answer)} />
        ) : (
          <div key={index}></div>
        )
      )}
    </div>
  )
}
export default FeedBackCard
