import { useTranslation } from 'react-i18next'
import { dayjstz } from 'src/helpers/datetime'
import i18n from 'src/i18n'
import { language } from 'src/i18n/langs'
import { Trip } from 'src/types'
import { CalendarIcon, FlagIcon, ClockIcon } from '@heroicons/react/outline'

type Props = {
  trip?: Trip
  departureTime?: Date
  arrivalTime?: Date
  date: Date
}
const TripCard: React.FC<Props> = ({ trip, departureTime, arrivalTime, date }: Props) => {
  const { t } = useTranslation()

  // Determine whether to show departure or arrival time as TBD
  const isDepartureTBD = trip?.destination.name === "CNMI";
  const departureTimeDisplay = isDepartureTBD ? t('TBC') : dayjstz(departureTime).locale('th').format('HH:mm');
  const arrivalTimeDisplay = !isDepartureTBD ? t('TBC')  : dayjstz(arrivalTime).locale('th').format('HH:mm');

  return (
    <>
      <div className="flex flex-row justify-start mb-2">
        <CalendarIcon className="icon w-5 h-5 mr-1 text-[#3E6ADF]" />  {/* text-[#3E6ADF] */}
        <div className="text-sm text-[#3E6ADF]">   {/* text-[#3E6ADF] */}
          {`${dayjstz(date)
            .locale(i18n.language)
            .format(`DD MMMM ${i18n.language === language.TH ? `BBBB` : `YYYY`}`)}`}
        </div>
      </div>
      <div className="flex flex-row justify-start mb-2">
        <FlagIcon className="icon w-5 h-5 mr-1 text-[#3E6ADF]" />  {/* text-[#3E6ADF] */}
        <div className="text-sm text-[#3E6ADF]">{trip?.destination.name}</div>  {/* text-[#3E6ADF] */}
      </div>
      <div className="flex flex-row justify-start mb-2">
        <ClockIcon className="icon w-5 h-5 mr-1" />  {/* text-[#3E6ADF] */}
        <div className="text-sm">
          <span>{`${t('Departure time')} : `}</span>
          {`${departureTimeDisplay} / `}
          <span>{`${t('Arrival time')} : `}</span>
          {`${arrivalTimeDisplay}`}
        </div>
      </div>
    </>
  )
}

export default TripCard
