import React, { useEffect, useState } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { cnmiCustomerState, lineState } from 'src/states'

import { useLocation, useNavigate } from 'react-router-dom'
import { BookingRoute } from 'src/consts/route'
import { useTranslation } from 'react-i18next'
import Header from 'src/components/Header'
import ModalTermConditionCNMISignup from 'src/components/ModalTermConditionCNMISignup'
//import { PatternFormat } from 'react-number-format'
import { useCallApi } from 'src/hooks/useCallApi'
import loading from 'src/assets/icons/loading.svg'
import { CustomerCNMIRecoilProps } from 'src/types'
import { UserCNMIFetchingStateValue } from 'src/enums'
import { USER_LANGUAGE } from 'src/consts'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import InputPhone from '../../components/Input/PhoneNumber'
import { formatInputPhoneText } from 'src/helpers/utils'

enum FecthingUserStatus {
  FETCHING,
  FOUND,
  NOT_FOUND,
  ADDING,
}

type Props = {
  errorMessage?: string
  isPhoneError?: boolean
  readonly?: boolean
}

export default function SignUpCNMI({ errorMessage, isPhoneError, readonly }: Props) {
  const { getCustomerCNMI, createCustomerCNMI } = useCallApi()
  const navigate = useNavigate()
  const location = useLocation()
  const { pictureUrl, userId } = useRecoilValue(lineState)
  const [customerRecoilState, setCustomerRecoilState] = useRecoilState(cnmiCustomerState)
  const [name, setName] = useState<string>('')
  const [surname, setSurname] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [isFetchingUser, setIsFetchingUser] = useState(FecthingUserStatus.FETCHING)

  const [isOpenConsent, setIsOpenConsent] = useState(false)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [isAgree, setIsAgree] = useState(false)

  const [checkName, setCheckName] = useState(false)
  const [checkSurname, setCheckSurname] = useState(false)
  const [checkPhoneNo, setCheckPhoneNo] = useState(false)

  const [, setLanguage] = useLocalStorage<string>(USER_LANGUAGE)
  const { t } = useTranslation()

  // validate name
  useEffect(() => {
    setCheckName(name !== '')
  }, [name])
  // validate surname
  useEffect(() => {
    setCheckSurname(surname !== '')
  }, [surname])
  // validate phone no
  useEffect(() => {
    setCheckPhoneNo(!phoneNumber.includes('x') && phoneNumber !== '')
  }, [phoneNumber])

  /**
   * Function fetchCustomer : Check whether user is already fetch via recoil, then fetch and set the status of customer.
   * id user's lineID from recoil, use to check the persistence of user in DB.
   */
  async function fetchCustomer() {
    getCustomerCNMI({ lineId: customerRecoilState.lineId }).then((res) => {
      if (res === null) {
        setIsFetchingUser(FecthingUserStatus.NOT_FOUND)
      } else {
        setIsFetchingUser(FecthingUserStatus.FOUND)
        setCustomerRecoilState({ ...res, fetchState: UserCNMIFetchingStateValue.FOUNDED })
      }
    })
  }

  /**
   * Function fetchCustomer : Check whether user is already fetch via recoil, then fetch and set the status of customer.
   * step 1: check if phoneNo already exist in DB
   * step 2: adding new user into DB
   * step 3: check if adding success
   *  step 3.1 if fail: clear form
   * step 4: fetch that new customer
   *  step 4.1 if fail: clear form
   * step 5: save new customer into recoil
   */
  async function signupCustomer() {
    const phoneString = phoneNumber.replaceAll('-', '')
    getCustomerCNMI({ phone: phoneString }).then((res) => {
      // reject and alert if phoneNo. has taken
      if (res !== null) {
        alert(`The phone number ${phoneNumber} has already been taken`)
        setPhoneNumber('')
      } else {
        // perform adding new customer
        setIsFetchingUser(FecthingUserStatus.ADDING)
        createCustomerCNMI({
          name: `${name} ${surname}`,
          phone: phoneString,
          // lineId: 'Tester',
          lineId: userId,
          contacts: [{ phone: phoneString, name: `${name} ${surname}`, relation: '1' }]
        }).then((isSuccess) => {
          // creating not success, clear form
          if (!isSuccess) {
            alert('create user fail')
            setName('')
            setSurname('')
            setPhoneNumber('')
          } else {
            // if success, save that data into recoil
            // should revise API to return created object if success
            getCustomerCNMI({ phone: phoneString }).then((res) => {
              // created but can't find on DB, clear form
              if (res === null) {
                alert('create user fail')
                setName('')
                setSurname('')
                setPhoneNumber('')
              } else {
                // created and save result into recoil
                setCustomerRecoilState({
                  ...res,
                  fetchState: UserCNMIFetchingStateValue.FOUNDED,
                } as CustomerCNMIRecoilProps)
                navigate(BookingRoute.AddressAndPriceCNMI)
              }
            })
          }
        })
      }
    })
  }

  /**
   * useEffect : When opened, fetching the data.
   * @event onMounted When the App loaded.
   * @returns start the data fetching process (with Mock as an option).
   */
  useEffect(() => {
    setLanguage('th');
    console.log('customerRecoilState.fetchState', customerRecoilState.fetchState)
    console.log('customerRecoilState.lineId', customerRecoilState.lineId)
    console.log('location.state.prevUrl', location.state.prevUrl)
    if (
      customerRecoilState.fetchState === UserCNMIFetchingStateValue.FOUNDED &&
      customerRecoilState.lineId !== undefined
    ) {
      // FOUNDED FROM LANDING
      setIsFetchingUser(FecthingUserStatus.FOUND)
      if (location.state.prevUrl === BookingRoute.AddressAndPriceCNMI) {
        navigate(BookingRoute.LandingPageCNMI)
      } else {
        navigate(BookingRoute.AddressAndPriceCNMI)
      }
    } else {
      // NOT SET FOUND FROM LANDING
      fetchCustomer()
    }
  }, [])

  return (
    <>
      <div className="flex flex-col justify-between min-h-screen">
        <div>
          <Header
            icon="back"
            title={t('Signup')}
            reload={true}
            onBack={() => {
              navigate(BookingRoute.LandingPageCNMI)
            }}
          ></Header>
          <div className="flex justify-center">
            <div className="flex flex-col items-center w-full h-full px-10 py-3">
              <div>
                <img src={pictureUrl} alt="" className="object-cover w-24 h-24 mx-auto rounded-full sm:w-48 sm:h-48" />
                {/* If user dat is never fetched, get data from DB */}
                {isFetchingUser === FecthingUserStatus.FETCHING && (
                  <div className="mt-6 sm:mt-10">
                    <div className="flex justify-center">
                      <img src={loading} width={'41px'} height={'41px'} />
                    </div>
                    <p className="mt-3 font-bold sm:mt-6">{t('checking user in database')}</p>
                  </div>
                )}
                {/* If user is fetched, navigate to next page */}
                {isFetchingUser === FecthingUserStatus.FOUND && (
                  <div className="flex flex-col items-center mt-6 sm:mt-10">
                    <p className="mt-3 font-bold sm:mt-6">{t('user founded, navigating to next page')}</p>
                  </div>
                )}
                {/* The data is fetched, but user does not exist on DB */}
                {isFetchingUser === FecthingUserStatus.NOT_FOUND && (
                  <>
                    <div className="mt-4 sm:mt-6">
                      <h2>
                        {t('Name')}:<span className={`text-red-600 ${checkName ? 'hidden' : ''}`}>*</span>
                      </h2>
                      <input
                        className={`bg-sky-50 focus:border-sky-500 focus:ring-sky-500block w-full py-2 pr-3 pl-5 border border-white rounded-md placeholder:text-slate-400 focus:outline-none focus:ring-1 sm:text-sm`}
                        type="text"
                        value={name}
                        placeholder={t('Enter ') + t('Name')}
                        onChange={(v) => setName(v.target.value)}
                      />
                    </div>
                    <div className="mt-2 sm:mt-3">
                      <h2>
                        {t('Surname')}:<span className={`text-red-600 ${checkSurname ? 'hidden' : ''}`}>*</span>
                      </h2>
                      <input
                        className={`bg-sky-50 focus:border-sky-500 focus:ring-sky-500block w-full py-2 pr-3 pl-5 border border-white rounded-md placeholder:text-slate-400 focus:outline-none focus:ring-1 sm:text-sm`}
                        type="text"
                        value={surname}
                        placeholder={t('Enter ') + t('Surname')}
                        required
                        onChange={(v) => setSurname(v.target.value)}
                      />
                    </div>
                    <div className="mt-2 sm:mt-3">
                      <h2>
                        {t('Phone Number')}:<span className={`text-red-600 ${checkPhoneNo ? 'hidden' : ''}`}>*</span>
                      </h2>
                      <InputPhone
                          value={formatInputPhoneText(phoneNumber)}
                          readOnly={readonly}
                          isPhoneError={isPhoneError}
                          isMessageError={errorMessage}
                          onChange={(newValue) => {
                            setPhoneNumber(formatInputPhoneText(newValue));
                          }}
                        />
                    </div>
                    <div className="flex justify-between w-full mt-6 mr-5 sm:mt-12">
                      <div className="flex">
                        <input
                          className="mt-[6px] accent-blue-500"
                          type="checkbox"
                          checked={isAgree}
                          onChange={() => {
                            if (isFirstTime) {
                              setIsOpenConsent(true)
                            } else {
                              setIsAgree(!isAgree)
                            }
                          }}
                        />
                        <p className="ml-2 mr-2 md:ml-4">
                          {t('I have read and accept ')}
                          <span className="underline ">{t('the agreement')}</span>
                        </p>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="white"
                        className="w-6 h-6 p-1 mt-[2px] bg-blue-500 rounded-full cursor-pointer"
                        onClick={() => setIsOpenConsent(true)}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                        />
                      </svg>
                    </div>
                  </>
                )}
              </div>
            </div>
            <ModalTermConditionCNMISignup
              visible={isOpenConsent}
              onOk={() => {
                setIsOpenConsent(false)
                setIsFirstTime(false)
                setIsAgree(true)
              }}
              onCancel={() => {
                setIsOpenConsent(false)
                setIsFirstTime(false)
                setIsAgree(false)
              }}
            />
          </div>
        </div>
        {/* process adding */}
        {isFetchingUser === FecthingUserStatus.ADDING && (
          <div className="flex justify-center mb-4 sm:mb-6">
            <div className="px-4 py-2 w-[340px] font-bold text-white bg-blue-500 rounded disabled:cursor-not-allowed">
              {t('Processing')}...
            </div>
          </div>
        )}
        {/* signup form */}
        {isFetchingUser === FecthingUserStatus.NOT_FOUND && (
          <div className="flex justify-center mb-4 sm:mb-6">
            <button
              className="px-4 py-2 w-[340px] font-bold text-white bg-blue-500 rounded disabled:cursor-not-allowed disabled:bg-gray-400 hover:bg-blue-700"
              disabled={!(checkName && checkSurname && checkPhoneNo) || !isAgree}
              onClick={() => signupCustomer()}
            >
              {t('Submit')}
            </button>
          </div>
        )}
      </div>
    </>
  )
}
