import { ReactI18NextChild, useTranslation } from 'react-i18next'
import { TripType } from 'src/enums'
/* import { Booking } from 'src/types' */
import { Booking, BookingVehicle } from 'src/types'

////////// Add on for Landing page /////////////
import { dayjstz } from 'src/helpers/datetime'
import i18n from 'src/i18n'
import { language } from 'src/i18n/langs'
import { BookingStatusCNMI } from 'src/enums'
import { useEffect, useState } from 'react'

type Props = {
  className?: string
  booking: BookingVehicle
  onClick?: () => void
}

const BookingCard: React.FC<Props> = ({ className, booking, onClick }: Props) => {
  const { t } = useTranslation()
  // const [CNMIConvertedBookingStatus, setCNMIConvertedBookingStatus] = useState<BookingStatusCNMI | undefined>(undefined)
  const [CNMIConvertedBookingStatus, setCNMIConvertedBookingStatus] = useState<BookingStatusCNMI | undefined>()
  const [isLoading, setIsLoading] = useState(true)

  /*
      Status
      1 -> Cancel
      2 -> Complete
      3 -> Wait Car
      4 -> Wait Payment
      5 -> Wait Trip Start
      6 -> In progress
      */
  // ----------------------------------------------------------------------------
  const fetchAndConvertBookingStatus = () => {
    setIsLoading(true)
    if (booking) {
      if (booking.status == 8 || booking.status == 9) {
        setCNMIConvertedBookingStatus(BookingStatusCNMI.CANCELED)
      } else if ('carId' in booking && booking.carId === null) {
        setCNMIConvertedBookingStatus(BookingStatusCNMI.WAIT_CONFIRM_CAR)
      } else if (booking.payments[0].paidStatus != 2) {
        setCNMIConvertedBookingStatus(BookingStatusCNMI.WAIT_PAYMENT)
      } else if (booking.status == 0) {
        setCNMIConvertedBookingStatus(BookingStatusCNMI.WAIT)
      } else if (booking.status > 0 && booking.status < 7) {
        setCNMIConvertedBookingStatus(BookingStatusCNMI.IN_PROGRESS)
      } else if (booking.status == 7) {
        setCNMIConvertedBookingStatus(BookingStatusCNMI.COMPLETED)
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAndConvertBookingStatus()
    // return setCNMIConvertedBookingStatus(undefined)
  }, [booking.bookingNumber])

  const GetStatusIcon = (status: BookingStatusCNMI): string => {
    switch (status) {
      case BookingStatusCNMI.CANCELED:
        return '🛑'
      case BookingStatusCNMI.COMPLETED:
        return '✔️'
      case BookingStatusCNMI.WAIT_CONFIRM_CAR:
        return '⌛'
      case BookingStatusCNMI.WAIT_PAYMENT:
        return '💲'
      case BookingStatusCNMI.WAIT:
        return '💼'
      case BookingStatusCNMI.IN_PROGRESS:
        return '🚐'
      default:
        return ''
    }
  }

  const TextStatusBasedElement = (text: string, icon: ReactI18NextChild): ReactI18NextChild => (
    <div className="flex items-center h-full">
      <div className="mr-3 text-xs font-semibold text-white">{text}</div>
      <div className="p-1 bg-white rounded-lg w-fit">{icon}</div>
    </div>
  )

  const GetBookingStatusSection = (status: BookingStatusCNMI): ReactI18NextChild => {
    switch (status) {
      case BookingStatusCNMI.CANCELED:
        return TextStatusBasedElement(t('Status.Canceled'), GetStatusIcon(status))
      case BookingStatusCNMI.COMPLETED:
        return TextStatusBasedElement(t('Status.Completed'), GetStatusIcon(status))
      case BookingStatusCNMI.WAIT_CONFIRM_CAR:
        return TextStatusBasedElement(t('Wait Confirm Car'), GetStatusIcon(status))
      case BookingStatusCNMI.WAIT_PAYMENT:
        return TextStatusBasedElement(t('Wait Payment'), GetStatusIcon(status))
      case BookingStatusCNMI.WAIT:
        return TextStatusBasedElement(t('Status.Wait'), GetStatusIcon(status))
      case BookingStatusCNMI.IN_PROGRESS:
        return TextStatusBasedElement(t('Status.In progress'), GetStatusIcon(status))
      default:
        return <></>
    }
  }

  const TextScheduleBasedElement = (text1?: string, text2?: string): ReactI18NextChild => {
    return (
      <>
        <span>{`${t(' Departure time')} : `}</span>
        <span className={`text-[15px] font-semibold ${!text1 && 'underline text-red-500'}`}>
          {text1 || t('Status.Wait')}
        </span>
        <span> / </span>
        <span>{`${t('Arrival time')} : `}</span>
        <span className={`text-[15px] font-semibold ${!text2 && 'underline text-red-500'}`}>
          {text2 || t('Status.Wait')}
        </span>
      </>
    )
  }

  const getBookingScheduleSection = (booking: Booking) => {
    if (!booking.driverImageUrl?.includes('default')) {
      return TextScheduleBasedElement(
        dayjstz(booking.departureTime).locale('th').format('HH:mm'),
        dayjstz(booking.arrivalTime).locale('th').format('HH:mm')
      )
    }
    if (booking.tripType === TripType.GO_TRIP) {
      return TextScheduleBasedElement(undefined, dayjstz(booking.arrivalTime).locale('th').format('HH:mm'))
    }
    return TextScheduleBasedElement(dayjstz(booking.arrivalTime).locale('th').format('HH:mm'), undefined)
  }

  function GetDynamicColorFromStatus(status: BookingStatusCNMI): string {
    switch (status) {
      case BookingStatusCNMI.CANCELED:
        return '#ef4444' // red-500
      case BookingStatusCNMI.COMPLETED:
        return '#22c55e' // green-500
      case BookingStatusCNMI.WAIT_CONFIRM_CAR:
      case BookingStatusCNMI.WAIT_PAYMENT:
        return '#f97316' //orange-500
      case BookingStatusCNMI.WAIT:
      case BookingStatusCNMI.IN_PROGRESS:
        return '#3B82F6' // blue-500
      default:
        return 'black'
    }
  }

  /////////////////////////////////// Check value ///////////////////////////////////
  // console.log("Booking Data: ", booking)
  // console.log("Booking Status: ", booking.status)
  // console.log("CNMI Converted Booking Status: ", CNMIConvertedBookingStatus)

  return (
    <>
      {CNMIConvertedBookingStatus !== undefined && !isLoading && (
        <div className={`${className}`}>
          {/* Large Style */}
          <div
            className="hidden w-full max-w-3xl grid-cols-12 rounded-lg shadow-md cursor-pointer shadow-blue-600/20 sm:grid"
            onClick={onClick}
          >
            <div className="grid col-span-9 grid-rows-2">
              <div className="relative bg-white">
                <div
                  className="h-[200%] rounded-r-full translate-y-[-50%]"
                  style={{
                    backgroundColor:
                      CNMIConvertedBookingStatus && GetDynamicColorFromStatus(CNMIConvertedBookingStatus),
                  }}
                />
                <div className="absolute top-0 z-10 flex items-center justify-between w-full px-4 mt-2 bg-transparent">
                  <p className="font-semibold text-white  text-[28px]">
                    {booking.tripType === TripType.GO_TRIP ? t('Go to CNMI') : t('Back Home')}
                  </p>
                  <div className="col-span-2 mt-1 mr-8">
                    <div className="flex">
                      {CNMIConvertedBookingStatus && GetBookingStatusSection(CNMIConvertedBookingStatus)}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex items-center px-4 py-2 bg-white border-r-2 border-white border-dashed item-start"
                style={{
                  borderColor: CNMIConvertedBookingStatus && GetDynamicColorFromStatus(CNMIConvertedBookingStatus),
                }}
              >
                <div className="flex flex-col justify-between">
                  {/* <p className="text-xs text-gray-800">{t('Departure date')} :</p> */}
                  <p className="text-[17px] font-semibold whitespace-nowrap">{booking.passengerName}</p>
                  <p className="text-[17px] font-semibold whitespace-nowrap">
                    {`${dayjstz(booking.scheduledDate)
                      .locale(i18n.language)
                      .format(`DD MMMM ${i18n.language === language.TH ? `BBBB` : `YYYY`}`)}`}
                  </p>
                </div>
                <div className="flex items-center justify-end w-full h-full">
                  <p className="text-xs text-gray-800">
                    <br />
                    <span className="mr-2 text-sky-500">◄</span>
                    {getBookingScheduleSection(booking)}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center col-span-3 p-1 bg-white">
              <div
                className="flex items-center justify-center p-3 text-xl border-4 rounded-full h-[110px] "
                style={{
                  borderColor: CNMIConvertedBookingStatus && GetDynamicColorFromStatus(CNMIConvertedBookingStatus),
                }}
              >
                {booking.tripType === TripType.GO_TRIP ? (
                  <div>
                    🏥<span className="text-3xl ">⇦</span>🚐
                  </div>
                ) : (
                  <div>
                    🏡<span className="text-3xl ">⇦</span>🚐
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* SMALL STYLE */}
          <div
            className="w-full max-w-3xl rounded-lg shadow-md cursor-pointer sm:hidden shadow-blue-600/20"
            onClick={onClick}
          >
            <div className="grid grid-cols-12 ">
              <div className="grid col-span-12 grid-rows-2">
                <div className="relative bg-white">
                  <div
                    className="h-[200%] rounded-r-full translate-y-[-50%]"
                    style={{
                      backgroundColor:
                        CNMIConvertedBookingStatus && GetDynamicColorFromStatus(CNMIConvertedBookingStatus),
                    }}
                  />
                  <div className="absolute top-0 z-10 flex items-center justify-between w-full h-full px-4 bg-transparent">
                    <p className="font-semibold text-white  text-[20px] ">
                      {booking.tripType === TripType.GO_TRIP ? t('Go to CNMI') : t('Back Home')}
                    </p>
                    <div className="col-span-2 py-1 mr-8">
                      <div className="flex">
                        {CNMIConvertedBookingStatus && GetBookingStatusSection(CNMIConvertedBookingStatus)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-start pt-2 bg-white border-r-2 border-white item-start">
                  <div
                    className="flex flex-col justify-between pt-2 pl-4 border-b-2 border-dashed"
                    style={{
                      borderColor: CNMIConvertedBookingStatus && GetDynamicColorFromStatus(CNMIConvertedBookingStatus),
                    }}
                  >
                    {/* <p className="text-xs text-gray-800">{t('Departure date')} :</p> */}
                    <p className="text-[17px] font-semibold whitespace-nowrap">{booking.passengerName}</p>
                    <p className="text-[17px] font-semibold whitespace-nowrap">
                      {`${dayjstz(booking.scheduledDate)
                        .locale(i18n.language)
                        .format(`DD MMMM ${i18n.language === language.TH ? `BBBB` : `YYYY`}`)}`}
                    </p>
                  </div>
                  <div className="flex items-center justify-end w-full pr-2">
                    <div
                      className="flex items-center justify-center px-2 py-1 text-2xl border-4 rounded-full"
                      style={{
                        borderColor:
                          CNMIConvertedBookingStatus && GetDynamicColorFromStatus(CNMIConvertedBookingStatus),
                      }}
                    >
                      {booking.tripType === TripType.GO_TRIP ? <div>🏥⇦🚐</div> : <div>🏡⇦🚐</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end w-full h-full p-1 pr-3 bg-white">
              <p className="text-xs text-gray-800">
                <span className="mr-2 text-sky-500">◄</span>
                {getBookingScheduleSection(booking)}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BookingCard
