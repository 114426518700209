import { ChevronDownIcon } from '@heroicons/react/solid'
import { Collapse } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icons } from 'src/enums'
import { Place } from 'src/types'
import destinationRouteBack from 'src/assets/icons/icon-destination-route-back.svg'
import destinationRouteGo from 'src/assets/icons/icon-destination-route-go.svg'
import originRouteBack from 'src/assets/icons/icon-origin-route-back.svg'
import driginRouteGo from 'src/assets/icons/icon-origin-route-go.svg'
import { otherLocationId } from 'src/consts'
import { useRecoilState } from 'recoil'
import { otherLocationState } from 'src/states'

type Props = {
  options?: Place[]
  onSetOtherPlace: () => void
  onClick: (p: Place) => void
  icon: Icons
  value?: string
  disabled?: boolean
  otherEnd?: string
}

const HospitalListInput: React.FC<Props> = ({
  options,
  onSetOtherPlace,
  onClick: cbFunction,
  icon,
  value,
  disabled,
  otherEnd,
}: Props) => {
  const { t } = useTranslation()
  const [otherLocation, setOtherLocation] = useRecoilState(otherLocationState)

  const [isOpen, setIsOpen] = useState(false)

  const _icon =
    icon === Icons.OriginRouteGo
      ? driginRouteGo
      : icon === Icons.OriginRouteBack
      ? originRouteBack
      : icon === Icons.DestinationRouteGo
      ? destinationRouteGo
      : destinationRouteBack

  const defaultValue = [Icons.OriginRouteGo, Icons.OriginRouteBack].includes(icon)
    ? t('LocationInput.Origin.Where to go ?')
    : t('LocationInput.Destination.Where to go ?')

  const inputClass = [Icons.OriginRouteGo, Icons.OriginRouteBack].includes(icon)
    ? `${disabled ? 'bg-gray-200 focus:ring-0' : 'bg-sky-50 focus:border-sky-500 focus:ring-sky-500'}`
    : `${disabled ? 'bg-gray-200 focus:ring-0' : 'bg-sky-50 focus:border-red-500 focus:ring-red-500'}`

  return (
    <label className="relative block py-2 " onClick={() => setIsOpen(!isOpen)}>
      <div
        className={`${inputClass} w-full flex py-2 justify-center border-0 ${isOpen ? 'rounded-t-md' : 'rounded-md'} cursor-pointer px-2 sm:text-sm`}
      >
        <img src={_icon} />
        <p className={`w-full pl-1 ${value === undefined || value === '' ? 'text-slate-400' : ''}`}>
          {value === undefined || value === '' ? defaultValue : otherLocation.place === undefined ? value : otherLocation.place?.name}
          {/* {value === undefined ? defaultValue : value} */}
        </p>
        <div className="w-6 h-6 p-0 m-0">
          <ChevronDownIcon className={`p-0 m-0 ${isOpen ? '' : 'rotate-180'}`} />
        </div>
      </div>
      <Collapse in={isOpen} className="pt-1 rounded-b-md bg-sky-50">
        <ul className="list-disc list-inside rounded-md">
          {options === undefined ? (
            <>no hospital list</>
          ) : (
            options?.map((h) => {
              return (
                <div className="px-6 rounded cursor-pointer hover:bg-[#99ccf8]" key={h.name}>
                  <li
                    className="bg-transparent rounded"
                    onClick={async () => {
                      if (h.id === otherLocationId) {
                        await onSetOtherPlace()
                      } else if (h.id !== otherEnd) {
                        await setOtherLocation({ place: undefined })
                        await cbFunction(h)
                      }
                    }}
                  >
                    {h.name}
                  </li>
                </div>
              )
            })
          )}
        </ul>
      </Collapse>
    </label>
  )
}
export default HospitalListInput