type Props = {
  children: React.ReactNode
  type: string
  onClick?: () => void
}

const Base: React.FC<Props> = ({ children, type, onClick }: Props) => {
  let disabled = false
  if (type.includes('disable')) {
    disabled = true
  }

  return (
    <>
      {onClick && (
        <button className={type} type="button" onClick={onClick} disabled={disabled}>
          {children}
        </button>
      )}
    </>
  )
}

export default Base
