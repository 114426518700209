import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { Carousel } from 'src/components/Carousel'
import { TripType } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import i18n from 'src/i18n'
import CarCard from 'src/pageviews/Booking/CarCard'
import { bookingDepartureState } from 'src/states'
import { AvailableVehicle, Car, Route, Vehicle } from 'src/types'

type Props = {
  vehicles: AvailableVehicle[]
  tripType?: number
  routeGo?: Route
  routeBack?: Route
  selectedCar?: Car
  goDepartureTime?: Date
  goArrivalTime?: Date
  backDepartureTime?: Date
  backArrivalTime?: Date
  readonly?: boolean
  isEdit?: boolean
  serviceTime: {
    startCarServiceTime: string
    stopCarServiceTime: string
  }
  onChange: (vehicle: Vehicle, price: { go: number; back?: number }) => void
  onEdit?: () => void
  onAvailable?: (isAvailable: boolean) => void
  isShowCarNumber?: boolean
  isCarDisabled?: (status: boolean) => void
}

export default function CarSelection({
  vehicles,
  tripType,
  routeGo,
  routeBack,
  selectedCar,
  goDepartureTime,
  goArrivalTime,
  backDepartureTime,
  backArrivalTime,
  readonly,
  isEdit,
  serviceTime,
  onChange,
  onEdit,
  isShowCarNumber,
  isCarDisabled,
}: Props) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [departure, setDeparture] = useRecoilState(bookingDepartureState)
  const [vehicleModel, setVehicleModel] = useState<AvailableVehicle[]>([])

  const { t } = useTranslation()

  // investigate here
  const vehicleAvailableList = useMemo(() => {
    if (!vehicleModel.length) return
    return vehicleModel.map((v) => {
        const isGoTripNoTimeAvailable = v.availability?.goTripAvailableTime.length === 0
        if (tripType === TripType.ONE_WAY) {
          return isGoTripNoTimeAvailable
        }
        const isBackTripNoTimeAvailable = v.availability?.backTripAvailableTime.length === 0
        return isGoTripNoTimeAvailable && isBackTripNoTimeAvailable
      })
  }, [vehicleModel])

  const carItemList = useMemo(() => {
    return vehicleModel.map((v: AvailableVehicle, index) => {
      return (
        <>
          <div className="absolute top-0 left-0 right-0 z-10 text-center text-white bg-red-800 rounded-t-md">
            {vehicleAvailableList?.[index] ? t('') : t('Fully booked')  }
          </div>
          <CarCard
            carModel={v}
            id={v.id}
            isSelected={selectedIndex === index}
            isDisabled={!v.availability?.isAvailable}
            isNoTimeAvailable={vehicleAvailableList?.[index]}
            onClick={() => {
              setSelectedIndex(index)
              if (v.vehicle) {
                onChange(v.vehicle, v.price)
              } else {
                setDeparture({ ...departure, vehicle: undefined })
              }
            }}
          />
        </>
      )
    })
  }, [
    selectedIndex,
    tripType,
    routeGo,
    routeBack,
    goDepartureTime,
    backDepartureTime,
    goArrivalTime,
    backArrivalTime,
    isShowCarNumber,
    vehicleModel,
  ])

  const ParsedStartServiceTime = useMemo(() => {
    return dayjstz(departure.date)
      .hour(serviceTime !== undefined ? parseInt(serviceTime.startCarServiceTime.split(':')[0]) : 4)
      .minute(serviceTime !== undefined ? parseInt(serviceTime.startCarServiceTime.split(':')[1]) : 0)
  }, [])

  const ParsedStopServiceTime = useMemo(() => {
    return dayjstz(departure.date)
      .hour(serviceTime !== undefined ? parseInt(serviceTime.stopCarServiceTime.split(':')[0]) : 23)
      .minute(serviceTime !== undefined ? parseInt(serviceTime.stopCarServiceTime.split(':')[1]) : 0)
  }, [])

  const availableTimeForBooking = useMemo(() => {
    if (!vehicleModel.length) return
    const _v = vehicleModel[selectedIndex]
    if (!_v || !_v.vehicle || _v.availability?.isAvailable) {
      return <div></div>
    }
    return (
      <div className="pl-4">
        {!_v.availability?.isGoTripAvailable &&
          (_v.availability?.goTripAvailableTime.length ? (
            _v.availability?.goTripAvailableTime.map((available, index) => {
              return (
                <span className="text-red-500" key={`go-available-${index}`}>
                  {index === 0
                    ? tripType === TripType.ROUND_TRIP
                      ? t('Available departure time')
                      : t('Available service time')
                    : t('and')}{' '}
                  {dayjstz(available.earliest).isBefore(ParsedStartServiceTime)
                    ? dayjstz(ParsedStartServiceTime).format('HH:mm')
                    : dayjstz(available.earliest).format('HH:mm')}{' '}
                  -{' '}
                  {dayjstz(available.latest).isAfter(ParsedStopServiceTime)
                    ? dayjstz(ParsedStopServiceTime).format('HH:mm')
                    : dayjstz(available.latest).format('HH:mm')}
                </span>
              )
            })
          ) : (
            <div className="text-red-500" key={`go-available`}>
              {t('No departure time available')}
            </div>
          ))}
        {tripType === TripType.ROUND_TRIP &&
          !_v.availability?.isBackTripAvailable &&
          (_v.availability?.backTripAvailableTime.length ? (
            _v.availability?.backTripAvailableTime.map((available, index) => {
              return (
                <div>
                  <span className="text-red-500" key={`back-available-${index}`}>
                    {index === 0 ? t('Available arrival time') : t('and')} {dayjstz(available.earliest).format('HH:mm')}{' '}
                    - {dayjstz(available.latest).format('HH:mm')}{' '}
                  </span>
                </div>
              )
            })
          ) : (
            <div className="text-red-500" key={`back-available`}>
              {vehicleAvailableList?.[selectedIndex] ? '' : t('No return time available')}
            </div>
          ))}
      </div>
    )
  }, [vehicleModel, selectedIndex, i18n.language])

  useEffect(() => {
    if (vehicles.length === 0) return
    const index = vehicles.findIndex((v) => departure.vehicle?.carModelId === v.vehicle?.carModelId)
    if (departure.vehicle && index !== -1) {
      setVehicleModel([...vehicles.slice(index), ...vehicles.slice(0, index)])
    } else {
      setVehicleModel(vehicles)
    }
  }, [])

  return (
    <>
      <div className="h-2 bg-gray-200" />
      <div className="card">
        <div className="flex justify-between mb-4 border-b border-gray-200 card-title">
          <h3>{t('Vehicle')}</h3>
          {isEdit && (
            <h3 className="underline text-primary" onClick={onEdit}>
              {t('Edit')}
            </h3>
          )}
        </div>
        <div>{availableTimeForBooking}</div>
        {readonly ? (
          selectedCar && (
            <div className="flex justify-center mb-4 " >
              <CarCard car={selectedCar} isForceActive />
            </div>
          )
        ) : (
          <div className="flex justify-center px-2 my-3 space-x-3" key={vehicleModel.length}>
            <Carousel
              items={carItemList}
              onClickIndicator={(index) => {
                if (vehicleModel.length === 0) return
                const v = vehicleModel[index]
                setSelectedIndex(index)
                isCarDisabled?.(v.availability?.isAvailable ? true : false)
                if (v && v.vehicle && v.availability?.isAvailable) {
                  onChange(v.vehicle as Vehicle, v.price)
                } else {
                  setDeparture({ ...departure, vehicle: undefined })
                }
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}
