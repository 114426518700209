import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
// import AddressInput from 'src/components/AddressInput'
// import { NOTE, PLACE_TYPE } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BeforeMapPage, UserCNMIFetchingStateValue } from 'src/enums'
import InputPhone from '../../components/Input/PhoneNumber'
// import { BeforeMapPage, Icons, InputType, UserCNMIFetchingStateValue } from 'src/enums'
import { formatInputPhoneText, resolveAddressName } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import { checkServicePostalCode, getCurrentLocation, useGoogleMaps } from 'src/hooks/useGoogleMaps'
import Loading from 'src/pageviews/Booking/Loading'
// import LocationInput from 'src/pageviews/Booking/LocationInput'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import {
  beforeMapPage,
  bookingCustomerState,
  bookingDepartureState,
  bookingLocationState,
  cnmiCustomerState,
  domainState,
  lineState,
  otherLocationState,
} from 'src/states'
import { Place } from 'src/types'
// import { Place, RouteDetail } from 'src/types'
// import Map from 'src/pageviews/Booking/Map'
import Header from 'src/components/Header'
// import i18n from 'src/i18n'
//import { PatternFormat } from 'react-number-format'

type Props = {
  errorMessage?: string
  isPhoneError?: boolean
  readonly?: boolean
}

export default function AddressCNMI({ errorMessage, isPhoneError, readonly }: Props) {
  const [location, setLocation] = useRecoilState(bookingLocationState)
  const [domain, setDomain] = useRecoilState(domainState)
  const { routeGo, routeType } = location
  const [open, setOpen] = useState(false)
  const [message] = useState('')
  const navigate = useNavigate()
  const { getSupportPostcode, getCarModelPrice, updateCustomerCNMI, getDomain, getCustomerCNMI } = useCallApi()
  const { getGeoLocation } = useGoogleMaps()
  const { t } = useTranslation()
  const [otherLocation, setOtherLocation] = useRecoilState(otherLocationState)
  const [loading, setLoading] = useState(false)
  const [departure, setDeparture] = useRecoilState(bookingDepartureState)
  const [price, setPrice] = useState<number | null>(null)
  const { booker } = useRecoilValue(bookingCustomerState)
  const [customerRecoilState, setCustomerRecoilState] = useRecoilState(cnmiCustomerState)
  const [, setBeforeMapPage] = useRecoilState(beforeMapPage)

  const { pictureUrl, userId } = useRecoilValue(lineState)
  const [cnmiCustomer, setCnmiCustomer] = useRecoilState(cnmiCustomerState)
  const [name, setName] = useState<string>(cnmiCustomer.name ? cnmiCustomer.name : '')
  const [phoneNumber, setPhoneNumber] = useState('')

  //const [checkName, setCheckName] = useState(false)
  //const [checkPhoneNo, setCheckPhoneNo] = useState(false)

  const fetchPrices = async (modelId: number, distance: number) => {
    try {
      const priceGet = await getCarModelPrice(modelId, distance)
      setPrice(await priceGet)
    } catch (error) {
      console.error(`Error fetching price for model ${modelId}:`, error)
    }
  }

  useEffect(() => {
    (async () => {
      fetchPrices(1, (routeGo.distance ?? 0) / 1000)
    })()
  }, [routeGo.distance])
  // useEffect(() => {
  //   setCheckName(name !== '')
  // }, [name])
  // useEffect(() => {
  //   setCheckPhoneNo(!phoneNumber.includes('x') && phoneNumber !== '')
  // }, [phoneNumber])

  useEffect(() => {
    setName(cnmiCustomer.name ? cnmiCustomer.name : '')
    setPhoneNumber(cnmiCustomer.phone ? cnmiCustomer.phone : '')
  }, [cnmiCustomer])

  const CNMIObject: Place = {
    id: 'ChIJIw4ra2haHTERV1ag-0TDBi0',
    name: 'CNMI',
    address: '111 ถนนเลียบคลองส่งน้ำสุวรรณภูมิ ตำบล บางปลา อำเภอบางพลี สมุทรปราการ 10540',
    post_code: '10540',
    lat: '13.527454980593362',
    lng: '100.76080796098233',
    note: '',
  }

  // function onSetNote(note: string, output: number) {
  //   if (output === NOTE.ORIGIN1) {
  //     setLocation({
  //       ...location,
  //       routeGo: { ...routeGo, origin: { ...routeGo.origin, note: note } },
  //     })
  //   } else if (output === NOTE.DESTINATION1) {
  //     setLocation({
  //       ...location,
  //       routeGo: { ...routeGo, destination: { ...routeGo.destination, note: note } },
  //     })
  //   }
  // }

  // async function setPlaceType(placeType: PLACE_TYPE) {
  //   await setLocation({ ...location, routeType: { ...routeType, placeType: placeType } })
  // }

  function removeNonNumeric(str: string) {
    return str.replace(/[^0-9.]/g, '') // This regex will remove any character that is not a digit or a dot
  }

  async function setHomeLocation() {
    let current
    const servicePostalCodes = await getSupportPostcode()
    const userData = await getCustomerCNMI({ lineId: customerRecoilState.lineId })

    const lat = removeNonNumeric(userData?.lat || '')
    const lng = removeNonNumeric(userData?.lng || '')

    console.log(`lat ${lat}`)
    console.log(`lng ${lng}`)

    if (customerRecoilState.lat && customerRecoilState.lng) {
      const latRecoil = removeNonNumeric(customerRecoilState.lat)
      const lngRecoil = removeNonNumeric(customerRecoilState.lng)
      let parseFloatLat
      let parseFloatLng
      if (!isNaN(parseFloat(latRecoil)) && !isNaN(parseFloat(lngRecoil))) {
        parseFloatLat = parseFloat(latRecoil)
        parseFloatLng = parseFloat(lngRecoil)
      } else {
        return
      }
      current = { lat: parseFloatLat, lng: parseFloatLng }
      console.log('RECOIL DATA')
    } else if (!isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng))) {
      current = { lat: parseFloat(lat), lng: parseFloat(lng) }
      console.log('API DATA')
    } else {
      current = await getCurrentLocation()
      console.log('CURRENT LOCATION')
    }

    console.log(current)

    const result = await getGeoLocation({ location: current })
    const { address_components, place_id, formatted_address: _formatted_address } = result
    const { name, formatted_address } = resolveAddressName(address_components, _formatted_address)
    const origin = {
      id: place_id,
      name,
      address: formatted_address,
      lat: current.lat.toString(),
      lng: current.lng.toString(),
    }

    const addrlength = address_components.length - 1

    if (!checkServicePostalCode(address_components[addrlength].long_name ?? '', servicePostalCodes)) {
      return
    }

    console.log(`origin : ${origin}`)

    setLocation({
      ...location,
      routeGo: { ...routeGo, origin: origin, destination: CNMIObject },
    })
  }

  useEffect(() => {
    setLoading(true)
    window.scrollTo(0, 0)
    setOtherLocation(otherLocation)

    if (domain == null || domain == undefined || domain == '') {
      (async () => {
        const dm = await getDomain()
        setDomain(dm)
      })()
    }

    if (!routeGo.destination.id) {
      setLocation({
        ...location,
        routeGo: {
          ...routeGo,
        },
      })
    } else if (routeType.isOneWay) {
      setLocation({
        ...location,
        routeGo: routeGo.origin.id
          ? {
            ...routeGo,
            origin: {
              ...routeGo.origin,
            },
            destination: {
              ...routeGo.destination,
            },
          }
          : {
            ...routeGo,
            origin: {
              ...routeGo.origin,
              disabled: false,
            },
            destination: {
              ...routeGo.destination,
              disabled: false,
            },
          },
      })
    }

    if (!routeGo.origin.id) {
      (async () => {
        setHomeLocation()
      })()
    }
    setLocation({
      ...location,
      routeGo: { ...routeGo, destination: CNMIObject },
    })

    setDeparture({ ...departure })
    // i18n.changeLanguage('th')

    setBeforeMapPage(BeforeMapPage.UserProfileCNMI)

    if (userId == undefined) {
      navigate('/signup', { state: { prevUrl: BookingRoute.LandingPageCNMI } })
    } else {
      setCnmiCustomer({ fetchState: UserCNMIFetchingStateValue.FETCHING })
      getCustomerCNMI({ lineId: userId }).then((res) => {
        if (res == null) {
          setCnmiCustomer({ lineId: userId, fetchState: UserCNMIFetchingStateValue.NOT_FOUND })
        } else {
          setCnmiCustomer({
            lineId: res.lineId ?? userId,
            phone: res.phone,
            name: res.name,
            lat: res.lat,
            lng: res.lng,
            distance: res.distance,
            duration: res.duration,
            fetchState: UserCNMIFetchingStateValue.FOUNDED,
          })
        }
      })
    }

    if (name != '' && phoneNumber != '') {
      console.log('name')
      console.log(name)
      console.log(phoneNumber)
      setLoading(false)
    }
  }, [])

  // function handleSetDistanceDuration(go: RouteDetail) {
  //   setLocation({
  //     ...location,
  //     routeGo: {
  //       ...routeGo,
  //       distance: go.distance,
  //       duration: go.duration,
  //     },
  //   })
  // }
  const queryParams = new URLSearchParams(window.location.search)
  const liffState = queryParams.get('liff.state')

  return liffState && loading ? (
    <div className="h-screen bg-powderblue">
      <Loading />
    </div>
  ) : (
    <div>
      <Header
        icon="back"
        title={t('User profile')}
        reload={true}
        onBack={() => {
          navigate(BookingRoute.LandingPageCNMI, { state: { prevUrl: BookingRoute.UserProfileCNMI } })
        }}
      ></Header>
      <div className="h-screen min-h-screen p-2 overflow-y-auto bg-gray-300">
        <div className="flex flex-col justify-between max-h-screen p-4 bg-white shadow-md">
          <img src={pictureUrl} alt="" className="object-cover w-24 h-24 mx-auto rounded-full sm:w-48 sm:h-48" />
          <div className="mt-4 sm:mt-6">
            <div className="pb-2 text-lg text-black">
              <label className="block text-lg font-light text-gray-700">
                {t('Name - Surname')}:<span className="text-danger">*</span>
              </label>
            </div>
            <div className="pl-4 pr-4">
              <input
                className={`bg-sky-50 focus:border-sky-500 focus:ring-sky-500block w-full py-2 pr-3 pl-5 border border-white rounded-md placeholder:text-slate-400 focus:outline-none focus:ring-1 sm:text-sm`}
                type="text"
                value={name}
                placeholder={t('Enter ') + t('Name')}
                onChange={(v) => setName(v.target.value)}
              />
            </div>
          </div>
          <div className="mt-2 sm:mt-3">
            <label className="block text-lg font-light text-gray-700">{t('Phone Number')}:<span className="text-danger">*</span></label>
            <div className="pl-4 mr-4 ">
              <InputPhone
                value={formatInputPhoneText(phoneNumber)}
                readOnly={readonly}
                isPhoneError={isPhoneError}
                isMessageError={errorMessage}
                onChange={(newValue) => {
                  setPhoneNumber(formatInputPhoneText(newValue));
                }}
              />
            </div>
          </div>
          <button
            className={`font-bold mt-5 mb-1 py-2 px-4 rounded bg-blue-500 hover:bg-blue-700 text-white`}
            onClick={async () => {
              setDeparture({ ...departure, goPrice: price || 0 })
              console.log(`lineId:${customerRecoilState.lineId}`)
              console.log(`booker.phone:${booker.phone}`)
              console.log(`lat:${routeGo.origin.lat}`)
              console.log(`lng:${routeGo.origin.lng}`)
              console.log(`distance:${routeGo.distance}`)
              console.log(`duration:${routeGo.duration}`)

              if (customerRecoilState.lat != routeGo.origin.lat || customerRecoilState.lng != routeGo.origin.lng) {
                console.log('SET NEW USER PROFILE')
                setCustomerRecoilState({
                  ...customerRecoilState,
                  name: name,
                  phone: phoneNumber,
                  // lat: routeGo.origin.lat,
                  // lng: routeGo.origin.lng,
                  // distance: routeGo.distance,
                  // duration: routeGo.duration,
                })
                updateCustomerCNMI({
                  lineId: customerRecoilState.lineId,
                  name: name,
                  phone: phoneNumber,
                  // lat: routeGo.origin.lat,
                  // lng: routeGo.origin.lng,
                  // distance: routeGo.distance,
                  // duration: routeGo.duration,
                }).then(() => {
                  navigate(BookingRoute.LandingPageCNMI)
                })
              } else {
                navigate(BookingRoute.LandingPageCNMI)
              }
            }}
          >
            {t('ยืนยันการแก้ไขข้อมูล')}
          </button>
        </div>

        <ModalCard
          title={t('Warning')}
          detail={message}
          status={open}
          singleButton={{ name: t('OK'), style: 'btn-primary', type: 'cancel' }}
          onClick={(s) => setOpen(s)}
        />
      </div>
    </div>
  )
}
