import liff from '@line/liff/dist/lib'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/Button/Base'
import { RECOIL_PERSIST } from 'src/consts'
import IconDatePicker from '../../assets/icons/icon-warning.svg'

export default function Canceled() {
  const { t } = useTranslation()

  useEffect(() => {
    localStorage.removeItem(RECOIL_PERSIST)
  }, [])

  return (
    <>
      <div className="flex flex-col items-center w-full h-screen text-white bg-blue-500">
        <div className="h-60"></div>
        <img className="w-30" src={IconDatePicker}></img>
        <strong className="py-5">{t('Your booking has been canceled.')}</strong>
        <p className="py-3 text-center">{t('Please make a new booking for your new trips.')}</p>
      </div>
      <div className="fixed bottom-0 w-full p-5 mb-5 space-y-3">
        <Button type={'btn btn-light block'} onClick={() => liff.closeWindow()}>
          {t('Back to chat')}
        </Button>
      </div>
    </>
  )
}
