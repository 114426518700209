import { Place } from 'src/types'

export const ACCESS_TOKEN = 'access_token'
export const RECOIL_PERSIST = 'recoil-persist'
export const USER_LANGUAGE = 'user_language'
export const USER_VISITED = 'user_visited'

// export const IS_REALTIME_ENABLE = true
export const DefaultTimezone = 'Asia/Bangkok'
export const StartHour = 0
export const EndHour = 23
// export const BufferedTimeDay = IS_REALTIME_ENABLE ? 0 : 1
export const BufferedTimeDay = 0
export const BufferedTravelTime = 30
export const BufferedRealTimeMinutes = 15
export const DateFormat = 'YYYY-MM-DD'
export const UserDataTTLHours = 1

export const startAvailableHourInit   = ''
export const startAvailableMinuteInit = ''
export const endAvailableHourInit     = ''
export const endAvailableMinuteInit   = ''
export const cutOffTimeHourInit       = ''
export const cutOffTimeMinuteInit     = ''

export const DEPARTMENT_LIST = [
  { key: '', name: 'Select department' },
  { key: 1, name: 'Physical Medicine and Rehabilitation Clinic' },
  { key: 2, name: 'General Pediatric and Well Baby Clinic' },
  { key: 3, name: 'Sports & Orthopedic Center' },
  { key: 4, name: 'Center for Healthy Aging' },
]

export enum NOTE {
  ORIGIN1 = 1,
  DESTINATION1 = 2,
  ORIGIN2 = 3,
  DESTINATION2 = 4,
}

export enum SWAP {
  ROUTE_GO,
  ROUTE_BACK,
}

export enum PLACE_TYPE {
  ORIGIN1 = 1,
  DESTINATION1 = 2,
  ORIGIN2 = 3,
  DESTINATION2 = 4,
}

export enum PAYMENT_STATUS {
  UNPAID = 1,
  PAID = 2,
  NOT_PAID = 3,
}

export enum PAYMENT_METHOD {
  CASH = 1,
  QR_CODE = 2,
}

export enum ERelation {
  Child = 1,
  Father = 2,
  Mother = 3,
  Sibling = 4,
  Couple = 5,
  Relative = 6,
  Intimate = 7,
  Other = 8,
}

export enum STATUS_CHECKED {
  NOT_CHECK = 0,
  PASSENGER = 1,
  EMERGENCY = 2,
}

export enum CarModel {
  COMMUTER = 'Toyota Commuter',
  SIENTA = 'Toyota Sienta',
}

export enum Baggages {
  Wheelchair = 1,
  Cane = 2,
  Basket = 3,
  Bag = 4,
}

export const BaggagesList = [
  { key: 1, value: 'Wheelchair' },
  { key: 2, value: 'Walker' },
  { key: 3, value: 'BabyBasket' },
  { key: 4, value: 'Luggage' },
]

export const intitialPlace = { id: '', name: '', address: '', lat: '', lng: '', note: '' }

export const intitialType = {
  isOneWay: true,
  placeType: PLACE_TYPE.ORIGIN1,
  showRoute: false,
}

export const intitialRoute = {
  origin: intitialPlace,
  destination: intitialPlace,
}

export const Places: { [key: string]: { [key: string]: Place } } = {
  SamitivejSrinakarinHospital: {
    en: {
      address: '488 Thanon Srinagarindra, Khwaeng Suan Luang, Khet Suan Luang, Krung Thep Maha Nakhon 10250, Thailand',
      id: 'ChIJU9rWFcFhHTERrqff1zZRn-8',
      lat: '13.7457342',
      lng: '100.6432621',
      name: 'Samitivej Srinakarin Hospital',
    },
    th: {
      address: '488 ถนน ศรีนครินทร์ แขวง สวนหลวง แขวงสวนหลวง กรุงเทพมหานคร 10250 ประเทศไทย',
      id: 'ChIJU9rWFcFhHTERrqff1zZRn-8',
      lat: '13.7457342',
      lng: '100.6432621',
      name: 'โรงพยาบาลสมิติเวช ศรีนครินทร์',
    },
  },
}

export function fetchAllData(){

  // let startAvailableHour = 8
  // let startAvailableMinute = 0
  // let endAvailableHour = 18
  // let endAvailableMinute = 0
  // let cutOffTimeHour = 16
  // let cutOffTimeMinute = 0

  // const { getStartStopCarServiceTime ,getCutOffAdvanceBookingTime} = useCallApi()

  
  // getStartStopCarServiceTime().then((res)=>{
  //   startAvailableHour = parseInt(res.startCarServiceTime.slice(0,2))
  //   startAvailableMinute = parseInt(res.startCarServiceTime.slice(-2))
  //   endAvailableHour = parseInt(res.stopCarServiceTime.slice(0,2))
  //   endAvailableMinute = parseInt(res.stopCarServiceTime.slice(-2))
  // })
  // getCutOffAdvanceBookingTime().then((res)=>{
  //   cutOffTimeHour = parseInt(res.cutOffAdvanceBookingTime.slice(0,2))
  //   cutOffTimeMinute = parseInt(res.cutOffAdvanceBookingTime.slice(-2))
  // })
  



  // const [startAvailableHour, setStartAvailableHour] = useState('07')
  // const [startAvailableMinute, setStartAvailableMinute] = useState('30')
  // const [endAvailableHour, setEndAvailableHour] = useState('18')
  // const [endAvailableMinute, setEndAvailableMinute] = useState('30')
  // const [cutOffTimeHour, setCutOffTimeHour] = useState('16')
  // const [cutOffTimeMinute, setCutOffTimeMinute] = useState('30')

  // useEffect(() => {
  //   async function fetchCarServiceTime() {
  //     const { startCarServiceTime, stopCarServiceTime } = await getStartStopCarServiceTime()
  //     const { cutOffAdvanceBookingTime } = await getCutOffAdvanceBookingTime()
  //     const [startHours, startMinutes] = startCarServiceTime.split(":")
  //     const [endHours, endMinutes] = stopCarServiceTime.split(":")
  //     const [cutOffHours,cutOffMinute] = cutOffAdvanceBookingTime.split(":")

  //     setCutOffTimeHour(cutOffHours)
  //     setCutOffTimeMinute(cutOffMinute)
  //     setStartAvailableHour(startHours)
  //     setStartAvailableMinute(startMinutes)
  //     setEndAvailableHour(endHours)
  //     setEndAvailableMinute(endMinutes)
  //   }

  //   fetchCarServiceTime();
  // }, []);


  const startAvailableHour = '8'
  const startAvailableMinute = '0'
  const endAvailableHour = '18'
  const endAvailableMinute = '0'
  const cutOffTimeHour = '16'
  const cutOffTimeMinute = '0'


  return {startAvailableHour,startAvailableMinute,endAvailableHour,endAvailableMinute,cutOffTimeHour,cutOffTimeMinute}
}

export const otherLocationId = '000000000000000000000000000'
