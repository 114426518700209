import { useTranslation } from 'react-i18next'
import Button from 'src/components/Button/Base'

type Props = {
  children: React.ReactNode
  alwayBottom?: boolean
  disabled?: boolean
  style?: string
  onClick: () => void
}

export default function SingleButtonCard({ children, alwayBottom, disabled, style, onClick }: Props) {
  const { t } = useTranslation()

  return (
    <div className={`flex w-full px-4 pt-5 pb-5 bg-white ${alwayBottom && 'fixed bottom-0 z-50'}`}>
      <Button
        type={`btn block ${children === t('Cancel') ? 'btn-outline-danger' : style ? style : 'btn-primary'} ${
          disabled && 'disable'
        }`}
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  )
}
