import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import Button from 'src/components/Button/Base'
import Header from 'src/components/Header'
import { intitialPlace, PLACE_TYPE } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BeforeMapPage, Icons } from 'src/enums'
import LocationInput from 'src/pageviews/Booking/LocationInput'
import MiniMap from 'src/pageviews/Booking/Map'
import {
  beforeMapPage,
  bookingLocationSearchState,
  bookingLocationState,
  domainState,
  hospitalOnBotState,
  otherLocationState,
} from 'src/states'
import { Place } from 'src/types'
import { useCallApi } from 'src/hooks/useCallApi'
import { checkServicePostalCode } from 'src/hooks/useGoogleMaps'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import { useLocalStorage } from 'src/hooks/useLocalStorage'

export default function Map() {
  const [location, setLocation] = useRecoilState(bookingLocationState)
  const searchResult = useRecoilValue(bookingLocationSearchState)
  const domain = useRecoilValue(domainState)
  const resetSearchResult = useResetRecoilState(bookingLocationSearchState)
  const { routeGo, routeBack, routeType } = location
  const navigate = useNavigate()
  const [place, setPlace] = useState<Place>(intitialPlace)
  const { t } = useTranslation()
  const [otherLocation, setOtherLocation] = useRecoilState(otherLocationState)
  const [swap, setSwap] = useRecoilState(hospitalOnBotState)
  const { getSupportPostcode, getSupportProvince, getNormalizeProvince } = useCallApi()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [selectedProvince] = useLocalStorage('selected_province')
  const [lastPage] = useRecoilState(beforeMapPage)


  async function handleSelectPlace() {
    if (place.post_code) {
      const servicePostalCodes = await getSupportPostcode()
      if (!checkServicePostalCode(place.post_code, servicePostalCodes)) {
        console.log(' Not support location ')
        setOpen(true)
        setMessage(
          t('The selected location is not in the service area.\nPlease contact customer service for service area.')
        )
        return false
      }
      if (routeType.isOneWay)
        if (routeType.placeType === PLACE_TYPE.ORIGIN1) {
          if (!swap) await setOtherLocation({ place: place })
          await setLocation({
            ...location,
            routeGo: { ...routeGo, origin: place },
            routeBack: { ...routeBack, destination: place },
          })
        } else {
          if (swap) await setOtherLocation({ place: place })
          await setLocation({
            ...location,
            routeGo: { ...routeGo, destination: place },
            routeBack: { ...routeBack, origin: place },
          })
        }
      else {
        if (routeType.placeType === PLACE_TYPE.ORIGIN1) {
          await setLocation({
            ...location,
            routeGo: { ...routeGo, origin: place },
            routeBack: { ...routeBack, destination: { ...place, note: '' } },
          })
        } else if (routeType.placeType === PLACE_TYPE.DESTINATION1) {
          setOtherLocation({ place: place })
          await setLocation({
            ...location,
            routeGo: { ...routeGo, destination: place },
            routeBack: { ...routeBack, origin: { ...place, note: routeBack.origin.note } },
          })
        } else if (routeType.placeType === PLACE_TYPE.ORIGIN2) {
          setOtherLocation({ place: place })
          await setLocation({
            ...location,
            routeBack: { ...routeBack, origin: place },
          })
        } else {
          await setLocation({
            ...location,
            routeBack: { ...routeBack, destination: place },
          })
        }
      }
      return true
    } else if (selectedProvince) {
      console.log(' else if (selectedProvince) ')
      const supportProvince = await getSupportProvince()
      const normalizeProvince = await getNormalizeProvince(String(selectedProvince))
      if (!supportProvince.includes(normalizeProvince)) {
        console.log(' Not support location ')
        setOpen(true)
        setMessage(
          t('The selected location is not in the service area.\nPlease contact customer service for service area.')
        )
        return false
      }

      if (routeType.isOneWay)
        if (routeType.placeType === PLACE_TYPE.ORIGIN1) {
          if (!swap) await setOtherLocation({ place: place })
          await setLocation({
            ...location,
            routeGo: { ...routeGo, origin: place },
            routeBack: { ...routeBack, destination: place },
          })
        } else {
          if (swap) await setOtherLocation({ place: place })
          await setLocation({
            ...location,
            routeGo: { ...routeGo, destination: place },
            routeBack: { ...routeBack, origin: place },
          })
        }
      else {
        if (routeType.placeType === PLACE_TYPE.ORIGIN1) {
          await setLocation({
            ...location,
            routeGo: { ...routeGo, origin: place },
            routeBack: { ...routeBack, destination: { ...place, note: '' } },
          })
        } else if (routeType.placeType === PLACE_TYPE.DESTINATION1) {
          setOtherLocation({ place: place })
          await setLocation({
            ...location,
            routeGo: { ...routeGo, destination: place },
            routeBack: { ...routeBack, origin: { ...place, note: routeBack.origin.note } },
          })
        } else if (routeType.placeType === PLACE_TYPE.ORIGIN2) {
          setOtherLocation({ place: place })
          await setLocation({
            ...location,
            routeBack: { ...routeBack, origin: place },
          })
        } else {
          await setLocation({
            ...location,
            routeBack: { ...routeBack, destination: place },
          })
        }
      }
      return true
    }
    resetSearchResult()
    return false
  }

  async function handleClick() {
    const isPlaceSelected = await handleSelectPlace()
    if (!isPlaceSelected) {
      return
    }
    if (open === false) {
      if (domain === 'cnmi') {
        if (lastPage == BeforeMapPage.UserProfileCNMI){
          navigate(BookingRoute.UserProfileCNMI)
        }
        if (lastPage == BeforeMapPage.AddressAndPriceCNMI){
          navigate(BookingRoute.AddressAndPriceCNMI)
        }
        // navigate(BookingRoute.AddressAndPriceCNMI)
      } else {
        navigate(BookingRoute.Location)
      }
    }
  }

  useEffect(() => {
    if (searchResult.id) {
      setPlace(searchResult)
    } else {
      setPlace(
        routeType.placeType === PLACE_TYPE.ORIGIN1
          ? routeGo.origin
          : routeType.placeType === PLACE_TYPE.DESTINATION1
          ? routeGo.destination
          : routeType.placeType === PLACE_TYPE.ORIGIN2
          ? routeBack.origin
          : routeBack.destination
      )
    }
  }, [routeGo, routeBack, routeType, searchResult])

  useEffect(() => {
    window.scrollTo(0, 0)
    setSwap(swap)
    setOtherLocation(otherLocation)
  }, [])

  useEffect(() => {
    console.log('Open state:', open)
  }, [open])

  return (
    <>
      <div>
        <Header
          icon="back"
          title={t('Route')}
          reload={true}
          onBack={() => {
            resetSearchResult()
            if (routeGo.destination.id) setLocation({ ...location, routeType: { ...routeType, showRoute: true } })
            navigate(-1)
          }}
        ></Header>
        <ModalCard
          title={t('Warning')}
          detail={t(message)}
          status={open}
          singleButton={{ name: t('OK'), style: 'btn-primary', type: 'cancel' }}
          onClick={(s) => setOpen(s)}
        />
        <div className="px-3 pb-4">
          {routeType.placeType === PLACE_TYPE.ORIGIN1 && (
            <LocationInput
              onClick={() => navigate(BookingRoute.Search)}
              isReadOnly
              icon={Icons.OriginRouteGo}
              value={place.name}
            />
          )}
          {routeType.placeType === PLACE_TYPE.DESTINATION1 && (
            <LocationInput
              onClick={() => navigate(BookingRoute.Search)}
              isReadOnly
              icon={Icons.DestinationRouteGo}
              value={place.name}
            />
          )}
          {routeType.placeType === PLACE_TYPE.ORIGIN2 && (
            <LocationInput
              onClick={() => navigate(BookingRoute.Search)}
              isReadOnly
              icon={Icons.OriginRouteBack}
              value={place.name}
            />
          )}
          {routeType.placeType === PLACE_TYPE.DESTINATION2 && (
            <LocationInput
              onClick={() => navigate(BookingRoute.Search)}
              isReadOnly
              icon={Icons.DestinationRouteBack}
              value={place.name}
            />
          )}
        </div>
        <MiniMap
          key={routeType.placeType}
          height={'72vh'}
          searchResult={searchResult}
          showFullMap={true}
          onClickSetPlace={setPlace}
        />
      </div>
      <div className="fixed bottom-0 z-50 flex w-full px-4 pt-4 pb-5 bg-white">
        {routeType.placeType === PLACE_TYPE.ORIGIN1 && (
          <Button type={'btn btn-primary block'} onClick={handleClick}>
            {t('Confirm Pick-up')}
          </Button>
        )}
        {routeType.placeType === PLACE_TYPE.DESTINATION1 && (
          <Button type={'btn btn-primary block'} onClick={handleClick}>
            {t('Confirm Destination')}
          </Button>
        )}
        {routeType.placeType === PLACE_TYPE.ORIGIN2 && (
          <Button type={'btn btn-primary block'} onClick={handleClick}>
            {t('Confirm Pick-up')}
          </Button>
        )}
        {routeType.placeType === PLACE_TYPE.DESTINATION2 && (
          <Button type={'btn btn-primary block'} onClick={handleClick}>
            {t('Confirm Destination')}
          </Button>
        )}
      </div>
    </>
  )
}
