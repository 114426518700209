import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import { useSetRecoilState } from 'recoil'
import flagEN from 'src/assets/icons/en-flag.svg'
import flagTH from 'src/assets/icons/th-flag.svg'
import { USER_LANGUAGE } from 'src/consts'
import { useCallApi } from 'src/hooks/useCallApi'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import i18n from 'src/i18n'
import languages, { language } from 'src/i18n/langs'
import { lineState, languageState } from 'src/states'
import { Languages } from 'src/enums'

type Props = {
  title: string
  icon: 'back' | 'exit' | 'null'
  onBack?: () => void
  onExit?: () => void
  titleLeft?: boolean
  reload?: boolean
  bgColor?: string
}

export default function Header({ title, icon, onBack, onExit, titleLeft, reload, bgColor }: Props) {
  const location = useLocation()
  const navigate = useNavigate()

  const { userId } = useRecoilValue(lineState)

  const [flag, setFlag] = useState(i18n.language === language.TH ? flagTH : flagEN)
  const [isShowOptions, setShowOptions] = useState(false)

  const [, setLanguage] = useLocalStorage(USER_LANGUAGE, '')
  const setLanguageState = useSetRecoilState(languageState)
  const { updateCustomer } = useCallApi()

  useEffect(() => {
    setFlag(i18n.language === language.TH ? flagTH : flagEN)
  }, [i18n.language])

  async function handleChangeLanguage(language: string) {
    setLanguage(language)
    localStorage.setItem('userLanguage', language)
    setLanguageState(language as Languages)

    if (userId) {
      await updateCustomer({ lineId: userId, language })
    }

    if (reload) {
      // Reload Google Maps loader
      window.location.href = `${location.pathname}${location.search}`
    } else {
      i18n.changeLanguage(language)
    }
  }

  return (
    <div className={`flex justify-between w-full px-4 py-2 justify-items-center ${bgColor}`}>
      {icon === 'back' ? (
        <div
          className="flex items-center pr-8 cursor-pointer place-self-start"
          onClick={() => (onBack ? onBack() : navigate(-1))}
        >
          <span className="material-icons-outlined">arrow_back_ios</span>
        </div>
      ) : (
        !titleLeft && <div className="px-6"></div>
      )}

      <div className={`${titleLeft && 'text-2xl'} font-semibold`}>{title}</div>

      {icon === 'exit' && (
        <div
          className="items-center cursor-pointer"
          onClick={() => (onExit ? onExit() : navigate('/', { replace: true }))}
        >
          <span className="material-icons-outlined">close</span>
        </div>
      )}

      <div
        key={i18n.language}
        className="right-0 flex items-center px-2 mr-1 border border-gray-400 rounded-lg"
        onClick={() => setShowOptions(!isShowOptions)}
      >
        <img className="rounded-full" src={flag} />
        <span className="text-gray-500 material-icons-outlined">arrow_drop_down</span>
        <span
          className={`absolute z-20 text-5xl text-gray-100 top-5 right-3 material-icons-outlined ${
            isShowOptions ? '' : 'hidden'
          }`}
        >
          arrow_drop_up
        </span>
        <ul
          className={`absolute top-12 -mr-4 right-8 w-fit bg-gray-100 shadow-lg z-10 rounded ${
            isShowOptions ? '' : 'hidden'
          }`}
        >
          {languages
            .filter((lang) => lang.code !== i18n.language)
            .map((lang) => (
              <li key={lang.code} onClick={() => handleChangeLanguage(lang.code)}>
                <div className="flex py-2 pl-1 w-28 place-content-center">
                  <img className="flex-none w-4 h-4 rounded-full place-self-center" src={lang.img} />
                  <div className="px-2">{lang.nativeName}</div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
