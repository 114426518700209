import liff from '@line/liff/dist/lib'
import { decodeJwt } from 'jose'
import { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  ACCESS_TOKEN,
  RECOIL_PERSIST,
  USER_LANGUAGE,
  USER_VISITED,
  UserDataTTLHours,
  intitialPlace,
  intitialType,
} from 'src/consts'
import { dayjstz } from 'src/helpers/datetime'
import { decoratePhoneNumber } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import { useGoogleMaps } from 'src/hooks/useGoogleMaps'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import i18n from 'src/i18n'
import Loading from 'src/pageviews/Booking/Loading'
import {
  bookingCustomerState,
  bookingLocationState,
  customerContacts,
  domainState,
  lineState,
  otherLocationState,
} from 'src/states'

type Props = {
  children: React.ReactNode
}

const Root: React.FC<Props> = ({ children }) => {
  const [, setAccessToken] = useLocalStorage<string>(ACCESS_TOKEN)
  const [currentLanguage, setLanguage] = useLocalStorage<string>(USER_LANGUAGE)
  const [visited, setVisited] = useLocalStorage<number>(USER_VISITED)
  const [, setOtherLocation] = useRecoilState(otherLocationState)
  const [, setLocation] = useRecoilState(bookingLocationState)
  const [localDomain, setLocalDomain] = useLocalStorage('domain_state')
  const [line, setLine] = useRecoilState(lineState)
  const [customer, setCustomer] = useRecoilState(bookingCustomerState)
  const setContacts = useSetRecoilState(customerContacts)
  const [loading, setLoading] = useState(true)
  const [bodyStart, setbodyStart] = useState(false)
  const { signin, getCustomer, getDomain } = useCallApi()
  const [, setDomainState] = useRecoilState(domainState)
  const { initGoogleMapsLoader } = useGoogleMaps()

  const mockMode = async () => {
    // const { pictureUrl } = await liff.getProfile() // For mockup test
    // const TestpicturetUrl = "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"  // For mockup test -> add test user's line profile picture
    // const finalPictureUrl = pictureUrl || TestpicturetUrl     // For mockup test -> use test user's line profile picture if pictureUrl is null

    if (liff.getLanguage() && !currentLanguage) {
      setLanguage(liff.getLanguage().split('-')[0])
    }
    // const jwt = decodeJwt(process.env.REACT_APP_API_ACCESS_TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJ0ZXN0IiwiZG9tYWluIjoic212X2JrayIsImlhdCI6MTcxNDk2OTM5N30.oRQJATHX7lGH7H6v7arvTYg2blA-YwJA2dYPK6Rdfsk')
    const jwt = decodeJwt('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJ0ZXN0IiwiZG9tYWluIjoic212X2JrayIsImlhdCI6MTcxNDk2OTM5N30.oRQJATHX7lGH7H6v7arvTYg2blA-YwJA2dYPK6Rdfsk')
    // setLine({ userId: (jwt?.userId as string) ?? '', displayName: '', pictureUrl:'' }) // Old
    setLine({ userId: (jwt?.userId as string) ?? '', displayName: '', pictureUrl: 'https://www.svgrepo.com/show/350417/user-circle.svg' }) // For mockup test
    const c = await getCustomer({ lineId: line.userId })
    console.log('c : ', c)

    if (c) {
      if (c.language) {
        setLanguage(c.language)
        setbodyStart(true)
      } else {
        // setLanguage('th')
        setbodyStart(true)
      }

      if (c.name) setCustomer({ ...customer, booker: { name: c.name, phone: decoratePhoneNumber(c.phone) } })
      setContacts(c.contacts)
    } else if (c == null) {
      console.log("c is ", c)
      // setLanguage('th')
      setbodyStart(true)
    }
  }

  useEffect(() => {
    if (window.location.hostname !== 'localhost') {
      const urlParams = new URLSearchParams(window.location.search)
      let domain = urlParams.get('domain')

      if (domain !== null) {
        setDomainState(domain)
        setLocalDomain(domain)
      }

      //========== E2E Test ==========//
      const e2eMode = urlParams.get('e2e')
      if (e2eMode) {
        mockMode()
      }
      else {
        //==============================//

        const var_REACT_APP_LINE_LIFF_ID = `REACT_APP_LINE_LIFF_ID_${domain !== null ? domain : localDomain}`
        const var_REACT_APP_LINE_OA_URL = `REACT_APP_LINE_OA_URL_${domain !== null ? domain : localDomain}`

        liff.init({ liffId: process.env[var_REACT_APP_LINE_LIFF_ID] || '' }).then(async () => {
          if (liff.isLoggedIn()) {
            try {
              const { userId, displayName, pictureUrl } = await liff.getProfile()
              // console.log(`picture URL: ${pictureUrl}`)
              // const TestpicturetUrl = "https://www.svgrepo.com/show/350417/user-circle.svg"  // Add test user's line profile picture
              // const finalPictureUrl = pictureUrl || TestpicturetUrl // Use test user's line profile picture if pictureUrl is null
              if (!domain) {
                domain = await getDomain()
              }
              const resultEl = document.querySelector('#result')
              resultEl?.append(String(domain))

              const token = await signin({ userId: userId, domain: String(domain) })
              setAccessToken(token)
              setLine({ userId: userId, displayName, pictureUrl })
              const c = await getCustomer({ lineId: line.userId })

              if (c) {
                if (c.language) {
                  setLanguage(c.language)
                  setbodyStart(true)
                } else {
                  setLanguage('th')
                  setbodyStart(true)
                }

                if (c.name && !customer.booker.name && !customer.booker.phone) {
                  setCustomer({ ...customer, booker: { name: c.name, phone: decoratePhoneNumber(c.phone) } })
                }

                if (c.contacts && c.contacts.length > 0) {
                  setContacts(c.contacts)
                }
              } else {
                console.log(`Message : can not getCustomer then set default`)
                if (currentLanguage) {
                  setbodyStart(true)
                } else {
                  setLanguage('th')
                  setbodyStart(true)
                }
              }

              const { friendFlag } = await liff.getFriendship()
              if (!friendFlag) {
                console.log(`Message : No friend`)
                liff.openWindow({
                  url: `${process.env[var_REACT_APP_LINE_OA_URL]}`,
                  external: false,
                })
              }
            } catch (error) {
              console.log(error)
            }
          } else {
            if (window.location.pathname.replaceAll('/', '') !== 'view' && !liff.isInClient()) {
              liff.login({ redirectUri: window.location.href })
            }
          }
        })
      }
    } else {
      ; (async () => {
        mockMode()
      })()
    }
  }, [line.userId])

  useEffect(() => {
    i18n.changeLanguage(currentLanguage)
  }, [currentLanguage])

  useEffect(() => {
    if (currentLanguage && bodyStart) {
      initGoogleMapsLoader(currentLanguage)
      setLoading(false)
    }
  }, [bodyStart])

  useEffect(() => {
    if (visited && dayjstz().diff(dayjstz(visited), 'hour') > UserDataTTLHours) {
      localStorage.removeItem(RECOIL_PERSIST)
    }
    setVisited(Date.now())

    setOtherLocation({ place: undefined })
    setLocation({
      routeType: intitialType,
      routeGo: { origin: intitialPlace, destination: intitialPlace },
      routeBack: { origin: intitialPlace, destination: intitialPlace },
    })
  }, [])

  return <>{loading ? <Loading /> : children}</>
}

export default Root