import { useTranslation } from 'react-i18next'
import { dayjstz } from 'src/helpers/datetime'
import { padZero } from 'src/helpers/utils'
import i18n from 'src/i18n'
import { language } from 'src/i18n/langs'
import { RouteType, TimeUnit } from 'src/types'

type Props = {
  date: Date
  departureTime: TimeUnit
  arrivalTime: TimeUnit
  departureTimeTwo: TimeUnit
  arrivalTimeTwo: TimeUnit
  routeType: RouteType
  onEdit?: () => void
}

export default function ShowSchedule({
  date,
  departureTime,
  arrivalTime,
  departureTimeTwo,
  arrivalTimeTwo,
  routeType,
  onEdit,
}: Props) {
  const { t } = useTranslation()

  return (
    <div className="pt-2 sm:pt-5">
      <div className="flex flex-col bg-white">
        <div className="flex justify-between px-4 py-6 text-lg font-semibold leading-6 text-gray-900 border-b border-gray-200 sm:px-0">
          <h3>{t('Departure date and time')}</h3>
          {onEdit && (
            <h3 className="underline text-primary" onClick={onEdit}>
              {t('Edit')}
            </h3>
          )}
        </div>
        <div className="py-3 border-b border-gray-200">
          <div className="block px-4 space-y-2 font-semibold bg-white">
            <div className="flex justify-between">
              <div className="opacity-60">{t('Departure date')}</div>
              <div className="text-blue-500">
                {dayjstz(date)
                  .locale(i18n.language)
                  .format(`DD MMMM ${i18n.language === language.TH ? `BBBB` : `YYYY`}`)}
              </div>
            </div>{' '}
            <div className="flex justify-between">
              <div className="opacity-60">{t('Estimate Departure time')}</div>
              <div className="text-blue-500">
                <span>{`${padZero(departureTime.hour)}:${padZero(departureTime.minute)} ${t('oclock')}`}</span>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="opacity-60">{t('Estimate time arrival')}</div>
              <div>{`${padZero(arrivalTime.hour)}:${padZero(arrivalTime.minute)} ${t('oclock')}`}</div>
            </div>
          </div>
        </div>
        {!routeType.isOneWay && (
          <div className="py-3 border-b border-gray-200">
            <div className="block px-4 space-y-2 font-bold bg-white">
              <div>{t('Return time')}</div>
              <div className="flex justify-between">
                <div className="opacity-60">{t('Departure time')}</div>
                <div className="text-blue-500">
                  <span>{`${padZero(departureTimeTwo.hour)}:${padZero(departureTimeTwo.minute)} ${t('oclock')}`}</span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="opacity-60">{t('Estimate time arrival')}</div>
                <div>
                  <span>{`${padZero(arrivalTimeTwo.hour)}:${padZero(arrivalTimeTwo.minute)} ${t('oclock')}`}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
