import * as React from 'react'
import { useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Badge from '@mui/material/Badge'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import { currentMonthYearState } from 'src/states'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { Languages, BookingStatusNewCNMI } from 'src/enums'
import { useRecoilValue } from 'recoil'
import { languageState,cnmiCustomerState } from 'src/states'
import { useState } from 'react'
import 'dayjs/locale/en'
import 'dayjs/locale/th'

type Props = {
    appointmentDates?: number[]
    scheduleddates?: (Date | null)[]
    bookingStatus?: number[]
    bookinglist?: boolean
    locale: Languages
    onClick?: (d: Dayjs) => void
}

type CustomSlotProps = {
    day: Dayjs
    outsideCurrentMonth: boolean

    // Custom parameter type
    appointmentDates: number[]
    scheduleddates?: (Date | null)[]
    bookingStatus?: number[]
    cancelStatusBooking?: Record<string, number[]>
    bookinglist?: boolean
    onSelectDay?: (d: dayjs.Dayjs) => void
}

/**
 * Mimic fetch with abort controller https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 * ⚠️ No IE11 support
 */

function ServerDay(
    props: PickersDayProps<Dayjs> & {
        appointmentDates?: number[]
        scheduleddates?: (Date | null)[]
        bookingStatus?: number[]
        cancelStatusBooking?: Record<string, number[]>
        bookinglist?: boolean
        onSelectDay?: (d: Dayjs) => void
    }
) {
    const {
        appointmentDates = [],
        cancelStatusBooking = {},
        bookinglist,
        day,
        outsideCurrentMonth,
        onSelectDay,
        ...other
    } = props

    /////////////////////////////////// Circle Background condition ////////////////////////////////////
    const isAppointment = !props.outsideCurrentMonth && appointmentDates.indexOf(props.day.date()) >= 0
    const isbooking = props.scheduleddates?.some(date =>
        dayjs(date).format('YYYY-MM-DD') === props.day.format('YYYY-MM-DD')
    ) && cancelStatusBooking[props.day.format('YYYY-MM-DD')]?.[0] !== 0

    // Set color
    let StatusDateColor = ''
    if (isbooking) StatusDateColor = '#B9F2AB' // light green
    else if (isAppointment) StatusDateColor = '#F7F994' // light yellow
    /////////////////////////////////// End: Circle Background condition ////////////////////////////////////

    /////////////////////////////////// Check Date ///////////////////////////////////
    ////////////// Check old day ///////////////
    //const isPastDay = day.isBefore(dayjs(), 'day')
    ////////////// Check N-3 day ///////////////
    const customerRecoilState = useRecoilValue(cnmiCustomerState)
    let advancebookingday = 3
    //Super user check
    //Kinda want to create checksuperuser(lineid) that return true false from backend for security but maybe next time
    if (customerRecoilState.lineId === "U780a6106a1bf09ae268a894913475bd0"){
        advancebookingday = 0
    }

    //const cantBookDay = day.isBefore(dayjs().add(2, 'day'), 'day') || day.isSame(dayjs().add(2, 'day'), 'day')
    const cantBookDay = day.isBefore(dayjs().add(advancebookingday, 'day'), 'day') 
    ////////////// Check today ///////////////
    const isToday = day.isSame(dayjs(), 'day')
    /////////////////////////////////// End: Check Date ///////////////////////////////////

    /////////////////////////////////// Selected Date Setting Part-I ///////////////////////////////////
    const [isSelectedToday, setSelectedToday] = useState(false)
    const [isDefaultToday, setDefaultToday] = useState(true)
    let todayClassName = ''
    let algotodayClassName = ''
    if (isToday) {
        if (isDefaultToday === true) {
            todayClassName = 'current-day'
        }
        algotodayClassName = 'n-2-current-day'
    }
    /////////////////////////////////// End: Selected Date Setting Part-I ///////////////////////////////////

    /////////////////////////////////// Check value ///////////////////////////////////
    //console.log('Date:', day.format('YYYY-MM-DD'), 'isAppointment:', isAppointment)

    /* const scaleFactor = isAppointment ? 0.75 : 1
    const customDayStyle = { fontSize: '1rem' } */

    /* const scaleFactor = 1 / Math.sqrt(2)
    const customDayStyle = { fontSize: '1rem' } */

    return (
        <div
            style={{
                display: 'flex',
                zIndex: '0',
                justifyContent: 'center',
                backgroundColor: StatusDateColor,
                // width: '11%',
                // height: '50%',
                // width: isAppointment ? '8.25%' : '11%', // isAppointment ? 'calc(11% / 1.414)' : '11%'
                // height: isAppointment ? '37.5%' : '50%', // isAppointment ? 'calc(50% / 1.414)' : '50%'
                width: '11%',
                height: '11%',
                // borderRadius: '50%',
                /* // isbooking -> Square, isAppointment -> Diamond, Select/Current -> Circle
                borderRadius: isAppointment ? '0%' : (isbooking ? '0%' : '50%'),
                // transform: isbooking ? 'none' : (isAppointment ? `rotate(45deg) scale(${scaleFactor})` : 'none'),
                transform: isbooking ? 'none' : (isAppointment ? `rotate(45deg)` : 'none'),
                transformOrigin: 'center', */
                // isbooking -> Circle, isAppointment -> Square, Select/Current -> Circle
                borderRadius: isbooking ? '50%' : (isAppointment ? '0%' : '50%'),
                marginLeft: '2px',
                marginRight: '2px',
            }}
            className={!cantBookDay ? 'cursor-pointer' : ''}
            onClick={() => {
                //setCurrentMonthYear(day)

                /////////////////////////////////// Selected Date Setting Part-II ///////////////////////////////////
                const checkSelectToday = day.isSame(dayjs(), 'day')
                setSelectedToday(checkSelectToday)
                setDefaultToday(false)
                if (isToday) {
                    if (isSelectedToday == true) {
                        todayClassName = 'select-current-day'
                    }
                    else {
                        todayClassName = 'current-day'
                    }
                }
                /////////////////////////////////// End: Selected Date Setting Part-II ///////////////////////////////////

                if (bookinglist) {
                    if (onSelectDay) {
                        onSelectDay(day)
                    }
                }
                else {
                    if (onSelectDay && !cantBookDay) {
                        onSelectDay(day)
                    }
                }
            }
            }
        >
            <div
                style={{
                    // transform: isbooking ? 'none' : (isAppointment ? 'rotate(-45deg)' : 'none'),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {bookinglist &&
                    <Badge>
                        <PickersDay
                            {...other}
                            outsideCurrentMonth={outsideCurrentMonth}
                            day={day}
                            className={todayClassName}
                        // style={isAppointment ? customDayStyle : undefined}
                        />
                    </Badge>
                }
                {!bookinglist &&
                    <Badge>
                        <PickersDay
                            {...other}
                            disabled={cantBookDay}
                            outsideCurrentMonth={outsideCurrentMonth}
                            day={day}
                            className={algotodayClassName}
                        // style={isAppointment ? customDayStyle : undefined}
                        />
                    </Badge>
                }
            </div>
        </div >
    )
}

export default function Calendar({
    onClick,
    appointmentDates,
    scheduleddates = [],
    bookingStatus = [],
    bookinglist,
}: Props) {

    const [currentMonthYear, setCurrentMonthYear] = useRecoilState(currentMonthYearState)
    const currentLanguage = useRecoilValue(languageState)
    const { t } = useTranslation()

    /////////////////////////////////// Current Month and Year ///////////////////////////////////
    // Update the current month
    const changeMonthYear = (newMonth: Dayjs) => {
        setCurrentMonthYear(newMonth)
    }

    // Filter scheduleddates for the current month
    const filteredScheduledDates = scheduleddates.filter(date =>
        dayjs(date).month() === currentMonthYear.month() &&
        dayjs(date).year() === currentMonthYear.year()
    )
    /////////////////////////////////// End: Current Month and Year ///////////////////////////////////

    /////////////////////////////////// Not show circle when cancel all booking of that date ///////////////////////////////////
    // New state to store cancel status booking for each date
    const [cancelStatusBooking, setCancelStatusBooking] = useState({})

    // Effect to update cancel status when scheduleddates or bookingStatus change
    useEffect(() => {
        // Function to check cancel status
        const checkCancelStatus = () => {
            const newStatusBooking: Record<string, number[]> = {}
            scheduleddates.forEach((date, index) => {
                if (date) { // Check if date is not null
                    const formattedDate = dayjs(date).format('YYYY-MM-DD')
                    const checkNotCancelBooking = bookingStatus[index] !== BookingStatusNewCNMI.CANCEL ? 1 : 0
                    if (!newStatusBooking[formattedDate] || checkNotCancelBooking > newStatusBooking[formattedDate][0]) {
                        newStatusBooking[formattedDate] = [checkNotCancelBooking]
                    }
                }
            })
            setCancelStatusBooking(newStatusBooking)
        }

        // Call checkCancelStatus function
        if (scheduleddates.length > 0 && bookingStatus.length > 0) {
            checkCancelStatus()
        }
    }, [scheduleddates, bookingStatus])
    /////////////////////////////////// End: Not show circle when cancel all booking of that date ///////////////////////////////////

    /////////////////////////////////// Language setting ///////////////////////////////////
    // Set the locale globally
    dayjs.locale(currentLanguage)

    // Define the dayOfWeekFormatter with explicit types
    const dayOfWeekFormatter = (day: string, date: Dayjs) => {
        return dayjs.locale() === 'th'
            ? `${date.format('dd').replace('.', '')}`    // TH locale
            : `${date.format('ddd')}`                   // EN locale 
    }
    /////////////////////////////////// End: Language setting ///////////////////////////////////



    /////////////////////////////////// Check value ///////////////////////////////////
    /*
    console.log("Current Month [Sent]:", currentMonthYear)
    console.log("Schedult Dates [Receive]: ", scheduleddates)
    console.log('Cancel Status Booking:', cancelStatusBooking)
    console.log("scheduleddates:", scheduleddates)
    console.log("booking Status:", bookingStatus)
    */

    return (
        <>
            {/* Selected Date Style */}
            <style>
                {`
                    .current-day {
                        color: black !important;
                        background-color: transparent !important;
                        border: 1px solid #5B5B5B !important;
                    }
                    .n-2-current-day {
                        color: #626161 !important;
                        background-color: transparent !important;
                        border: 1px solid black !important;
                    }
                    .select-current-day {
                        color: white !important;
                        background-color: #1565c0 !important;
                    }
                `}
            </style>
            {/* End: Selected Date Style */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLanguage}>
                <div className="flex flex-col items-center mt-4">
                    {bookinglist && (
                        <div className="grid grid-cols-2 mt-2">
                            {/*<div style={{ paddingLeft: '20px' }}><span role="img" aria-label="Appointed Date">🟨</span> {t('Appointed Date')}</div> {/* 🟡 */}
                            <div style={{ paddingLeft: '20px' }}><span role="img" aria-label="Booked Date">🟢</span> {t('Booked Date')}</div>
                            <div style={{ paddingLeft: '20px' }}><span role="img" aria-label="Selected Date">🔵</span> {t('Selected Date')}</div>
                        </div>
                    )}
                    {!bookinglist && (
                        <div className="grid grid-cols-2 mt-4">
                            {/*<div style={{ paddingLeft: '20px' }}><span role="img" aria-label="Appointed Date">🟨</span> {t('Appointed Date')}</div> {/* 🟡 */}
                            <div style={{ paddingLeft: '20px' }}><span role="img" aria-label="Booked Date">🟢</span> {t('Booked Date')}</div>
                            <div style={{ paddingLeft: '20px' }}><span role="img" aria-label="Selected Date">🔵</span> {t('Selected Date')}</div>
                        </div>
                    )}
                    <DateCalendar
                        defaultValue={currentMonthYear}
                        // loading={isLoading}
                        onMonthChange={(newValue) => changeMonthYear(newValue)}
                        renderLoading={() => <DayCalendarSkeleton />}
                        slots={{
                            day: ServerDay,
                        }}
                        slotProps={{
                            day: {
                                appointmentDates,
                                scheduleddates: filteredScheduledDates,
                                cancelStatusBooking,
                                bookinglist,
                                onSelectDay: onClick,
                            } as CustomSlotProps,
                        }}
                        dayOfWeekFormatter={dayOfWeekFormatter}
                        views={['year', 'month', 'day']}
                        sx={{
                            //////////// Header of calendar //////////
                            ".MuiPickersCalendarHeader-root": {
                                /* display: "flex",
                                alignItems: "center",
                                justifyItems: "center", */
                                paddingLeft: "12px",
                            },
                            // Left and Right arrow 
                            ".MuiPickersArrowSwitcher-root": {
                                display: "inline-flex"
                            },
                            // Space between left and right arrow 
                            ".MuiPickersArrowSwitcher-spacer": {
                                //width: "300px",
                                // width: "60vw",
                                //flex: 1,
                            },
                            // Margin of both arrow
                            ".css-9reuh9-MuiPickersArrowSwitcher-root": {
                                // backgroundColor: 'blue',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between'
                                // position: 'absolute',
                            },
                            // Targeting the arrow buttons
                            ".MuiPickersArrowSwitcher-button": {
                                // position: 'absolute',
                                // backgroundColor: 'red',
                                //top: '50%',
                                // transform: 'translateY(-50%)',
                            },
                            // Left arrow position
                            // ".MuiPickersArrowSwitcher-button:first-child": {
                            ".MuiPickersArrowSwitcher-button:first-of-type": {
                                //position: 'absolute',
                                //right: '92%',
                                //left: '-2%'
                            },
                            // Right arrow position
                            ".MuiPickersArrowSwitcher-button:last-child": {
                                //position: 'absolute',
                                //right: '8%',
                            },
                            ////////// Month Year Text ////////
                            ".MuiPickersCalendarHeader-label": {
                                textAlign: "center",
                                margin: "0px"
                            },
                            ".css-31ca4x-MuiPickersFadeTransitionGroup-root": {
                                display: "flex",
                                position: "absolute",
                                flexWrap: "nowrap", // Prevents text from wrapping onto the next line.
                                // Month Year position
                                left: '50%',
                                transform: 'translateX(-50%)',
                            },
                            ////////// Switch View Button ////////
                            ".MuiPickersCalendarHeader-switchViewButton": {
                                display: 'none'
                            },
                            //////////// End : Header of calendar //////////
                        }}
                    />
                </div>
            </LocalizationProvider>
        </>
    )
}
