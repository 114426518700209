import liff from '@line/liff/dist/lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import Header from 'src/components/Header'
import ViewShowCarCard from 'src/components/ViewShowCarCard'
import { EndHour, PAYMENT_STATUS, PLACE_TYPE } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BookingStatus, RouteType as RouteTypes, TripType, VehicleState } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { decoratePhoneNumber } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import useInterval from 'src/hooks/useInterval'
import DoubleButtonCard from 'src/pageviews/Booking/DoubleButtonCard'
import DriverCard from 'src/pageviews/Booking/DriverCard'
import Duration from 'src/pageviews/Booking/Duration'
import Loading from 'src/pageviews/Booking/Loading'
import Map from 'src/pageviews/Booking/Map'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import NotificationCard from 'src/pageviews/Booking/NotificationCard'
import Page404 from 'src/pageviews/Booking/Page404'
import RouteCard from 'src/pageviews/Booking/RouteCard'
import ShowBaggageAndPassenger from 'src/pageviews/Booking/ShowBaggageAndPassenger'
import ShowInfo from 'src/pageviews/Booking/ShowInfo'
import ShowSchedule from 'src/pageviews/Booking/ShowSchedule'
import ShowTotal from 'src/pageviews/Booking/ShowTotal'
import Tracking from 'src/pageviews/Booking/Tracking'
import {
  bookingState,
  editState,
  lineState,
  pathState,
  viewCustomerState,
  viewDepartureState,
  viewLocationState,
} from 'src/states'
import { BookingVehicle, Driver, DriverLocation, Route, RouteType, Staff } from 'src/types'

export default function PrivateView() {
  const [open, setOpen] = useState(false)
  const [openContact, setOpenContact] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [booking, setBooking] = useState<BookingVehicle | null>()
  const [routeType, setRouteType] = useState<RouteType | undefined>()
  const [routeGo, setRouteGo] = useState<Route | undefined>()
  const [routeBack, setRouteBack] = useState<Route | undefined>()
  const [staff, setStaff] = useState<Staff | undefined>()
  const [loading, setLoading] = useState(false)
  const [onProcess, setOnProcess] = useState(false)
  const [driverLocation, setDriverLocation] = useState<DriverLocation | undefined>()
  const [driver, setDriver] = useState<Driver | undefined>()
  const [isShowDateCar, setIsShowDateCar] = useState(false)
  const [isShowIconEdit, setIsShowIconEdit] = useState(false)
  const [isShowNotificationCard, setIsShowNotificationCard] = useState(false)
  const [isHistory, setIsHistory] = useState(false)
  const { t } = useTranslation()

  const [customer, setCustomer] = useRecoilState(viewCustomerState)
  const [departure, setDeparture] = useRecoilState(viewDepartureState)
  const [path, setPath] = useRecoilState(pathState)
  const [edit, setEdit] = useRecoilState(editState)
  const [location, setLocation] = useRecoilState(viewLocationState)
  const bookingVehicle = useRecoilValue(bookingState)
  const { userId } = useRecoilValue(lineState)
  const [isShowNotification, setIsShowNotification] = useState(false)

  const { getBooking, cancelBooking, getDriverLocation, getStaff, getDriverById, getDomain } = useCallApi()
  const navigate = useNavigate()
  const defaultLocation = { lat: '13.595549', lng: '100.8770063' }

  const queryParams = new URLSearchParams(window.location.search)
  const bn = queryParams.get('bn')

  const checkViewState = () => {
    if (!path.fromMenu && bn) {
      navigate(`/view/?bn=${bn}`)
    } else if (path.fromMenu && path.prvePath) {
      navigate(path.prvePath)
    }
  }
  window.addEventListener('popstate', checkViewState)

  const OnPorcessOneWay = [
    VehicleState.START,
    VehicleState.PICK_UP_POINT,
    VehicleState.CONFIRM_PICK_UP,
    VehicleState.CONFIRM_DRIVER,
    VehicleState.DEPART,
    VehicleState.ARRIVE,
  ]

  const OnPorcessRoundTrip = [
    ...OnPorcessOneWay,
    VehicleState.FINISH,
    VehicleState.PICK_UP_POINT_BACK,
    VehicleState.DEPART_BACK,
    VehicleState.ARRIVE_BACK,
  ]

  useInterval(() => {
    checkState()
  }, 10000)

  useEffect(() => {
    checkState()
  }, [booking])

  async function checkState() {
    if (!booking) return

    if (booking.driverId && booking.carId && driver === undefined) {
      fetchDriver(booking.driverId)
    }

    const dateNow = dayjstz(new Date())
    const beforeStartDate = dayjstz(booking.scheduledDate).subtract(1, 'day').hour(EndHour).minute(0).second(0)

    if (booking.driverId && booking.carId) {
      setIsShowDateCar(true)
    }

    if (
      dateNow.isSameOrBefore(beforeStartDate) &&
      userId === booking.bookerLineId &&
      ![BookingStatus.CANCELED, BookingStatus.COMPLETED].includes(booking.status)
    ) {
      setIsShowIconEdit(true)
    }
  }

  async function fetchDriver(driverId: string) {
    const driver = await getDriverById(driverId)
    setDriver(driver)
  }

  async function fetchBooking() {
    const queryParams = new URLSearchParams(window.location.search)
    const bn = queryParams.get('bn')
    if (bn) {
      const b = bookingVehicle ? bookingVehicle : await getBooking(bn)
      if (!b) return
      setIsHistory([BookingStatus.CANCELED, BookingStatus.COMPLETED].includes(b.status))
      setIsShowNotificationCard(b.status === BookingStatus.NEW)

      setLocation({
        routeType: { isOneWay: !b.trip2, placeType: PLACE_TYPE.ORIGIN1, showRoute: true },
        routeGo: b.trip,
        routeBack: b.trip2 ? b.trip2 : location.routeBack,
      })

      setEdit({ ...edit, bn: b.bookingNumber })

      setCustomer({
        booker: { name: b.bookerName, phone: decoratePhoneNumber(b.bookerPhone), lineId: b.bookerLineId },
        passenger: { name: b.passengerName, phone: decoratePhoneNumber(b.passengerPhone), lineId: b.passengerLineId },
        emergencyContact: {
          name: b.emergencyContactName,
          phone: decoratePhoneNumber(b.emergencyContactPhone),
          relation: b.emergencyContactRelation,
        },
        note: b.note ?? '',
        checked: 0,
      })

      const departureTime = dayjstz(b.departureTime)
      const arrivalTime = dayjstz(b.arrivalTime)
      const departureTime2 = dayjstz(b.departureTime2)
      const arrivalTime2 = dayjstz(b.arrivalTime2)

      const goPrice = b.payments.find((p) => p.routeType === RouteTypes.GO)?.amount ?? 0
      const backPrice = b.payments.find((p) => p.routeType === RouteTypes.BACK)?.amount ?? 0
      setDeparture({
        date: b.scheduledDate,
        goTime: {
          departure: { hour: departureTime.hour(), minute: departureTime.minute() },
          arrival: { hour: arrivalTime.hour(), minute: arrivalTime.minute() },
        },
        backTime: {
          departure: { hour: departureTime2.hour(), minute: departureTime2.minute() },
          arrival: { hour: arrivalTime2.hour(), minute: arrivalTime2.minute() },
        },
        vehicle: {
          id: b.vehicleId,
          date: `${dayjstz(b.scheduledDate)}`,
          carId: b.carId,
          carModelId: b.modelId,
          car: {
            id: b.carId,
            modelId: b.modelId,
            model: b.carModel,
            color: b.carColor,
            licensePlateId: b.carLicensePlateId,
            seat: b.seat,
            imageUrl: b.carImageUrl,
          },
        },
        baggages: b.baggages,
        followers: b.followers ?? 0,
        note: b.note ?? '',
        goPrice: Number(goPrice),
        backPrice: Number(backPrice),
      })

      setBooking(b)

      setRouteType({
        isOneWay: !b.trip2,
        placeType: PLACE_TYPE.ORIGIN1,
        showRoute: true,
      })
      setRouteGo(b.trip)
      if (b.trip2) {
        setRouteBack(b.trip2)
      }
    }
  }

  async function fetchStaff() {
    const domain = await getDomain()
    setStaff(await getStaff(domain))
  }

  async function fetchVihecleState() {
    if (!booking?.vehicleId) return
    if (booking.status === BookingStatus.IN_PROGRESS) {
      const { lat, lng } = handleTracking()
      const _driverLocation = await getDriverLocation(booking.bookingNumber, `${lat},${lng}`)
      if (booking.tripType === TripType.ONE_WAY) {
        setDriverLocation(_driverLocation)
        if (OnPorcessOneWay.includes(_driverLocation.state)) {
          setOnProcess(true)
        } else {
          setOnProcess(false)
        }
      } else {
        setDriverLocation(_driverLocation)
        if (OnPorcessRoundTrip.includes(_driverLocation.state)) {
          setOnProcess(true)
        } else {
          setOnProcess(false)
        }
      }
    }
  }

  function handleTracking() {
    if (!driverLocation || !routeGo) {
      return {
        lat: parseFloat(defaultLocation.lat),
        lng: parseFloat(defaultLocation.lng),
      }
    }

    const { state } = driverLocation
    if (OnPorcessOneWay.includes(state)) {
      if (
        [
          VehicleState.START,
          VehicleState.PICK_UP_POINT,
          VehicleState.CONFIRM_PICK_UP,
          VehicleState.CONFIRM_DRIVER,
        ].includes(state)
      ) {
        return {
          lat: parseFloat(routeGo.origin.lat ?? defaultLocation.lat),
          lng: parseFloat(routeGo.origin.lng ?? defaultLocation.lng),
        }
      } else if ([VehicleState.DEPART, VehicleState.ARRIVE].includes(state)) {
        return {
          lat: parseFloat(routeGo.destination.lat ?? defaultLocation.lat),
          lng: parseFloat(routeGo.destination.lng ?? defaultLocation.lng),
        }
      }
    }

    if (OnPorcessRoundTrip.includes(state) && routeBack) {
      if ([VehicleState.FINISH, VehicleState.PICK_UP_POINT_BACK].includes(driverLocation.state)) {
        return {
          lat: parseFloat(routeBack.origin.lat ?? defaultLocation.lat),
          lng: parseFloat(routeBack.origin.lng ?? defaultLocation.lng),
        }
      } else if ([VehicleState.DEPART_BACK, VehicleState.ARRIVE_BACK].includes(driverLocation?.state)) {
        return {
          lat: parseFloat(routeBack.destination.lat ?? defaultLocation.lat),
          lng: parseFloat(routeBack.destination.lng ?? defaultLocation.lng),
        }
      } else {
        return {
          lat: parseFloat(routeGo.origin.lat ?? defaultLocation.lat),
          lng: parseFloat(routeGo.origin.lng ?? defaultLocation.lng),
        }
      }
    }

    return {
      lat: parseFloat(defaultLocation.lat),
      lng: parseFloat(defaultLocation.lng),
    }
  }

  function cancel() {
    if (!booking) return
    ;(async () => {
      try {
        const res = await cancelBooking(booking.bookingNumber)
        if (res.success === true) {
          navigate(BookingRoute.Canceled)
        } else {
          toast.error(t('Failed'))
        }
      } catch (err) {
        toast.error(t('Request failed'))
      }
    })()
    setOpen(false)
  }

  function contactCustomer() {
    if (liff.getOS() === 'ios') {
      const a = document.createElement('a')
      a.href = `tel:${staff?.phone}`
      a.click()
    } else {
      setOpenContact(true)
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      if (userId) {
        await fetchStaff()
        await fetchBooking()
      }
      setLoading(false)
    })()
  }, [userId])

  useInterval(() => {
    ;(async () => {
      const isBetween = dayjstz(new Date()).isBetween(
        dayjstz(booking?.departureTime).subtract(1, 'hour'),
        dayjstz(booking?.departureTime).add(1, 'hour')
      )
      if (isBetween || booking?.status === BookingStatus.IN_PROGRESS) {
        if (booking?.status !== BookingStatus.IN_PROGRESS) {
          await fetchBooking()
        } else {
          await fetchVihecleState()
          if (
            driverLocation &&
            [VehicleState.FINISH, VehicleState.FINISH_BACK, VehicleState.ARRIVE, VehicleState.ARRIVE_BACK].includes(
              driverLocation?.state
            )
          ) {
            await fetchBooking()
          }
        }
      }
    })()
  }, 10000)

  useEffect(() => {
    ;(async () => {
      if (routeGo?.origin.id) fetchVihecleState()
    })()
  }, [routeGo?.origin.id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function handleCheckCancel() {
    if (!booking) return
    setOpenCancel(false)
    setOpen(true)
  }

  useEffect(() => {
    setIsShowNotification(dayjstz(departure.date).isToday())
  }, [departure.date])

  return loading ? (
    <Loading />
  ) : !booking || !routeType ? (
    <Page404
      description={t('The system is not working properly, please try again.')}
      textButton={t('Try again')}
      onClick={() => navigate(0)}
    />
  ) : (
    <div>
      <ToastContainer position="top-center" hideProgressBar={false} pauseOnFocusLoss draggable pauseOnHover />
      <Header
        onBack={() => {
          if (path.fromMenu) {
            setPath({ fromMenu: false, prvePath: '' })
            navigate(-1)
          } else {
            liff.closeWindow()
          }
        }}
        icon={path.fromMenu ? 'back' : 'null'}
        title={t('Booking Information')}
      />
      <div className="pt-2 bg-gray-200"></div>
      {isShowNotificationCard && userId === booking.bookerLineId && !isShowNotification && <NotificationCard departureData={departure} />}
      {!onProcess
        ? routeGo && routeType && <Map isView key={routeGo.origin.id} height={'60vh'} />
        : routeGo &&
          driverLocation && (
            <Tracking
              key={dayjstz(driverLocation.time).valueOf()}
              height={'60vh'}
              destination={handleTracking()}
              driverLocation={{
                lat: parseFloat(driverLocation.lat ?? '0'),
                lng: parseFloat(driverLocation.lng ?? '0'),
              }}
              state={driverLocation.state ?? booking.state}
            />
          )}
      {location.routeGo.origin.id && (
        <Duration
          departure={departure}
          bookingStatus={booking.status}
          vehicleState={driverLocation?.state ?? booking.vehicleState}
          duration={driverLocation?.duration}
        />
      )}
      {routeType.isOneWay
        ? routeGo && <RouteCard routeGo={routeGo} readonly={true} payments={booking.payments} />
        : routeGo && <RouteCard routeBack={routeBack} routeGo={routeGo} readonly={true} payments={booking.payments} />}
      <div className="bg-gray-200 gap-y-1">
                <ShowTotal
          // isFree={booking.payments[0].paidStatus === PAYMENT_STATUS.NOT_PAID}
          isFree={{
            go: booking.payments[0].paidStatus === PAYMENT_STATUS.NOT_PAID,
            //back: booking.payments[1].paidStatus === PAYMENT_STATUS.NOT_PAID,
            back: booking.payments.length === 1 ? true : booking.payments[1].paidStatus === PAYMENT_STATUS.NOT_PAID,
          }}
          goPrice={departure.goPrice}
          backPrice={departure.backPrice}
          bookingNumber={booking.bookingNumber}
        />
        <ShowSchedule
          routeType={routeType}
          date={departure.date}
          departureTime={departure.goTime.departure}
          arrivalTime={departure.goTime.arrival}
          departureTimeTwo={departure.backTime?.departure ?? { hour: 0, minute: 0 }}
          arrivalTimeTwo={departure.backTime?.arrival ?? { hour: 0, minute: 0 }}
        />
        <ViewShowCarCard
          booking={booking}
          departure={departure}
          isShowDateCar={isShowDateCar}
          onChange={() => {}}
          onEdit={() => {
            setEdit({ ...edit, byView: true })
            navigate(BookingRoute.Departure)
          }}
        ></ViewShowCarCard>
        {isShowDateCar && (
          <>
            <div className="pt-2 bg-gray-200" />
            <DriverCard driver={driver} />
          </>
        )}
        <div className="pt-2 bg-gray-200"></div>
        <ShowBaggageAndPassenger
          showTitle
          baggages={departure.baggages}
          passenger={departure.followers}
          onEdit={() => {
            setEdit({ ...edit, byView: true })
            navigate(BookingRoute.Departure)
          }}
          isEdit={isShowIconEdit}
        />
        <ShowInfo
          booker={customer.booker}
          passenger={customer.passenger}
          emergencyContact={customer.emergencyContact}
          note={customer.note}
          onEdit={() => {
            setEdit({ ...edit, byView: true })
            navigate(BookingRoute.Customer)
          }}
          isEdit={isShowIconEdit}
          booking={booking}
        />
        <ModalCard
          title={t('Are you sure to cancel booking?')}
          detail={t(
            'Your booking information will be deleted after cancel booking. If you want to edit please booking again.'
          )}
          status={open}
          duoButton={{
            left: { name: t('Yes'), type: 'confirm', style: 'btn-danger' },
            right: { name: t('No'), type: 'cancel', style: 'btn-outline-primary' },
          }}
          onClick={(val) => (val ? (cancel(), setOpen(false)) : setOpen(val))}
          haveCheck
        />
        <ModalCard
          title={t('Reservation cannot be canceled.')}
          detail={t('You must cancel your booking at least 3 hours before the departure time.')}
          status={openCancel}
          singleButton={{ name: t('Close'), type: 'cancel', style: 'btn-primary' }}
          onClick={(val) => setOpenCancel(val)}
        />
        <ModalCard
          title={t('Are you want to contact customer service ?')}
          detail={''}
          status={openContact}
          duoButton={{
            left: { name: t('No'), type: 'cancel', style: 'btn-outline-primary' },
            right: { name: t('Yes'), type: 'confirm', style: 'btn-primary' },
          }}
          onClick={(val) => {
            setOpenContact(false)
            if (val) {
              window.open(`tel:${staff?.phone}`)
            }
          }}
        />
        {!isHistory && (
          <>
            <div className="h-20"></div>
            <DoubleButtonCard
              vertical
              shuffle
              style="btn-outline-danger"
              textCancel={t('Cancel booking')}
              textConfirm={t('Contact customer service')}
              onCancel={() => handleCheckCancel()}
              onConfirm={() => contactCustomer()}
              isHideCancelButton={!isShowIconEdit}
            />
          </>
        )}
      </div>
    </div>
  )
}
