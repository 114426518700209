import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from 'src/i18n/en.json'
import th from 'src/i18n/th.json'

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: { translation: en },
    th: { translation: th },
  },
  keySeparator: '.',
  interpolation: { escapeValue: false },
})

export default i18n
