type Props = {
  title: string
  address: string
  onClick: () => void
}

const AddressCard: React.FC<Props> = ({ title, address, onClick }: Props) => {
  return (
    <div
      className="py-2 border-2 border-opacity-0 rounded-lg bg-blue-50 hover:bg-blue-100 hover:border-blue-400"
      onClick={onClick}
    >
      <div className="flex justify-between">
        <div className="flex">
          <div className="grid content-center px-3">
            <span className="text-2xl text-blue-400 material-icons">location_on</span>
          </div>
          <div className="flex-row">
            <p className="text-lg font-semibold text-gray-800">{title}</p>
            <p className="text-xs text-opacity-80">{address}</p>
          </div>
        </div>
        <div className="flex flex-wrap content-center">
          <span className="text-base material-icons">arrow_forward_ios</span>
        </div>
      </div>
    </div>
  )
}

export default AddressCard
