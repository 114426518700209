import { t } from 'i18next'
import { useMemo } from 'react'
import SelectDate from 'src/components/SelectDate'
import { dayjstz } from 'src/helpers/datetime'
import { padZero } from 'src/helpers/utils'

type Props = {
  title: string
  date: Date
  cutOffTime: { cutOffAdvanceBookingTime: string }
  serviceTime: { startCarServiceTime: string; stopCarServiceTime: string }
  canSelectToday: boolean
  canSelectTomorrow: boolean
  isCarAvailable: boolean
  onChange: (date: Date) => void
}

function DateSelectCard({ title, date, cutOffTime, serviceTime, canSelectToday, canSelectTomorrow, onChange }: Props) {
  const parsedCutOffTime = useMemo(() => {
    const [hour, minute] = cutOffTime.cutOffAdvanceBookingTime.split(':')
    return {
      hour: hour,
      minute: minute,
    }
  }, [cutOffTime])

  const parsedServiceTime = useMemo(() => {
    const [startHour, startMinute] = serviceTime.startCarServiceTime.split(':')
    const [stopHour, stopMinute] = serviceTime.stopCarServiceTime.split(':')
    return {
      startCarServiceTime: {
        hour: startHour,
        minute: startMinute,
      },
      stopCarServiceTime: {
        hour: stopHour,
        minute: stopMinute,
      },
    }
  }, [serviceTime])

  const selectedDayInvalid = useMemo(() => {
    return dayjstz() < dayjstz(date)
      ? false
      : canSelectToday
      ? parseInt('' + parsedServiceTime.stopCarServiceTime.hour + parsedServiceTime.stopCarServiceTime.minute) <
        parseInt(dayjstz().format('HHmm'))
      : parseInt('' + parsedCutOffTime.hour + parsedCutOffTime.minute) <
        parseInt(padZero(dayjstz().hour()) + padZero(dayjstz().minute()))
  }, [parsedCutOffTime, date])

  return (
    <div>
      <div className="border-b border-gray-200">
        <h3 className="card-title" data-testid="date-card-go_title">
          {title}
        </h3>
      </div>
      <div className="flex flex-col p-4 space-y-2">
        <label htmlFor="dateTime" className="block text-lg font-light text-gray-700">
          {t('Departure date')}
        </label>
        <p className="mt-1 text-sm text-gray-600" data-testid="date-card-go_error">
          {t('If the service is fully booked on that day')}
          <br />
          {t('It cannot be selected')}
        </p>
        <SelectDate
          canSelectToday={canSelectToday}
          canSelectTomorrow={canSelectTomorrow}
          isAlert={selectedDayInvalid}
          date={date}
          onChangeDate={onChange}
        />
        {selectedDayInvalid && (
          <label className="block text-sm font-light text-red-500">
            {t('Our service is not available on selected date. Please select another date.')}
          </label>
        )}
      </div>
    </div>
  )
}

export default DateSelectCard
