import iconDestinationRouteBack from 'src/assets/icons/icon-destination-route-back.svg'
import iconDestinationRouteGo from 'src/assets/icons/icon-destination-route-go.svg'
import iconOriginRouteBack from 'src/assets/icons/icon-origin-route-back.svg'
import iconOriginRouteGo from 'src/assets/icons/icon-origin-route-go.svg'
import { RouteType } from 'src/enums'
import { Route } from 'src/types'

type Props = {
  route: Route
  routeType: RouteType
}

export default function RouteDetail({ route, routeType }: Props) {
  return (
    <>
      <div className="flex">
        <img src={routeType === RouteType.GO ? iconOriginRouteGo : iconOriginRouteBack} />
        <div className="block px-2 text-lg font-light text-gray-700">
          {route.origin.name}
          <br />
          <p className="pr-6 text-sm text-gray-500 break-words">{route.origin.note ?? ''}</p>
        </div>
      </div>
      <span className="text-2xl text-gray-400 material-icons">south</span>
      <div className="flex">
        <img src={routeType === RouteType.GO ? iconDestinationRouteGo : iconDestinationRouteBack} />
        <div className="block px-2 text-lg font-light text-gray-700">
          {route.destination.name}
          <br />
          <p className="pr-6 text-sm text-gray-500 break-words">{route.destination.note ?? ''}</p>
        </div>
      </div>
    </>
  )
}
