/**
 * Card for show departure time of return trip
 *
 * @param route - Route type : route information from origin to destination
 * @param date - Date type : Booking date
 * @param time - TimePackage type : Departure time of return trip
 * @param departureStateData - DepartureState type : State which contain booking data
 * @param serviceTime - const type { startCarServiceTime: string; stopCarServiceTime: string }: Range of service time
 * @param cutOffTime - const type { cutOffAdvanceBookingTime: string }: Cutoff time
 * @param minTime - TimeUnit type : Arrival time of Going trip
 * @param goTime - TimePackage type : Departure and arrival time of Going trip
 * @param isOvernightBooking - boolean type : True = Going trip duration is overnight (ex: start at 23:50 finish at 00:20)
 * @param hasNoCarOrVehicleActive - boolean type : True = No car available on that day
 * @param onChange - (time: TimePackage) => void : trigger when change
 * @param onValidate - (v: boolean) => void : trigger when validate
 * @returns HTML of card for show departure time of return trip
 * 
 * @remarks
 * useMemo_NO1 : calculate when route is changed, trigger when route is changed
 * useEffect_NO1 : Initial check and set the time to card
 * 
 **/

import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BufferedTravelTime } from 'src/consts'
import { dayjstz, isRouteTimeValid } from 'src/helpers/datetime'
import { padZero } from 'src/helpers/utils'
import { Route, TimePackage, TimeUnit } from 'src/types'
import TimePicker from './TimePicker'
import { DepartureState } from 'src/states'

type Props = {
  route: Route
  date: Date
  time?: TimePackage
  departureStateData: DepartureState
  serviceTime: { startCarServiceTime: string; stopCarServiceTime: string }
  cutOffTime: { cutOffAdvanceBookingTime: string }
  minTime: TimeUnit
  goTime: TimePackage
  isOvernightBooking: boolean
  hasNoCarOrVehicleActive: boolean
  onChange: (time: TimePackage) => void
  onValidate: (v: boolean) => void
}

export default function DateCardBack({
  route,
  date,
  time,
  departureStateData,
  serviceTime,
  cutOffTime,
  minTime, //departure.goTime.arrival
  isOvernightBooking,
  hasNoCarOrVehicleActive,
  onChange,
  onValidate,
}: Props) {

  /* Internal use variable */
  /* t - useTranslation() function : translate word in HTML */
  const { t } = useTranslation()
  /* goTime - TimePackage type : Departure and arrival time of Going trip  */
  /* backTime - TimePackage type : Departure and arrival time of Return trip  */
  const { goTime, backTime } = departureStateData
  /* goTimeArrival - TimeUnit type : Arrival time of Going trip  */
  const { arrival: goTimeArrival } = goTime
  /* backTimeDeparture - TimeUnit type : Departure time of Return trip  */
  /* backTimeArrival - TimeUnit type : Arrival time of Return trip  */
  const { departure: backTimeDeparture, arrival: backTimeArrival } = backTime as TimePackage

  /**
   * useMemo_NO1 : calculate miniute of duration, trigger when route is changed 
   * @param route - [Input/Trigger]Route type : route information from origin to destination
   * @returns RouteMinutes : miniute of duration
   * */
  const RouteMinutes = useMemo(() => Math.round((route.duration ?? 0) / 60), [route])

  /**
   * useEffect_NO1 : Initial check and set the time to card
   * @param date - [Input/Trigger] Date type : Booking date
   * @param serviceTime - const type { startCarServiceTime: string; stopCarServiceTime: string }: Range of service time
   * @param cutOffTime - const type { cutOffAdvanceBookingTime: string }: Cutoff time
   * @param minTime - [Input/Trigger] TimeUnit type : Arrival time of Going trip
   * @param goTime - TimePackage type : Departure and arrival time of Going trip
   * @param backTimeDeparture - TimeUnit type : Departure time of Return trip *Internal
   * @param backTimeArrival - TimeUnit type : Arrival time of Return trip *Internal
   * @returns onChange - set backTimeDeparture and backTimeArrival
   * 
   * @remarks
   * BUG_202309 : Time toggling bug rootcause 
   * Using common method
   * - toDate
   * Using datetime method
   * - dayjstz
   * - isRouteTimeValid
   * */
  // useEffect(() => {
  //   const mtime = dayjstz(date).hour(minTime.hour).minute(minTime.minute).second(0)
  //   const [startAvailableHour, startAvailableMinute] = serviceTime.startCarServiceTime.split(':')
  //   const [endAvailableHour, endAvailableMinute] = serviceTime.stopCarServiceTime.split(':')
  //   const [cutOffTimeHour, cutOffTimeMinute] = cutOffTime.cutOffAdvanceBookingTime.split(':')
  //   const startStopTime = {
  //     startAvailableHour: startAvailableHour,
  //     startAvailableMinute: startAvailableMinute,
  //     endAvailableHour: endAvailableHour,
  //     endAvailableMinute: endAvailableMinute,
  //     cutOffTimeHour: cutOffTimeHour,
  //     cutOffTimeMinute: cutOffTimeMinute,
  //   }
  //   const v = isRouteTimeValid(
  //     date,
  //     backTimeDeparture,
  //     backTimeArrival,
  //     startStopTime,
  //     mtime.toDate(),
  //     goTime.departure,
  //     goTime.arrival
  //   )
  //   if (v) onChange({ departure: backTimeDeparture, arrival: backTimeArrival })
  //   console.log("DCBuseEffect date/mintime")
  // }, [date,             // Check date                update or not (useEffect work when its update)
  //     minTime.hour,     // Check goTime.arrival.hour update or not (useEffect work when its update)
  //     minTime.minute])  // Check goTime.arrival.min  update or not (useEffect work when its update)

  function handleChangeDeparture(t: TimeUnit) {
    if (time !== undefined) {
      const mtimeHandle = dayjstz(date).hour(minTime.hour).minute(minTime.minute).second(0)
      const [startAvailableHourHandle, startAvailableMinuteHandle] = serviceTime.startCarServiceTime.split(':')
      const [endAvailableHourHandle, endAvailableMinuteHandle] = serviceTime.stopCarServiceTime.split(':')
      const [cutOffTimeHourHandle, cutOffTimeMinuteHandle] = cutOffTime.cutOffAdvanceBookingTime.split(':')
      const startStopTimeHandle = {
        startAvailableHour: startAvailableHourHandle,
        startAvailableMinute: startAvailableMinuteHandle,
        endAvailableHour: endAvailableHourHandle,
        endAvailableMinute: endAvailableMinuteHandle,
        cutOffTimeHour: cutOffTimeHourHandle,
        cutOffTimeMinute: cutOffTimeMinuteHandle,
      }

      const arrived = dayjstz(date)
        .hour(t.hour)
        .minute(t.minute)
        .add(RouteMinutes, 'minute')
        .add(BufferedTravelTime, 'minute')
      const newTime = { ...time, arrival: { hour: arrived.hour(), minute: arrived.minute() } }
      let newDepartTime = t
      if (t.hour === goTimeArrival.hour && backTimeDeparture.minute < goTimeArrival.minute) {
        newDepartTime = { hour: t.hour, minute: goTimeArrival.minute }
      } else if (
        t.hour === parseInt(parsedServiceTime.stopCarServiceTime.hour) &&
        backTimeDeparture.minute > parseInt(parsedServiceTime.stopCarServiceTime.minute)
      ) {
        newDepartTime = { hour: t.hour, minute: parseInt(parsedServiceTime.stopCarServiceTime.minute) }
      }
      newTime.departure = newDepartTime

      // Add vHandle for check isRouteTimeValid
      const vHandle = isRouteTimeValid(
        date,
        newTime.departure,
        newTime.arrival,
        startStopTimeHandle,
        mtimeHandle.toDate(),
        goTime.departure,
        goTime.arrival
      )

      // Add vHandle for check isRouteTimeValid
      if (vHandle) {
        onChange(newTime)
      }
    }
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("DCBfn handleChangeDeparture")
    /* DEBUG_DEPARTURE : For debug  */
  }

  const parsedServiceTime = useMemo(() => {
    const [startHour, startMinute] = serviceTime.startCarServiceTime.split(':')
    const [stopHour, stopMinute] = serviceTime.stopCarServiceTime.split(':')
    return {
      startCarServiceTime: {
        hour: startHour,
        minute: startMinute,
      },
      stopCarServiceTime: {
        hour: stopHour,
        minute: stopMinute,
      },
    }
  }, [serviceTime])

  const parsedCutOffTime = useMemo(() => {
    const [hour, minute] = cutOffTime.cutOffAdvanceBookingTime.split(':')
    return {
      hour: hour,
      minute: minute,
    }
  }, [cutOffTime])

  const bufferedTime = useMemo(() => {
    return {
      hour: goTime.arrival.hour,
      minute: goTime.arrival.minute,
    }
  }, [goTime])

  const DepartAfterBufferTime = useMemo(() => {
    return (
      parseInt(padZero(bufferedTime.hour) + padZero(bufferedTime.minute)) >
      parseInt('' + padZero(backTimeDeparture.hour) + padZero(backTimeDeparture.minute))
    )
  }, [backTimeDeparture, bufferedTime])

  const checkNotInServiceTime = useMemo(() => {
    return (
      dayjstz()
        .hour(backTimeDeparture.hour)
        .minute(backTimeDeparture.minute)
        .isBefore(
          dayjstz()
            .hour(parseInt(parsedServiceTime.startCarServiceTime.hour))
            .minute(parseInt(parsedServiceTime.startCarServiceTime.minute))
        ) ||
      dayjstz()
        .hour(backTimeDeparture.hour)
        .minute(backTimeDeparture.minute)
        .isAfter(
          dayjstz()
            .hour(parseInt(parsedServiceTime.stopCarServiceTime.hour))
            .minute(parseInt(parsedServiceTime.stopCarServiceTime.minute))
        )
    )
  }, [parsedServiceTime, backTimeDeparture])

  const isGoArrivalPassServiceTime = useMemo(() => {
    return dayjstz()
      .hour(goTimeArrival.hour)
      .minute(goTimeArrival.minute)
      .isAfter(
        dayjstz()
          .hour(parseInt(parsedServiceTime.stopCarServiceTime.hour))
          .minute(parseInt(parsedServiceTime.stopCarServiceTime.minute))
      )
  }, [goTimeArrival, parsedServiceTime])

  const dateCardError = useMemo(() => {
    // check if goArrialTime is after operationTime, and overnight
    if (isGoArrivalPassServiceTime || isOvernightBooking) {
      return `${t('The return time is not available during service period')}`
    }
  
    if (checkNotInServiceTime)
      return `${t('Please select service time ')}${parseInt(parsedServiceTime.startCarServiceTime.hour)}:${
        parsedServiceTime.startCarServiceTime.minute
      } - ${parseInt(parsedServiceTime.stopCarServiceTime.hour)}:${parsedServiceTime.stopCarServiceTime.minute}`

    if (DepartAfterBufferTime)
      return `${t('Please select a later time')} ${padZero(bufferedTime.hour)}:${padZero(bufferedTime.minute)}`


    // alert at timePicker but msg will be show on car selection
    // if (hasNoCarOrVehicleActive) return ''

    return null
  }, [
    goTime,
    parsedCutOffTime,
    DepartAfterBufferTime,
    parsedServiceTime,
    backTimeDeparture,
    isOvernightBooking,
    checkNotInServiceTime,
    hasNoCarOrVehicleActive,
    t
  ])

  useEffect(() => {
    onValidate(dateCardError === null)
  }, [dateCardError])

  return (
    <div className="flex flex-col p-4 space-y-2 card">
      <label className="block text-lg font-light text-gray-700">{t('Return time')}</label>
      <label
        htmlFor="alertTime"
        className="block text-sm font-light text-gray-500"
        data-testid="date-card-go_error-message-time"
      >
        {t('Available service time ')}
        {parseInt(parsedServiceTime.startCarServiceTime.hour)}:{parsedServiceTime.startCarServiceTime.minute} -{' '}
        {parseInt(parsedServiceTime.stopCarServiceTime.hour)}:{parsedServiceTime.stopCarServiceTime.minute}
      </label>
      <TimePicker
        isDisabled={isGoArrivalPassServiceTime || isOvernightBooking}
        limitStartTime={{ hour: goTimeArrival.hour.toString() || '0', minute: goTimeArrival.minute.toString() || '0' }}
        limitStopTime={parsedServiceTime.stopCarServiceTime}
        isAlert={dateCardError !== null}
        time={backTimeDeparture}
        onChange={handleChangeDeparture}
        data-testid="dateCardBackTime"
      />
      {dateCardError !== null && (
        <label htmlFor="alertTime" className="block text-sm font-light text-red-500" data-testid="card-today">
          {dateCardError === null ? t('Invalid time') : dateCardError}
        </label>
      )}
      <label className="block pt-2 text-lg font-light text-gray-700">
        {`${t('Estimate time arrival')}:`}
        <strong className="pl-2 font-semibold">
          {backTimeArrival.hour}:{padZero(backTimeArrival.minute)}
        </strong>
      </label>
    </div>
  )
}
