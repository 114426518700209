import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'
import BookingCard from 'src/pageviews/Booking/BookingCardCNMI'
import { useCallApi } from 'src/hooks/useCallApi'
import { BookingVehicle } from 'src/types'
import Calendar from 'src/components/Calendar'
import dayjs, { Dayjs } from 'dayjs'
import { useSetRecoilState } from 'recoil'
import { pathState } from 'src/states'
import { BookingRoute } from 'src/consts/route'
import { useNavigate } from 'react-router-dom'
import loadingAnimation from 'src/assets/icons/loading.svg'
import Header from 'src/components/Header'
import { currentMonthYearState, languageState } from 'src/states'
import { useRecoilValue } from 'recoil'
import TabButton from 'src/components/Button/tabButtonBookingListCNMI'
import { BookingStatusNewCNMI } from 'src/enums'

export default function BookingListTmp() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(window.location.search)
  const domain = queryParams.get('domain')

  const currentLanguage = useRecoilValue(languageState)

  const currentMonthYear = useRecoilValue(currentMonthYearState)
  const [bookingList, setBookingList] = useState<BookingVehicle[]>([])

  const [isLoadingBookings, setIsLoadingBookings] = useState(false)
  const setPath = useSetRecoilState(pathState)
  const [medData, setMedData] = useState<{
    appointmentDates?: number[]
  }>({
    appointmentDates: [],
  })

  const { getAppointment, getBookingList } = useCallApi()

  const [scheduledDate, setScheduledDate] = useState<(Date | null)[]>([])
  const [bookingStatus, setBookingStatus] = useState<number[]>([])
  const [filteredBookingCard, setFilteredBookingCard] = useState<BookingVehicle[]>([])

  async function fetchGetBookings() {
    setIsLoadingBookings(true)

    const res = await getBookingList()
    if (!res) {
      setIsLoadingBookings(false)
      return
    }

    const BookingData = res.bookings as BookingVehicle[]
    setBookingList( BookingData )

    /////////////////////////////////// Get booking data of each month ///////////////////////////////////
    // Filter Booking of each month //
    const bookingsInCurrentMonth = BookingData.filter(
      (booking) =>
        dayjs(booking.scheduledDate).month() === currentMonthYear.month() &&
        dayjs(booking.scheduledDate).year() === currentMonthYear.year()
    )
    setFilteredBookingCard(bookingsInCurrentMonth)

    // Set booking status for bookings in the current month
    const bookingStatusInCurrentMonth = bookingsInCurrentMonth.map(booking => booking.status)
    setBookingStatus(bookingStatusInCurrentMonth)

    // Save Month&Year Keys and their Booking Numbers //
    const monthYearScheduleDates: Record<string, Date[]> = {}

    // Map Booking Numbers of each month //
    res.bookings.forEach((booking) => {
      const date = dayjs(booking.scheduledDate)
      const monthYearKey = `${date.month() + 1}-${date.year()}` // Format: MM-YYYY

      if (!monthYearScheduleDates[monthYearKey]) {
        monthYearScheduleDates[monthYearKey] = []
      }

      monthYearScheduleDates[monthYearKey].push(booking.scheduledDate)
    })

    // Extract booking numbers for the current month
    const currentMonthKey = `${currentMonthYear.month() + 1}-${currentMonthYear.year()}`
    const currentMonthBookingDates = monthYearScheduleDates[currentMonthKey] || []

    // Get scheduledate of current month
    setScheduledDate(currentMonthBookingDates)
    /////////////////////////////////// End: Get booking data of each month ///////////////////////////////////

    setIsLoadingBookings(false)

    /////////////////////////////////// Check value ///////////////////////////////////
    //console.log('Current Month Bookings:', currentMonthBookings)
    //console.log('Month Year Bookings:', monthYearBookings)
  }

  function fetchGetAppointment() {
    setIsLoadingBookings(true)
    getAppointment().then((res) => {
      setMedData(res)
      setIsLoadingBookings(true)
    })
    //setIsLoadingBookings(false)
  }

  useEffect(() => {
    fetchGetBookings()
    fetchGetAppointment()
  }, [currentMonthYear])

  /////////////////////////////////// Design Color Tab button ////////////////////////////////////
  // State tab button
  const [activeButton, setActiveButton] = useState('ongoing') // 'ongoing' or 'history'
  // Background and Text color of tab button
  const getBgColor = (buttonType: string) => (activeButton === buttonType ? 'bg-blue-400' : 'bg-gray-100')
  const getTextColor = (buttonType: string) => (activeButton === buttonType ? 'text-white' : 'text-blue-500')
  /////////////////////////////////// End: Design Color Tab button ////////////////////////////////////

  /////////////////////////////////// Filter Booking Cards -> Present & History button ////////////////////////////////////
  const filterBookingsByStatus = (statusArray: number[]) => {
    const filtered = bookingList.filter(
      (booking) =>
        statusArray.includes(booking.status) &&
        dayjs(booking.scheduledDate).month() === currentMonthYear.month() &&
        dayjs(booking.scheduledDate).year() === currentMonthYear.year()
    )
    setFilteredBookingCard(filtered)
  }

  const InProgressStatusFilter = () => {
    filterBookingsByStatus([BookingStatusNewCNMI.PENDING,BookingStatusNewCNMI.START,BookingStatusNewCNMI.PICK_UP_POINT,BookingStatusNewCNMI.CONFIRM_PICK_UP,BookingStatusNewCNMI.CONFIRM_DRIVER,BookingStatusNewCNMI.DEPART,BookingStatusNewCNMI.ARRIVE])
  }
  const HistoryStatusFilter = () => {
    filterBookingsByStatus([BookingStatusNewCNMI.FINISH,BookingStatusNewCNMI.CANCEL,BookingStatusNewCNMI.ABSENT])
  }
  /////////////////////////////////// End: Filter Booking Cards -> Present & History button ////////////////////////////////////

  /////////////////////////////////// Set Default Booking Card Display -> Ongoing ////////////////////////////////////
  useEffect(() => {
    if (bookingList && bookingList.length > 0) {
      InProgressStatusFilter()
      setActiveButton('ongoing')
    }
  }, [bookingList])
  /////////////////////////////////// End: Set Default Booking Card Display -> Ongoing ////////////////////////////////////

  /////////////////////////////////// Filter Booking Cards -> Specific Date ////////////////////////////////////
  const filterBookingsByDate = (selectedDate: Dayjs) => {
    const filtered = bookingList.filter((booking) => dayjs(booking.scheduledDate).isSame(selectedDate, 'day'))
    setFilteredBookingCard(filtered)
    setActiveButton('')
  }
  /////////////////////////////////// Filter Booking Cards -> Specific Date ////////////////////////////////////

  /////////////////////////////////// Check value ///////////////////////////////////
  /* console.log('Scheduled Date [Sent]:', scheduleddates)
    console.log('Current Month [Receive]:', currentMonthYear) */

  return (
    <>
      {/* <h1 className="px-5 bg-[#d8e0e5] h-[25px] font-bold align-bottom pl-5">ข้อมูลการจอง</h1> */}
      {/* <h1 className="px-5 bg-[#d8e0e5] h-[25px] font-bold align-bottom pl-5">{t('My Booking')}</h1> */}
      {/* <Header title={t('My booking')} icon={'null'} bgColor="bg-[#d8e0e5]" reload={true} /> */}
      <Header
        title={t('My booking')}
        icon="back"
        bgColor="bg-[#d8e0e5]"
        reload={true}
        onBack={() => {
          navigate(BookingRoute.LandingPageCNMI)
        }}
      />
      <div className="px-5">
        <div className="flex flex-col items-center mt-4">
          <div className="flex justify-center align-middle">
            {isLoadingBookings ? (
              <div className="flex items-center justify-center pt-10 pb-10">
                <img src={loadingAnimation} width={'41px'} height={'41px'} alt="Loading" />
              </div>
            ) : (
              medData !== undefined && (
                <Calendar
                  //appointmentDates={medData.appointmentDates}
                  appointmentDates={[]}
                  //onClick={(d: Dayjs) => console.log('CLICK! ', d)}
                  onClick={(selectedDate: Dayjs) => filterBookingsByDate(selectedDate)}
                  scheduleddates={scheduledDate}
                  bookingStatus={bookingStatus}
                  bookinglist={true}
                  locale={currentLanguage}
                />
              )
            )}
          </div>
        </div>
        <div className="flex flex-col items-center">
          <h1 className="flex my-4" style={{ fontWeight: 'bold' }}>
            {t('Booking Status')}
          </h1>
          {/* <h1 className="flex px-4 pt-2 pb-2 my-4" style={{ backgroundColor: 'skyblue', borderRadius: '10px' }}>{t('Booking Status')}</h1> */}
          <div className="flex justify-center w-full mb-3 space-x-4">
            <TabButton
              title={t('Ongoing')}
              onClick={() => {
                InProgressStatusFilter()
                setActiveButton('ongoing')
              }}
              bgColor={getBgColor('ongoing')}
              textColor={getTextColor('ongoing')}
            />
            <TabButton
              title={t('History')}
              onClick={() => {
                HistoryStatusFilter()
                setActiveButton('history')
              }}
              bgColor={getBgColor('history')}
              textColor={getTextColor('history')}
            />
          </div>
          {isLoadingBookings ? (
            <div className="flex items-center justify-center pt-2">
              <img src={loadingAnimation} width={'41px'} height={'41px'} alt="Loading" />
            </div>
          ) : (
            filteredBookingCard.map((booking, key) => (
              <div className="flex justify-center w-full mt-4 " key={key}>
                <div
                  className="overflow-hidden"
                  onClick={() => {
                    setPath({ fromMenu: true, prvePath: location.pathname })
                    navigate(`${BookingRoute.View}/?bn=${booking.bookingNumber}${domain ? `&domain=${domain}` : ''}`)
                  }}
                >
                  <BookingCard className="border border-black sm:w-[530px] cursor-pointer" booking={booking} />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <div className=" w-[360px] md:w-[560px]">
          <SingleButtonCard
            onClick={() => {
              navigate(BookingRoute.LandingPageCNMI)
            }}
          >
            {t('Close')}
          </SingleButtonCard>
        </div>
      </div>
    </>
  )
}
