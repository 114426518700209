import en from 'src/assets/icons/en-flag.svg'
import th from 'src/assets/icons/th-flag.svg'

const languages = [
  { code: 'en', nativeName: 'English', img: en },
  { code: 'th', nativeName: 'Thai', img: th },
]

export enum language {
  TH = 'th',
  EN = 'en',
}

export default languages
