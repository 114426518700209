import liff from '@line/liff/dist/lib'
import { BookingType } from 'src/enums'
import { language } from 'src/i18n/langs'

export default async function shareTargetPicker(
  bookingNumber: string,
  bookingType: BookingType,
  bookerName: string,
  passengerLanguage: string,
  domain: string
): Promise<void> {

  try {
    const var_REACT_APP_LINE_LIFF_ID = `REACT_APP_LINE_LIFF_ID_${domain}`

    if (bookingType === BookingType.NORMAL) {
      await liff.shareTargetPicker(
        [
          {
            type: 'flex',
            altText:
              passengerLanguage === language.TH
                ? `มีการจองรถจากคุณ${bookerName}`
                : `You have been booked for a car booking from ${bookerName}`,
            contents: {
              type: 'bubble',
              size: 'kilo',
              hero: {
                type: 'image',
                url: `${process.env.REACT_APP_AWS_S3_PUBLIC_URL}/liff/cover-line-flex-message.png`,
                size: 'full',
                aspectRatio: '20:13',
                aspectMode: 'cover',
              },
              body: {
                type: 'box',
                layout: 'vertical',
                contents: [
                  {
                    type: 'text',
                    text:
                      passengerLanguage === language.TH
                        ? 'กรุณากด "ยอมรับ" เพื่ออนุญาตให้มีการแจ้งเตือนจากระบบ'
                        : 'Please click "Accept" to agree receiving notification',
                    weight: 'bold',
                    size: 'md',
                    wrap: true,
                  },
                  {
                    type: 'text',
                    text:
                      passengerLanguage === language.TH
                        ? 'สวัสดีค่ะ มีการจองรถจาก'
                        : 'Hi, you have been booked for a car booking from',
                    size: 'md',
                    color: '#858585',
                    margin: 'xxl',
                    wrap: true,
                  },
                  {
                    type: 'text',
                    size: 'md',
                    color: '#858585',
                    text: passengerLanguage === language.TH ? `คุณ${bookerName}` : bookerName,
                  },
                  {
                    type: 'text',
                    text: passengerLanguage === language.TH ? 'รายละเอียด' : 'Detail',
                    margin: 'xxl',
                    color: '#858585',
                    size: 'md',
                  },
                  {
                    type: 'text',
                    text: `https://liff.line.me/${process.env[var_REACT_APP_LINE_LIFF_ID]}/view/?bn=${bookingNumber}&lang=${passengerLanguage}`,
                    size: 'md',
                    wrap: true,
                    action: {
                      type: 'uri',
                      label: 'action',
                      uri: `https://liff.line.me/${process.env[var_REACT_APP_LINE_LIFF_ID]}/view/?bn=${bookingNumber}&lang=${passengerLanguage}`,
                    },
                    color: '#456FFF',
                  },
                ],
              },
              footer: {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                contents: [
                  {
                    type: 'button',
                    style: 'primary',
                    height: 'sm',
                    action: {
                      type: 'uri',
                      label: passengerLanguage === language.TH ? 'ยอมรับ' : 'Accept',
                      uri: `https://liff.line.me/${process.env[var_REACT_APP_LINE_LIFF_ID]}/invite/?bn=${bookingNumber}&lang=${passengerLanguage}`,
                    },
                  },
                ],
              },
            },
          },
        ],
        {
          isMultiple: false,
        }
      )
    } else {
      await liff.shareTargetPicker(
        [
          {
            type: 'flex',
            altText:
              passengerLanguage === language.TH
                ? `คุณ${bookerName} ได้เชิญคุณเข้าใช้งานระบบจองรถ T-Mobility`
                : `${bookerName} invites you to use T-Mobility.`,
            contents: {
              type: 'bubble',
              size: 'kilo',
              hero: {
                type: 'image',
                url: `${process.env.REACT_APP_AWS_S3_PUBLIC_URL}/liff/cover-line-flex-message.png`,
                size: 'full',
                aspectRatio: '20:13',
                aspectMode: 'cover',
              },
              body: {
                type: 'box',
                layout: 'vertical',
                contents: [
                  {
                    type: 'text',
                    text:
                      passengerLanguage === language.TH
                        ? `คุณ${bookerName} ได้เชิญคุณเข้าใช้งานระบบจองรถ T-Mobility`
                        : `${bookerName} invites you to use T-Mobility.`,
                    weight: 'bold',
                    size: 'md',
                    wrap: true,
                  },
                  {
                    type: 'text',
                    text:
                      passengerLanguage === language.TH
                        ? 'กรุณากด "ยอมรับ" เพื่ออนุญาตให้มีการแจ้งเตือนจากระบบ'
                        : 'Please click "Accept" to agree receiving notification.',
                    weight: 'bold',
                    size: 'md',
                    wrap: true,
                  },
                ],
              },
              footer: {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                contents: [
                  {
                    type: 'button',
                    style: 'primary',
                    height: 'sm',
                    action: {
                      type: 'uri',
                      label: passengerLanguage === language.TH ? 'ยอมรับ' : 'Accept',
                      uri: `https://liff.line.me/${process.env[var_REACT_APP_LINE_LIFF_ID]}/invite/?bn=${bookingNumber}&lang=${passengerLanguage}`,
                    },
                  },
                ],
              },
            },
          },
        ],
        {
          isMultiple: false,
        }
      )
    }
  } catch (e) {
    console.error(e)
  }
}
