import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
//import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import loadingAnimation from 'src/assets/icons/loading.svg'
import Header from 'src/components/Header'
import { BookingRoute } from 'src/consts/route'
import { useCallApi } from 'src/hooks/useCallApi'
import BookingCard from 'src/pageviews/Booking/PaymentCardCNMI'
import Loading from 'src/pageviews/Booking/Loading'
import PageEmpty from 'src/pageviews/Booking/PageEmpty'
//import { pathState, totalPriceState, pendingBookingState } from 'src/states'
import { totalPriceState, pendingBookingState } from 'src/states'
import { BookingVehicle } from 'src/types'
import { PAYMENT_STATUS } from 'src/consts'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'

type SelectedBookings = {
  [bookingNumber: string]: boolean
}

export default function BookingList() {
  const { t } = useTranslation()
  const { getPaymentSummary } = useCallApi()
  const [loading, setLoading] = useState(true)
  const [isLoadingBookings, setIsLoadingBookings] = useState(false)
  //const setPath = useSetRecoilState(pathState)
  const navigate = useNavigate()

  //const queryParams = new URLSearchParams(window.location.search)
  //const domain = queryParams.get('domain')

  /////////// Initial set up //////////
  const [totalPrice, setTotalPrice] = useRecoilState(totalPriceState)
  const pendingBookings = useRecoilValue(pendingBookingState)
  const [allUnpaidStatus, setAllUnpaidStatus] = useState<BookingVehicle[]>([])
  const [selectedBookings, setSelectedBookings] = useState<SelectedBookings>({})
  const [bookingVehicles, setBookingVehicles] = useState<{ [bookingNumber: string]: BookingVehicle }>({})
  /////////// End: Initial set up //////////

  /////////////////// Display Booking Card /////////////////////
  async function fetchGetBookings() {
    const res = await getPaymentSummary()
    if (!res) {
      setIsLoadingBookings(false)
      return
    }

    const tempAllUnpaidStatus = []
    const paymentSummaryBooking = res.bookings as BookingVehicle[]

    for (const allBookingPayment of paymentSummaryBooking) {
      if (allBookingPayment && allBookingPayment.payments) {
        const unpaidPayments = allBookingPayment.payments.filter(payment => payment.paidStatus === PAYMENT_STATUS.UNPAID || PAYMENT_STATUS.NOT_PAID)
        if (unpaidPayments.length > 0) {
          tempAllUnpaidStatus.push(allBookingPayment)
          bookingVehicles[allBookingPayment.bookingNumber] = allBookingPayment
        }
      }
    }

    console.log("Res: ", res.bookings)
    setAllUnpaidStatus(tempAllUnpaidStatus)
    console.log("allUnpaidStatus: ", allUnpaidStatus)
    setBookingVehicles({ ...bookingVehicles })
    setLoading(false)
  }

  /////////////////// Checkbox function /////////////////////
  const handleCheckboxChange = (bookingNumber: string, isChecked: boolean) => {
    setSelectedBookings(prev => ({
      ...prev,
      [bookingNumber]: isChecked
    }))
  }

  // async function selectBookings() {
  //   let calTotalPrice = 0
  //   for (const price of allUnpaidStatus) {
  //     if (selectedBookings[price.bookingNumber] && price.payments && price.payments.length > 0) {
  //       const amount = Math.floor(price.payments[0].amount)
  //       calTotalPrice += amount
  //     }
  //   }
  //   setTotalPrice(calTotalPrice)
  // }

  async function selectBookings() {
    let calTotalPrice = 0
    for (const booking of allUnpaidStatus) {
      if (selectedBookings[booking.bookingNumber] && booking.payments && booking.payments.length > 0) {
        const payment = booking.payments[0];
        if (payment.paidStatus === PAYMENT_STATUS.NOT_PAID) {
          calTotalPrice += 0;
        } else {
          const amount = Math.floor(payment.amount);
          calTotalPrice += amount;
        }
      }
    }
    setTotalPrice(calTotalPrice)
  }

  // useEffect(() => {
  //   fetchGetBookings()
  // }, [])

  useEffect(() => {
    if (pendingBookings === 0) {
      fetchGetBookings()
    }
  }, [pendingBookings])

  useEffect(() => {
    selectBookings()
  }, [selectedBookings, allUnpaidStatus])


  /////////////////// END Display Booking Card /////////////////////


  return (
    <div className="bg-powderblue">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header title={t('Payment Summary')} icon={'null'} bgColor="bg-white" onBack={() => { navigate(BookingRoute.BookingCardCalendarCNMI) }} />
          <div className="p-4">
            {isLoadingBookings ? (
              <div className="flex items-center justify-center pt-48">
                <img src={loadingAnimation} width={'41px'} height={'41px'} />
              </div>
            ) : (
              <div className="flex flex-col space-y-4 overflow-hidden">
                {allUnpaidStatus && allUnpaidStatus.length > 0 ? (
                  allUnpaidStatus.map((val, key) => (
                    <div key={key} className="flex items-center space-x-4">
                      <input
                        type="checkbox"
                        checked={selectedBookings[val.bookingNumber] || false}
                        onChange={(e) => handleCheckboxChange(val.bookingNumber, e.target.checked)}
                        style={{
                          accentColor: '#1F9B66',
                          width: '30px',
                          height: '30px',
                        }}
                      />
                      <BookingCard
                        booking={val}
                        onClick={() => {
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <PageEmpty descriptionTop={t('Oops! Empty')} descriptionDown={t('No unpaid booking')} />
                )}
              </div>
            )}
          </div>
          <div className={`px-4 bg-white ${allUnpaidStatus && allUnpaidStatus.length > 0 ? '' : 'mt-20'}`}>
            <div className="flex justify-between">
              <div className="flex items-end font-bold mt-3">{t('Total')}</div>
              <div className="flex flex-row font-bold text-2xl text-blue-500 mt-3">
                {/* {`${totalPrice ? totalPrice : '-'} ${t('THB')}`} */}
                {totalPrice === 0 ? (
                  <span>{t('Free')}</span>
                ) : (
                  <>
                    <span style={{ textDecoration: 'line-through' }}>{totalPrice} </span>
                    <span>{t('Free')}</span>
                  </>
                )}
              </div>
            </div>
            <div>
              <SingleButtonCard onClick={() => navigate(BookingRoute.LandingPageCNMI)}>{t('Close')}</SingleButtonCard>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
