import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import Header from 'src/components/Header'
import { PAYMENT_METHOD, PAYMENT_STATUS } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BookingStatus, BookingType, ErrorCode, RouteType, TripType, VehicleState } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { useCallApi } from 'src/hooks/useCallApi'
import i18n from 'src/i18n'
import CarSelection from 'src/pageviews/Booking/CarSelection'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import RouteCard from 'src/pageviews/Booking/RouteCard'
import ShowBaggageAndPassenger from 'src/pageviews/Booking/ShowBaggageAndPassenger'
import ShowInfo from 'src/pageviews/Booking/ShowInfo'
import ShowSchedule from 'src/pageviews/Booking/ShowSchedule'
import ShowTotal from 'src/pageviews/Booking/ShowTotal'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'
import {
  bookingCustomerState,
  bookingDepartureState,
  bookingLocationState,
  editState,
  lineState,
  tempBooking,
} from 'src/states'
import { BookingVehicle, Payment } from 'src/types'

export default function Confirm() {
  const [loading, setLoading] = useState(false)
  const { userId } = useRecoilValue(lineState)
  const { routeGo, routeBack, routeType } = useRecoilValue(bookingLocationState)
  const { date, goTime, backTime, vehicle, baggages, followers, isFree, goPrice, backPrice } =
    useRecoilValue(bookingDepartureState)
  const { booker, passenger, emergencyContact, note } = useRecoilValue(bookingCustomerState)
  const [edit, setEdit] = useRecoilState(editState)
  const [shouldNavigate, setShouldNavigate] = useState(false)
  const routerLocation = useLocation()
  const routerState = routerLocation.state as { editSuccess: boolean }
  const setBooking = useSetRecoilState(tempBooking)
  const [serviceTime, setServiceTime] = useState({ startCarServiceTime: '01:00', stopCarServiceTime: '23:00' })
  const { getStartStopCarServiceTime, createRealtimeBooking, createBooking } = useCallApi()
  const isTodayBooking = useMemo(() => dayjstz(date).hour(0).minute(0).isToday(), [date])
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isCarUnavailable, setIsCarUnavailable] = useState(false)
  const [openBooking, setOpenBooking] = useState(false)

  function prepareBooking(): BookingVehicle {
    const booking: BookingVehicle = {
      bookingNumber: edit.bn ?? '',
      type: dayjstz(date).hour(0).minute(0).isToday() ? BookingType.REAL_TIME : BookingType.NORMAL,
      tripType: routeType.isOneWay ? TripType.ONE_WAY : TripType.ROUND_TRIP,
      trip: {
        origin: routeGo.origin,
        destination: routeGo.destination,
        distance: routeGo.distance || 0,
        duration: routeGo.duration || 0,
        price: goPrice ?? 0,
      },
      trip2: !routeType.isOneWay
        ? {
            origin: routeBack.origin,
            destination: routeBack.destination,
            distance: routeBack.distance || 0,
            duration: routeBack.duration || 0,
            price: backPrice ?? 0,
          }
        : undefined,

      scheduledDate: dayjstz(date).hour(0).minute(0).toDate(),
      departureTime: dayjstz(date).hour(goTime.departure.hour).minute(goTime.departure.minute).toDate(),
      arrivalTime: dayjstz(date).hour(goTime.arrival.hour).minute(goTime.arrival.minute).toDate(),
      departureTime2:
        backTime && !routeType.isOneWay
          ? dayjstz(date).hour(backTime.departure.hour).minute(backTime.departure.minute).toDate()
          : undefined,
      arrivalTime2:
        backTime && !routeType.isOneWay
          ? dayjstz(date).hour(backTime.arrival.hour).minute(backTime.arrival.minute).toDate()
          : undefined,
      vehicleId: vehicle ? vehicle.id : 0,
      carModelId: vehicle ? vehicle.carModelId : 0,
      followers,
      baggages,
      note,
      price: goPrice + (backPrice ?? 0),
      status: BookingStatus.NEW,

      bookerName: booker.name,
      bookerPhone: booker.phone,
      bookerLineId: userId ?? '',
      passengerName: passenger.name,
      passengerPhone: passenger.phone,
      passengerLineId: '',
      emergencyContactName: emergencyContact.name,
      emergencyContactPhone: emergencyContact.phone,
      emergencyContactRelation: emergencyContact.relation || '',
      language: i18n.language,
      carId: 0,
      carModel: '',
      modelId: 0,
      carLicensePlateId: '',
      carColor: '',
      carImageUrl: '',
      driverId: '',
      driverName: '',
      driverPhone: '',
      driverEmail: '',
      driverInfo: { cpr: 0, firstaid: 0 },
      state: VehicleState.PENDING,
      seat: 0,
      payments: payments,
    }
    return booking
  }

  async function submit() {
    const booking: BookingVehicle = prepareBooking()
    try {
      setLoading(true)
      setBooking(booking)
      if (!booking.bookingNumber) {
        const { booking: createdBooking, code } = isTodayBooking
          ? await createRealtimeBooking({
              ...booking,
              paymentMethod: PAYMENT_METHOD.CASH,
            })
          : await createBooking({
              ...booking,
              paymentMethod: PAYMENT_METHOD.CASH,
            })
        if (!createdBooking) {
          if (code === ErrorCode.CAR_UNAVAILABLE) {
            setIsCarUnavailable(true)
            setOpenBooking(true)
          }
          return
        }
      }
      setLoading(false)
      navigate(BookingRoute.Payment)
    } catch {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (shouldNavigate) {
      navigate(BookingRoute.Departure)
    }
  }, [shouldNavigate])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (routerState?.editSuccess) {
      toast.success(t('Save successfully'), { autoClose: 2000 })
    }
  }, [])

  const payments: Payment[] = useMemo(
    () =>
      routeType.isOneWay
        ? [
            {
              routeType: RouteType.GO,
              method: 0,
              amount: goPrice,
              paidStatus: isFree ? PAYMENT_STATUS.NOT_PAID : PAYMENT_STATUS.UNPAID,
            },
          ]
        : [
            {
              routeType: RouteType.GO,
              method: 0,
              amount: goPrice,
              paidStatus: isFree ? PAYMENT_STATUS.NOT_PAID : PAYMENT_STATUS.UNPAID,
            },
            {
              routeType: RouteType.BACK,
              method: 0,
              amount: backPrice ?? 0,
              paidStatus: isFree ? PAYMENT_STATUS.NOT_PAID : PAYMENT_STATUS.UNPAID,
            },
          ],
    [isFree, goPrice, backPrice]
  )

  useEffect(() => {
    getStartStopCarServiceTime().then((res) => {
      setServiceTime(res)
    })
  }, [])

  return (
    <>
      <ToastContainer position="top-center" hideProgressBar={false} />
      <Header
        onBack={() => navigate(BookingRoute.Customer)}
        icon={edit.byConfirm ? 'null' : 'back'}
        title={t('Booking Detail')}
      />
      <div className="bg-gray-200 gap-y-1">
        {routeType.isOneWay ? (
          <RouteCard
            routeGo={routeGo}
            arrivalTime={goTime.arrival}
            isEdit
            isFree={isFree}
            readonly={true}
            onEdit={() => {
              setEdit({ ...edit, byConfirm: true })
              navigate(BookingRoute.Location)
            }}
            payments={payments}
          />
        ) : (
          <RouteCard
            routeBack={routeBack}
            routeGo={routeGo}
            arrivalTime={goTime.arrival}
            isEdit
            isFree={isFree}
            readonly={true}
            onEdit={() => {
              setEdit({ ...edit, byConfirm: true })
              navigate(BookingRoute.Location)
            }}
            payments={payments}
          />
        )}
        <ShowTotal isFree={{go:false,back:false}} goPrice={goPrice} backPrice={backPrice} /> 
        <ShowInfo
          booker={booker}
          passenger={passenger}
          emergencyContact={emergencyContact}
          note={note}
          isEdit
          onEdit={() => {
            setEdit({ ...edit, byConfirm: true })
            navigate(BookingRoute.Customer)
          }}
        />
        <ShowSchedule
          routeType={routeType}
          date={date}
          departureTime={goTime.departure}
          arrivalTime={goTime.arrival}
          departureTimeTwo={backTime?.departure ?? { hour: 0, minute: 0 }}
          arrivalTimeTwo={backTime?.arrival ?? { hour: 0, minute: 0 }}
          onEdit={() => {
            setEdit({ ...edit, byConfirm: true })
            setShouldNavigate(true)
          }}
        />
        <ShowBaggageAndPassenger isEdit baggages={baggages} passenger={followers} />
        <CarSelection
          vehicles={[]}
          serviceTime={serviceTime}
          selectedCar={vehicle?.car}
          goDepartureTime={dayjstz(date).hour(goTime.departure.hour).minute(goTime.departure.minute).second(0).toDate()}
          backDepartureTime={
            backTime
              ? dayjstz(date).hour(backTime.departure.hour).minute(backTime.departure.minute).second(0).toDate()
              : undefined
          }
          isEdit
          readonly
          onChange={() => {}}
          onEdit={() => {
            setEdit({ ...edit, byConfirm: true })
            navigate(BookingRoute.Departure, { state: { isEdit: true } })
          }}
          isShowCarNumber={false}
        />
        <div className="mt-3">
          <SingleButtonCard disabled={loading} onClick={submit}>
            {loading && <i className="mr-2 fa fa-circle-o-notch fa-spin"></i>}
            {t('ConfirmPage.Confirm Pick-up')}
          </SingleButtonCard>
        </div>
      </div>
      <ModalCard
        title={t(`Can't make a booking`)}
        detail={
          isCarUnavailable
            ? t('Your car is fully booked, please change your departure time.')
            : t('Please try again or correct the information before booking.')
        }
        status={openBooking}
        singleButton={{ name: t('Close'), type: 'cancel', style: 'btn-primary' }}
        onClick={(val) => {
          setOpenBooking(val)
          setIsCarUnavailable(false)
        }}
      />
    </>
  )
}
