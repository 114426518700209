type Props = {
  title: string
  isChecked: boolean
  onCheck: (e: boolean) => void
}

export default function CheckboxButton({ title, isChecked, onCheck }: Props) {
  return (
    <div className={`btn ${isChecked ? 'btn-primary' : 'btn-outline-primary'} mb-2 mr-2`}>
      <label className="p-5">
        <input
          className="hidden appearance-none default:display"
          type="checkbox"
          id="checkbox"
          defaultChecked={isChecked}
          value={title}
          onChange={() => onCheck(!isChecked)}
        />
        {title}
      </label>
    </div>
  )
}
