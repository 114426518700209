type Props = {
  title: string
  onClick: () => void
  bgColor: string
  textColor: string
}

export default function TabButton({ title, bgColor, textColor, onClick }: Props) {
  return (
    <button className={`w-40 px-2 py-1 mr-3 text-base rounded-full ${bgColor} ${textColor}`} onClick={onClick}>
      {title}
    </button>
  )
}
