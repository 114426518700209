import { PAYMENT_METHOD, PLACE_TYPE } from 'src/consts'
import { BookingType, UserCNMIFetchingStateValue, VehicleState } from 'src/enums'

export interface Booking {
  bookingNumber: string
  type: BookingType
  tripType: number
  trip: Trip
  trip2?: Trip
  scheduledDate: Date
  departureTime?: Date
  departureTime2?: Date
  arrivalTime?: Date
  arrivalTime2?: Date
  passengerName: string
  passengerPhone: string
  passengerLineId: string
  emergencyContactName: string
  emergencyContactPhone: string
  emergencyContactRelation: string
  bookerName: string
  bookerPhone: string
  bookerLineId: string
  vehicleId: number
  carModelId: number
  followers: number
  baggages: Baggage
  note: string
  price: number
  status: number
  createdAt?: Date
  updatedAt?: Date
  vehicleState?: number
  driverImageUrl?: string
  language?: string
}

export interface Bookings {
  bookings: Booking[]
  count: number
}

export interface IBooking extends Booking {
  paymentMethod: PAYMENT_METHOD
}

export interface BookingVehicle extends Booking {
  carId: number
  carModel: string
  modelId: number
  carLicensePlateId: string
  carColor: string
  carImageUrl: string
  driverId: string
  driverName: string
  driverPhone: string
  driverEmail: string
  driverInfo: DriverInfo
  state: VehicleState
  seat: number
  payments: Payment[]
}

export interface PublicBooking {
  booking: BookingVehicle
  driver: Driver
  car: Car
  driverLocation: DriverLocation
}

export interface CreateBookingResponse {
  code: string
  message: string
  booking: Booking | null
}

export interface Customer {
  booker: Person
  passenger: Person
  emergencyContact: Person
  checked: number
  note: string
}

export interface appointmentCNMI {
  user: Person
  contacts: Person[]
  appointDate: Date
}

export interface BookerContact {
  phone: string
  lineId: string
  name: string
  language: string
  contacts: Person[]
}

export interface BookerContactCNMI {
  phone: string
  lineId: string
  name: string
  language: string
  contacts: Person[]
  addressHistory: AddressHistoryPerson[]
}

export interface UpdateCustomer {
  lineId?: string
  phone?: string
  name?: string
  language?: string
  contacts?: Person[]
}

export interface CreateCustomer {
  lineId?: string
  phone?: string
  name?: string
  surname?: string
  language?: string
  contacts: Person[]
  addressHistory?: AddressHistoryPerson[]
}

export interface DriverLocation {
  time?: string
  lat?: string
  lng?: string
  state: number
  duration: number
}
export interface DriverInfo {
  cpr: number
  firstaid: number
}
export interface Car {
  id: number
  model: string
  modelId: number
  color: string
  licensePlateId: string
  seat: number
  imageUrl?: string
  accessories?: { [key: string]: string }[]
}

export interface Driver {
  id?: number
  name: string
  phone: string
  email: string
  licenseId: string
  licenseExpiredDate: string
  photoId?: string
  expertise: number
  skills?: string
  imageUrl?: string
  isActive: boolean

  createdAt?: Date
  updatedAt?: Date
  deletedAt?: Date
}

export interface DriverInfo {
  cpr: number
  firstaid: number
}

export interface IFeedback {
  id: number
  bookingNumber: string
  routeType: number
  questionId: number
  answer: number
  bookerComment: string
  passengerComment: string
  createdAt?: string
}

export interface FeedbackStatus {
  id: number
  answer: FeedbackAnswer
}

export interface FeedbackAnswer {
  like: number | null
  note: string | null
  choice: string | null
}
export interface Vehicle {
  id: number
  date: string
  carId: number
  driverId?: number
  car?: Car
  driver?: Driver
  carModelId: number
  isFree?: boolean
}

export interface Payment {
  id?: string
  bookingNumber?: string
  routeType: number
  method: number
  amount: number
  paidStatus: number
  paidAt?: Date
  paymentSlipPath?: string
  paymentSlipUrl?: string
  driverReceivedAt?: Date
}

export interface CarAvailable {
  isFree?: boolean
  vehicle: Vehicle
  availability: {
    isAvailable: boolean
    isGoTripAvailable: boolean
    isBackTripAvailable: boolean
    goTripAvailableTime: {
      earliest: Date
      latest: Date
    }[]
    backTripAvailableTime: {
      earliest: Date
      latest: Date
    }[]
  }
}

export interface AvailableVehicle extends Partial<CarAvailable>, CarModel {
  price: {
    go: number
    back?: number
  }
}

export interface Payment {
  id?: string
  bookingNumber?: string
  routeType: number
  method: number
  amount: number
  paidStatus: number
  paidAt?: Date
  paymentSlipPath?: string
  paymentSlipUrl?: string
}

export interface Person {
  name: string
  phone: string
  lineId?: string
  relation?: string
  lat?: string
  lng?: string
}

export interface AddressHistoryPerson {
  addressname?: string
  address?: string
  lat?: string
  lng?: string
  distance?: number
  id?: string
  pickupDetail?: string
  phone?: string
  name?: string
}

export interface Place {
  id: string
  name: string
  address?: string
  post_code?: string
  lat?: string
  lng?: string
  note?: string
  disabled?: boolean
}
export interface PostFeedback {
  bookingNumber: string
  lineId: string
  answers: FeedbackStatus[]
  bookerComment: string
  passengerComment: string
}

export interface IRequestTrial {
  id?: number
  lineId: string
  department: string
  symptom: string
  citizenId: string
  language: string
}

export interface Question {
  id: number
  parentId: number
  position: number
  bodyTh: string
  bodyEn: string
  type: number
  choices: QuestionChile[]
}

export interface QuestionChile {
  titleTh: string
  titleEn: string
  url?: string
}

export interface Questions {
  questions?: TopicQuestion[]
  count?: number
}

export interface Route {
  origin: Place
  destination: Place
  distance?: number
  duration?: number
}

export interface RouteDetail {
  distance: number
  duration: number
}

export interface RouteType {
  isOneWay: boolean
  placeType: PLACE_TYPE
  showRoute?: boolean
}

export enum InviteState {
  LOADING = 1,
  NOT_FOUND = 0,
  USED = 2,
  SUCCESS = 3,
}

export interface Baggage {
  items: number[]
  note: string
}

export interface TimePackage {
  departure: TimeUnit
  arrival: TimeUnit
}

export interface TimeUnit {
  hour: number
  minute: number
}

export interface StartStopTime {
  startAvailableHour: string
  startAvailableMinute: string
  endAvailableHour: string
  endAvailableMinute: string
  cutOffTimeHour: string
  cutOffTimeMinute: string
}

export interface Trip {
  origin: Place
  destination: Place
  distance: number
  duration: number
  price: number
  fromBase?: number
  toBase?: number
}

export interface User {
  id: string
  name: string
}

export interface Edit {
  byConfirm: boolean
  byView: boolean
  bn: string
}
export interface Staff {
  name: string
  phone: string
}

export interface TopicQuestion {
  id: number
  parentId: number
  position: number
  bodyTh: string
  bodyEn: string
  questions?: { data: Question[]; count?: number }
}

export type QueryBooking = Partial<{
  date: string
  historyBooking: boolean
  status: number
  statuses: number[]
  startDate: string
  endDate: string
}>

export interface CarModel {
  id: number
  model: string
  seat: number
  accessories: { [key: string]: string }[]
  imageUrl: string
  isActive: boolean
  pricingModelId: number
}

export interface IConsent {
  isAccepted: boolean
}

export interface CarModelPrice {
  id: number
  go: number
  back: number
}

export interface SigninData {
  userId: string;
  domain: string;
}

export interface PaymentMethod {
  qrCodeImageUrl: string;
  transferEN_account: string;
  transferEN_bank: string;
  transferEN_id: string;
  transferTH_account: string;
  transferTH_bank: string;
  transferTH_id: string;
}

export interface trip {
  pickup?: TimeUnit
  arrive?: TimeUnit
}

export interface Timeslot {
  isGoing: boolean
  isAvailable: boolean
  time: TimeUnit
}

export interface CustomerCNMIInterface {
  lineId?: string,
  phone?: string,
  name?: string,
  lat?: string,
  lng?: string,
  distance?: number,
  duration?: number,
}

export interface CustomerAddressCNMIInterface {
  lineId?: string,
  name?: string,
  phone?: string,
  addressname?: string,
  address?: string
  lat?: string,
  lng?: string,
  distance?: number
  Id?: string
  pickupDetail?: string,
}

export interface CustomerBookingInfoCNMIInterface {
  lineId?: string,
  phone?: string,
  name?: string,
  lat?: string,
  lng?: string,
  distance?: number,
  duration?: number,
}

export interface CustomerCNMIRecoilProps extends CustomerCNMIInterface {
  fetchState: UserCNMIFetchingStateValue,
  postCode?: string,
  id?: string,
  addressName?: string,
  address?: string
  pickUpPoint?: string
}