import { useEffect, useState } from 'react'

type Props = {
  leftToggle: string
  rightToggle: string
  isOneWay: boolean
  onChange: (s: boolean) => void
}

export default function SwitchButton({ leftToggle, rightToggle, isOneWay, onChange }: Props) {
  const [toggle, setToggle] = useState(isOneWay)
  const active = `bg-blue-500 inline-block py-2 text-white`
  const link = `bg-gray-100 inline-block py-2 text-blue-500`

  useEffect(() => {
    onChange(toggle)
  }, [toggle])

  return (
    <div className="flex justify-center w-full px-8">
      <button
        className={`${toggle ? active : link} rounded-l-lg w-1/2`}
        type="button"
        onClick={() => setToggle(!toggle)}
        disabled={toggle}
      >
        {leftToggle}
      </button>
      <button
        className={`${toggle ? link : active} rounded-r-lg w-1/2`}
        type="button"
        onClick={() => setToggle(!toggle)}
        disabled={!toggle}
      >
        {rightToggle}
      </button>
    </div>
  )
}
