import liff from '@line/liff/dist/lib'
import { useTranslation } from 'react-i18next'
import hourglass from 'src/assets/icons/icon-hourglass.svg'
import Button from 'src/components/Button/Base'

export default function Success() {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-col items-center w-full h-screen text-white bg-blue-500">
        <div className="h-60"></div>
        <div className="flex place-content-center">
          <img src={hourglass} width={'80px'} height={'80px'} />
        </div>
        <strong className="py-5">{t('You are pending approval')}</strong>
        <p className="py-3 text-center w-60">
          {t('Your request is on process, your result will be notice as soon by line')}
        </p>
      </div>
      <div className="fixed bottom-0 w-full p-5 mb-5 space-y-3">
        <Button type={'btn btn-light block'} onClick={() => liff.closeWindow()}>
          {t('Back to chat')}
        </Button>
      </div>
    </>
  )
}
