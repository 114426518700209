import { Loader } from '@googlemaps/js-api-loader'
import { Places } from 'src/consts'

declare global {
  interface Window {
    googleMapsLoader: Loader
  }
}

export function getPlaceInfo(lang: string) {
  return Places.SamitivejSrinakarinHospital[lang]
}

export function useGoogleMaps() {
  function initGoogleMapsLoader(language: string) {
    window.googleMapsLoader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_API ?? '',
      region: 'TH',
      language: language || 'th',
      version: 'weekly',
      libraries: ['places', 'geometry'],
    })
  }

  function getGoogleMapsLoader() {
    return window.googleMapsLoader
  }

  function getGeoLocation(request: google.maps.GeocoderRequest): Promise<google.maps.GeocoderResult> {
    return new Promise((resolve, reject) => {
      getGoogleMapsLoader()
        .load()
        .then(() => {
          const geocoder = new google.maps.Geocoder()
          geocoder.geocode(
            { ...request, region: 'th' },
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
              if (status === google.maps.GeocoderStatus.OK) {
                resolve(results[0])
              } else {
                reject(status)
              }
            }
          )
        })
    })
  }

  function searchLocation(input: string): Promise<google.maps.places.QueryAutocompletePrediction[]> {
    return new Promise((resolve) => {
      getGoogleMapsLoader()
        .load()
        .then(() => {
          const service = new google.maps.places.AutocompleteService()
          const displaySuggestions = function (
            predictions: google.maps.places.QueryAutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
          ) {
            if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
              resolve([])
              return
            }
            resolve(predictions)
          }
          service.getPlacePredictions({ input, componentRestrictions: { country: 'th' } }, displaySuggestions)
        })
    })
  }

  function calculateWaypoints(originID: string, destinationID: string): Promise<google.maps.DirectionsResult> {
    const directionsService = new google.maps.DirectionsService()
    return new Promise((resolve, reject) => {
      getGoogleMapsLoader()
        .load()
        .then(() => {
          directionsService.route(
            {
              origin: {
                placeId: 'ChIJXwzp9cJDHTERPKhK7yyRucg',
              },
              destination: {
                placeId: destinationID,
              },
              waypoints: [{ location: { placeId: originID }, stopover: true }],
              travelMode: google.maps.TravelMode.DRIVING,
              unitSystem: google.maps.UnitSystem.METRIC,
            },
            (res, status) => {
              if (status == google.maps.DirectionsStatus.OK) {
                resolve(res)
              } else {
                reject(0)
              }
            }
          )
        })
    })
  }

  function calculateDriectionFromLatLng(
    origin: google.maps.LatLngLiteral,
    destination: google.maps.LatLngLiteral
  ): Promise<google.maps.DirectionsResult> {
    const directionsService = new google.maps.DirectionsService()
    return new Promise((resolve, reject) => {
      getGoogleMapsLoader()
        .load()
        .then(() => {
          directionsService.route(
            {
              origin: {
                location: origin,
              },
              destination: {
                location: destination,
              },
              travelMode: google.maps.TravelMode.DRIVING,
              unitSystem: google.maps.UnitSystem.METRIC,
            },
            (res, status) => {
              if (status == google.maps.DirectionsStatus.OK) {
                resolve(res)
              } else {
                reject(0)
              }
            }
          )
        })
    })
  }

  return {
    initGoogleMapsLoader,
    getGoogleMapsLoader,
    getGeoLocation,
    searchLocation,
    calculateWaypoints,
    calculateDriectionFromLatLng,
  }
}

export function getCurrentLocation(): Promise<google.maps.LatLngLiteral> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition((success: GeolocationPosition) =>
      resolve({ lat: success.coords.latitude, lng: success.coords.longitude })
    ),
      (err: GeolocationPositionError) => reject(err)
  })
}

export function calculateAndDisplayRoute(
  originID: string,
  destinationID: string
): Promise<google.maps.DirectionsResult> {
  const directionsService = new google.maps.DirectionsService()
  return new Promise((resolve, reject) => {
    directionsService.route(
      {
        origin: {
          placeId: originID,
        },
        destination: {
          placeId: destinationID,
        },
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      },
      (res, status) => {
        if (status == google.maps.DirectionsStatus.OK) {
          resolve(res)
        } else {
          reject(0)
        }
      }
    )
  })
}

export function makeMarker(
  position: google.maps.LatLng | google.maps.ReadonlyLatLngLiteral,
  icon: string,
  title: string,
  map: google.maps.Map,
  animation?: google.maps.Animation,
  zIndex?: number,
  anchor?: google.maps.Point
): google.maps.Marker {
  const marker = new google.maps.Marker({
    position: position,
    map: map,
    animation: animation,
    icon: {
      url: icon,
      anchor: anchor ? anchor : new google.maps.Point(20, 45),
    },
    title: title,
    zIndex,
  })
  return marker
}

// last check checkPostalCode method
export function checkPostalCode(postal_code: string): boolean {
  const service_area = ['10', '11', '12', '73', '74']
  postal_code = postal_code.slice(0, 2)
  for (let i = 0; i < service_area.length; i++) {
    if (postal_code === service_area[i]) {
      return true
    }
  }
  return false
}

export function checkServicePostalCode(postal_code: string, service_postal_code: Array<string>): boolean {
  let service_area = ['']
  if (service_postal_code) {
    service_area = service_postal_code
  }

  for (let i = 0; i < service_area.length; i++) {
    if (postal_code === service_area[i]) {
      return true
    }
  }

  return false
}
