import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { t } from 'i18next'
import { Fragment, useMemo } from 'react'
import { padZero } from 'src/helpers/utils'
import { TimeUnit } from 'src/types'

type Props = {
  limitStartTime: { hour: string; minute: string }
  limitStopTime: { hour: string; minute: string }
  isAlert: boolean
  time: TimeUnit
  isDisabled?: boolean
  onChange: (time: TimeUnit) => void
}

export default function TimePicker({ isDisabled, limitStartTime, limitStopTime, time, isAlert, onChange }: Props) {

  const hours = useMemo(() => {
    const temp = []
    for (let i = parseInt(limitStartTime.hour); i <= parseInt(limitStopTime.hour); i++) {
      temp.push(i)
    }
    // const temp = Array.from(Array(24).keys())
    return temp
  }, [limitStartTime, limitStopTime])

  const minutes = useMemo(() => {
    const temp = []
    for (let i = (time.hour === parseInt(limitStartTime.hour) ? parseInt(limitStartTime.minute): 0); i <= (time.hour === parseInt(limitStopTime.hour) ? parseInt(limitStopTime.minute) : 59); i++) {
      temp.push(i)
    }
    // const temp = Array.from(Array(60).keys())
    return temp
  }, [limitStartTime, limitStopTime, time.hour])

  return (
    <div className="flex flex-row items-center">
      <Listbox disabled={isDisabled} value={time.hour} onChange={(e) => onChange({ hour: Number(e), minute: Number(time.minute) })}>
        <div className="w-32 mt-1">
          <Listbox.Button
            className={`${
              isAlert && 'border-solid border-2 border-red-500'
            } relative w-full py-2 pl-3 pr-10 text-left ${isDisabled ? 'bg-gray-300' : 'bg-white'} rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm`}
          >
            <span data-testid="test-timepicker-hour" className={`block truncate ${isDisabled ? 'text-gray-500' : ''}`}>{padZero(time.hour)}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className='absolute z-50 w-32 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {hours.map((val, Idx) => (
                <Listbox.Option
                  key={Idx}
                  className={({ active }) =>
                    `${active && 'text-blue-900 bg-blue-100'} cursor-default select-none py-2 pl-10 pr-4 z-50`
                  }
                  value={val}
                >
                  <div className="flex flex-row">
                    <span className={`${time.hour === val ? 'font-semibold' : 'font-normal'} block truncate`}>
                      {padZero(val)}
                    </span>
                    <span className={`text-blue-600 inset-y-0 left-0 flex items-center pl-3`}>
                      {time.hour === val ? <CheckIcon className="w-5 h-5" aria-hidden="true" /> : ''}
                    </span>
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <span className="pl-2 pr-5 font-semibold">{t('Hour')}</span>
      <Listbox disabled={isDisabled} value={time.minute} onChange={(e) => onChange({ hour: Number(time.hour), minute: Number(e) })}>
        <div className="w-32 mt-1">
          <Listbox.Button
            className={`${
              isAlert && 'border-solid border-2 border-red-500'
            } relative w-full py-2 pl-3 pr-10 text-left ${isDisabled ? 'bg-gray-300' : 'bg-white'} rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm`}
          >
            <span data-testid="test-timepicker-miniute" className={`block truncate ${isDisabled ? 'text-gray-500' : ''}`}>{padZero(time.minute)}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-50 w-32 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {minutes.map((val, Idx) => (
                <Listbox.Option
                  key={Idx}
                  className={({ active }) =>
                    `${active && 'text-blue-900 bg-blue-100'} cursor-default select-none py-2 pl-10 pr-4 z-50`
                  }
                  value={val}
                >
                  <div className="flex flex-row">
                    <span className={`${time.minute === val ? 'font-semibold' : 'font-normal'} block truncate`}>
                      {padZero(val)}
                    </span>
                    <span className={`text-blue-600 inset-y-0 left-0 flex items-center pl-3`}>
                      {time.minute === val ? <CheckIcon className="w-5 h-5" aria-hidden="true" /> : ''}
                    </span>
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <label className="px-2 font-semibold">{t('Minute')}</label>
    </div>
  )
}
