import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import loadingAnimation from 'src/assets/icons/loading.svg'
import SwitchButton from 'src/components/Button/SwitchButton'
import TabButton from 'src/components/Button/tabButton'
import CustomDatePicker from 'src/components/CustomDatePicker'
import Header from 'src/components/Header'
import { BookingRoute } from 'src/consts/route'
import { BookingStatus } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { isDev } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import BookingCard from 'src/pageviews/Booking/BookingCard'
import Loading from 'src/pageviews/Booking/Loading'
import PageEmpty from 'src/pageviews/Booking/PageEmpty'
import { lineState, pathState } from 'src/states'
import { Bookings, QueryBooking } from 'src/types'

export default function BookingList() {
  const { t } = useTranslation()
  const { getBookings } = useCallApi()
  const [loading, setLoading] = useState(false)
  const [isLoadingBookings, setIsLoadingBookings] = useState(false)
  const setPath = useSetRecoilState(pathState)
  const navigate = useNavigate()
  const [bookingData, setBookingData] = useState<Bookings | null>()
  const [activeButton, setActiveButton] = useState<BookingStatus>(BookingStatus.NEW)

  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const { userId } = useRecoilValue(lineState)
  const [clearDate, setClearDate] = useState(false)
  const [isUpcoming, setIsUpcoming] = useState(true)

  const queryParams = new URLSearchParams(window.location.search)
  const domain = queryParams.get('domain')

  async function fetchBookings(status: number) {
    setIsLoadingBookings(true)
    const query: QueryBooking = {
      historyBooking: true,
      status,
      date: dayjstz(startDate).format('YYYY-MM-DD').toString(),
      startDate: dayjstz(startDate).format('YYYY-MM-DD').toString(),
      endDate: dayjstz(endDate).format('YYYY-MM-DD').toString(),
    }
    if (isUpcoming) delete query.historyBooking
    if (endDate && startDate) delete query.date
    if (!startDate) delete query.date
    if (!endDate) {
      delete query.startDate
      delete query.endDate
    }

    const data = await getBookings(query)
    setBookingData(data)
    setIsLoadingBookings(false)
  }

  function onChangeButton(status: number) {
    setActiveButton(status)
    fetchBookings(status)
    setStartDate(null)
    setEndDate(null)
    setClearDate(!clearDate)
  }

  function onChangeToggleButton(s: boolean) {
    setIsUpcoming(s)
    if (s) {
      setActiveButton(BookingStatus.NEW)
    } else {
      setActiveButton(BookingStatus.COMPLETED)
    }
    setStartDate(null)
    setEndDate(null)
    setClearDate(!clearDate)
  }

  function getBgColor(tab: number, currentTab: number, bgColor: string) {
    if (isUpcoming) {
      if (tab === currentTab) {
        return bgColor
      } else {
        return 'bg-white'
      }
    }
    if (!isUpcoming) {
      if (tab === currentTab) {
        return bgColor
      } else {
        return 'bg-white'
      }
    }
    return 'bg-white'
  }

  function getTextColor(active: boolean) {
    if (active) {
      return 'text-white'
    } else {
      return 'text-blue-500'
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        if (userId || isDev) {
          fetchBookings(activeButton)
          
        }
      } catch {
        setBookingData({ bookings: [], count: 0 })
      } finally {
        setLoading(false)
      }
    })()
  }, [startDate, endDate, userId, isUpcoming])

  return (
    <div className={`h-screen ${isUpcoming ? 'bg-powderblue' : 'bg-blue-150'}`}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header title={t('My booking')} icon={'null'} bgColor="bg-white" />
          <div className={`p-4 ${isUpcoming ? 'bg-powderblue' : 'bg-blue-150'}`}>
            <div className="py-3">
              <SwitchButton
                leftToggle={t('Upcoming')}
                rightToggle={t('Completed')}
                onChange={(s) => onChangeToggleButton(s)}
                isOneWay={isUpcoming}
              />
            </div>
            <div className="flex pt-5 mb-4 flex-start">
              <TabButton
                title={isUpcoming ? t('Status.Wait') : t('Status.Completed')}
                onClick={() => onChangeButton(isUpcoming ? BookingStatus.NEW : BookingStatus.COMPLETED)}
                bgColor={getBgColor(
                  isUpcoming ? BookingStatus.NEW : BookingStatus.COMPLETED,
                  activeButton,
                  isUpcoming ? 'bg-blue-400' : 'bg-green-500'
                )}
                textColor={getTextColor(activeButton === BookingStatus.NEW || activeButton === BookingStatus.COMPLETED)}
              />
              <TabButton
                title={isUpcoming ? t('Status.In progress') : t('Status.Canceled')}
                onClick={() => onChangeButton(isUpcoming ? BookingStatus.IN_PROGRESS : BookingStatus.CANCELED)}
                bgColor={getBgColor(
                  isUpcoming ? BookingStatus.IN_PROGRESS : BookingStatus.CANCELED,
                  activeButton,
                  isUpcoming ? 'bg-orange-500' : 'bg-red-500'
                )}
                textColor={getTextColor(
                  activeButton === BookingStatus.IN_PROGRESS || activeButton === BookingStatus.CANCELED
                )}
              />
            </div>
            {!isUpcoming && (
              <div className="flex flex-row justify-between">
                <CustomDatePicker
                  clearDate={clearDate}
                  onChangeStartDate={(value): void => setStartDate(value)}
                  onChangeEndDate={(value): void => setEndDate(value)}
                />
              </div>
            )}
            {isLoadingBookings ? (
              <div className="flex items-center justify-center pt-48">
                <img src={loadingAnimation} width={'41px'} height={'41px'} />
              </div>
            ) : (
              <div className="flex flex-col space-y-4 overflow-hidden">
                {bookingData?.count ? (
                  bookingData.bookings.map((val, key) => (
                    <BookingCard
                      booking={val}
                      key={key}
                      onClick={() => {
                        setPath({ fromMenu: true, prvePath: location.pathname })
                        navigate(`${BookingRoute.View}/?bn=${val.bookingNumber}${domain ? `&domain=${domain}` : ''}`)
                      }}
                    />
                  ))
                ) : (
                  <PageEmpty
                    descriptionTop={t('Oops! Empty')}
                    descriptionDown={
                      startDate && startDate !== 'Invalid Date'
                        ? t('No booking on date selected.')
                        : isUpcoming
                        ? t('When you booked completed. Your booking list will show here.')
                        : t('Finish booking or Cancel booking Your booking list will show here.')
                    }
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
