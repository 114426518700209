import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'src/helpers/number'

type Props = {
  // isFree: boolean
  isFree: { go: boolean; back: boolean }
  bookingNumber?: string
  goPrice: number
  backPrice: number | undefined
}

export default function ShowTotal({ isFree, bookingNumber, goPrice, backPrice }: Props) {
  const { t } = useTranslation()

  // const total = useMemo(() => goPrice + (backPrice ?? 0), [goPrice, backPrice])

  //1 GO BACK are free(true) or GO && BACK are not free(false): total = GO + BACK
  //2 GO free , BACK not free : total = BACK
  //3 GO not free , BACK free : total = GO

  const total = useMemo(() => {
    if ((isFree.go && isFree.back) || !(isFree.go || isFree.back)) {
      return goPrice + (backPrice ?? 0);
    } else if (isFree.go && !isFree.back) {
      return backPrice ?? 0;
    } else if (!isFree.go && isFree.back) {
      return goPrice;
    } else {
      return 0;
    }
  }, [goPrice, backPrice, isFree]);

  return (
    <div className="block p-4 space-y-1 font-semibold bg-white">
      {bookingNumber && (
        <div className="flex justify-between">
          <div className="flex items-end opacity-60">{t('Booking Number')}</div>
          <div className="text-bas">
            <div className="flex flex-row opacity-60">
              <div>{bookingNumber}</div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <div className="flex items-end opacity-60">{t('Total')}</div>
        <div className="text-2xl text-blue-500">
          <div className="flex flex-row">
            {isFree.go &&isFree.back ? (
              <>
                <div className="text-blue-500 line-through">{`${formatNumber(total)} ${t('THB')}`}</div>
                <div className="pl-1">{t('Free')}</div>
              </>
            ) : (
              <div className="text-blue-500">{`${formatNumber(total)} ${t('THB')}`}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
