import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BufferedRealTimeMinutes, BufferedTravelTime } from 'src/consts'
import { dayjstz } from 'src/helpers/datetime'
import { padZero } from 'src/helpers/utils'
import { Route, TimePackage, TimeUnit } from 'src/types'
import TimePicker from './TimePicker'

type Props = {
  route: Route
  date: Date
  time: TimePackage
  serviceTime: { startCarServiceTime: string; stopCarServiceTime: string }
  cutOffTime: { cutOffAdvanceBookingTime: string }
  isEdit?: boolean
  hasNoCarOrVehicleActive: boolean
  onChange: (time: TimePackage) => void
  onValidate: (v: boolean) => void
}

export default function DateCardGo({
  route,
  date: departureDate,
  time,
  serviceTime,
  hasNoCarOrVehicleActive,
  onChange,
  onValidate,
}: Props) {
  const { t } = useTranslation()

  const { departure, arrival } = time

  const RouteMinutes = useMemo(() => Math.round((route.duration ?? 0) / 60), [route])

  const parsedServiceTime = useMemo(() => {
    const [startHour, startMinute] = serviceTime.startCarServiceTime.split(':')
    const [stopHour, stopMinute] = serviceTime.stopCarServiceTime.split(':')
    return {
      startCarServiceTime: {
        hour: startHour,
        minute: startMinute,
      },
      stopCarServiceTime: {
        hour: stopHour,
        minute: stopMinute,
      },
    }
  }, [serviceTime])

  const calculatedStartTime = useMemo(() => {
    let temp = parsedServiceTime.startCarServiceTime
    if (dayjstz(departureDate).isToday()) {
      if (
        dayjstz().isAfter(
          dayjstz()
            .hour(parseInt(parsedServiceTime.startCarServiceTime.hour))
            .minute(parseInt(parsedServiceTime.startCarServiceTime.minute))
        )
      ) {
        const currentAddBuffer = dayjstz().add(BufferedRealTimeMinutes, 'minute')
        temp = {
          hour: currentAddBuffer.format('HH'),
          minute: currentAddBuffer.format('mm'),
        }
      }
    }
    return temp
  }, [departureDate, serviceTime])

  const checkNotInServiceTime = useMemo(() => {
    return (
      dayjstz(departureDate)
        .hour(departure.hour)
        .minute(departure.minute)
        .isBefore(
          dayjstz(departureDate)
            .hour(parseInt(parsedServiceTime.startCarServiceTime.hour))
            .minute(parseInt(parsedServiceTime.startCarServiceTime.minute))
        ) ||
      dayjstz(departureDate)
        .hour(departure.hour)
        .minute(departure.minute)
        .isAfter(
          dayjstz(departureDate)
            .hour(parseInt(parsedServiceTime.stopCarServiceTime.hour))
            .minute(parseInt(parsedServiceTime.stopCarServiceTime.minute))
        )
    )
  }, [parsedServiceTime, departure])

  const dateCardError = useMemo(() => {

    if (checkNotInServiceTime)
      return (
        t('Please select service time') +
        parseInt(parsedServiceTime.startCarServiceTime.hour) +
        ':' +
        parsedServiceTime.startCarServiceTime.minute +
        ' - ' +
        parseInt(parsedServiceTime.stopCarServiceTime.hour) +
        ':' +
        parsedServiceTime.stopCarServiceTime.minute
      )

    // alert at timePicker but msg will be show on car selection
    // if (hasNoCarOrVehicleActive) return ''

    return null
  }, [hasNoCarOrVehicleActive, t, checkNotInServiceTime])

  useEffect(() => {
    onValidate(dateCardError === null)
  }, [dateCardError])

  function handleChangeDeparture(t: TimeUnit) {
    const arrived = dayjstz(departureDate)
      .hour(t.hour)
      .minute(t.minute)
      .add(RouteMinutes, 'minute')
      .add(BufferedTravelTime, 'minute')
    const newTime = { ...time, arrival: { hour: arrived.hour(), minute: arrived.minute() } }
    let newDepartTime = t
    if (
      t.hour === parseInt(parsedServiceTime.startCarServiceTime.hour) &&
      departure.minute < parseInt(parsedServiceTime.startCarServiceTime.minute)
    ) {
      newDepartTime = { hour: t.hour, minute: parseInt(parsedServiceTime.startCarServiceTime.minute) }
    } else if (
      t.hour === parseInt(parsedServiceTime.stopCarServiceTime.hour) &&
      departure.minute > parseInt(parsedServiceTime.stopCarServiceTime.minute)
    ) {
      newDepartTime = { hour: t.hour, minute: parseInt(parsedServiceTime.stopCarServiceTime.minute) }
    }
    newTime.departure = newDepartTime
    onChange(newTime)
  }

  return (
    <div className="flex flex-col px-4 pb-4 space-y-2 bg-white">
      <label htmlFor="finishTime" className="block pt-2 text-lg font-light text-gray-700">
        {t('Departure time')}
      </label>
      <label
        htmlFor="alertTime"
        className="block text-sm font-light text-gray-500"
        data-testid="date-card-go_error-message-time"
      >
        {t('Available service time ')}
        {parseInt(parsedServiceTime.startCarServiceTime.hour)}:{parsedServiceTime.startCarServiceTime.minute}
        {' - '}
        {parseInt(parsedServiceTime.stopCarServiceTime.hour)}:{parsedServiceTime.stopCarServiceTime.minute}
      </label>
      <TimePicker
        limitStartTime={calculatedStartTime}
        limitStopTime={parsedServiceTime.stopCarServiceTime}
        isAlert={dateCardError !== null}
        time={departure}
        onChange={handleChangeDeparture}
      />
      <label
        htmlFor="alertTime"
        className="block text-sm font-light text-red-500"
        data-testid="date-card-go_error-message-time"
      >
        {dateCardError}
      </label>
      <label className="block pt-2 text-lg font-light text-gray-700">
        {`${t('Estimate time arrival')}:`}
        <strong className="pl-2 font-semibold">
          {padZero(arrival.hour)}:{padZero(arrival.minute)}
        </strong>
      </label>
    </div>
  )
}
