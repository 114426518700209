import { useState } from 'react'
import { Vote } from 'src/enums'
import i18n from 'src/i18n'
import { Question } from 'src/types'

type PropsAnswer = {
  id: number
  like: Vote | null
  note: string | null
  choice: string | null
}

type Props = {
  question: Question
  answer: ({ id, like, note, choice }: PropsAnswer) => void
}

const QuestionChoice = ({ question, answer }: Props) => {
  const [check, setCheck] = useState(0)

  return (
    <div className="pb-2">
      <div className="flex flex-row justify-between pb-2">
        <div className="text-base">{i18n.language === 'th' ? question.bodyTh : question.bodyEn}</div>
      </div>
      <div className="flex flex-col items-start">
        {question.choices.map((item, index) => (
          <div className="flex justify-start px-1 pb-2" key={index}>
            <input
              className="w-4 h-4 mt-1 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
              type="radio"
              value={item.titleTh}
              checked={check === index + 1}
              onChange={(v) => {
                answer({ id: question.id, like: null, note: null, choice: v.target.value })
                setCheck(index + 1)
              }}
            />
            <div className="px-2 text-sm ">{i18n.language === 'th' ? item.titleEn : item.titleTh}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default QuestionChoice
