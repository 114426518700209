import liff from '@line/liff/dist/lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import Header from 'src/components/Header'
import ViewShowCarCard from 'src/components/ViewShowCarCard'
import { PAYMENT_STATUS, PLACE_TYPE } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BookingStatus, RouteType as RouteTypes, TripType, VehicleState } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { decoratePhoneNumber } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import useInterval from 'src/hooks/useInterval'
import DriverCard from 'src/pageviews/Booking/DriverCard'
import Duration from 'src/pageviews/Booking/Duration'
import Loading from 'src/pageviews/Booking/Loading'
import Map from 'src/pageviews/Booking/Map'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import Page404 from 'src/pageviews/Booking/Page404'
import RouteCard from 'src/pageviews/Booking/RouteCard'
import ShowBaggageAndPassenger from 'src/pageviews/Booking/ShowBaggageAndPassenger'
import ShowInfo from 'src/pageviews/Booking/ShowInfo'
import ShowSchedule from 'src/pageviews/Booking/ShowSchedule'
import ShowTotal from 'src/pageviews/Booking/ShowTotal'
import Tracking from 'src/pageviews/Booking/Tracking'
import {
  editState,
  pathState,
  publicBookingState,
  viewCustomerState,
  viewDepartureState,
  viewLocationState,
} from 'src/states'
import { BookingVehicle, Driver, DriverLocation, Route, RouteType, Staff } from 'src/types'
import SingleButtonCard from './SingleButtonCard'

export default function Cancel() {
  const { t } = useTranslation()

  const [openContact, setOpenContact] = useState(false)
  const [booking, setBooking] = useState<BookingVehicle | null>()
  const [routeType, setRouteType] = useState<RouteType | undefined>()
  const [routeGo, setRouteGo] = useState<Route | undefined>()
  const [routeBack, setRouteBack] = useState<Route | undefined>()
  const [staff, setStaff] = useState<Staff | undefined>()
  const [loading, setLoading] = useState(false)
  const [onProcess, setOnProcess] = useState(false)
  const [driverLocation, setDriverLocation] = useState<DriverLocation | undefined>()
  const [driver, setDriver] = useState<Driver | undefined>()
  const [isShowDateCar, setIsShowDateCar] = useState(false)
  const [isHistory, setIsHistory] = useState(false)

  const publicBooking = useRecoilValue(publicBookingState)
  const [customer, setCustomer] = useRecoilState(viewCustomerState)
  const [departure, setDeparture] = useRecoilState(viewDepartureState)
  const [path, setPath] = useRecoilState(pathState)
  const [edit, setEdit] = useRecoilState(editState)
  const [location, setLocation] = useRecoilState(viewLocationState)

  const { getPublicBooking, getStaff, getDomain } = useCallApi()
  const navigate = useNavigate()
  const defaultLocation = { lat: '13.595549', lng: '100.8770063' }

  const OnPorcessOneWay = [
    VehicleState.START,
    VehicleState.PICK_UP_POINT,
    VehicleState.CONFIRM_PICK_UP,
    VehicleState.CONFIRM_DRIVER,
    VehicleState.DEPART,
    VehicleState.ARRIVE,
  ]

  const OnPorcessRoundTrip = [
    ...OnPorcessOneWay,
    VehicleState.FINISH,
    VehicleState.PICK_UP_POINT_BACK,
    VehicleState.DEPART_BACK,
    VehicleState.ARRIVE_BACK,
  ]

  async function checkState() {
    if (booking?.driverId && booking?.carId && (driver === undefined || driver === null)) {
      await fetchDriver()
    }

    if (booking?.driverId && booking?.carId) {
      setIsShowDateCar(true)
    }
  }

  async function fetchDriver() {
    const queryParams = new URLSearchParams(window.location.search)
    const bn = queryParams.get('bn')
    const domain = queryParams.get('domain')

    if (!bn || !domain) return
    const data = await getPublicBooking(bn,domain)

    if (!data?.driver) return
    setDriver(data.driver)
  }

  async function fetchPublicBooking() {
    const queryParams = new URLSearchParams(window.location.search)
    const bn = queryParams.get('bn')
    const domain = queryParams.get('domain')

    if (bn && domain ) {
      const data = publicBooking ? publicBooking : await getPublicBooking(bn,domain)
      if (!data) return

      const { booking } = data

      setBooking(booking)

      setIsHistory([BookingStatus.CANCELED, BookingStatus.COMPLETED].includes(booking.status))

      setLocation({
        routeType: { isOneWay: !booking.trip2, placeType: PLACE_TYPE.ORIGIN1, showRoute: true },
        routeGo: booking.trip,
        routeBack: booking.trip2 ? booking.trip2 : location.routeBack,
      })

      setCustomer({
        booker: {
          name: booking.bookerName,
          phone: decoratePhoneNumber(booking.bookerPhone),
          lineId: booking.bookerLineId,
        },
        passenger: {
          name: booking.passengerName,
          phone: decoratePhoneNumber(booking.passengerPhone),
          lineId: booking.passengerLineId,
        },
        emergencyContact: {
          name: booking.emergencyContactName,
          phone: decoratePhoneNumber(booking.emergencyContactPhone),
          relation: booking.emergencyContactRelation,
        },
        note: booking.note ?? '',
        checked: 0,
      })

      const departureTime = dayjstz(booking.departureTime)
      const arrivalTime = dayjstz(booking.arrivalTime)
      const departureTime2 = dayjstz(booking.departureTime2)
      const arrivalTime2 = dayjstz(booking.arrivalTime2)
      const goPrice = booking.payments.find((p) => p.routeType === RouteTypes.GO)?.amount ?? 0
      const backPrice = booking.payments.find((p) => p.routeType === RouteTypes.BACK)?.amount ?? 0
      setDeparture({
        date: booking.scheduledDate,
        goTime: {
          departure: { hour: departureTime.hour(), minute: departureTime.minute() },
          arrival: { hour: arrivalTime.hour(), minute: arrivalTime.minute() },
        },
        backTime: {
          departure: { hour: departureTime2.hour(), minute: departureTime2.minute() },
          arrival: { hour: arrivalTime2.hour(), minute: arrivalTime2.minute() },
        },
        vehicle: {
          id: booking.vehicleId,
          date: `${dayjstz(booking.scheduledDate)}`,
          carId: booking.carId,
          carModelId: booking.modelId,
          car: {
            id: booking.carId,
            model: booking.carModel,
            modelId: booking.modelId,
            color: booking.carColor,
            licensePlateId: booking.carLicensePlateId,
            seat: booking.seat,
            imageUrl: booking.carImageUrl,
          },
        },
        baggages: booking.baggages,
        followers: booking.followers ?? 0,
        note: booking.note ?? '',
        goPrice: Number(goPrice),
        backPrice: Number(backPrice),
      })

      setRouteType({
        isOneWay: !booking.trip2,
        placeType: PLACE_TYPE.ORIGIN1,
        showRoute: true,
      })
      setRouteGo(booking.trip)
      if (booking.trip2) {
        setRouteBack(booking.trip2)
      }

      await fetchVihecleState()
    }
  }

  async function fetchStaff() {
    const domain = await getDomain()
    setStaff(await getStaff(domain))
  }

  async function fetchVihecleState() {
    const queryParams = new URLSearchParams(window.location.search)
    const domain = queryParams.get('domain')

    if (!booking?.vehicleId) return
    if (booking.status === BookingStatus.IN_PROGRESS) {
      const { lat, lng } = handleTracking()
      if(domain){
        const data = await getPublicBooking(booking.bookingNumber,domain, `${lat},${lng}`)

        if (!data?.driverLocation) return
        const { driverLocation } = data

        if (booking.tripType === TripType.ONE_WAY) {
          setDriverLocation(driverLocation)
          if (OnPorcessOneWay.includes(driverLocation.state)) {
            setOnProcess(true)
          } else {
            setOnProcess(false)
          }
        } else {
          setDriverLocation(driverLocation)
          if (OnPorcessRoundTrip.includes(driverLocation.state)) {
            setOnProcess(true)
          } else {
            setOnProcess(false)
          }
        }
      }

    }
  }

  function contactCustomer() {
    if (liff.getOS() === 'ios') {
      const a = document.createElement('a')
      a.href = `tel:${staff?.phone}`
      a.click()
    } else {
      setOpenContact(true)
    }
  }

  function handleTracking() {
    if (!driverLocation || !routeGo) {
      return {
        lat: parseFloat(defaultLocation.lat),
        lng: parseFloat(defaultLocation.lng),
      }
    }

    const { state } = driverLocation
    if (OnPorcessOneWay.includes(state)) {
      if (
        [
          VehicleState.START,
          VehicleState.PICK_UP_POINT,
          VehicleState.CONFIRM_PICK_UP,
          VehicleState.CONFIRM_DRIVER,
        ].includes(state)
      ) {
        return {
          lat: parseFloat(routeGo.origin.lat ?? defaultLocation.lat),
          lng: parseFloat(routeGo.origin.lng ?? defaultLocation.lng),
        }
      } else if ([VehicleState.DEPART, VehicleState.ARRIVE].includes(state)) {
        return {
          lat: parseFloat(routeGo.destination.lat ?? defaultLocation.lat),
          lng: parseFloat(routeGo.destination.lng ?? defaultLocation.lng),
        }
      }
    }

    if (OnPorcessRoundTrip.includes(state) && routeBack) {
      if ([VehicleState.FINISH, VehicleState.PICK_UP_POINT_BACK].includes(driverLocation.state)) {
        return {
          lat: parseFloat(routeBack.origin.lat ?? defaultLocation.lat),
          lng: parseFloat(routeBack.origin.lng ?? defaultLocation.lng),
        }
      } else if ([VehicleState.DEPART_BACK, VehicleState.ARRIVE_BACK].includes(driverLocation?.state)) {
        return {
          lat: parseFloat(routeBack.destination.lat ?? defaultLocation.lat),
          lng: parseFloat(routeBack.destination.lng ?? defaultLocation.lng),
        }
      } else {
        return {
          lat: parseFloat(routeGo.origin.lat ?? defaultLocation.lat),
          lng: parseFloat(routeGo.origin.lng ?? defaultLocation.lng),
        }
      }
    }

    return {
      lat: parseFloat(defaultLocation.lat),
      lng: parseFloat(defaultLocation.lng),
    }
  }

  useInterval(() => {
    checkState()
  }, 10000)

  useInterval(() => {
    ;(async () => {
      const isBetween = dayjstz(new Date()).isBetween(
        dayjstz(booking?.departureTime).subtract(1, 'hour'),
        dayjstz(booking?.departureTime).add(1, 'hour')
      )
      if (isBetween || booking?.status === BookingStatus.IN_PROGRESS) {
        if (booking?.status !== BookingStatus.IN_PROGRESS) {
          await fetchPublicBooking()
        } else {
          await fetchVihecleState()
          if (
            driverLocation &&
            [VehicleState.FINISH, VehicleState.FINISH_BACK, VehicleState.ARRIVE, VehicleState.ARRIVE_BACK].includes(
              driverLocation?.state
            )
          ) {
            await fetchPublicBooking()
          }
        }
      }
    })()
  }, 10000)
  useEffect(() => {
    ;(async () => {
      if (routeGo?.origin.id) await fetchVihecleState()
    })()
  }, [routeGo?.origin.id])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      if (!booking) {
        await fetchPublicBooking()
      }
      await fetchStaff()
      await checkState()
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return loading ? (
    <Loading />
  ) : !booking || !routeType ? (
    <Page404
      description={t('The system is not working properly, please try again.')}
      textButton={t('Try again')}
      onClick={() => navigate(0)}
    />
  ) : (
    <>
      <ToastContainer position="top-center" hideProgressBar={false} pauseOnFocusLoss draggable pauseOnHover />
      <Header
        onBack={() => {
          if (path.fromMenu) {
            setPath({ fromMenu: false, prvePath: '' })
            navigate(-1)
          } else {
            liff.closeWindow()
          }
        }}
        icon={path.fromMenu ? 'back' : 'null'}
        title={t('Booking Information')}
      />
      <div className="pt-2 bg-gray-200"></div>
      {!onProcess
        ? routeGo && routeType && <Map isView key={routeGo.origin.id} height={'60vh'} />
        : routeGo &&
          driverLocation && (
            <Tracking
              key={dayjstz(driverLocation.time).valueOf()}
              height={'60vh'}
              destination={handleTracking()}
              driverLocation={{
                lat: parseFloat(driverLocation.lat ?? '0'),
                lng: parseFloat(driverLocation.lng ?? '0'),
              }}
              state={driverLocation.state ?? booking.state}
            />
          )}
      {location.routeGo.origin.id && (
        <Duration
          departure={departure}
          bookingStatus={booking.status}
          vehicleState={driverLocation?.state ?? booking.vehicleState}
          duration={driverLocation?.duration}
        />
      )}
      {routeType.isOneWay
        ? routeGo && <RouteCard routeGo={routeGo} readonly={true} payments={booking.payments} />
        : routeGo && <RouteCard routeBack={routeBack} routeGo={routeGo} readonly={true} payments={booking.payments} />}
      <div className="bg-gray-200 gap-y-1">
                <ShowTotal
          // isFree={booking.payments[0].paidStatus === PAYMENT_STATUS.NOT_PAID}
          isFree={{
            go: booking.payments[0].paidStatus === PAYMENT_STATUS.NOT_PAID,
            // if(booking.payments.len) {}
            back: booking.payments.length === 1 ? true : booking.payments[1].paidStatus === PAYMENT_STATUS.NOT_PAID,
          }}
          goPrice={departure.goPrice}
          backPrice={departure.backPrice}
          bookingNumber={booking.bookingNumber}
        />
        <ShowSchedule
          routeType={routeType}
          date={departure.date}
          departureTime={departure.goTime.departure}
          arrivalTime={departure.goTime.arrival}
          departureTimeTwo={departure.backTime?.departure ?? { hour: 0, minute: 0 }}
          arrivalTimeTwo={departure.backTime?.arrival ?? { hour: 0, minute: 0 }}
        />
        <ViewShowCarCard
          booking={booking}
          departure={departure}
          isShowDateCar={isShowDateCar}
          onChange={() => {}}
          onEdit={() => {
            setEdit({ ...edit, byView: true })
            navigate(BookingRoute.Departure)
          }}
        ></ViewShowCarCard>
        {isShowDateCar && (
          <>
            <div className="pt-2 bg-gray-200" />
            <DriverCard driver={driver} />
          </>
        )}
        <div className="pt-2 bg-gray-200"></div>
        <ShowBaggageAndPassenger
          showTitle
          baggages={departure.baggages}
          passenger={departure.followers}
          onEdit={() => {
            setEdit({ ...edit, byView: true })
            navigate(BookingRoute.Departure)
          }}
        />
        <ShowInfo
          booker={customer.booker}
          passenger={customer.passenger}
          emergencyContact={customer.emergencyContact}
          note={customer.note}
          onEdit={() => {
            setEdit({ ...edit, byView: true })
            navigate(BookingRoute.Customer)
          }}
          booking={booking}
        />
        <ModalCard
          title={t('Are you want to contact customer service ?')}
          detail={''}
          status={openContact}
          duoButton={{
            left: { name: t('Yes'), type: 'cancel', style: 'btn-outline-primary' },
            right: { name: t('No'), type: 'confirm', style: 'btn-primary' },
          }}
          onClick={(val) => {
            setOpenContact(false)
            if (val) {
              window.open(`tel:${staff?.phone}`)
            }
          }}
        />
        {!isHistory && (
          <>
            <div className="h-3"></div>
            <SingleButtonCard children={t('Contact customer service')} onClick={() => contactCustomer()} />
          </>
        )}
      </div>
    </>
  )
}
