import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import RangePicker from 'react-range-picker'
import { dayjstz } from 'src/helpers/datetime'
import i18n from 'src/i18n'
import { language } from 'src/i18n/langs'
import IconDatePicker from '../assets/icons/DatePicker.svg'
import IconClose from '../assets/icons/icon-close.svg'

type Props = {
  onChangeStartDate: (d: string | null) => void
  onChangeEndDate: (d: string | null) => void
  clearDate?: boolean
}

export default function CustomDatePicker({ onChangeStartDate, onChangeEndDate }: Props) {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [showCloseButton, setShowCloseButton] = useState(false)

  const showDate = () => {
    const formatDate = i18n.language === language.TH ? 'DD MMM BBBB' : 'DD MMM YYYY'
    const firstDate = dayjstz(startDate).locale(i18n.language).format(formatDate).toString()
    const lastData = dayjstz(endDate).locale(i18n.language).format(formatDate).toString()
    return `${firstDate} ${endDate === 'Invalid Date' || endDate === undefined ? '' : `- ${lastData}`}`
  }

  return (
    <div className="flex justify-between w-full px-2 mb-4 align-middle bg-white border-2 border-white border-solid rounded-md">
      <RangePicker
        closeOnSelect
        onDateSelected={(first: string, last: string) => {
          setStartDate(first)
          setEndDate(last)
          onChangeStartDate(dayjstz(first).toString())
          last !== undefined ? onChangeEndDate(dayjstz(last).toString()) : onChangeEndDate(null)
          setShowCloseButton(true)
        }}
        placeholder={() => (
          <div className={`placeholder h-10 flex align-middle justify-between`}>
            <div className={'flex items-center'}>{startDate ? showDate() : t('Select date')}</div>
            <div className="flex items-center px-2">
              {!showCloseButton && <img className="w-6 mr-2" src={IconDatePicker}></img>}
            </div>
            <style>
              {showCloseButton
                ? `
                .placeholder {
                  width: 80vw;
                }
              `
                : `
              .placeholder {
                width: 92vw;
              }
            `}
            </style>
          </div>
        )}
      />
      {showCloseButton && (
        <div
          className="flex h-10 align-middle"
          onClick={() => {
            setStartDate('')
            setEndDate('')
            setShowCloseButton(false)
            onChangeStartDate(null)
            onChangeEndDate(null)
          }}
        >
          <img className="w-6 ml-2" src={IconClose}></img>
        </div>
      )}
    </div>
  )
}
