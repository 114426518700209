import { useTranslation } from 'react-i18next'

type Props = {
  value: string
  readOnly: boolean | undefined
  isPhoneError: boolean | undefined
  isMessageError: string | undefined
  onChange: (v: string) => void
}

export default function PhoneNumber({ value, readOnly, isPhoneError, isMessageError, onChange }: Props) {
  const checkValid = value.length > 0 && value.length < 12
  const { t } = useTranslation()
  return (
    <>
      <input
        type="text"
        name="contact"
        id="contact"
        maxLength={12}
        placeholder={t('Ex. 081-234-5678')}
        pattern="[0-9]*"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        readOnly={readOnly}
        className={`bg-sky-50 focus:border-sky-500 focus:ring-sky-500 block w-full py-2 pr-3 pl-5 border ${
          isPhoneError ? 'border-2 border-red-500' : 'border-white'
        } rounded-md placeholder:text-slate-400 focus:outline-none focus:ring-1 sm:text-sm ${
          readOnly ? 'read-only:bg-gray-300' : ''
        }`}
        // className={`${
        //   (checkValid || isPhoneError) && 'border-2 border-red-500'
        // } read-only:bg-gray-300 h-10 form-control focus:outline-none`}
      />
      <label className="text-alert">
        {checkValid ? t('Enter a 10-digit number.') : isPhoneError ? isMessageError : ''}
      </label>
    </>
  )
}
