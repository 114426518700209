import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { BookingStatus, VehicleState } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import i18n from 'src/i18n'
import { language } from 'src/i18n/langs'
import { DepartureState, viewLocationState } from 'src/states'

type Props = {
  departure: DepartureState
  bookingStatus: number
  vehicleState?: number
  duration?: number
}

export default function Duration({ bookingStatus, vehicleState, duration, departure }: Props) {
  const location = useRecoilValue(viewLocationState)
  const [isFinish, setIsFinish] = useState(false)
  const d = duration ? Math.floor(duration / 60) : 0

  const goTimeDeparture = dayjstz()
    .hour(departure.goTime.departure.hour)
    .minute(departure.goTime.departure.minute)
    .format('HH:mm')
  const goTimeArrival = dayjstz()
    .hour(departure.goTime.arrival.hour)
    .minute(departure.goTime.arrival.minute)
    .format('HH:mm')
  const backTimeArrival = departure.backTime
    ? dayjstz().hour(departure.backTime.arrival.hour).minute(departure.backTime.arrival.minute).format('HH:mm')
    : dayjstz().hour(0).minute(0).format('HH:mm')

  function handleState() {
    if (
      vehicleState &&
      [
        VehicleState.START,
        VehicleState.PICK_UP_POINT,
        VehicleState.DEPART,
        VehicleState.ARRIVE,
        VehicleState.ARRIVE_BACK,
        VehicleState.DEPART_BACK,
      ].includes(vehicleState)
    ) {
      if (d > 30) {
        if ([VehicleState.START, VehicleState.PICK_UP_POINT].includes(vehicleState)) {
          return `${t('Duration.Estimate time arrival')} ${goTimeDeparture} ${
            i18n.language === language.TH ? 'น.' : ''
          }`
        } else if ([VehicleState.DEPART, VehicleState.ARRIVE].includes(vehicleState)) {
          return `${t('Duration.Estimate time arrival')} ${goTimeArrival} ${i18n.language === language.TH ? 'น.' : ''}`
        } else {
          if (departure.backTime) {
            return `${t('Duration.Estimate time arrival')} ${backTimeArrival} ${
              i18n.language === language.TH ? 'น.' : ''
            }`
          }
        }
      } else {
        return `${t('Coming in about')} ${d} ${t('minutes.')}`
      }
    } else if (
      vehicleState &&
      [
        VehicleState.CONFIRM_DRIVER,
        VehicleState.CONFIRM_PICK_UP,
        VehicleState.FINISH,
        VehicleState.PICK_UP_POINT_BACK,
      ].includes(vehicleState)
    ) {
      return `${t('Arrived')}`
    }
    return undefined
  }

  useEffect(() => {
    if (vehicleState === VehicleState.FINISH && location.routeType.isOneWay) {
      setIsFinish(true)
    } else if (vehicleState === VehicleState.FINISH_BACK) {
      setIsFinish(true)
    }
  }, [vehicleState])

  return (
    <>
      {[BookingStatus.IN_PROGRESS, BookingStatus.COMPLETED].includes(bookingStatus) ? (
        bookingStatus === BookingStatus.COMPLETED || isFinish ? (
          <div className="flex flex-row p-4">
            <img src="/CarClock.png" height={'22px'} width={'22px'} />
            <div className="ml-2 font-bold text-green-600 align-middle">{t('Complete')}</div>
          </div>
        ) : (
          handleState() &&
          duration && (
            <div className="flex flex-row p-4">
              <img src="/CarClock.png" height={'22px'} width={'22px'} />
              <div className="ml-2 font-bold text-green-600 align-middle">{handleState()}</div>
            </div>
          )
        )
      ) : null}
    </>
  )
}
