import { useTranslation } from 'react-i18next'
import Button from 'src/components/Button/Base'
import Header from 'src/components/Header'

type Props = {
  description: string
  textButton: string
  onClick: () => void
}

export default function Page404({ description, onClick, textButton }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Header title={''} icon={'null'} />
      <div className="flex flex-col justify-between h-screen">
        <div className="flex flex-col items-center text-lg text-center text-gray-600 pt-28">
          <img src="/404.png" height={278} width={278} />
          <div className="pt-8 font-bold">{t('Sorry, Page not found')}</div>
          <div className="pt-2 font-normal">{description}</div>
        </div>
        <div className="px-4 pb-16">
          <Button type={'btn btn-primary block text-w'} onClick={onClick}>
            {textButton}
          </Button>
        </div>
      </div>
    </>
  )
}
