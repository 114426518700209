import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import './App.css'
import Root from './components/Root'
import { BookingRoute, FeedbackRoute, RequestTrialRoute } from './consts/route'
import BookingList from './pages/Booking/BookingList'
import BookingCanceled from './pages/Booking/Canceled'
import BookingConfirm from './pages/Booking/Confirm'
import BookingCustomer from './pages/Booking/Customer'
import BookingDeparture from './pages/Booking/Departure'
import BookingInvite from './pages/Booking/Invite'
import BookingLandingPage from './pages/Booking/LandingPageCNMI'
import BookingPaymentSummary from './pages/Booking/PaymentSummaryCNMI'
import BookingLocation from './pages/Booking/Location'
import BookingLocationSearch from './pages/Booking/LocationSearch'
import BookingMap from './pages/Booking/Map'
import Payment from './pages/Booking/Payment'
import BookingSuccess from './pages/Booking/Success'
import BookingSummary from './pages/Booking/Summary'
import BookingView from './pages/Booking/View'
import Feedback from './pages/Feedback/Feedback'
import FeedbackSuccess from './pages/Feedback/Success'
import RequestTrial from './pages/RequestTrial/RequestTrial'
import RequestTrialSuccess from './pages/RequestTrial/Success'
import './styles/tailwind.css'
///// Add on for CNMI /////
// import BookingLandingPage from './pages/Booking/LandingPageCNMI'
import BookingSignUpCNMI from './pages/Booking/SignUpCNMI'
// import BookingConsent from './pages/Booking/ConsentCNMI'
import BookingAddressAndPrice from './pages/Booking/AddressAndPriceCNMI'
import BookingCardCalendar from './pages/Booking/BookingCardCalendarCNMI'
// import BookingTimeModal from './pages/Booking/BookingTimeModalCNMI'
import BookingBookingList from './pages/Booking/BookingListCNMI'
import UserProfileCNMI from './pages/Booking/UserProfileCNMI'
// import BookingDetail from './pages/Booking/BookingDetailCNMI'

export default function App() {
  return (
    <RecoilRoot>
      <Root>
        <BrowserRouter>
          <Routes>
            //Questionnaire
            <Route path={RequestTrialRoute.Index} element={<RequestTrial />} />
            <Route path={RequestTrialRoute.Success} element={<RequestTrialSuccess />} />
            //Booking
            <Route path={BookingRoute.SignUpCNMI} element={<BookingSignUpCNMI />} />
            <Route path={BookingRoute.AddressAndPriceCNMI} element={<BookingAddressAndPrice />} />
            <Route path={BookingRoute.BookingCardCalendarCNMI} element={<BookingCardCalendar />} />
            <Route path={BookingRoute.PaymentSummary} element={<BookingPaymentSummary />} />
            <Route path={BookingRoute.LandingPageCNMI} element={<BookingLandingPage />} />
            <Route path={BookingRoute.BookingListCNMI} element={<BookingBookingList />} />
            <Route path={BookingRoute.UserProfileCNMI} element={<UserProfileCNMI />} />
            <Route path={BookingRoute.Location} element={<BookingLocation />} />
            <Route path={BookingRoute.Map} element={<BookingMap />} />
            <Route path={BookingRoute.Search} element={<BookingLocationSearch />} />
            <Route path={BookingRoute.Summary} element={<BookingSummary />} />
            <Route path={BookingRoute.Departure} element={<BookingDeparture />} />
            <Route path={BookingRoute.Customer} element={<BookingCustomer />} />
            <Route path={BookingRoute.Confirm} element={<BookingConfirm />} />
            <Route path={BookingRoute.Success} element={<BookingSuccess />} />
            <Route path={BookingRoute.View} element={<BookingView />} />
            <Route path={BookingRoute.Canceled} element={<BookingCanceled />} />
            <Route path={BookingRoute.Invite} element={<BookingInvite />} />
            <Route path={BookingRoute.List} element={<BookingList />} />
            <Route path={BookingRoute.Payment} element={<Payment />} />
            //Feedback
            <Route path={FeedbackRoute.Feedback} element={<Feedback />} />
            <Route path={FeedbackRoute.Success} element={<FeedbackSuccess />} />
          </Routes>
        </BrowserRouter>
      </Root>
    </RecoilRoot>
  )
}
