import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import AddressInput from 'src/components/AddressInput'
import { NOTE, PLACE_TYPE } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BeforeMapPage, Icons, InputType } from 'src/enums'
import { formatInputPhoneText, resolveAddressName } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import InputPhone from '../../components/Input/PhoneNumber'
import { checkServicePostalCode, getCurrentLocation, useGoogleMaps } from 'src/hooks/useGoogleMaps'
import Loading from 'src/pageviews/Booking/Loading'
import loadingAnimation from 'src/assets/icons/loading.svg'
import LocationInput from 'src/pageviews/Booking/LocationInput'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import ModalContactHistoryCard from 'src/components/ModalContactHistoryCNMI'
import ModalAddressHistoryCard from 'src/components/ModalAddressHistoryCNMI'
import {
  beforeMapPage,
  bookingDepartureState,
  bookingLocationState,
  cnmiCustomerState,
  cnmiPassengerState,
  domainState,
  otherLocationState,
} from 'src/states'
import { Place, RouteDetail, AddressHistoryPerson } from 'src/types'
import Map from 'src/pageviews/Booking/Map'
import Header from 'src/components/Header'
// import i18n from 'src/i18n'
//import { PatternFormat } from 'react-number-format'
import { lineState } from 'src/states'

type Props = {
  errorMessage?: string
  isPhoneError?: boolean
  readonly?: boolean
}

export default function AddressCNMI({ errorMessage, isPhoneError, readonly }: Props) {
  const [location, setLocation] = useRecoilState(bookingLocationState)
  const [domain, setDomain] = useRecoilState(domainState)
  const { routeGo, routeType } = location
  const [open, setOpen] = useState(false)
  const [message] = useState('')
  const navigate = useNavigate()
  const {
    getSupportPostcode,
    getCarModelPrice,
    updateContactHistoryCNMI,
    updateAddressHistoryCNMI,
    getAddressHistoryCNMI,
    getDomain,
    updateCustomerCNMI,
    getCustomerCNMI,
  } = useCallApi()
  const { getGeoLocation } = useGoogleMaps()
  const { t } = useTranslation()
  const [otherLocation, setOtherLocation] = useRecoilState(otherLocationState)
  const [loading, setLoading] = useState(false)
  const [priceLoading, setPriceLoading] = useState(false)
  const [departure, setDeparture] = useRecoilState(bookingDepartureState)
  const [baggages, setBaggages] = useState(departure.baggages)
  const baggagesWithWheelchair = { items: [1], note: '' }
  const baggagesNull = { items: [], note: '' }
  const [wheelChair, setWheelChair] = useState(baggages.items == null ? false : true)
  const [isFormValid, setIsFormValid] = useState(false)
  const [price, setPrice] = useState<number | null>(null)
  // const { booker } = useRecoilValue(bookingCustomerState)
  const [customerRecoilState, setCustomerRecoilState] = useRecoilState(cnmiCustomerState)
  const [, setPassengerRecoilState] = useRecoilState(cnmiPassengerState)
  const [, setBeforeMapPage] = useRecoilState(beforeMapPage)

  const [showContactHistory, setShowContactHistory] = useState(false)
  const [showAddressHistory, setShowAddressHistory] = useState(false)
  const { userId } = useRecoilValue(lineState)
  const [addressHistoryData, setAddressHistoryData] = useState<AddressHistoryPerson[]>([])
  const [otherpersonContactHistoryData, setOtherPersonContactHistoryData] = useState<AddressHistoryPerson[]>([])
  const [selectedPerson, setSelectedPerson] = useState<string>('Myself')

  const [otherName, setOtherName] = useState<string>('')
  const [otherPhoneNumber, setOtherPhoneNumber] = useState<string>('')


  const fetchPrices = async (modelId: number, distance: number) => {
    try {
      setPriceLoading(true)
      const priceGet = await getCarModelPrice(modelId, distance)
      setPrice(await priceGet)

      setTimeout(() => {
        validateForm()
      }, 1000)
    } catch (error) {
      console.error(`Error fetching price for model ${modelId}:`, error)
    } finally {
      setPriceLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      console.log('selected Distance: ', customerRecoilState.distance)
      console.log('routeGo Distance: ', routeGo.distance)
      console.log('!isFormValid IN USEEFFECT:', !isFormValid)
      if (customerRecoilState.distance !== 0 && customerRecoilState.distance != undefined) {
        fetchPrices(1, customerRecoilState.distance / 1000)
      } else if (routeGo.distance != 0 && routeGo.distance != undefined) {
        fetchPrices(1, (routeGo.distance ?? 0) / 1000)
      }
    })()
  }, [routeGo.distance, customerRecoilState.distance])

  async function validateForm() {
    if (customerRecoilState.distance !== 0 && customerRecoilState.distance != undefined) {
      setIsFormValid(customerRecoilState.distance !== 0 && customerRecoilState.distance != undefined)
    } else {
      setIsFormValid(routeGo.distance !== 0 && routeGo.distance !== undefined)
    }
  }

  const CNMIObject: Place = {
    id: 'ChIJIw4ra2haHTERV1ag-0TDBi0',
    name: 'CNMI',
    address: '111 ถนนเลียบคลองส่งน้ำสุวรรณภูมิ ตำบล บางปลา อำเภอบางพลี สมุทรปราการ 10540',
    post_code: '10540',
    lat: '13.527454980593362',
    lng: '100.76080796098233',
    note: '',
  }

  function onSetNote(note: string, output: number) {
    if (output === NOTE.ORIGIN1) {
      setLocation({
        ...location,
        routeGo: { ...routeGo, origin: { ...routeGo.origin, note: note } },
      })
    } else if (output === NOTE.DESTINATION1) {
      setLocation({
        ...location,
        routeGo: { ...routeGo, destination: { ...routeGo.destination, note: note } },
      })
    }

    validateForm()
  }

  async function setPlaceType(placeType: PLACE_TYPE) {
    await setLocation({ ...location, routeType: { ...routeType, placeType: placeType } })
  }

  function removeNonNumeric(str: string) {
    return str.replace(/[^0-9.]/g, '') // This regex will remove any character that is not a digit or a dot
  }

  async function setHomeLocation() {
    let current
    const servicePostalCodes = await getSupportPostcode()
    const userData = await getCustomerCNMI({ lineId: customerRecoilState.lineId })

    const lat = removeNonNumeric(userData?.lat || '')
    const lng = removeNonNumeric(userData?.lng || '')

    console.log(`lat ${lat}`)
    console.log(`lng ${lng}`)

    if (customerRecoilState.lat && customerRecoilState.lng) {
      const latRecoil = removeNonNumeric(customerRecoilState.lat)
      const lngRecoil = removeNonNumeric(customerRecoilState.lng)
      let parseFloatLat
      let parseFloatLng
      if (!isNaN(parseFloat(latRecoil)) && !isNaN(parseFloat(lngRecoil))) {
        parseFloatLat = parseFloat(latRecoil)
        parseFloatLng = parseFloat(lngRecoil)
      } else {
        return
      }
      current = { lat: parseFloatLat, lng: parseFloatLng }
      console.log('RECOIL DATA')
    } else if (!isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng))) {
      current = { lat: parseFloat(lat), lng: parseFloat(lng) }
      console.log('API DATA')
    } else {
      current = await getCurrentLocation()
      console.log('CURRENT LOCATION')
    }

    console.log(current)

    const result = await getGeoLocation({ location: current })
    const { address_components, place_id, formatted_address: _formatted_address } = result
    const { name, formatted_address } = resolveAddressName(address_components, _formatted_address)
    const origin = {
      id: place_id,
      name,
      address: formatted_address,
      lat: current.lat.toString(),
      lng: current.lng.toString(),
    }

    const addrlength = address_components.length - 1

    if (!checkServicePostalCode(address_components[addrlength].long_name ?? '', servicePostalCodes)) {
      return
    }

    console.log(`origin : ${origin}`)

    setLocation({
      ...location,
      routeGo: { ...routeGo, origin: origin, destination: CNMIObject },
    })

    validateForm()
  }

  useEffect(() => {
    setLoading(true)
    window.scrollTo(0, 0)
    setOtherLocation(otherLocation)

    if (domain == null || domain == undefined || domain == '') {
      ;(async () => {
        const dm = await getDomain()
        setDomain(dm)
      })()
    }

    if (!routeGo.destination.id) {
      setLocation({
        ...location,
        routeGo: {
          ...routeGo,
        },
      })
    } else if (routeType.isOneWay) {
      setLocation({
        ...location,
        routeGo: routeGo.origin.id
          ? {
              ...routeGo,
              origin: {
                ...routeGo.origin,
              },
              destination: {
                ...routeGo.destination,
              },
            }
          : {
              ...routeGo,
              origin: {
                ...routeGo.origin,
                disabled: false,
              },
              destination: {
                ...routeGo.destination,
                disabled: false,
              },
            },
      })
    }

    if (!routeGo.origin.id) {
      ;(async () => {
        setHomeLocation()
      })()
    }
    setLocation({
      ...location,
      routeGo: { ...routeGo, destination: CNMIObject },
    })
    setLoading(false)

    setDeparture({ ...departure, baggages })
    // i18n.changeLanguage('th')

    setBeforeMapPage(BeforeMapPage.AddressAndPriceCNMI)
  }, [])

  function handleSetDistanceDuration(go: RouteDetail) {
    setLocation({
      ...location,
      routeGo: {
        ...routeGo,
        distance: go.distance,
        duration: go.duration,
      },
    })

    setCustomerRecoilState({
      ...customerRecoilState,
      lat: routeGo.origin.lat,
      lng: routeGo.origin.lng,
      distance: go.distance,
      id: routeGo.origin.id,
      address: routeGo.origin.address,
      addressName: routeGo.origin.name,
      pickUpPoint:'',
    })
    validateForm()
  }
  const queryParams = new URLSearchParams(window.location.search)
  const liffState = queryParams.get('liff.state')

  // useEffect(() => {
  //   console.log('@PAGE customerRecoilState', customerRecoilState) // testing prompt
  // }, [])

  /////////////// Store contact history to display ////////////////
  // Load saved state from localStorage when component mounts
  useEffect(() => {
    const savedPerson = localStorage.getItem('selectedPerson')
    const savedOtherName = localStorage.getItem('otherName')
    const savedOtherPhoneNumber = localStorage.getItem('otherPhoneNumber')
    if (savedOtherName) setOtherName(savedOtherName)
    if (savedOtherPhoneNumber) setOtherPhoneNumber(savedOtherPhoneNumber)
    if (savedPerson) setSelectedPerson(savedPerson)
  }, [])

  ////////// Save state to localStorage whenever it changes ////////////
  useEffect(() => {
    localStorage.setItem('selectedPerson', selectedPerson)
    localStorage.setItem('otherName', otherName)
    localStorage.setItem('otherPhoneNumber', otherPhoneNumber)
  }, [selectedPerson, otherName, otherPhoneNumber])
  /////////////// END: Save state to localStorage whenever it changes ////////////////

  /////////////// Open Modal Function ////////////////
  const onClickContactHistory = () => {
    setShowContactHistory(true)
  }

  const onClickAddressHistory = () => {
    setShowAddressHistory(true)
  }
  /////////////// END: Open Modal Function ////////////////

  ////////////// Fetch Address History Data ///////////////
  async function fetchGetAddressHistory() {
    const res = await getAddressHistoryCNMI({ lineId: customerRecoilState.lineId })
    // console.log("Resulttttttttt: ", res)
    if (!res) {
      return
    }

    if (res && res.contacts) {
      setOtherPersonContactHistoryData(res.contacts.filter((addrHist) => addrHist.name != customerRecoilState.name))
    }
    if (res && res.addressHistory) {
      setAddressHistoryData(res.addressHistory)
    }
  }

  useEffect(() => {
    fetchGetAddressHistory()
  }, [userId, selectedPerson])
  ////////////// END: Fetch Address History Data ///////////////

  /////////////// Store history data to display when selecting history option ////////////////
  // Already selected Contact History
  const handleSelectContactHistoryItem = (item: AddressHistoryPerson) => {
    if (selectedPerson == 'Other_person') {
      setOtherName(item.name || '')
      setOtherPhoneNumber(item.phone || '')
    }
    setShowContactHistory(false)
  }

  useEffect(() => {
    console.log('Recoil Customer Info: ', customerRecoilState)
  }, [customerRecoilState])

  async function handleSelectAddressHistoryItem(item: AddressHistoryPerson) {
    console.log('Item lat: ', item.lat)
    console.log('Item lng: ', item.lng)
    await setCustomerRecoilState({
      ...customerRecoilState,
      lat: item.lat,
      lng: item.lng,
      distance: item.distance,
      id: item.id,
      address: item.address,
      addressName: item.addressname,
      pickUpPoint: item.pickupDetail || ' ',
    })
    setShowAddressHistory(false)
  }
  /////////////// END: Store history data to display when selecting history option ////////////////

  // Function to handle the update
  const handleUpdateAddressHistory = async () => {
    // Check if selectedAddressName is not empty
    try {
      if (customerRecoilState.addressName !== '') {
        updateAddressHistoryCNMI({
          lineId: customerRecoilState.lineId,
          addressname: customerRecoilState.addressName,
          address: customerRecoilState.address,
          lat: customerRecoilState.lat,
          lng: customerRecoilState.lng,
          distance: customerRecoilState.distance,
          Id: customerRecoilState.id,
          pickupDetail: routeGo.origin.note !== '' ? routeGo.origin.note : '',
        })
      } else {
        // Use routeGo.origin values if selectedAddressName is empty
        updateAddressHistoryCNMI({
          lineId: customerRecoilState.lineId,
          addressname: routeGo.origin.name,
          address: routeGo.origin.address,
          lat: routeGo.origin.lat,
          lng: routeGo.origin.lng,
          distance: routeGo.distance,
          Id: routeGo.origin.id,
          pickupDetail:
            customerRecoilState.pickUpPoint !== ''
              ? customerRecoilState.pickUpPoint
              : routeGo.origin.note !== ''
              ? routeGo.origin.note
              : '',
        })
      }
    } catch (error) {
      console.error('Error updating address history:', error)
    }
  }

  // Check parameter value //
  // console.log("Selected Pick up Point Detail: ", selectedPickupPointDetail)
  // console.log("Route Go Note: ", routeGo.origin.note)
  // console.log("Customer Recoil State: ", customerRecoilState)
  // console.log("Selected Distace Value: ", customerRecoilState.distance)
  // console.log("Selected or Route Go Distance: ", customerRecoilState.distance ?? routeGo.distance)
  // console.log("Route Go:", routeGo)
  // console.log("Selected Address Name: ", selectedAddressName)
  // console.log("Selected Addres: ", selectedAddress)
  // console.log("Selected Lat: ", selectedLat)
  // console.log("Selected Lng: ", selectedLng)
  // console.log("Selected Distance: ", customerRecoilState.distance)
  // console.log("SelectedPickupPointDetail: ", selectedPickupPointDetail)

  return liffState && loading ? (
    <div className="h-screen bg-powderblue">
      <Loading />
    </div>
  ) : (
    <div>
      <Header
        icon="back"
        title={t('Route')}
        reload={true}
        onBack={() => {
          navigate(BookingRoute.SignUpCNMI, { state: { prevUrl: BookingRoute.AddressAndPriceCNMI } })
        }}
      ></Header>
      <div className="min-h-screen p-2 bg-gray-300">
        {/* <div className="h-screen min-h-screen p-2 bg-gray-300"> */}{' '}
        {/* Need to remove overflow-y but when remove -> layout of next button is changed */}
        {/* <div className="p-1 text-lg font-bold bg-slate-200">การจองรถ</div> */}
        <div className="flex flex-col justify-between p-4 bg-white shadow-md">
          {/* <div className="flex flex-col justify-between max-h-screen p-4 bg-white shadow-md"> */}
          <div className="">
            <div className="py-2">
              <div className="flex items-center space-x-4">
                <div className="flex items-center pb-3 text-lg text-black">
                  <span>{t('Traveled person')}</span>
                  {/* <span>🧑 {t('Traveled person')}</span> */}
                </div>
              </div>
              <div>
                <div className="flex items-center pl-4 space-x-4">
                  {/* <div className="flex items-center pl-4 space-x-4"> */}{' '}
                  {/* Use when add icon 🧑 to 'Traveled person' topic line */}
                  <div className="flex items-center space-x-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="traveled-person-group"
                        className="w-4 h-4 mr-2 text-blue-500 form-radio"
                        onChange={() => {
                          setSelectedPerson('Myself')
                        }}
                        checked={selectedPerson == 'Myself'}
                      />
                      <span>{t('Myself')}</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="traveled-person-group"
                        className="w-4 h-4 mr-2 text-blue-500 form-radio"
                        onChange={() => {
                          setSelectedPerson('Other_person')
                        }}
                        checked={selectedPerson == 'Other_person'}
                      />
                      <span>{t('Other person')}</span>
                    </label>
                  </div>
                </div>
                {/* Conditional rendering based on selectedPerson */}
                {selectedPerson == 'Other_person' && (
                  <div className="pl-4">
                    <div className="flex justify-end mr-10">
                      <button className="font-bold text-blue-500 underline" onClick={onClickContactHistory}>
                        {t('Contact History')}
                      </button>
                    </div>
                    <ModalContactHistoryCard
                      openContactHistory={showContactHistory}
                      lineId={customerRecoilState.lineId}
                      historyData={otherpersonContactHistoryData}
                      onClose={() => setShowContactHistory(false)}
                      onSelectHistoryItem={handleSelectContactHistoryItem}
                    />
                    <div className="pb-3 pl-4 space-x-4">
                      <div className="mt-2 mr-8 sm:mt-3">
                        {/* <div className="mt-2 ml-4 mr-10 sm:mt-3"> */}{' '}
                        {/* Use when add icon 🧑 to 'Traveled person' topic line */}
                        <h2>
                          {t('Name') + '-' + t('Surname')}:<span className="text-danger">*</span>
                        </h2>
                        <input
                          className={`bg-sky-50 focus:border-sky-500 focus:ring-sky-500block w-full py-2 pr-3 pl-5 border border-white rounded-md placeholder:text-slate-400 focus:outline-none focus:ring-1 sm:text-sm`}
                          type="text"
                          value={otherName}
                          placeholder={t('Enter ') + t('Name') + '-' + t('Surname')}
                          onChange={(v) => setOtherName(v.target.value)}
                        />
                        <label className="block text-lg font-light text-gray-700">
                          {t('Phone Number')}:<span className="text-danger">*</span>
                        </label>
                        <InputPhone
                          value={formatInputPhoneText(otherPhoneNumber)}
                          readOnly={readonly}
                          isPhoneError={isPhoneError}
                          isMessageError={errorMessage}
                          onChange={(newValue) => {
                            setOtherPhoneNumber(formatInputPhoneText(newValue))
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="pb-2 text-lg text-black">🏠 {t('Pick Up Point')}</div>
            <div className="flex justify-end mr-10">
              {selectedPerson == 'Myself' && (
                <button className="font-bold text-blue-500 underline" onClick={onClickAddressHistory}>
                  {t('Pick Up Point History')}
                </button>
              )}
              {selectedPerson == 'Other_person' && (
                <button className="font-bold text-blue-500 underline" onClick={onClickAddressHistory}>
                  {t('Pick Up Point History')}
                </button>
              )}
              <ModalAddressHistoryCard
                openAddressHistory={showAddressHistory}
                lineId={customerRecoilState.lineId}
                historyData={addressHistoryData}
                onClose={() => setShowAddressHistory(false)}
                onSelectHistoryItem={handleSelectAddressHistoryItem}
              />
            </div>
            <div className="pl-4 pr-4">
              <LocationInput
                isReadOnly={true}
                icon={Icons.DestinationRouteGo}
                value={customerRecoilState.addressName != '' ? customerRecoilState.addressName : routeGo.origin.name}
                onClick={async () => {
                  setIsFormValid(false)
                  // Reset selectedAddressName when going to the map page
                  // setSelectedAddressName('')
                  await setPlaceType(PLACE_TYPE.ORIGIN1)
                  await navigate(BookingRoute.Map)
                }}
                disabled={routeGo.origin.disabled}
              />
              <AddressInput
                value={
                  customerRecoilState.pickUpPoint != '' ? customerRecoilState.pickUpPoint : routeGo.origin.note ?? ''
                }
                placeholder={t('Pick up point details')}
                type={InputType.ORIGIN}
                onChangeNote={(s) => {
                  onSetNote(s, NOTE.ORIGIN1)
                  // setSelectedPickupPointDetail(s)
                  setCustomerRecoilState({
                    ...customerRecoilState,
                    pickUpPoint: s,
                  })
                }}
              />
            </div>

            <div className="py-2">
              <div className="flex items-center pb-3 text-lg text-black">
                <span>{t('Advanced Services')}</span>
              </div>
              <div className="flex items-center pl-4 space-x-4">
                <div className="flex items-center pb-3 text-lg text-black">
                  <span>🦽 {t('Want wheelchair from hospital')}</span>
                </div>
              </div>
              <div className="flex items-center pb-3 pl-4 space-x-4">
                <div className="flex items-center pb-3 pl-4 space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="radio-group"
                      className="w-4 h-4 mr-2 text-blue-500 form-radio"
                      onChange={() => {
                        setBaggages(baggagesWithWheelchair)
                        setWheelChair(true)
                      }}
                      checked={wheelChair}
                    />
                    <span>{t('Require')}</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="radio-group"
                      className="w-4 h-4 mr-2 text-blue-500 form-radio"
                      onChange={() => {
                        setBaggages(baggagesNull)
                        setWheelChair(false)
                      }}
                      checked={!wheelChair}
                    />
                    <span>{t('No require')}</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="py-4 text-center">
              <div className="pb-1 text-sm text-gray-500">{t('Price per trip (one way)')}</div>
              <div className="py-2 text-2xl font-bold text-blue-500">
                {priceLoading ? (
                  <div className="flex items-center justify-center pt-10 pb-10">
                    <img src={loadingAnimation} width={'41px'} height={'41px'} alt="Loading" />
                  </div>
                ) : !isFormValid ? (
                  '-'
                ) : price == null ? (
                  '-'
                ) : price == 0 ? (
                  <span>{t('Free')}</span>
                ) : (
                  <>
                    <span style={{ textDecoration: 'line-through' }}>{price} </span>
                    {t('THB')}
                    <span>{t('Free')}</span>
                  </>
                )}
              </div>
            </div>
          </div>

          <Map key={routeGo.origin.id} height={'0vh'} setDistanceDuration={handleSetDistanceDuration} />
          <button
            className={`font-bold py-2 px-4 rounded ${
              !isFormValid ||
              price == null ||
              (selectedPerson === 'Other_person' && (otherName === '' || otherPhoneNumber.includes('x'))) ||
              priceLoading == true
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-700 text-white'
            }`}
            disabled={
              !isFormValid ||
              (selectedPerson === 'Other_person' && (otherName === '' || otherPhoneNumber.includes('x'))) ||
              priceLoading == true
            }
            onClick={async () => {
              setDeparture({ ...departure, goPrice: price || 0, baggages: baggages })

              // console.log(`lineId:${customerRecoilState.lineId}`)
              // console.log(`booker.phone:${booker.phone}`)
              // console.log(`customerRecoilState lat:${customerRecoilState.lat}`)
              // console.log(`customerRecoilState lng:${customerRecoilState.lng}`)
              // console.log(`customerRecoilState distance:${customerRecoilState.distance}`)

              // console.log(`routeGo lat:${routeGo.origin.lat}`)
              // console.log(`routeGo lng:${routeGo.origin.lng}`)
              // console.log(`routeGo distance:${routeGo.distance}`)

              // console.log(`duration:${routeGo.duration}`)
              // console.log("selectedPerson:", selectedPerson)

                console.log('SET LAST LOCATION')
                updateCustomerCNMI({
                  lineId: customerRecoilState.lineId,
                  phone:customerRecoilState.phone,
                  lat: customerRecoilState.lat,
                  lng: customerRecoilState.lng,
                  distance: customerRecoilState.distance,
                })
                // setCustomerRecoilState({
                //   ...customerRecoilState,
                //   // lat: routeGo.origin.lat,
                //   // lng: routeGo.origin.lng,
                //   // distance: routeGo.distance,
                //   // duration: routeGo.duration,
                // })
                if (selectedPerson == 'Myself') {
                  setPassengerRecoilState({
                    ...customerRecoilState,
                  })
                  updateContactHistoryCNMI({
                    lineId: customerRecoilState.lineId,
                    phone: customerRecoilState.phone,
                    name: customerRecoilState.name,
                  })
                  // Use history data case
                  if (
                    customerRecoilState.address !== '' &&
                    customerRecoilState.lat !== '' &&
                    customerRecoilState.lng !== '' &&
                    customerRecoilState.addressName !== ''
                  ) {
                    setLocation({
                      ...location,
                      routeGo: {
                        ...routeGo,
                        distance: customerRecoilState.distance,
                        origin: {
                          ...routeGo.origin,
                          id: customerRecoilState.id || '',
                          address: customerRecoilState.address,
                          lat: customerRecoilState.lat,
                          lng: customerRecoilState.lng,
                          name: customerRecoilState.addressName || '',
                          note: customerRecoilState.pickUpPoint,
                        },
                      },
                    })
                  }
                  if (customerRecoilState.pickUpPoint !== '') {
                    setLocation({
                      ...location,
                      routeGo: {
                        ...routeGo,
                        distance: customerRecoilState.distance,
                        origin: {
                          ...routeGo.origin,
                          note: routeGo.origin.note,
                        },
                      },
                    })
                  }
                  console.log('LOCATION: ', location)
                  handleUpdateAddressHistory().then(() => {
                    navigate(BookingRoute.BookingCardCalendarCNMI)
                  })
                } else if (selectedPerson == 'Other_person') {
                  console.log('seleted address: ', customerRecoilState.addressName)
                  console.log('route Go Origin: ', routeGo.origin.address)
                  console.log('selected address/ route Go: ', customerRecoilState.addressName ?? routeGo.origin.address)
                  setPassengerRecoilState({
                    ...customerRecoilState,
                    phone: otherPhoneNumber,
                    name: otherName,
                  })
                  updateContactHistoryCNMI({
                    lineId: customerRecoilState.lineId,
                    phone: otherPhoneNumber,
                    name: otherName,
                  })
                  // Use history data case
                  if (
                    customerRecoilState.address !== '' &&
                    customerRecoilState.lat !== '' &&
                    customerRecoilState.lng !== '' &&
                    customerRecoilState.addressName !== ''
                  ) {
                    setLocation({
                      ...location,
                      routeGo: {
                        ...routeGo,
                        origin: {
                          ...routeGo.origin,
                          id: customerRecoilState.id || '',
                          address: customerRecoilState.address,
                          lat: customerRecoilState.lat,
                          lng: customerRecoilState.lng,
                          name: customerRecoilState.addressName || '',
                          note: routeGo.origin.note,
                        },
                      },
                    })
                  }
                  handleUpdateAddressHistory().then(() => {
                    navigate(BookingRoute.BookingCardCalendarCNMI)
                  })
                }

            }}
          >
            {t('Next')}
          </button>
        </div>
        <ModalCard
          title={t('Warning')}
          detail={message}
          status={open}
          singleButton={{ name: t('OK'), style: 'btn-primary', type: 'cancel' }}
          onClick={(s) => setOpen(s)}
        />
      </div>
    </div>
  )
}
