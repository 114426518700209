import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  maxFollower: number
  value: number
  alert: boolean
  isDisabled: boolean
  onCalculate: (n: number) => void
}

export default function PassengerCard({ maxFollower, value, alert, isDisabled, onCalculate }: Props) {
  const { t } = useTranslation()
  const styleNormalInput = 'mt-1 font-semibold text-center bg-gray-200 rounded-md shadow-sm w-14 h-9 sm:text-sm mx-1'
  const styleAlertlInput =
    'border-red-500 border-solid border-2 mt-1 font-semibold text-center bg-gray-200 rounded-md shadow-sm w-14 h-9 sm:text-sm mx-1'

  const [classPassengerInput, setClassPassengerInput] = useState(styleNormalInput)

  function setAlert() {
    setTimeout(function () {
      setClassPassengerInput(styleNormalInput)
    }, 1500)
    setClassPassengerInput(styleAlertlInput)
  }

  useEffect(() => {
    if (alert && value === 0) {
      setAlert()
    } else {
      return
    }
  }, [alert, value])

  return (
    <div className="flex items-center pt-2 bg-white">
      <div className="flex-1">
        <h3 className="card-title">{t('Number of companion(s)')}</h3>
      </div>
      <div className="flex items-center mr-4">
        <button
          type="button"
          onClick={() => onCalculate(value - 1)}
          disabled={value === 0 || isDisabled}
          className="flex items-center justify-center w-10 h-10"
        >
          <span
            className={`${
              value <= 0 || isDisabled ? 'text-gray-300' : 'text-blue-400'
            } text-3xl material-icons-outlined`}
          >
            do_disturb_on
          </span>
        </button>
        <input type="number" value={value} readOnly className={classPassengerInput} />
        <button
          type="button"
          onClick={() => onCalculate(value + 1)}
          disabled={value >= maxFollower || isDisabled}
          className="flex items-center justify-center w-10 h-10"
        >
          <span
            className={`${
              value >= maxFollower || isDisabled ? 'text-gray-300' : 'text-blue-400'
            } text-3xl material-icons-outlined`}
          >
            add_circle
          </span>
        </button>
      </div>
    </div>
  )
}
