import { useTranslation } from 'react-i18next'
import Button from 'src/components/Button/Base'

type Props = {
  textCancel?: string
  textConfirm?: string
  onCancel: () => void
  onConfirm: () => void
  vertical?: boolean
  shuffle?: boolean
  isDisabled?: boolean
  isHideCancelButton?: boolean
  style?: string
}

export default function DoubleButtonCard({
  textCancel,
  textConfirm,
  onCancel,
  onConfirm,
  vertical,
  shuffle,
  isHideCancelButton,
  isDisabled,
  style,
}: Props) {
  const { t } = useTranslation()
  let container = 'flex px-4 pt-3'
  if (vertical) {
    container = `${container} flex-col space-y-4`
  } else {
    container = `${container} flex-row space-x-4`
  }
  if (shuffle) {
    if (vertical) {
      container = `${container} flex-col-reverse space-y-reverse`
    } else {
      container = `${container} flex-row-reverse space-x-reverse`
    }
  }
  return (
    <div className="py-5 bg-white">
      <div className={container}>
        {!isHideCancelButton && (
          <div className={`${!vertical && 'w-1/3'}`}>
            <Button type={`${style ? style : 'btn-outline-primary'} btn block`} onClick={onCancel}>
              {textCancel || t('Cancel')}
            </Button>
          </div>
        )}

        <div className={`${!vertical && 'w-2/3'}`}>
          <Button type={`btn btn-primary block ${isDisabled && 'disabled opacity-50'}`} onClick={onConfirm}>
            {textConfirm || t('Confirm Destination')}
          </Button>
        </div>
      </div>
    </div>
  )
}
