import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useCallApi } from 'src/hooks/useCallApi'
import { AddressHistoryPerson } from 'src/types'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import loadingIcon from 'src/assets/icons/loading.svg'
// import { XIcon, TrashIcon } from '@heroicons/react/outline'
/* eslint-disable @typescript-eslint/no-non-null-assertion */

type Props = {
  openAddressHistory: boolean
  lineId: string | undefined
  historyData: Array<AddressHistoryPerson>
  onClose: () => void
  onSelectHistoryItem: (item: AddressHistoryPerson) => void
}

const AddressHistoryModal = ({ openAddressHistory, lineId, historyData, onClose, onSelectHistoryItem }: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [custHistoryData, setCustHistoryData] = useState<Array<AddressHistoryPerson>>(historyData)
  const [isTrashClicked, setIsTrashClicked] = useState(false)
  const [selectedItems, setSelectedItems] = useState(new Set<number>())
  const { deleteAddressHistoryCNMI } = useCallApi()

  ///////////////// Delete Contact History ///////////////////
  /////////// Display Checkbox Condition ////////////
  const handleTrashClick = () => {
    setIsTrashClicked((prevState) => !prevState)
  }

  ///////////// Any selected checkbox ///////////////
  const handleCheckboxChange = (index: number) => {
    setSelectedItems((prev) => {
      const newSelected = new Set(prev)
      if (newSelected.has(index)) {
        newSelected.delete(index)
      } else {
        newSelected.add(index)
      }
      return newSelected
    })
  }

  const isAnyItemSelected = selectedItems.size > 0
  ///////// END: Display Checkbox Condition ////////////

  //////////////////// Delete Function ////////////////////
  useEffect(() => {
    setLoading(true)
    setCustHistoryData(historyData)
    setLoading(false)
  }, [historyData])

  const handleDelete = async () => {
    const newHistoryData = historyData.filter((_, index) => !selectedItems.has(index))

    if (lineId != undefined) {
      setLoading(true)
      try {
        // Wait for all delete operations to complete
        await Promise.all(
          Array.from(selectedItems).map((index) => {
            const item = historyData[index]
            return deleteAddressHistoryCNMI({
              lineId: lineId,
              addressname: item.addressname,
              address: item.address,
              lat: item.lat,
              lng: item.lng,
              pickupDetail: item.pickupDetail,
            })
          })
        )

        // Update state without deleted history data
        setCustHistoryData(newHistoryData)
      } catch (error) {
        console.error('Error deleting history items: ', error)
      } finally {
        setLoading(false)
      }
    }

    console.log('History Data After:', historyData)

    // Reset selectedItems after deletion
    setSelectedItems(new Set())
  }
  ///////////////// END: Delete Contact History ///////////////////

  /////////////////// Reset states when closing the modal /////////////////////
  const handleClose = () => {
    setIsTrashClicked(false)
    setSelectedItems(new Set())
    onClose()
  }
  /////////////////// Reset states when closing the modal /////////////////////

  if (!openAddressHistory) {
    return null
  }

  return (
    <Modal
      open={openAddressHistory}
      onClose={handleClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        style={{
          minWidth: '300px',
          maxHeight: '300px',
          overflowY: historyData.length > 3 ? 'scroll' : 'hidden',
          backgroundColor: 'white',
          boxShadow: '24px',
          padding: '16px',
          position: 'relative',
        }}
      >
        {isTrashClicked ? (
          <Button
            onClick={isAnyItemSelected ? handleDelete : handleTrashClick}
            style={{
              position: 'absolute',
              top: '15px',
              right: '30px',
              minWidth: '35px',
              padding: '0',
              color: isAnyItemSelected ? 'red' : '',
              marginTop: isAnyItemSelected ? '5px' : '0',
            }}
          >
            {isAnyItemSelected ? (
              'Delete'
            ) : (
              <div className="w-4 text-left text-blue-500" onClick={handleTrashClick}>
                <span className="text-2xl material-icons">delete_outlined</span>
              </div>
            )}
          </Button>
        ) : (
          // <Button
          //     onClick={handleTrashClick}
          //     style={{ position: 'absolute', top: '15px', right: '50px', minWidth: '35px', padding: '0' }}
          // >
          //     <TrashIcon className="text-light-blue" />
          // </Button>
          // <div className="w-4 text-left text-blue-500" onClick={() => setSelectContacts(true)}>
          <Button
            style={{
              position: 'absolute',
              top: '15px',
              right: '30px',
              minWidth: '35px',
              padding: '0',
              color: isAnyItemSelected ? 'red' : '',
              marginTop: isAnyItemSelected ? '5px' : '0',
            }}
          >
            <div className="w-4 text-left text-blue-500" onClick={handleTrashClick}>
              <span className="text-2xl material-icons">delete_outlined</span>
            </div>
          </Button>
        )}
        {/* <Button
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        minWidth: '25px',
                        padding: '0',
                    }}
                >
                    <XIcon style={{ color: 'black' }} />
                </Button> */}
        <div className="flex flex-col">
          <label className="block text-lg font-bold">{t('Pick Up Point History')}</label>
        </div>
        <Divider style={{ marginTop: '20px', marginBottom: '10px', width: '100%' }} />
        <div>
          {loading ? (
            <div className="flex justify-center w-full loading-overlay">
              <img src={loadingIcon} alt="Loading" />
            </div>
          ) : (
            custHistoryData.map((item, index) => (
              <React.Fragment key={index}>
                <div key={index} className="flex items-center justify-between">
                  <Button
                    onClick={() => onSelectHistoryItem(item)}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      flexGrow: 1,
                      width: '100%',
                    }}
                  >
                    <div
                      className="overflow-hidden font-bold text-black text-ellipsis"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {`${item.addressname?.substring(0, 20) + (item.addressname!.length > 20 ? '...' : '')}`}
                    </div>
                    <div className="text-gray-400">{item.pickupDetail || ' '}</div>
                  </Button>
                  {isTrashClicked && (
                    <input
                      type="checkbox"
                      style={{
                        accentColor: '#1F9B66',
                      }}
                      className="self-center w-5 h-5 ml-4 mr-5 form-checkbox"
                      checked={selectedItems.has(index)}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  )}
                </div>
                {index < custHistoryData.length - 1 && (
                  <Divider
                    style={{
                      backgroundColor: '#E8E8E8',
                      height: '1px',
                      width: 'calc(100% - 10px)',
                      margin: '10px 5px',
                    }}
                  />
                )}
              </React.Fragment>
            ))
          )}
        </div>
      </Box>
    </Modal>
  )
}

export default AddressHistoryModal
