import { useEffect, useState } from 'react'
import { VehicleState } from 'src/enums'
import { makeMarker, useGoogleMaps } from 'src/hooks/useGoogleMaps'
import { Place, RouteDetail } from 'src/types'
import ModalCard from './ModalCard'
import { useTranslation } from 'react-i18next'

type Props = {
  height: string
  onSetDefaultOrigin?: (origin: Place) => void
  setDistanceDuration?: (go: RouteDetail, back: RouteDetail) => void
  onClickSetPlace?: (place: Place) => void
  destination: google.maps.LatLngLiteral
  driverLocation?: google.maps.LatLngLiteral
  state?: VehicleState
}

export default function Map({ height, destination, driverLocation, state }: Props) {
  const { t } = useTranslation()
  let google: typeof globalThis.google | undefined = undefined
  let map: google.maps.Map
  let marker: google.maps.Marker
  let markerDes: google.maps.Marker
  let directionsRendererGo: google.maps.DirectionsRenderer

  let lastZoom = 14
  let circle1: google.maps.Circle
  let circle2: google.maps.Circle
  let rMin = 100
  let rMax = 220
  let step = 6
  let intID: NodeJS.Timer

  const iconDestinationRouteBack = '/icons/destination-route-back.svg'
  const iconDestinationRouteGo = '/icons/destination-route-go.svg'
  const iconOriginRouteBack = '/icons/origin-route-back.svg'
  const iconOriginRouteGo = '/icons/origin-route-go.svg'
  const driver = '/icons/driver.svg'

  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<google.maps.LatLngLiteral>()
  const [message] = useState('')

  const { getGoogleMapsLoader, calculateDriectionFromLatLng } = useGoogleMaps()

  async function initMap() {
    try {
      google = await getGoogleMapsLoader().load()
      map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        zoom: 18,
        mapTypeId: 'roadmap',
        center: { lat: 13.736717, lng: 100.523186 },
        disableDefaultUI: true,
        keyboardShortcuts: false,
      })
      if (!driverLocation) return

      google.maps.event.addListener(map, 'zoom_changed', function () {
        const zoom = map.getZoom()
        clearInterval(intID)

        if (zoom > lastZoom) {
          const r = zoom - lastZoom
          rMax /= 2 ** r
          rMin /= 2 ** r
          step /= 2 ** r
        } else if (zoom < lastZoom) {
          const r = lastZoom - zoom
          rMax *= 2 ** r
          rMin *= 2 ** r
          step *= 2 ** r
        }
        lastZoom = zoom

        circle1.setRadius(rMax)
        circle2.setRadius(rMax * 1.25)
        setAnimation()
      })

      SetMarker(driverLocation)
    } catch (e) {
      console.error(e)
    }
  }

  function setAnimation() {
    let direction = 1
    intID = setInterval(function () {
      const radius = circle1.getRadius()
      if (radius > rMax || radius < rMin) {
        direction *= -1
      }
      circle1.setRadius(radius + direction * step)
      circle2.setRadius(radius * 1.5 + direction * step)
    }, 80)
  }

  async function SetMarker(driverLocation: google.maps.LatLngLiteral) {
    if (!google) return
    //Remove previous Marker.
    if (marker != null) {
      marker.setMap(null)
    }

    if (directionsRendererGo != null) {
      directionsRendererGo.setMap(null)
    }

    if (!driverLocation && state) return
    if (!state) return

    let _icon = iconOriginRouteGo
    if (
      [
        VehicleState.START,
        VehicleState.PICK_UP_POINT,
        VehicleState.CONFIRM_PICK_UP,
        VehicleState.CONFIRM_DRIVER,
      ].includes(state)
    ) {
      _icon = iconOriginRouteGo
    } else if ([VehicleState.DEPART, VehicleState.ARRIVE, VehicleState.FINISH].includes(state)) {
      _icon = iconDestinationRouteGo
    } else if ([VehicleState.FINISH, VehicleState.PICK_UP_POINT_BACK].includes(state)) {
      _icon = iconOriginRouteBack
    } else if ([VehicleState.DEPART_BACK, VehicleState.ARRIVE_BACK].includes(state)) {
      _icon = iconDestinationRouteBack
    }

    const icon = {
      url: driver,
      scale: 0.7,
      strokeColor: 'white',
      strokeWeight: 0.1,
      fillOpacity: 1,
      fillColor: '#404040',
      offset: '5%',
      rotation: 0,
      anchor: new google.maps.Point(15, 17), // orig 10,50 back of car, 10,0 front of car, 10,25 center of car
    }

    const routeGoDetail = await calculateDriectionFromLatLng(driverLocation, destination)
    const leg = routeGoDetail.routes[0].legs[0]
    directionsRendererGo = new google.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
      preserveViewport: false,
      polylineOptions: {
        strokeColor: [VehicleState.PICK_UP_POINT_BACK, VehicleState.DEPART_BACK, VehicleState.ARRIVE_BACK].includes(
          state
        )
          ? '#E95D5D'
          : '#0181EF',
        strokeWeight: 5,
        strokeOpacity: 0.65,
        zIndex: 3,
      },
    })
    directionsRendererGo.setDirections(routeGoDetail)
    //Set Marker on Map.
    if (value) {
      const lastPos = new google.maps.LatLng(value)
      icon.rotation = google.maps.geometry.spherical.computeHeading(lastPos, leg.start_location)
    }

    map.panTo(leg.start_location)
    marker = makeMarker(leg.start_location, driver, 'car', map, undefined, 2)
    marker.setIcon(icon)
    markerDes = makeMarker(leg.end_location, _icon, 'origin', map, undefined, 2)
    markerDes

    circle1 = new google.maps.Circle({
      center: leg.start_location,
      radius: 0,
      strokeColor: '#FD902C',
      strokeWeight: 0,
      strokeOpacity: 0.6,
      fillColor: '#FD902C',
      fillOpacity: 0.3,
    })

    circle2 = new google.maps.Circle({
      center: leg.start_location,
      radius: 0,
      strokeColor: '#FD902C',
      strokeWeight: 0,
      fillColor: '#FD902C',
      fillOpacity: 0.1,
    })

    clearInterval(intID)
    circle1.setMap(map)
    circle2.setMap(map)
    setAnimation()
  }

  useEffect(() => {
    initMap()
  }, [])

  useEffect(() => {
    if (!driverLocation) return
    if (value?.lat != driverLocation.lat || value?.lng != driverLocation.lng) {
      SetMarker(driverLocation)
      setValue(driverLocation)
    }
  }, [driverLocation])

  return (
    <div>
      <ModalCard
        title={t('Warning')}
        detail={message}
        status={open}
        singleButton={{ name: t('OK'), style: 'btn-primary', type: 'cancel' }}
        onClick={(s) => setOpen(s)}
      />
      <div id="map" style={{ height }} className="w-full"></div>
    </div>
  )
}
