export enum BookingRoute {
  Index = '/',
  Canceled = '/canceled',
  Confirm = '/confirm',
  Customer = '/customer',
  Departure = '/departure',
  Invite = '/invite',
  Map = '/map',
  List = '/list',
  Location = '/location',
  Payment = '/payment',
  Search = '/search',
  Success = '/success',
  Summary = '/summary',
  View = '/view',
  /////// Add on for CNMI /////////////
  LandingPageCNMI = '/landingpage',
  SignUpCNMI = '/signup',
  // ConsentCNMI = '/consent',
  AddressAndPriceCNMI = '/addressandprice',
  BookingCardCalendarCNMI = '/bookingcardcalendar',
  // BookingTimeModalCNMI = '/bookingtimemodal',
  BookingListCNMI = 'bookinglist',
  // BookingDetailCNMI = 'bookingdetail',
  PaymentSummary = '/paymentsummary',
  UserProfileCNMI = '/userprofile',
}

export enum FeedbackRoute {
  Feedback = '/feedback',
  Success = '/feedback/success',
}

export enum RequestTrialRoute {
  Index = '/',
  Approval = '/request-trial/approval',
  Success = '/request-trial/success',
}
