import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'src/i18n'
import { Car, CarModel } from 'src/types'

type Props = {
  id?: number
  carModel?: CarModel
  car?: Car
  isForceActive?: boolean
  isSelected?: boolean
  isDisabled?: boolean
  isNoTimeAvailable?: boolean
  onClick?: () => void
  onChangeAvailable?: (carModelId: number, v: boolean) => void
}

export default function CarCard({ carModel, car, isSelected, isDisabled, isNoTimeAvailable, isForceActive, onClick }: Props) {
  const parentBackgroundStyle = isDisabled ? 'bg-gray-200' : 'bg-white'
  const parentBorderStyle = isSelected ? `border-2 ${isDisabled ? 'border-gray-400' : 'border-blue-500'}` : ''
  const parentStyle = `${parentBorderStyle} ${parentBackgroundStyle}`
  const childStyle = isSelected ? 'border-blue-100' : ''
  const { t } = useTranslation()

  const renderAccessories = useMemo(() => {
    if (carModel?.accessories) {
      const accessories = carModel.accessories.map((accessory) => accessory[i18n.language]).join(', ')
      return <div>{accessories}</div>
    } else if (car?.accessories) {
      const accessories = car.accessories.map((accessory) => accessory[i18n.language]).join(', ')
      return <div>{accessories}</div>
    }
    return <div />
  }, [carModel?.accessories, car?.accessories, i18n.language])

  return (
    <div
      className={`${parentStyle} flex flex-col rounded-lg drop-shadow-md`}
      onClick={!isDisabled ? onClick : () => {}}
    >
      <div className={`relative ${!isDisabled ? childStyle : 'border-b border-white'}`}>
        <img
          alt="car"
          src={carModel?.imageUrl ?? car?.imageUrl}
          className={(isForceActive || isNoTimeAvailable ? '' : 'opacity-50 mt-6') + ' h-[124px] w-[200px] mx-auto my-auto'}
          // className='h-[124px] w-[200px] mx-auto my-auto'
        />
      </div>
      <div className="border-b border-gray-200" />
      <div className="flex flex-col p-3">
        <div className="flex justify-center pb-2 font-semibold">{carModel?.model ?? car?.model}</div>
        <div className="flex justify-between mb-2 text-sm font-normal">
          <div>{t('Support Seat')}</div>
          <div>
            {carModel?.seat ?? car?.seat} {t('CarCard.seats')}
          </div>
        </div>
        <div className="text-sm font-normal">
          <div className="mt-2">{renderAccessories}</div>
        </div>
      </div>
    </div>
  )
}
