import { t } from 'i18next'
import { find } from 'lodash'
import { useState } from 'react'
import { Vote } from 'src/enums'
import i18n from 'src/i18n'
import { FeedbackStatus, Question } from 'src/types'
import IconActiveAdjust from '../../assets/icons/icon-active-adjust.svg'
import IconActiveBad from '../../assets/icons/icon-active-bad.svg'
import IconActiveGood from '../../assets/icons/icon-active-good.svg'
import IconActiveVeryGood from '../../assets/icons/icon-active-very-good.svg'
import IconAdjust from '../../assets/icons/icon-adjust.svg'
import IconBad from '../../assets/icons/icon-bad.svg'
import IconGood from '../../assets/icons/icon-good.svg'
import IconVeryGood from '../../assets/icons/icon-very-good.svg'
import NoteInput from '../Input/NoteInput'

type PropsAnswer = {
  id: number
  like: Vote | null
  note: string | null
  choice: string | null
}

type Props = {
  active: FeedbackStatus[]
  question: Question
  setAnswer: ({ id, like, note, choice }: PropsAnswer) => void
}

const QuestionVote = ({ active, question, setAnswer }: Props) => {
  const [validate, setValidate] = useState(true)

  const handleNote = (value: string) => {
    const data = find(active, { id: question.id })
    setAnswer({
      id: question.id,
      like: data?.answer?.like ?? null,
      note: value,
      choice: null,
    })
    setValidate(!value)
  }

  const handleAnswer = (value: Vote) => {
    const data = find(active, { id: question.id })
    setAnswer({
      id: question.id,
      like: value,
      note: data?.answer?.note ?? null,
      choice: null,
    })
  }

  return (
    <div>
      <div className="flex flex-row justify-between pb-1">
        <div className="pb-2 text-base">{i18n.language === 'th' ? question.bodyTh : question.bodyEn}</div>
      </div>
      <div>
        <div className="flex flex-row justify-center pb-2">
          <div className="flex flex-col items-center px-3">
            <div className="h-10">
              <button onClick={() => handleAnswer(Vote.ADJUST)}>
                {find(active, { id: question.id, answer: { like: Vote.ADJUST } }) ? (
                  <img src={IconActiveAdjust}></img>
                ) : (
                  <img src={IconAdjust}></img>
                )}
              </button>
            </div>
            <div className="text-base ">{t('Adjust')}</div>
          </div>
          <div className="flex flex-col items-center px-3">
            <div className="h-10">
              <button onClick={() => handleAnswer(Vote.BAD)}>
                {find(active, { id: question.id, answer: { like: Vote.BAD } }) ? (
                  <img src={IconActiveBad}></img>
                ) : (
                  <img src={IconBad}></img>
                )}
              </button>
            </div>
            <div className="text-base ">{t('Bad')}</div>
          </div>
          <div className="flex flex-col items-center px-3">
            <div className="h-10">
              <button onClick={() => handleAnswer(Vote.GOOD)}>
                {find(active, { id: question.id, answer: { like: Vote.GOOD } }) ? (
                  <img src={IconActiveGood}></img>
                ) : (
                  <img src={IconGood}></img>
                )}
              </button>
            </div>
            <div className="text-base ">{t('Good')}</div>
          </div>
          <div className="flex flex-col items-center px-3">
            <div className="h-10">
              <button onClick={() => handleAnswer(Vote.VERY_GOOD)}>
                {find(active, { id: question.id, answer: { like: Vote.VERY_GOOD } }) ? (
                  <img src={IconActiveVeryGood}></img>
                ) : (
                  <img src={IconVeryGood}></img>
                )}
              </button>
            </div>
            <div className="text-base ">{t('Very Good')}</div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="pb-2">
          <NoteInput
            onChange={(value) => handleNote(value)}
            maxlength={200}
            high={1}
            disableShowLength
            showValidate={validate}
            placeholder={t('Please leave a comment to improve it.') as string} 
          />
        </div>
      </div>
    </div>
  )
}

export default QuestionVote
