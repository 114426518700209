type Props = {
  descriptionTop: string
  descriptionDown: string
}

export default function PageEmpty({ descriptionTop, descriptionDown }: Props) {
  return (
    <div className="flex flex-col justify-between">
      <div className="flex flex-col items-center text-lg text-center text-gray-600 pt-28">
        <img src="/Empty.png" height={278} width={278} />
        <div className="pt-8 font-bold">{descriptionTop}</div>
        <div className="pt-2 font-normal">{descriptionDown}</div>
      </div>
    </div>
  )
}
