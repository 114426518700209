import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { PLACE_TYPE } from 'src/consts'
import { resolveAddressName } from 'src/helpers/utils'
import {
  calculateAndDisplayRoute,
  getCurrentLocation,
  makeMarker,
  useGoogleMaps,
} from 'src/hooks/useGoogleMaps'
import { bookingLocationState, viewLocationState } from 'src/states'
import { Place, RouteDetail } from 'src/types'
import ModalCard from './ModalCard'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'src/hooks/useLocalStorage'

type Props = {
  height: string
  setDistanceDuration?: (go: RouteDetail, back: RouteDetail) => void
  onClickSetPlace?: (place: Place) => void
  showFullMap?: boolean
  onSwap?: boolean
  searchResult?: Place
  isView?: boolean
}

const iconOriginRouteGo = '/icons/origin-route-go.svg'
const iconDestinationRouteGo = '/icons/destination-route-go.svg'
const iconOriginRouteBack = '/icons/origin-route-back.svg'
const iconDestinationRouteBack = '/icons/destination-route-back.svg'

export default function Map({
  height,
  setDistanceDuration,
  onClickSetPlace,
  showFullMap,
  onSwap,
  searchResult,
  isView,
}: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [message, ] = useState('')
  const [, setSelectedProvince] = useLocalStorage('selected_province')

  const mapState = useRecoilValue(isView ? viewLocationState : bookingLocationState)
  const { routeGo, routeBack, routeType } = mapState

  const { getGeoLocation, getGoogleMapsLoader } = useGoogleMaps()

  useEffect(() => {
    const showRoute = routeGo.destination.id && routeGo.origin.id && !showFullMap ? true : false

    const placeIcon =
      routeType.placeType === PLACE_TYPE.ORIGIN1
        ? iconOriginRouteGo
        : routeType.placeType === PLACE_TYPE.DESTINATION1
        ? iconDestinationRouteGo
        : routeType.placeType === PLACE_TYPE.ORIGIN2
        ? iconOriginRouteBack
        : iconDestinationRouteBack

    getGoogleMapsLoader()
      .load()
      .then(async () => {
        const mapDiv = document.getElementById('map') as HTMLElement
        const map = new google.maps.Map(mapDiv, {
          zoom: 18,
          mapTypeId: 'roadmap',
          center: { lat: 13.736717, lng: 100.523186 },
          disableDefaultUI: true,
          keyboardShortcuts: false,
          clickableIcons: false,
        })
        let marker = new google.maps.Marker()
        const infoWindow = new google.maps.InfoWindow()

        const infoWindowContent = document.createElement('div') as HTMLElement
        const address = document.createElement('span') as HTMLElement
        infoWindowContent.appendChild(address)
        infoWindow.setContent(infoWindowContent)

        const myLocationButton = document.createElement('button') as HTMLElement
        myLocationButton.textContent = 'my_location'
        myLocationButton.className = 'm-3 text-3xl text-blue-600 bg-white rounded-md material-icons h-9 w-9'
        myLocationButton.style.cssText = `box-shadow: 0px 0px 15px #b0b0b0;`

        if (showFullMap) {
          mapDiv.appendChild(myLocationButton)
          map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(myLocationButton)
        }

        if (showRoute) {
          const routeGoDetail = await calculateAndDisplayRoute(routeGo.origin.id, routeGo.destination.id)
          if (!routeGoDetail) {
            setOpen(true)
            return
          }

          const directionsRendererGo = new google.maps.DirectionsRenderer({
            map: map,
            suppressMarkers: true,
          })
          directionsRendererGo.setDirections(routeGoDetail)

          const legGo = routeGoDetail.routes[0].legs[0]
          const go: RouteDetail = {
            distance: legGo.distance.value,
            duration: legGo.duration.value,
          }
          makeMarker(legGo.start_location, iconOriginRouteGo, 'origin', map, google.maps.Animation.DROP, 2)
          makeMarker(legGo.end_location, iconDestinationRouteGo, 'destination', map, google.maps.Animation.DROP, 2)

          let back: RouteDetail = { distance: 0, duration: 0 }

          if (!routeType.isOneWay) {
            const routeBackDetail = await calculateAndDisplayRoute(routeBack?.origin?.id, routeBack?.destination?.id)
            const legBack = routeBackDetail.routes[0].legs[0]
            const directionsRendererBack = new google.maps.DirectionsRenderer({
              map: map,
              polylineOptions: {
                strokeColor: 'red',
                strokeWeight: 5,
                strokeOpacity: 80,
              },
              suppressMarkers: true,
            })
            back = {
              distance: legBack.distance.value,
              duration: legBack.duration.value,
            }

            if (
              legGo.end_location.lat() === legBack.start_location.lat() &&
              legGo.end_location.lng() === legBack.start_location.lng()
            ) {
              makeMarker(
                legBack.start_location,
                iconOriginRouteBack,
                'origin',
                map,
                google.maps.Animation.DROP,
                1,
                new google.maps.Point(25, 45)
              )
            } else {
              makeMarker(legBack.start_location, iconOriginRouteBack, 'origin', map, google.maps.Animation.DROP, 1)
            }

            if (
              legGo.start_location.lat() === legBack.end_location.lat() &&
              legGo.start_location.lng() === legBack.end_location.lng()
            ) {
              makeMarker(
                legBack.end_location,
                iconDestinationRouteBack,
                'destination',
                map,
                google.maps.Animation.DROP,
                1,
                new google.maps.Point(25, 45)
              )
            } else {
              makeMarker(
                legBack.end_location,
                iconDestinationRouteBack,
                'destination',
                map,
                google.maps.Animation.DROP,
                1
              )
            }
            directionsRendererBack.setDirections(routeBackDetail)
          }

          if (setDistanceDuration) {
            setDistanceDuration(go, back)
          }
        } else {
          if (routeType.placeType === PLACE_TYPE.ORIGIN1) {
            if (routeGo.origin.name !== '' || (searchResult && searchResult.id)) {
              if (searchResult && searchResult.id) {
                searchPlace(searchResult)
              } else {
                searchPlace(routeGo.origin)
              }
            }
            panToCurrentLocation()
            if (onClickSetPlace) {
              getPlaceOnClick()
            }
          } else if (routeType.placeType === PLACE_TYPE.DESTINATION1) {
            if (routeGo.destination.name !== '' || (searchResult && searchResult.id)) {
              if (searchResult && searchResult.id) {
                searchPlace(searchResult)
              } else {
                searchPlace(routeGo.destination)
              }
            }
            panToCurrentLocation()
            if (onClickSetPlace) {
              getPlaceOnClick()
            }
          } else if (routeType.placeType === PLACE_TYPE.ORIGIN2) {
            if (routeBack.origin.name !== '' || (searchResult && searchResult.id)) {
              if (searchResult && searchResult.id) {
                searchPlace(searchResult)
              } else {
                searchPlace(routeBack.origin)
              }
            }
            panToCurrentLocation()
            if (onClickSetPlace) {
              getPlaceOnClick()
            }
          } else {
            if (routeBack.destination.name !== '' || (searchResult && searchResult.id)) {
              if (searchResult && searchResult.id) {
                searchPlace(searchResult)
              } else {
                searchPlace(routeBack.destination)
              }
            }
            panToCurrentLocation()
            if (onClickSetPlace) {
              getPlaceOnClick()
            }
          }
        }

        async function currentLocation(): Promise<Place | null> {
          if (navigator.geolocation) {
            const location = await getCurrentLocation()
            const result = await getGeoLocation({ location })
            const { address_components, place_id, formatted_address: _formatted_address } = result
            const { name, formatted_address } = resolveAddressName(address_components, _formatted_address)
            let postcode = ''
            
            for (let i = 0; i <= address_components.length - 1; i++) {
              if (address_components[i].types.includes('administrative_area_level_1') && address_components[i].types.includes('political')) {
                setSelectedProvince(address_components[i].long_name)
              }

              if (address_components[i].types.includes('postal_code')) {
                postcode = address_components[address_components.length - 1].long_name
              }
            }

            infoWindow.close()
            marker.setVisible(false)

            map.panTo(location)
            marker = makeMarker(location, placeIcon, 'people icon', map, google.maps.Animation.DROP)
            address.textContent = formatted_address
            infoWindow.open(map, marker)

            google.maps.event.addListener(marker, 'click', () => {
              infoWindow.setContent(infoWindowContent)
              infoWindow.open(map, marker)
            })

            return {
              id: place_id,
              name,
              address: formatted_address,
              post_code:postcode,
              lat: location.lat.toString(),
              lng: location.lng.toString(),
            }
          } else {
            console.error('error to get geolocation')
            return null
          }
        }

        function panToCurrentLocation() {
          myLocationButton.addEventListener('click', async () => {
            const place = await currentLocation()
            if (place) {
              const { id, name, address,post_code, lat, lng } = place
              if (onClickSetPlace) {
                onClickSetPlace({ id, name, address,post_code, lat, lng })
              }
            }
          })
        }

        function getPlaceOnClick() {
          map.addListener('click', async (event) => {
            const result = await getGeoLocation({ location: event.latLng })
            const { address_components, place_id, formatted_address: _formatted_address } = result
            const { name, formatted_address } = resolveAddressName(address_components, _formatted_address)
            let postcode = ''
            
            for (let i = 0; i <= address_components.length - 1; i++) {
              if (address_components[i].types.includes('administrative_area_level_1') && address_components[i].types.includes('political')) {
                setSelectedProvince(address_components[i].long_name)
              }

              if (address_components[i].types.includes('postal_code')) {
                postcode = address_components[address_components.length - 1].long_name
              }
            }

            infoWindow.close()
            marker.setVisible(false)

            map.panTo(event.latLng)
            marker = makeMarker(event.latLng, placeIcon, 'select place', map, google.maps.Animation.DROP)
            address.textContent = formatted_address as string
            infoWindow.open(map, marker)

            google.maps.event.addListener(marker, 'click', () => {
              infoWindow.setContent(infoWindowContent)
              infoWindow.open(map, marker)
            })
            

            if (onClickSetPlace) {
              onClickSetPlace({
                id: place_id,
                name,
                address: formatted_address,
                post_code:postcode,
                lat: event.latLng.lat().toString(),
                lng: event.latLng.lng().toString(),
              })
            }
          })
        }

        function searchPlace(place: Place) {
          infoWindow.close()
          marker.setVisible(false)

          const position = {
            lat: parseFloat(place.lat ?? '0'),
            lng: parseFloat(place.lng ?? '0'),
          }
          marker.setVisible(true)
          marker = makeMarker(position, placeIcon, 'people icon', map, google.maps.Animation.DROP)
          map.setCenter(position)

          address.textContent = place.address ?? ''
          infoWindow.open(map, marker)

          google.maps.event.addListener(marker, 'click', () => {
            infoWindow.setContent(infoWindowContent)
            infoWindow.open(map, marker)
          })
        }
      })
  }, [routeType.isOneWay, showFullMap, onSwap])

  return (
    <div>
      <ModalCard
        title={t('Warning')}
        detail={message}
        status={open}
        singleButton={{ name: t('OK'), style: 'btn-primary', type: 'cancel' }}
        onClick={(s) => setOpen(s)}
      />
      <div id="map" style={{ height }} className="w-full"></div>
    </div>
  )
}
// function then() {
//   throw new Error('Function not implemented.')
// }

