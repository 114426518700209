import liff from '@line/liff/dist/lib'
import { t } from 'i18next'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import Header from 'src/components/Header'
import NoteInput from 'src/components/Input/NoteInput'
import ModalTermCondition from 'src/components/ModalTermCondition'
import { DEPARTMENT_LIST } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { useCallApi } from 'src/hooks/useCallApi'
import i18n from 'src/i18n'
import Loading from 'src/pageviews/Booking/Loading'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'
import { bookingDepartureState, lineState } from 'src/states'
import { IRequestTrial } from 'src/types'

export default function RequestTrial() {
  const { createRequestTrial, getAcceptConsent, createAcceptConsent } = useCallApi()
  const { userId } = useRecoilValue(lineState)
  const navigate = useNavigate()
  const [departure, setDeparture] = useRecoilState(bookingDepartureState)

  const [department, setDepartment] = useState<string>()
  const [symptom, setSymptom] = useState<string>()
  const [citizenId, setCitizenId] = useState<string>()

  const [validateDepartment, setValidateDepartment] = useState<boolean>(false)
  const [validateSymptom, setValidateSymptom] = useState<boolean>(false)
  const [validateCitizenId, setValidateCitizenId] = useState<boolean>(false)

  const [isVisibleConsent, setIsVisibleConsent] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const urlParams = new URLSearchParams(window.location.search)
  const domain = urlParams.get('domain')

  const handleDepartment = (value: string) => {
    const data = getDepartment(value)
    setDepartment(data?.key.toString())
    setValidateDepartment(false)
  }

  const getDepartment = (value: string | undefined) => {
    return _.find(DEPARTMENT_LIST, (i) => i.key == value)
  }

  const Submit = async () => {
    validate()
    if (department && symptom && citizenId && citizenId.length === 4 && userId) {
      const dataDepartment = getDepartment(department?.toString())?.name.toString()
      const data: IRequestTrial = {
        lineId: userId,
        department: dataDepartment ? dataDepartment : '',
        symptom: symptom ? symptom : '',
        citizenId: citizenId ? citizenId : '',
        language: i18n.language,
      }

      const { request, success } = await createRequestTrial(data)

      if (success) {
        if (domain == 'cnmi') {
          setDeparture({ ...departure, requestId: request.id })
          navigate(BookingRoute.LandingPageCNMI)
        } else {
          setDeparture({ ...departure, requestId: request.id })
          navigate(BookingRoute.Location)
        }
      }
    }
  }

  const validate = () => {
    if (!department) setValidateDepartment(true)
    if (!symptom) setValidateSymptom(true)
    if (!citizenId || citizenId.length !== 4) setValidateCitizenId(true)
  }

  async function fetchIsAcceptConsent() {
    const result = await getAcceptConsent(userId)

    if (result) {
      if (domain == 'cnmi') {
        !result.showRequestTrial && navigate(BookingRoute.LandingPageCNMI)
        setIsVisibleConsent(!result.consent.isAccepted)
      } else {
        !result.showRequestTrial && navigate(BookingRoute.Location)
        setIsVisibleConsent(!result.consent.isAccepted)
      }
    }
    setIsLoading(false)
  }

  async function acceptConsent() {
    const result = await createAcceptConsent(userId)
    if (result) {
      setIsVisibleConsent(false)
    }
  }

  const isButtonDisable = useMemo(
    () => !(citizenId && citizenId.match(/^\d{4}$/)) || !symptom || !department,
    [citizenId, symptom, department]
  )

  useEffect(() => {
    setIsLoading(true)
    if (domain == 'cnmi') {
      navigate(BookingRoute.LandingPageCNMI)
    }
    else{
      fetchIsAcceptConsent()
    }
  }, [])

  if (domain == 'cnmi') { 
    localStorage.removeItem('selectedPerson')
    localStorage.removeItem('otherName')
    localStorage.removeItem('otherPhoneNumber')
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-full h-screen bg-white">
          <Header icon={'back'} title={t('Questions')} reload={true} />
          <div className="w-full p-4">
            <div className="text-sm text-[#E95D5D] pb-4">{t('*Not support, transportation of COVID-19 patient.')}</div>
            <div className="text-sm text-[#898A8D] pb-4">
              {t('Please answer all below question for check authorized person for this service.')}
            </div>
            <div className="pb-4">
              <label className="pb-4 text-base font-semibold">{`1. ${t('Treatment department')}`}</label>
              <select
                value={department}
                onChange={(e) => handleDepartment(e.target.value)}
                className={`w-full h-10 pb-2 font-light outline-none resize-none form-control ${
                  validateDepartment ? 'border-solid border-2 border-red-500' : ''
                }`}
              >
                {DEPARTMENT_LIST.map((val, index) => (
                  <option key={index} value={val.key}>
                    {t(`${val.name}`)}
                  </option>
                ))}
              </select>
            </div>
            <div className="pb-4">
              <label className="text-base font-semibold ">{`2. ${t('Symptoms treated')}`}</label>
              <NoteInput
                onChange={(v) => {
                  setSymptom(v)
                  setValidateSymptom(false)
                }}
                maxlength={125}
                placeholder={`${t('e.g.,')} ${t('Hyperinsulinemia')}`}
                disableShowLength
                showValidate={validateSymptom}
              />
            </div>
            <div className="pb-4">
              <label className="text-base font-semibold" htmlFor="email">
                {`3. ${t('Please fill in last 4 digits of your ID card number')}`}
              </label>
              <input
                className={`w-full p-2 font-light outline-none resize-none form-control ${
                  validateCitizenId ? 'border-solid border-2 border-red-500' : ''
                }`}
                pattern="[0-9]*"
                value={citizenId}
                inputMode="numeric"
                placeholder={`${t('e.g.,')} 4003`}
                onChange={(e) => {
                  if (!e.target.validity.valid || e.target.value.length > 4) return
                  setCitizenId(e.target.value)
                  setValidateCitizenId(false)
                }}
                type="text"
              ></input>
            </div>
          </div>

          <SingleButtonCard style="btn btn-primary" onClick={() => Submit()} disabled={isButtonDisable}>
            {t('Confirm')}
          </SingleButtonCard>
          <ModalTermCondition
            visible={isVisibleConsent}
            onCancel={() => liff.closeWindow()}
            onOk={() => acceptConsent()}
          />
        </div>
      )}
    </>
  )
}
