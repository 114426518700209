import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PAYMENT_METHOD, PAYMENT_STATUS } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { RouteType } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { formatNumber } from 'src/helpers/number'
import { padZero } from 'src/helpers/utils'
import i18n from 'src/i18n'
import { language } from 'src/i18n/langs'
import { Payment, Route, TimeUnit } from 'src/types'
import RouteDetail from './RouteDetail'

type Props = {
  onEdit?: () => void
  isEdit?: boolean
  readonly?: boolean
  dateTitle?: boolean
  date?: Date
  routeGo: Route
  routeBack?: Route
  departureTime?: TimeUnit
  arrivalTime?: TimeUnit
  departureTimeTwo?: TimeUnit
  arrivalTimeTwo?: TimeUnit
  isFree?: boolean
  isSummary?: boolean
  total?: number
  payments?: Payment[]
}

export default function RouteCard({
  onEdit,
  isSummary,
  readonly,
  isEdit,
  date,
  routeGo,
  routeBack,
  departureTime,
  arrivalTime,
  departureTimeTwo,
  arrivalTimeTwo,
  isFree,
  total,
  payments,
}: Props) {
  const { t } = useTranslation()

  arrivalTime ?? (arrivalTime = { hour: 0, minute: 0 })
  departureTime ?? (departureTime = { hour: 0, minute: 0 })
  departureTimeTwo ?? (departureTimeTwo = { hour: 0, minute: 0 })
  arrivalTimeTwo ?? (arrivalTimeTwo = { hour: 0, minute: 0 })

  const distanceGo = useMemo(() => ((routeGo?.distance ?? 0) / 1000).toFixed(2), [routeGo?.distance])
  const distanceBack = useMemo(() => ((routeBack?.distance ?? 0) / 1000).toFixed(2), [routeBack?.distance])

  const paymentGo = useMemo(() => payments?.find((p) => p.routeType === RouteType.GO), [payments])
  const paymentBack = useMemo(() => payments?.find((p) => p.routeType === RouteType.BACK), [payments])

  return (
    <div>
      {date && (
        <div className="text-2xl font-bold bg-white">
          {dayjstz(date)
            .locale(i18n.language)
            .format(`DD MMMM ${i18n.language === language.TH ? `BBBB` : `YYYY`}`)}
        </div>
      )}
      {routeBack ? (
        <div className="flex flex-col bg-white">
          <div className="flex justify-between bg-blue-100 sm:px-0">
            {readonly ? (
              <h3 className="card-title">{t('RouteCard.Route')}</h3>
            ) : (
              <h3 className="card-title">{t('Departure Route')}</h3>
            )}
            {isEdit && (
              <h3 className="underline card-title text-primary" onClick={onEdit}>
                {t('Edit')}
              </h3>
            )}
          </div>
          <div className="flex flex-col p-4">
            {readonly && <label className="py-2 text-lg font-bold text-gray-700">{t('Departure')}</label>}
            <RouteDetail route={routeGo} routeType={RouteType.GO} />
          </div>
          <div className="flex justify-between p-4 border-b">
            <label className="text-lg font-light text-gray-700">{t('Distance')}</label>
            <label className="text-lg font-bold text-right text-black">
              {distanceGo} {t('Km')}.
            </label>
          </div>
          {paymentGo && <PriceDetail payment={paymentGo} />}
          {!readonly && (
            <div className="block p-4">
              <div className="flex justify-between">
                <label className="text-lg font-light text-gray-700">{t('Estimate time arrival')}</label>
                <label className="text-lg font-bold text-right text-blue-500">
                  {`${padZero(departureTime.hour)}:${padZero(departureTime.minute)} ${t('oclock')}`}
                </label>
              </div>
              {isSummary && (
                <div className="flex justify-between">
                  <label className="text-lg font-light text-gray-700">{t('Estimate time arrival')}</label>
                  <label className="text-lg font-bold text-right text-black">
                    {`${padZero(arrivalTime.hour)}:${padZero(arrivalTime.minute)}  ${t('oclock')}`}
                  </label>
                </div>
              )}
            </div>
          )}
          {!readonly && (
            <div className="flex justify-between bg-blue-100 ">
              <h3 className="card-title">{t('Return Route')}</h3>
              {isEdit && (
                <h3 className="underline card-title text-primary" onClick={onEdit}>
                  {t('Edit')}
                </h3>
              )}
            </div>
          )}
          <div className="flex flex-col p-4">
            {readonly && <label className="text-lg font-bold text-gray-700">{t('Return')}</label>}
            <RouteDetail routeType={RouteType.BACK} route={routeBack} />
          </div>
          <div className="flex justify-between p-4 border-b">
            <label className="text-lg font-light text-gray-700">{t('Distance')}</label>
            <label className="text-lg font-bold text-right text-black">
              {distanceBack} {t('Km')}.
            </label>
          </div>
          {paymentBack && <PriceDetail payment={paymentBack} />}
          {!readonly && (
            <>
              <div className="block p-4">
                <div className="flex justify-between">
                  <label className="text-lg font-light text-gray-700">{t('Estimate time arrival')}</label>
                  <label className="text-lg font-bold text-right text-blue-500">
                    {`${padZero(departureTimeTwo.hour)}:${padZero(departureTimeTwo.minute)} ${t('oclock')}`}
                  </label>
                </div>
                {isSummary && (
                  <div className="flex justify-between">
                    <label className="text-lg font-light text-gray-700">{t('Estimate time arrival')}</label>
                    <label className="text-lg font-bold text-right text-black">
                      {`${padZero(arrivalTimeTwo.hour)}:${padZero(arrivalTimeTwo.minute)} ${t('oclock')}`}
                    </label>
                  </div>
                )}
              </div>
              <div className="flex justify-between p-4 border-t">
                <div className="flex flex-col py-1">
                  <label className="text-lg font-light text-gray-700">{t('Total')}</label>
                </div>
                <div className="flex items-end">
                  <h1 className="text-lg font-bold text-right text-blue-500">
                    <div className="flex py-1 space-x-1">
                      {isFree || paymentGo?.amount === 0 || total === 0 ? (
                        <div className="text-2xl text-blue-500">
                          <div className="flex space-x-1">
                            <div className="pl-1">{t('Free')}</div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {paymentGo && paymentBack
                            ? formatNumber((paymentGo?.amount ?? 0) + (paymentBack?.amount ?? 0))
                            : formatNumber(total ?? 0)}{' '}
                          {t('THB')}
                        </div>
                      )}
                    </div>
                  </h1>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col bg-white">
          <div className="flex justify-between bg-blue-100">
            <h3 className="card-title">{t('RouteCard.Route')}</h3>
            {isEdit && (
              <h3 className="underline card-title text-primary" onClick={onEdit}>
                {t('Edit')}
              </h3>
            )}
          </div>
          <div className="flex flex-col p-4">
            <RouteDetail route={routeGo} routeType={RouteType.GO} />
          </div>
          <div className="flex justify-between p-4 border-b">
            <label className="text-lg font-light text-gray-700">{t('Distance')}</label>
            <label className="text-lg font-bold text-right text-black">
              {distanceGo} {t('Km')}.
            </label>
          </div>
          {paymentGo && <PriceDetail payment={paymentGo} />}
          {!readonly && (
            <>
              <div className="block p-4">
                {isSummary && (
                  <div className="flex justify-between">
                    <label className="text-lg font-light text-gray-700">{t('Estimate time arrival')}</label>
                    <label className="text-lg font-bold text-right text-blue-500">
                      {`${padZero(departureTime.hour)}:${padZero(departureTime.minute)} ${t('oclock')}`}
                    </label>
                  </div>
                )}
                <div className="flex justify-between">
                  <label className="text-lg font-light text-gray-700">{t('Estimate time arrival')}</label>
                  <label className="text-lg font-bold text-right text-black">
                    {`${padZero(arrivalTime.hour)}:${padZero(arrivalTime.minute)} ${t('oclock')}`}
                  </label>
                </div>
              </div>
              <div className="flex justify-between p-4 border-t">
                <div className="flex flex-col py-1">
                  <label className="text-lg font-light text-gray-700">{t('Total')}</label>
                </div>
                <div className="flex items-end">
                  <h1 className="text-lg font-bold text-right text-blue-500">
                    <div className="flex py-1 space-x-1">
                      {isFree || paymentGo?.amount === 0 || total === 0 ? (
                        <div className="text-2xl text-blue-500">
                          <div className="flex space-x-1">
                            <div className="pl-1">{t('Free')}</div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {paymentGo ? formatNumber(paymentGo?.amount ?? 0) : formatNumber(total ?? 0)} {t('THB')}
                        </div>
                      )}
                    </div>
                  </h1>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

const PriceDetail = ({ payment }: { payment: Payment }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <div className="p-4 bg-white border-b font-base">
        <div className="flex justify-between pb-1">
          <div>{t('Amount (included VAT)')}</div>
          <div className="font-semibold"> {`${payment.amount} ${t('THB')}`}</div>
        </div>
        {(payment.method === PAYMENT_METHOD.QR_CODE || payment.method === PAYMENT_METHOD.CASH) && (
          <div className="flex justify-between pb-1">
            <div>{t('Payment method')}</div>
            <div className="font-semibold">
              {' '}
              {/* {payment.method === PAYMENT_METHOD.CASH
                ? t('Cash')
                : payment.method === PAYMENT_METHOD.QR_CODE
                ? t('QR Code')
                : '-'} */}
              {t('Transfer')}
            </div>
          </div>
        )}
        <div className="flex justify-between pb-1">
          <div>{t('Payment status')}</div>
          <div className="font-semibold">
            {payment.paidStatus === PAYMENT_STATUS.NOT_PAID ? (
              <div className="text-[#8A8D92]">{t('Do not pay')}</div>
            ) : payment.paidAt ? (
              <div className="text-[#13B605]">{t('Confirm Paid')}</div>
            ) : payment.driverReceivedAt ? (
              <div className="text-[#0181EF]">{t('Paid')}</div>
            ) : payment.paidStatus === PAYMENT_STATUS.UNPAID ? (
              <div className="text-[#E05A5A]">{t('Unpaid')}</div>
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>
      {payment.id && (
        <div className="p-4 bg-white border-b font-base">
          <div
            className={`flex justify-center pb-1 underline ${
              payment.paidStatus === PAYMENT_STATUS.UNPAID ? 'text-[#0181EF]' : 'text-[#8A8D92]'
            }`}
            onClick={() => {
              if (payment.paidStatus === PAYMENT_STATUS.UNPAID) {
                navigate(`${BookingRoute.Payment}/?bn=${payment.bookingNumber}&rt=${payment.routeType}`)
              }
            }}
          >
            <div>{t('Change payment method')}</div>
          </div>
        </div>
      )}
    </>
  )
}
