import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Header from 'src/components/Header'
import { PLACE_TYPE } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { Icons } from 'src/enums'
import { useGoogleMaps } from 'src/hooks/useGoogleMaps'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { useLocationSearch } from 'src/hooks/useLocationSearch'
import AddressCard from 'src/pageviews/Booking/AddressCard'
import LocationInput from 'src/pageviews/Booking/LocationInput'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import { bookingLocationSearchState, bookingLocationState } from 'src/states'

export default function LocationSearch() {
  const { t } = useTranslation()
  const location = useRecoilValue(bookingLocationState)
  const setSearchResult = useSetRecoilState(bookingLocationSearchState)
  const { routeGo, routeBack, routeType } = location
  const icon = routeType.placeType
  const navigate = useNavigate()
  const [, setSelectedProvince] = useLocalStorage('selected_province')


  const { getGeoLocation } = useGoogleMaps()

  const [input, setInput] = useState('')
  const showicon =
    icon === PLACE_TYPE.ORIGIN1
      ? Icons.OriginRouteGo
      : icon === PLACE_TYPE.ORIGIN2
      ? Icons.OriginRouteBack
      : icon === PLACE_TYPE.DESTINATION1
      ? Icons.DestinationRouteGo
      : Icons.DestinationRouteBack

  const value =
    icon === PLACE_TYPE.ORIGIN1
      ? routeGo.origin.name
      : icon === PLACE_TYPE.DESTINATION1
      ? routeGo.destination.name
      : icon === PLACE_TYPE.ORIGIN2
      ? routeBack.origin.name
      : routeBack.destination.name

  const results = useLocationSearch(input)
  const [open, setOpen] = useState(false)
  const [message, ] = useState('')

  function handleSearchLocation(e: React.ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header icon="back" title={t('Route')} onBack={() => navigate(BookingRoute.Map)} reload={true} />
      <div className="px-3">
        <LocationInput
          placeholder={value}
          onClick={() => navigate(BookingRoute.Map)}
          onChange={handleSearchLocation}
          icon={showicon}
        />
      </div>
      <ModalCard
        title={t('Warning')}
        detail={t(message)}
        status={open}
        singleButton={{ name: t('OK'), style: 'btn-primary', type: 'cancel' }}
        onClick={(s) => setOpen(s)}
      />
      {results.length > 0 && (
        <>
          <p className="px-3 py-4 text-lg font-semibold text-gray-800 text-opacity-60">{t('List')}</p>
          <div id="results" className="block px-3 space-y-4">
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {results.map((r: any) => {
              const title = r['structured_formatting']['main_text']
              const description = r['structured_formatting']['secondary_text']
              const id = r.place_id
              const name = title
              return (
                <AddressCard
                  key={r.place_id}
                  title={title}
                  address={description}
                  onClick={async () => {
                    const result = await getGeoLocation({ placeId: id })
                    const { address_components, formatted_address, geometry } = result
                    const lat = geometry.location.lat().toString()
                    const lng = geometry.location.lng().toString()
                    let postcode = ''
                    
                    //----------------------------------------------Dev province service api
                    for (let i = 0; i <= address_components.length - 1; i++) {
                      if (address_components[i].types.includes('administrative_area_level_1') && address_components[i].types.includes('political')) {
                        setSelectedProvince(address_components[i].long_name)
                      }

                      if (address_components[i].types.includes('postal_code')) {
                        postcode = address_components[address_components.length - 1].long_name
                      }
                    }
                    setSearchResult({ id, name, address: formatted_address,post_code:postcode, lat, lng })
                    navigate(BookingRoute.Map)
                  }}
                ></AddressCard>
              )
            })}
          </div>
        </>
      )}
    </>
  )
}
