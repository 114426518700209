import liff from '@line/liff/dist/lib'
import { isNull } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import Button from 'src/components/Button/Base'
import { useCallApi } from 'src/hooks/useCallApi'
import i18n from 'src/i18n'
import Loading from 'src/pageviews/Booking/Loading'
import Page404 from 'src/pageviews/Booking/Page404'
import { lineState } from 'src/states'
import { InviteState } from 'src/types'

export default function Invite() {
  const { t } = useTranslation()
  const [state, setState] = useState<InviteState>(InviteState.LOADING)
  const line = useRecoilValue(lineState)
  const { getPublicBooking, updatePassengerLineId } = useCallApi()
  const navigate = useNavigate()
  // const domain_state = useRecoilValue(domainState)

  async function fetchBooking() {
    const queryParams = new URLSearchParams(window.location.search)
    const b = queryParams.get('bn')
    const domain = queryParams.get('domain')
    if (b) {
      if (!b || !domain) return
        const data = await getPublicBooking(b,domain)

      if (!data?.booking) {
        setState(InviteState.NOT_FOUND)
        return
      }

      const { booking } = data
      if ((!booking?.passengerLineId || isNull(booking?.passengerLineId)) && line.userId) {
        try {
          booking.bookerPhone !== booking.passengerPhone
            ? (await updatePassengerLineId(booking?.bookingNumber, i18n.language, line.userId),
              setState(InviteState.SUCCESS))
            : setState(InviteState.USED)
        } catch (e) {
          setState(InviteState.USED)
        }
      } else {
        setState(InviteState.USED)
      }
    } else {
      setState(InviteState.NOT_FOUND)
    }
  }

  useEffect(() => {
    ;(async () => {
      setState(InviteState.LOADING)
      if (line.userId) await fetchBooking()
    })()
  }, [line.userId])

  if (state === InviteState.LOADING) return <Loading />

  if (state === InviteState.NOT_FOUND)
    return <Page404 description={t('Traffic jam.')} textButton={t('Try again')} onClick={() => navigate(0)} />

  if (state === InviteState.USED)
    return <Page404 description={t('Access denied.')} textButton={t('Close')} onClick={() => liff.closeWindow()} />

  return (
    <>
      <div className="flex flex-col items-center w-full h-screen text-white bg-green-600">
        <div className="h-40"></div>
        <span className="text-9xl material-icons-outlined">check_circle</span>
        <strong className="py-5">{t('Sign up to receive notifications successfully')}</strong>
        <p className="py-3 text-center">
          {t('Thank you for registering to be notified')}
          <br />
          {t('you will be notified when your departure time is near')}
        </p>
      </div>
      <div className="fixed bottom-0 w-full p-5 mb-5 space-y-3">
        <Button type={'btn btn-light block'} onClick={() => liff.closeWindow()}>
          {t('Close')}
        </Button>
      </div>
    </>
  )
}
