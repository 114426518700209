import React, { ReactNode } from 'react'

interface BookingCardDisplayBlockProps {
  description: string
  imageUrl?: string
  icon?: ReactNode
  children?: ReactNode
}

const BookingCardDisplayBlock: React.FC<BookingCardDisplayBlockProps & { className?: string }> = ({description, className, icon, imageUrl, children }) => {
  return (
    <div className={`flex flex-col w-full p-4 bg-blue-50 shadow-md rounded-md ${className}`}>
      {/* Image -> Display image if imageUrl is provided */}
      {imageUrl && <img src={imageUrl} alt="Display" className="w-16 h-16 mr-4" />}
      {/* Icon */}
      {icon && <div className="mr-4">{icon}</div>}
      {/* Description */}
      {/* Add mb(margin bottom) to give some spacing between the description and children */}
      <div className="mb-4 text-xs font-normal">{description}</div>
      {/* Children */}
      {children}
    </div>
  )
}

export default BookingCardDisplayBlock
