import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Header from 'src/components/Header'
import { BookingRoute } from 'src/consts/route'
import { RouteType } from 'src/enums'
import { resolveAddressName } from 'src/helpers/utils'
import { checkPostalCode, getCurrentLocation, useGoogleMaps } from 'src/hooks/useGoogleMaps'
import DoubleButtonCard from 'src/pageviews/Booking/DoubleButtonCard'
import Map from 'src/pageviews/Booking/Map'
import RouteDetailCard from 'src/pageviews/Booking/RouteDetail'
import { bookingLocationState } from 'src/states'
import { RouteDetail } from 'src/types'

export default function Summary() {
  const [location, setLocation] = useRecoilState(bookingLocationState)
  const { routeGo, routeBack, routeType } = location
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { getGeoLocation } = useGoogleMaps()

  function handleSetDistanceDuration(go: RouteDetail, back: RouteDetail) {
    setLocation({
      ...location,
      routeGo: {
        ...routeGo,
        distance: go.distance,
        duration: go.duration,
      },
      routeBack: {
        ...routeBack,
        distance: back.distance,
        duration: back.duration,
      },
    })
  }

  async function setCurrentLocation() {
    const current = await getCurrentLocation()
    const result = await getGeoLocation({ location: current })
    const { address_components, place_id, formatted_address: _formatted_address } = result
    const { name, formatted_address } = resolveAddressName(address_components, _formatted_address)
    const origin = {
      id: place_id,
      name,
      address: formatted_address,
      lat: current.lat.toString(),
      lng: current.lng.toString(),
    }
    const addrlength = address_components.length - 1
    if (!checkPostalCode(address_components[addrlength].long_name ?? '')) {
      return
    }

    setLocation({
      ...location,
      routeGo: { ...routeGo, origin: origin },
      routeBack: { ...routeBack, destination: origin },
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!routeGo.origin.id) {
      ;(async () => {
        await setCurrentLocation()
      })()
    }
  }, [])

  const tripTrophic = 'w-3/12 py-1 text-lg font-medium text-white rounded-r-xl bg-zinc-800 flex place-content-center'

  return (
    <div className="flex flex-col justify-between w-screen h-screen">
      <div className="flex flex-col">
        <Header icon={'null'} title={t('Route detail')} />
        <Map key={routeGo.origin.id} height={'35vh'} setDistanceDuration={handleSetDistanceDuration} />

        <div className="z-50 px-3 py-4 -mt-5 text-blue-500 bg-white rounded-t-2xl">
          {t(`${routeType.isOneWay ? 'One way' : 'Round-trip'} route detail`)}
        </div>
        {!routeType.isOneWay && <div className={tripTrophic}>{t('Departure')}</div>}
        <div className="px-3 pt-2 pb-3">
          <RouteDetailCard route={routeGo} routeType={RouteType.GO} />
        </div>
        {!routeType.isOneWay && (
          <>
            <div className={tripTrophic}>{t('Return')}</div>
            <div className="px-3 mt-2">
              <RouteDetailCard route={routeBack} routeType={RouteType.BACK} />
            </div>
          </>
        )}
      </div>
      <div>
        {routeGo.destination.id && (routeType.isOneWay || routeBack?.destination.id) && (
          <DoubleButtonCard
            onCancel={() => navigate(BookingRoute.Location)}
            onConfirm={async () => {
              navigate(BookingRoute.Departure)
            }}
            textCancel={t('Edit')}
          />
        )}
      </div>
    </div>
  )
}
