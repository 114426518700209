import liff from '@line/liff/dist/lib'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/Button/Base'
export default function Success() {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-col items-center w-full h-screen text-white bg-blue-500">
        <div className="h-40"></div>
        <span className="text-9xl material-icons-outlined">check_circle_outline</span>
        <strong className="py-5">{t('Thank you')}</strong>
        <p className="py-3 text-center w-60">
          {t('We are appreciate to received your feedback. We will continuous improvement our service.')}
        </p>
      </div>
      <div className="fixed bottom-0 w-full p-5 mb-5 space-y-3">
        <Button type={'btn btn-light block'} onClick={() => liff.closeWindow()}>
          {t('Back to chat')}
        </Button>
      </div>
    </>
  )
}
