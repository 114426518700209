import DayJsUtils from '@date-io/dayjs'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { dayjstz } from 'src/helpers/datetime'
import i18n from 'src/i18n'
import { language } from 'src/i18n/langs'

type Props = {
  date: Date
  canSelectToday: boolean
  canSelectTomorrow: boolean
  isAlert: boolean
  onChangeDate: (date: Date) => void
}

export default function SelectDate({
  date: _date,
  isAlert,
  canSelectToday,
  canSelectTomorrow,
  onChangeDate,
}: Props) {

  return (
    <>
      <div
        className={`flex w-full px-3 py-2 text-left bg-white rounded-lg shadow-md cursor-default
        place-content-center mx-10py-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75
        focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2
        focus-visible:border-indigo-500 sm:text-sm ${isAlert ? 'border-solid border-2 border-red-500' : ''}`}
      >
        <MuiPickersUtilsProvider utils={DayJsUtils} locale={i18n.language}>
          <DatePicker
            disableToolbar
            minDate={dayjstz().add(canSelectToday ? 0 : canSelectTomorrow ? 1 : 2, 'day')}
            className="w-full"
            InputProps={{ disableUnderline: true }}
            value={_date}
            format={`DD MMMM ${i18n.language === language.TH ? `BBBB` : `YYYY`}`}
            onChange={(d) => onChangeDate(dayjstz(d).toDate())}
          />
        </MuiPickersUtilsProvider>
        <span className="text-xl material-icons-outlined">insert_invitation</span>
      </div>
    </>
  )
}
