import { useState } from 'react'

type Props = {
  onChange: (v: string) => void
  value?: string
  placeholder?: string
  maxlength: number
  disableShowLength?: boolean
  showValidate?: boolean
  high?: number
}

export default function NoteInput({
  value,
  onChange,
  placeholder,
  maxlength,
  disableShowLength,
  showValidate,
  high,
}: Props) {
  const [length, setLength] = useState(0)
  const row = value ? Math.floor(value?.length / 65) + 1 : high || 3

  const handleValue = (value: string) => {
    const _value = value.length > maxlength ? value.substring(0, maxlength) : value
    onChange(_value)
    setLength(_value.length)
  }

  return (
    <>
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleValue(e.target.value)}
        rows={row}
        maxLength={maxlength}
        className={`w-full p-2 font-light outline-none resize-none form-control ${
          showValidate ? 'border-solid border-2 border-red-500' : ''
        }`}
      />

      {!disableShowLength && (
        <div className="flex justify-end w-full text-xs text-[#8C8C8C]">{`${length}/${maxlength}`}</div>
      )}
    </>
  )
}
