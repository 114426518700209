import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
//import { ERelation } from 'src/consts'
import { Person } from 'src/types'
import ModalCard from './ModalCard'
import { useCallApi } from 'src/hooks/useCallApi'
import { formatPhoneNumber } from 'src/helpers/utils'
import { BookingVehicle } from 'src/types'
import shareTargetPicker from 'src/hooks/useTargetPicker'

type Props = {
  booker: Person
  passenger: Person
  emergencyContact: Person
  note: string
  isEdit?: boolean
  onEdit?: () => void
  booking?: BookingVehicle
}

//export default function ShowInfoCNMI({ booker, passenger, emergencyContact, note, onEdit, isEdit, booking }: Props) {
  export default function ShowInfoCNMI({ booker, passenger, note, booking }: Props) {
  const { t } = useTranslation()
  const [openShareTargetPicker, setOpenShareTargetPicker] = useState(false)
  const [domain, setDomain] = useState('')
  const [passengerLanguage, setPassengerLanguage] = useState('en')
  const { updateCustomer, getDomain, getCustomer } = useCallApi()

  useEffect(() => {
    ;(async () => {
      setDomain(await getDomain())
    })()
  }, [domain])

  // function isOtherRelation(relation: string) {
  //   for (const r of Object.values(ERelation).filter((r) => typeof r === 'number')) {
  //     if (r.toString() === relation) return false
  //   }
  //   return true
  // }

  return (
    <>
      <div className="card">
        <div className="flex justify-between border-b border-gray-200 card-title sm:px-0">
          <h3>{t('Customer Information')}</h3>
          {/*isEdit && (
            <h3 className="underline text-primary" onClick={onEdit}>
              {t('Edit')}
            </h3>
          )*/}
        </div>
        <div className="py-3 bg-white border-b border-gray-200 ">
          <div className="block px-4 space-y-2 font-semibold bg-white">
            <div className="">{t('Booker')}</div>
            <div className="flex justify-between">
              <div className="opacity-60">{t('Name - Surname')}</div>
              <div>{booker.name}</div>
            </div>
            <div className="flex justify-between">
              <div className="opacity-60">{t('Mobile Phone')}</div>
              <div>{booker.phone}</div>
            </div>
          </div>
        </div>
        <div className="py-3 border-b border-gray-200">
          <div className="block px-4 space-y-2 font-semibold">
            <div className="flex justify-between">
              <div className="">{t('Passenger')}</div>
              {booker.phone == passenger.phone ? (
                ''
              ) : (
                <h2
                  className="text-xs text-blue-500 underline"
                  onClick={async () => {
                    getCustomer({ phone: formatPhoneNumber(booking?.passengerPhone || '') }).then((val) => {
                      if (val == null) return

                      if (!val.lineId) {
                        setOpenShareTargetPicker(true)
                      } else {
                        setOpenShareTargetPicker(false)
                      }
                    })
                  }}
                >
                  {/* 
                  {booking != undefined && (
                    <>
                      {passenger.lineId === undefined || passenger.lineId === '' || passenger.lineId === null ? (
                        <button className="shadow-md pl-4 pr-3.5 py-1 bg-blue-600 rounded-full">
                          <img src={`${process.env.REACT_APP_AWS_S3_PUBLIC_URL}/liff/mail-account1.png`} />
                        </button>
                      ) : (
                        <button className="shadow-md pl-4 pr-3.5 py-1 bg-slate-600 rounded-full">
                          <img src={`${process.env.REACT_APP_AWS_S3_PUBLIC_URL}/liff/mail-account2.png`} />
                        </button>
                      )}
                    </>
                  )}
                  */}
                </h2>
              )}
            </div>
            <div className="flex justify-between">
              <div className="opacity-60">{t('Name - Surname')}</div>
              <div>{passenger.name}</div>
            </div>
            <div className="flex justify-between">
              <div className="opacity-60">{t('Mobile Phone')}</div>
              <div>{passenger.phone}</div>
            </div>
          </div>
        </div>
         {/*<div className="py-3 border-b border-gray-200">
          <div className="block px-4 space-y-2 font-semibold">
            <div className="">{t('Emergency contact')}</div>
            <div className="flex justify-between">
              <div className="opacity-60">{t('Name - Surname')}</div>
              <div>{emergencyContact.name}</div>
            </div>
            <div className="flex justify-between">
              <div className="opacity-60">{t('Mobile Phone')}</div>
              <div>{emergencyContact.phone}</div>
            </div>
          </div>
        </div>
       <div className="flex justify-between px-4 py-3 font-semibold">
          <div>{t('Relationship')}</div>
          <div>
            {isOtherRelation(emergencyContact.relation ? emergencyContact.relation : '8')
              ? emergencyContact.relation
              : t(`Relation.${ERelation[parseInt(emergencyContact.relation || '8')]}`)}
          </div>
        </div>*/}
        {note && (
          <div className="py-3 font-semibold border-t border-gray-200">
            <div className="block px-4 bg-white">
              <div>{t('Note (optional)')}</div>
              <div>{note}</div>
            </div>
          </div>
        )}
      </div>
      <ModalCard
        selectLanguage={true}
        loading={false}
        title={t('Send notification to passenger')}
        detail={t('Please select a passenger contact for notification.')}
        status={openShareTargetPicker}
        singleButton={{ name: t('Select passenger'), type: 'cancel', style: 'btn-primary' }}
        onClick={async () => {
          await updateCustomer({
            phone: formatPhoneNumber(passenger.phone),
            language: passengerLanguage,
          })
          if (booking) {
            await shareTargetPicker(booking.bookingNumber, booking.type, booking.bookerName, passengerLanguage, domain)
          }
          setOpenShareTargetPicker(false)
        }}
        languageChange={(val) => {
          setPassengerLanguage(val)
        }}
      />
    </>
  )
}
