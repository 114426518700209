export enum ErrorCode {
  CAR_UNAVAILABLE = 'CAR_UNAVAILABLE',
}

export enum Status {
  Wait = 'wait',
  InProgress = 'in_progress',
}

export enum BookingStatus {
  NEW = 1,
  CANCELED = 2,
  IN_PROGRESS = 3,
  COMPLETED = 4,
}

export enum BookingStatusCNMI {
  WAIT = 1,
  CANCELED = 2,
  IN_PROGRESS = 3,
  COMPLETED = 4,
  WAIT_CONFIRM_CAR = 5,
  WAIT_PAYMENT = 6,
}

export enum BookingStatusNewCNMI {
  PENDING = 0,
  START = 1,
  PICK_UP_POINT = 2,
  CONFIRM_PICK_UP = 3,
  CONFIRM_DRIVER = 4,
  DEPART = 5,
  ARRIVE = 6,
  FINISH = 7,
  CANCEL = 8,
  ABSENT = 9,
}

export enum BookingType {
  NORMAL = 1,
  REAL_TIME = 2,
}

export enum BaggageItems {
  Wheelchair = 1,
  Walker = 2,
  BabyBasket = 3,
  Luggage = 4,
}

export enum VehicleState {
  PENDING = 0,
  START = 1,
  PICK_UP_POINT = 2,
  CONFIRM_PICK_UP = 3,
  CONFIRM_DRIVER = 4,
  DEPART = 5,
  ARRIVE = 6,
  FINISH = 7,
  PICK_UP_POINT_BACK = 8,
  DEPART_BACK = 9,
  ARRIVE_BACK = 10,
  FINISH_BACK = 11,
}

export enum Icons {
  OriginRouteGo = 1,
  OriginRouteBack = 2,
  DestinationRouteGo = 3,
  DestinationRouteBack = 4,
}

export enum RouteType {
  GO = 1,
  BACK = 2,
}

export enum TripType {
  ONE_WAY = 1,
  ROUND_TRIP = 2,
  GO_TRIP = 3,
  BACK_TRIP = 4,
}

export enum InputType {
  ORIGIN = 1,
  DESTINATION = 2,
}

export enum Vote {
  ADJUST = 1,
  BAD = 2,
  GOOD = 3,
  VERY_GOOD = 4,
}

export enum QuestionsAbout {
  BOOKING = 0,
  CAR = 1,
  DRIVER = 2,
}

export enum HttpStatusCode {
  OK = 200,
}

export enum QuestionType {
  Vote = 0,
  Choice = 1,
  ChoiceImg = 2,
}

export enum UserCNMIFetchingStateValue {
  NOT_FETCH = 'NOT_FETCH',
  FETCHING = 'FETCHING',
  NOT_FOUND = 'NOT_FOUND',
  FOUNDED = 'FOUNDED',
}

export enum Languages {
  EN = 'en',
  TH = 'th',
}

export enum BeforeMapPage {
  UserProfileCNMI = 0,
  AddressAndPriceCNMI = 1,
}
