import liff from '@line/liff'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import Header from 'src/components/Header'
import { PAYMENT_METHOD, PAYMENT_STATUS } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BookingType, ErrorCode, RouteType } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { formatNumber } from 'src/helpers/number'
import { formatPhoneNumber } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import shareTargetPicker from 'src/hooks/useTargetPicker'
import i18n from 'src/i18n'
import Loading from 'src/pageviews/Booking/Loading'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import Page404 from 'src/pageviews/Booking/Page404'
import { bookingDepartureState, tempBooking, totalPriceState } from 'src/states'
import { BookingVehicle, Payment } from 'src/types'

export default function PaymentCard() {
  const [isLoading, setIsLoading] = useState(true)
  const [booking, setBooking] = useState<BookingVehicle | null>()
  const [bookingNumber, setBookingNumber] = useState('')
  const [routeType, setRouteType] = useState<RouteType>(RouteType.GO)
  const [passengerLanguage, setPassengerLanguage] = useState('en')
  const [showSelectLanguage, setShowSelectLanguage] = useState(false)
  const [openShareTargetPicker, setOpenShareTargetPicker] = useState(false)
  const [loadingSelectLanguage, setLoadingSelectLanguage] = useState(false)
  const [totalPrice, setTotalPrice] = useState<{ amount: number; status: PAYMENT_STATUS }>()
  const [openBooking, setOpenBooking] = useState(false)
  const [isCarUnavailable, setIsCarUnavailable] = useState(false)
  const [paymentRouteGo, setPaymentRouteGo] = useState<Payment>()
  const [paymentRouteBack, setPaymentRouteBack] = useState<Payment>()
  const [domain, setDomain] = useState('')
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState('')
  const [transferEN, setTransferEN] = useState([''])
  const [transferTH, setTransferTH] = useState([''])

  const [bookingTemp, setBookingTemp] = useRecoilState(tempBooking)
  const { date, requestId } = useRecoilValue(bookingDepartureState)
  const cnmiTotalPrice = useRecoilValue(totalPriceState)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    getBooking,
    getCustomer,
    createBooking,
    createRealtimeBooking,
    updateRequestById,
    updateCustomer,
    updatePayment,
    getDomain,
    getPaymentMethod,
  } = useCallApi()

  const isTodayBooking = useMemo(() => dayjstz(date).hour(0).minute(0).isToday(), [date])

  useEffect(() => {
    getPaymentMethod().then((data) => {
      const qrCodeImageUrl = data.qrCodeImageUrl
      const transferEN = [data.transferEN_account, data.transferEN_bank, data.transferEN_id]
      const transferTH = [data.transferTH_account, data.transferTH_bank, data.transferTH_id]
      setQrCodeImageUrl(qrCodeImageUrl)
      setTransferEN(transferEN)
      setTransferTH(transferTH)
    })
  }, [])

  useEffect(() => {
    ; (async () => {
      setDomain(await getDomain())
    })()
  }, [domain])

  useEffect(() => {
    setIsLoading(true)
      ; (async () => {
        try {
          const queryParams = new URLSearchParams(window.location.search)
          const bn = queryParams.get('bn')
          const rt = queryParams.get('rt') ?? RouteType.GO
          setRouteType(Number(rt))
          if (!bn && bookingTemp) {
            setBooking(bookingTemp)
          } else {
            const data = bn ? await getBooking(bn) : null
            setBooking(data)
          }
        } catch (e) {
          setBooking(null)
        }
        setIsLoading(false)
      })()
  }, [window.location.search])

  useEffect(() => {
    setBooking(bookingTemp)
  }, [bookingTemp])

  useEffect(() => {
    if (!booking) return
    const _paymentRouteGo = _.find(booking.payments, { routeType: RouteType.GO })
    const _paymentRouteBack = _.find(booking.payments, { routeType: RouteType.BACK })
    setPaymentRouteGo(
      _paymentRouteGo
        ? _paymentRouteGo.method
          ? _paymentRouteGo
          : { ..._paymentRouteGo, method: PAYMENT_METHOD.QR_CODE, amount: booking.price }
        : undefined
    )
    setPaymentRouteBack(
      _paymentRouteBack
        ? _paymentRouteBack.method
          ? _paymentRouteBack
          : { ..._paymentRouteBack, method: PAYMENT_METHOD.QR_CODE, amount: booking.price }
        : undefined
    )
    !routeType && !booking.bookingNumber && _paymentRouteGo
      ? setTotalPrice({ amount: booking.price, status: _paymentRouteGo?.paidStatus })
      : !routeType && _paymentRouteGo
        ? setTotalPrice({ amount: booking.price, status: _paymentRouteGo.paidStatus })
        : routeType === RouteType.GO && _paymentRouteGo
          ? setTotalPrice({
            amount: booking.price,
            status: _paymentRouteGo.paidStatus,
          })
          : routeType === RouteType.BACK && _paymentRouteBack
            ? setTotalPrice({
              amount: booking.price,
              status: _paymentRouteBack.paidStatus,
            })
            : null
  }, [booking])

  useEffect(() => {
    setBooking((b) => (!b ? null : { ...b, type: isTodayBooking ? BookingType.REAL_TIME : BookingType.NORMAL }))
  }, [isTodayBooking])

  useEffect(() => {
    if (!bookingTemp) return
    setBooking({ ...bookingTemp, language: i18n.language })
  }, [bookingTemp, i18n.language])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const redirect = () => {
    const isTodayBooking = dayjstz(date).hour(0).minute(0).isToday()
    isTodayBooking ? navigate(`${BookingRoute.Success}/?RealtimeBooking=true`) : navigate(BookingRoute.Success)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submitPaymentMethod = async () => {
    try {
      if (!booking) return

      setIsLoading(true)
      if (!booking.bookingNumber) {
        const { booking: createdBooking, code } = isTodayBooking
          ? await createRealtimeBooking({
            ...booking,
            paymentMethod: paymentRouteGo?.method ? paymentRouteGo?.method : PAYMENT_METHOD.QR_CODE,
          })
          : await createBooking({
            ...booking,
            paymentMethod: paymentRouteGo?.method ? paymentRouteGo?.method : PAYMENT_METHOD.QR_CODE,
          })
        if (!createdBooking) {
          if (code === ErrorCode.CAR_UNAVAILABLE) {
            setIsCarUnavailable(true)
            setOpenBooking(true)
          }
          return
        }

        if (requestId) {
          await updateRequestById(requestId, createdBooking.bookingNumber)
        }

        const passengerCustomer = await getCustomer({ phone: formatPhoneNumber(booking.passengerPhone) })

        if (createdBooking.bookingNumber) {
          setBookingNumber(createdBooking.bookingNumber)
          if (createdBooking.bookerPhone !== createdBooking.passengerPhone && passengerCustomer) {
            if (!passengerCustomer.lineId) {
              if (passengerCustomer.language) {
                setPassengerLanguage(passengerCustomer.language)
                setShowSelectLanguage(false)
              } else {
                setShowSelectLanguage(true)
              }
              setOpenShareTargetPicker(true)
            } else {
              redirect()
            }
          } else {
            redirect()
          }
        } else {
          setOpenBooking(true)
        }
      } else {
        if (totalPrice?.status !== PAYMENT_STATUS.NOT_PAID) {
          for (const payment of booking.payments) {
            if (payment?.id && payment?.bookingNumber && payment?.routeType && payment?.method) {
              if (routeType === payment.routeType) {
                const query = { bookingNumber: payment.bookingNumber, method: payment.method.toString() }
                await updatePayment(payment.id, query)
              }
            }
          }
        }
        navigate(-1)
      }
    } catch (e) {
      setOpenBooking(true)
    } finally {
      setIsLoading(false)
    }
  }

  const changePaymentMethod = (method: PAYMENT_METHOD) => {
    if (!booking) return
    if (!routeType) {
      const data = booking.payments.map((p) => ({ ...p, method }))
      setBookingTemp({ ...booking, payments: data })
    } else {
      const data = booking.payments.map((p) => (routeType === p.routeType ? { ...p, method } : p))
      setBookingTemp({ ...booking, payments: data })
    }
  }

  return isLoading ? (
    <Loading />
  ) : !booking ? (
    <Page404
      description={t('The system is not working properly, please try again.')}
      textButton={t('Try again')}
      onClick={() => navigate('/')}
    />
  ) : (
    <>
      <Header onBack={() => navigate(-1)} icon={'back'} title={t('Payment')} reload />
      <div className="pt-2 bg-gray-200" />
      <div className="flex flex-col justify-between w-full h-[800px]  bg-gray-100">
        <div>
          <div className="p-4 bg-white border-2">
            <div className="pb-2 text-lg font-bold">{t('Payment')}</div>
            <div className="text-base text-[#DC0707] rows-3">
              {/* <div className="mb-2" >
                {realtimeState
                ?t(`Please make the payment before your journey.`)
                :t(`Please make the payment one day before your journey.`)} 
              </div> */}
              {/* <div >{t(`You can find instructions on how to make the payment in the booking history menu.`)}</div> */}
              {/* <div className="mb-2">{t(`Once the payment is completed, please send a photo of the payment slip via Line chat.`)}</div> */}
              <div>{t(`Please make the payment* and`)}</div>
              <div>{t(`send slip photo via Line chat before your journey`)}</div>
              <div className="mt-3 text-sm text-[#000000]">{t(`* Please see instruction in "History" menu`)}</div>
            </div>
          </div>
          <div className="p-4 bg-white">
            <div className="pb-2 text-base font-bold">{t('Choose payment method')}</div>
            <div className="text-sm">
              <div
                className="flex items-center justify-start px-1 pb-2"
                onClick={() =>
                  totalPrice?.status !== PAYMENT_STATUS.NOT_PAID && changePaymentMethod(PAYMENT_METHOD.CASH)
                }
              >
                <input
                  className="w-6 h-6 mt-1 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  type="radio"
                  readOnly
                  disabled={totalPrice?.status === PAYMENT_STATUS.NOT_PAID}
                  checked={
                    routeType
                      ? routeType === RouteType.GO && paymentRouteGo?.method === PAYMENT_METHOD.CASH
                        ? true
                        : routeType === RouteType.BACK && paymentRouteBack?.method === PAYMENT_METHOD.CASH
                      : paymentRouteGo?.method === PAYMENT_METHOD.CASH
                  }
                />
                <div className={`px-2 ${totalPrice?.status === PAYMENT_STATUS.NOT_PAID ? 'text-[#CACACA]' : ''}`}>
                  {t('Transfer')}
                </div>
              </div>
              <div
                className="flex items-center justify-start px-1 pb-2"
                onClick={() =>
                  totalPrice?.status !== PAYMENT_STATUS.NOT_PAID && changePaymentMethod(PAYMENT_METHOD.QR_CODE)
                }
              >
                <input
                  className="w-6 h-6 mt-1 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  type="radio"
                  readOnly
                  disabled={totalPrice?.status === PAYMENT_STATUS.NOT_PAID}
                  checked={
                    routeType
                      ? routeType === RouteType.GO && paymentRouteGo?.method === PAYMENT_METHOD.QR_CODE
                        ? true
                        : routeType === RouteType.BACK && paymentRouteBack?.method === PAYMENT_METHOD.QR_CODE
                      : paymentRouteGo?.method === PAYMENT_METHOD.QR_CODE
                  }
                />
                <div className={`px-2 ${totalPrice?.status === PAYMENT_STATUS.NOT_PAID ? 'text-[#CACACA]' : ''}`}>
                  QR Code
                </div>
              </div>
            </div>
            <div>
              <div className="text-center h-[270px] ">
                <div className="text-center h-[270px]">
                  {routeType === RouteType.GO ? (
                    paymentRouteGo?.method === PAYMENT_METHOD.CASH ? (
                      i18n.language === 'en' ? (
                        <div className="pt-20">
                          <div className="font-bold">{t('Account Detail')}</div>
                          {transferEN.map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </div>
                      ) : (
                        <div className="pt-20">
                          <div className="font-bold">{t('Account Detail')}</div>
                          {transferTH.map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </div>
                      )
                    ) : (
                      <div>
                        {paymentRouteGo?.method === PAYMENT_METHOD.QR_CODE ? (
                          <a href={qrCodeImageUrl} download>
                            <img src={qrCodeImageUrl} alt="QR Code Image" className="h-[270px] w-[200px] mx-auto" />
                          </a>
                        ) : (
                          <div />
                        )}
                      </div>
                    )
                  ) : paymentRouteBack?.method === PAYMENT_METHOD.CASH ? (
                    i18n.language === 'en' ? (
                      <div className="pt-20">
                        <div className="font-bold">{t('Account Detail')}</div>
                        {transferEN.map((line, index) => (
                          <div key={index}>{line}</div>
                        ))}
                      </div>
                    ) : (
                      <div className="pt-20">
                        <div className="font-bold">{t('Account Detail')}</div>
                        {transferTH.map((line, index) => (
                          <div key={index}>{line}</div>
                        ))}
                      </div>
                    )
                  ) : (
                    <div>
                      {paymentRouteBack?.method === PAYMENT_METHOD.QR_CODE ? (
                        <a href={qrCodeImageUrl} download>
                          <img src={qrCodeImageUrl} alt="QR Code Image" className="h-[270px] w-[200px] mx-auto" />
                        </a>
                      ) : (
                        <div />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full p-2 mb-5 space-y-3 bg-white">
        {booking.bookingNumber && (
          <div className="flex justify-between px-2">
            <div className="flex items-end opacity-60">{t('Booking Number')}</div>
            <div className="text-bas">
              <div className="flex flex-row opacity-60">
                <div>{booking.bookingNumber ? booking.bookingNumber : '-'}</div>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-between px-2 pb-4">
          <div className="flex items-end opacity-60">{t('Total')}</div>
          <div className="text-2xl text-blue-500">
            {/* <div className="flex flex-row">
              <div
                className={`font-bold text-blue-500 ${
                  totalPrice?.status === PAYMENT_STATUS.NOT_PAID ? 'line-through' : ''
                }`}
              >
                {`${totalPrice?.amount ? formatNumber(totalPrice.amount) : '-'} ${t('THB')}`}
              </div>
              {totalPrice?.status === PAYMENT_STATUS.NOT_PAID && <div className="pl-1 font-bold">{t('Free')}</div>}
            </div> */}
            {domain === '' ? (
              <div className="font-bold text-blue-500">
                {`- ${t('THB')}`}
              </div>
            ) : ( domain === 'cnmi' ? (
              <div className="font-bold text-blue-500">
                {`${cnmiTotalPrice ? formatNumber(cnmiTotalPrice) : '-'} ${t('THB')}`}
              </div>
            ) : (
              <div className="flex flex-row">
                <div
                  className={`font-bold text-blue-500 ${totalPrice?.status === PAYMENT_STATUS.NOT_PAID ? 'line-through' : ''
                    }`}
                >
                  {`${totalPrice?.amount ? formatNumber(totalPrice.amount) : '-'} ${t('THB')}`}
                </div>
                {totalPrice?.status === PAYMENT_STATUS.NOT_PAID && <div className="pl-1 font-bold">{t('Free')}</div>}
              </div>
            ))}
          </div>
        </div>
        <div>
          <button
            className={`block btn text-lg ${domain === '' ? 'bg-gray-400' : 'bg-[#0181EF]'} text-white`}
            type="button"
            onClick={() => {
              if (domain == 'cnmi') {
                navigate(BookingRoute.LandingPageCNMI)
              } else {
                liff.closeWindow()
              }
            }}
            disabled={domain === ''}
          >
            {t('Close')}
          </button>
        </div>
      </div>
      <ModalCard
        title={t(`Can't make a booking`)}
        detail={
          isCarUnavailable
            ? t('Your car is fully booked, please change your departure time.')
            : t('Please try again or correct the information before booking.')
        }
        status={openBooking}
        singleButton={{ name: t('Close'), type: 'cancel', style: 'btn-primary' }}
        onClick={(val) => {
          setOpenBooking(val)
          setIsCarUnavailable(false)
        }}
      />
      <ModalCard
        selectLanguage={showSelectLanguage}
        loading={loadingSelectLanguage}
        title={t('Send notification to passenger')}
        detail={t('Please select a passenger contact for notification.')}
        status={openShareTargetPicker}
        singleButton={{ name: t('Select passenger'), type: 'cancel', style: 'btn-primary' }}
        onClick={async () => {
          setLoadingSelectLanguage(true)
          if (showSelectLanguage) {
            await updateCustomer({
              phone: formatPhoneNumber(booking.passengerPhone),
              language: passengerLanguage,
            })
          }
          if (bookingNumber && booking) {
            await shareTargetPicker(bookingNumber, booking.type, booking.bookerName, passengerLanguage, domain)
          }
          setLoadingSelectLanguage(false)
          setOpenShareTargetPicker(false)
          redirect()
        }}
        languageChange={(val) => {
          setPassengerLanguage(val)
        }}
      />
    </>
  )
}
