import { isNull } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

/* Below is use for debug Useeffect (DEBUG_DEPARTURE_UF-Fetch) */
// import { useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { useRecoilState, useRecoilValue } from 'recoil'
import loadingIcon from 'src/assets/icons/loading.svg'
import Header from 'src/components/Header'
import { BufferedTravelTime, BufferedRealTimeMinutes } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BookingStatus, BookingType, RouteType, TripType } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { editRouteCheck, isNil } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import BaggageCard from 'src/pageviews/Booking/BaggageCard'
import CarSelection from 'src/pageviews/Booking/CarSelection'
import DateCardBack from 'src/pageviews/Booking/DateCardBack'
import DateCardGo from 'src/pageviews/Booking/DateCardGo'
import DateSelectCard from 'src/pageviews/Booking/DateSelectCard'
import PassengerCard from 'src/pageviews/Booking/PassengeCard'
import RouteCard from 'src/pageviews/Booking/RouteCard'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'

import {
  bookingCustomerState,
  bookingDepartureState,
  bookingLocationState,
  editState,
  viewCustomerState,
  viewDepartureState,
  viewLocationState,
  isRealtimeState,
} from 'src/states'
import { AvailableVehicle, Baggage, Booking, CarModel, CarModelPrice, TimePackage, Vehicle } from 'src/types'

interface BookingUpdate extends Booking {
  followers: number
  baggages: Baggage
  note: string
}

export default function Departure() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    getAvailableVehicles,
    updateBooking,
    getCarModelPrice,
    getCarModels,
    getCutOffAdvanceBookingTime,
    getStartStopCarServiceTime,
    getRealtimeStatus,
  } = useCallApi()

  const [edit, setEdit] = useRecoilState(editState)
  const [, setRealtimeState] = useRecoilState(isRealtimeState)
  const [departure, setDeparture] = useRecoilState(edit.byView ? viewDepartureState : bookingDepartureState)
  const {
    goTime: { arrival: goTimeArrival, departure: goTimeDeparture },
  } = departure
  const { routeGo, routeBack, routeType } = useRecoilValue(edit.byView ? viewLocationState : bookingLocationState)
  const { booker, passenger, emergencyContact, note } = useRecoilValue(
    edit.byView ? viewCustomerState : bookingCustomerState
  )

  const [vehicles, setVehicles] = useState<AvailableVehicle[]>([])
  const [previousVehicle, setPreviousVehicle] = useState<Vehicle | null>(null)
  const [newFollower, setNewFollower] = useState<number | null>(null)

  const [isFetchingVehicles, setIsFetchingVehicles] = useState(true)
  const [isCarAvailable, setIsCarAvailable] = useState(true)
  const [isValid, setIsValid] = useState({
    dateCardGo: true,
    dateCardBack: true,
  })
  const [carModels, setCarModels] = useState<CarModel[]>([])
  const [carModelPrices, setCarModelPrices] = useState<CarModelPrice[]>([])
  const [showAlert, setShowAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isCarDisabled, setIsCarDisabled] = useState(false)
  const [isFetchPriceSucceed, setIsFetchPriceSucceed] = useState(false)

  const [serviceTime, setServiceTime] = useState({ startCarServiceTime: '01:00', stopCarServiceTime: '23:00' })
  const [cutOff, setCutOff] = useState({ cutOffAdvanceBookingTime: '12:00 ' })
  const [isRealtimeToday, setIsRealtimeToday] = useState(false)
  const [isRealtimeTmr, setIsRealtimeTmr] = useState(false)

  const isAllValid = useMemo(() => Object.values(isValid).every((v) => v), [isValid])

  const defaultGoTime: TimePackage = {
    departure: { hour: dayjstz().hour(), minute: dayjstz().minute() },
    arrival: { hour: dayjstz().hour() + 1, minute: dayjstz().minute() },
  }

  const defaultBackTime: TimePackage = {
    departure: { hour: dayjstz().hour() + 1, minute: dayjstz().minute() },
    arrival: { hour: dayjstz().hour() + 2, minute: dayjstz().minute() },
  }

  const isBeforeStopService = useMemo(() => {
    const parsedStopService = serviceTime.stopCarServiceTime.split(':')
    // add buffer departure 15 minute
    const res = dayjstz()
      .add(15, 'minute')
      .isSameOrBefore(dayjstz().hour(parseInt(parsedStopService[0])).minute(parseInt(parsedStopService[1])))
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useMemo isBeforeStopService")
    /* DEBUG_DEPARTURE : For debug  */
      return res
  }, [serviceTime])

  const isAfterStartTime = useMemo(() => {
    const parsedStartService = serviceTime.startCarServiceTime.split(':')
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useMemo isAfterStartTime")
    /* DEBUG_DEPARTURE : For debug  */
    return dayjstz().isSameOrAfter(
      dayjstz().hour(parseInt(parsedStartService[0])).minute(parseInt(parsedStartService[1]))
    )
  }, [serviceTime])

  const canSelectToday = useMemo(() => {
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useMemo canSelectToday")
    /* DEBUG_DEPARTURE : For debug  */
    return isRealtimeToday && isBeforeStopService
  }, [isRealtimeToday, serviceTime])

  const canSelectTomorrow = useMemo(() => {
    const [hour, minute] = cutOff.cutOffAdvanceBookingTime.split(':').map(v => parseInt(v))
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("hour : ", hour," minuite : ", minute) 
    // console.log("dayjstz() : ", dayjstz())
    // console.log("dayjstz().hour(hour).minute(minute)", dayjstz().hour(hour).minute(minute))
    /* DEBUG_DEPARTURE : For debug  */
    const isBeforeCutoffTime = dayjstz().isBefore(dayjstz().hour(hour).minute(minute))
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useMemo canSelectTomorrow")
    // console.log("isRealtimeTmr : ", isRealtimeTmr)
    // console.log("isBeforeCutoffTime : ", isBeforeCutoffTime)
    /* DEBUG_DEPARTURE : For debug  */
    return isRealtimeTmr ? true : isBeforeCutoffTime
  }, [isRealtimeTmr, cutOff.cutOffAdvanceBookingTime])

  const isOvernightBooking = useMemo(() => {
    const res = dayjstz()
      .hour(departure.goTime.departure.hour)
      .minute(departure.goTime.departure.minute)
      .isAfter(dayjstz().hour(departure.goTime.arrival.hour).minute(departure.goTime.arrival.minute))
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useMemo isOvernightBooking")
    /* DEBUG_DEPARTURE : For debug  */
    return res
  }, [departure.goTime])

  const isNoAvailableVehicle = useMemo(() => {
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useMemo isNoAvailableVehicle")
    /* DEBUG_DEPARTURE : For debug  */
    return !vehicles
      .map((v) => {
        return v.availability?.isAvailable
      })
      .includes(true)
  }, [vehicles])

  const RouteMinutes = useMemo(() => Math.round((routeGo.duration ?? 0) / 60), [routeGo])
  const [baggages, setBaggages] = useState(departure.baggages)
  const [routeGoTime, setRouteGoTime] = useState(edit.byConfirm ? departure.goTime : defaultGoTime)
  const [routeBackTime, setRouteBackTime] = useState(edit.byConfirm ? departure.backTime : defaultBackTime)

  function DateCardLoading() {
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("fn DateCardLoading")
    /* DEBUG_DEPARTURE : For debug  */
    return (
      <div className="absolute z-50 flex flex-col items-center justify-center w-full h-full overflow-hidden opacity-40 bg-neutral-50">
        <img className="motion-reduce:animate-spin" src={loadingIcon} width={'41px'} height={'41px'} />
      </div>
    )
  }

  function CarCardLoading() {
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("fn CarCardLoading")
    /* DEBUG_DEPARTURE : For debug  */
    return (
      <div className="bg-gray-100 rounded-lg drop-shadow-md animate-pulse">
        <div className="border-b border-white">
          <div className="h-32 rounded-t-lg bg-slate-200 w-44" />
        </div>
        <div className="flex flex-col p-3">
          <div className="w-1/2 h-4 pb-4 rounded-lg bg-slate-200" />
          <div className="flex items-center justify-between pt-4">
            <div className="w-1/3 h-2 pb-2 rounded-lg bg-slate-200" />
            <div className="w-1/6 h-2 pb-2 rounded-lg bg-slate-200" />
          </div>
          <div className="flex items-center justify-between pt-2">
            <div className="w-2/3 h-2 pb-2 rounded-lg bg-slate-200" />
            <div className="w-1/6 h-2 pb-2 rounded-lg bg-slate-200" />
          </div>
          <div className="flex items-center justify-between pt-2">
            <div className="w-1/2 h-2 pb-2 rounded-lg bg-slate-200" />
            <div className="w-1/6 h-2 pb-2 rounded-lg bg-slate-200" />
          </div>
          <div className="w-1/2 h-2 mt-4 rounded-lg bg-slate-200" />
        </div>
      </div>
    )
  }

  function prepareBooking(): BookingUpdate {
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("fn prepareBooking")
    /* DEBUG_DEPARTURE : For debug  */
    const booking: BookingUpdate = {
      bookingNumber: edit.bn ?? '',
      type: dayjstz(departure.date).hour(0).minute(0).isToday() ? BookingType.REAL_TIME : BookingType.NORMAL,
      tripType: routeType.isOneWay ? TripType.ONE_WAY : TripType.ROUND_TRIP,
      trip: {
        origin: routeGo.origin,
        destination: routeGo.destination,
        distance: routeGo.distance || 0,
        duration: routeGo.duration || 0,
        price: departure.goPrice ?? 0,
      },
      trip2: !routeType.isOneWay
        ? {
            origin: routeBack.origin,
            destination: routeBack.destination,
            distance: routeBack.distance || 0,
            duration: routeBack.duration || 0,
            price: departure.backPrice ?? 0,
          }
        : undefined,

      scheduledDate: dayjstz(departure.date).toDate(),
      departureTime: dayjstz(departure.date)
        .hour(departure.goTime.departure.hour)
        .minute(departure.goTime.departure.minute)
        .toDate(),
      arrivalTime: dayjstz(departure.date)
        .hour(departure.goTime.arrival.hour)
        .minute(departure.goTime.arrival.minute)
        .toDate(),
      departureTime2: departure.backTime
        ? dayjstz(departure.date)
            .hour(departure.backTime.departure.hour)
            .minute(departure.backTime.departure.minute)
            .toDate()
        : undefined,
      arrivalTime2: departure.backTime
        ? dayjstz(departure.date)
            .hour(departure.backTime.arrival.hour)
            .minute(departure.backTime.arrival.minute)
            .toDate()
        : undefined,
      vehicleId: departure.vehicle ? departure.vehicle.id : 0,
      carModelId: departure.carModelId ? departure.carModelId : 0,
      followers: departure.followers,
      baggages: departure.baggages,
      note: note,
      status: BookingStatus.NEW,

      price: departure.goPrice + (departure.backPrice ?? 0),

      bookerName: booker.name,
      bookerPhone: booker.phone,
      bookerLineId: booker.lineId ?? '',
      passengerName: passenger.name,
      passengerPhone: passenger.phone,
      passengerLineId: passenger.lineId ?? '',
      emergencyContactName: emergencyContact.name,
      emergencyContactPhone: emergencyContact.phone,
      emergencyContactRelation: emergencyContact.relation || '',
    }
    return booking
  }

  async function update() {
    setLoading(true)
    const bookingUpdate: Booking = prepareBooking()
    try {
      const bu = await updateBooking(bookingUpdate)
      if (bu) {
        toast.success(t('Save successfully'))
        setTimeout(() => {
          setEdit({ ...edit, byView: false })
          setLoading(false)
          navigate(`${BookingRoute.View}/?bn=${edit.bn}`)
        }, 2000)
      } else {
        toast.error(t('Failed to save'))
      }
    } catch {
      toast.error(t('Failed to save'))
      setLoading(false)
    }
  }

  function changeNewVehicle(
    v: Vehicle,
    price: {
      go: number
      back?: number
    }
  ) {
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("fn changeNewVehicle")
    /* DEBUG_DEPARTURE : For debug  */
    if (edit.byConfirm && isNull(newFollower)) setNewFollower(departure.followers)
    setDeparture({ ...departure, vehicle: v, goPrice: price.go, backPrice: price.back })

    if (!v) return

    const seats = v.car?.seat ?? 0
    if (newFollower && newFollower > seats) {
      setNewFollower(0)
      setShowAlert(true)
    }
  }

  function onSave() {
    if (edit.byView) {
      update()
    } else if (edit.byConfirm) {
      let newDeparture = departure
      if (newFollower) {
        newDeparture = { ...newDeparture, followers: newFollower }
      }
      newDeparture = { ...newDeparture, baggages }
      setDeparture(newDeparture)
      setEdit({ ...edit, byConfirm: false })
      navigate(BookingRoute.Confirm, { state: { editSuccess: true } })
    } else {
      setEdit({ ...edit, byConfirm: false })
      navigate(BookingRoute.Customer)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    ;(async function () {
      const { carModels } = await getCarModels()
      const filteredCarModels = carModels.filter((model) => model.isActive && model.pricingModelId)
      setCarModels(filteredCarModels)
    })()

    if (edit.byConfirm) {
      setPreviousVehicle(departure.vehicle ?? null)
    }

    FetchingGetStartStopCarServiceTime()
    FetchingGetCutOffAdvanceBookingTime()
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useEffect FetchingGetStartStopCarServiceTime and FetchingGetCutOffAdvanceBookingTime")
    /* DEBUG_DEPARTURE : For debug  */
  }, [])

  useEffect(() => {
    if (!edit.byView) {
      const seats = departure.vehicle?.car?.seat || null
      if (seats && departure.followers > seats) {
        setDeparture({ ...departure, followers: 0 })
        setShowAlert(true)
      }
    }
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useEffect follower")
    /* DEBUG_DEPARTURE : For debug  */
  }, [departure.vehicle?.car?.seat, departure.followers, edit.byView])

  /** 
  * This useEffect use for fetch avaliable vehicle when below params is changed
  * @params departure.date
  * @params departure.goTime
  * @params departure.backTime
  * @params routeType
  * @params routeGo
  * @params carModelPrices
  * 
  * @Remark Below is use for debug Useeffect (DEBUG_DEPARTURE_UF-Fetch)
  * const prevDeps = useRef({
  * departureDate: departure.date,
  * departureGoTime: departure.goTime,
  * departureBackTime: departure.backTime,
  * routeType,
  * routeGo,
  * carModelPrices
  * });
  */
  useEffect(() => {
    /** @Remark Below is use for debug Useeffect (DEBUG_DEPARTURE_UF-Fetch)
    * const currentDeps = {
    * departureDate: departure.date,
    * departureGoTime: departure.goTime,
    * departureBackTime: departure.backTime,
    * routeType,
    * routeGo,
    * carModelPrices
    * };
    *  Object.entries(currentDeps).forEach(([key, value]) => {
    *  if ((prevDeps.current as any)[key] !== value) {
    *    console.log(`Changed dependency: ${key}`, (prevDeps.current as any)[key], value);
    *  }
    * });
    * prevDeps.current = currentDeps;
    */

    /** DEBUG_DEPARTURE : For debug 
    * console.log("useEffect_TempFetcher - Start")
    */
    const fetchdata = async() => {
      setIsFetchingVehicles(true)
      try{
      if (!isValid) return
      const availableVehicles =
        carModels.length > 0 && isFetchPriceSucceed
          ? await getAvailableVehicles(
              dayjstz(departure.date).format('YYYY-MM-DD'),
              routeType.isOneWay ? RouteType.GO : RouteType.BACK,
              routeGo,
              dayjstz(departure.date).hour(goTimeDeparture.hour).minute(goTimeDeparture.minute).second(0).toDate(),
              dayjstz(departure.date).hour(goTimeArrival.hour).minute(goTimeArrival.minute).second(0).toDate(),
              routeBack,
              departure.backTime?.departure.hour && !isNil(departure.backTime?.departure.minute)
                ? dayjstz(departure.date)
                    .hour(departure.backTime.departure.hour)
                    .minute(departure.backTime.departure.minute)
                    .toDate()
                : undefined,
              departure.backTime?.arrival.hour && !isNil(departure.backTime?.arrival.minute)
                ? dayjstz(departure.date)
                    .hour(departure.backTime.arrival.hour)
                    .minute(departure.backTime.arrival.minute)
                    .toDate()
                : undefined
            )
          : []

        const _available: AvailableVehicle[] = []

        for (let index = 0; index < carModels.length; index++) {
          const carModel = carModels[index]
          const vehicle = availableVehicles.find((vehicle) => vehicle.vehicle.carModelId === carModel.id)
          const modelPrice = carModelPrices.find((modelPrice) => carModel.id === modelPrice.id)

          _available.push({
            ...carModel,
            ...vehicle,
            price: {
              go: modelPrice?.go ?? 0,
              back: modelPrice?.back ?? 0,
            },
          })
        }

        setVehicles(_available)

        if (vehicles.length > 0 && vehicles.sort().toString() !== availableVehicles.sort().toString()) {
          setDeparture({ ...departure, vehicle: undefined, followers: 0 })
        }
        /* DEBUG_DEPARTURE : For debug  */
        // console.log("useEffect_TempFetcher - Set vehicle done")
        /* DEBUG_DEPARTURE : For debug  */
      }catch(error){
        console.error("Error fetching data:", error);
      }finally{
        setIsFetchingVehicles(false)
        /* DEBUG_DEPARTURE : For debug  */
        // console.log("useEffect_TempFetcherEND")
        // console.log("departure.date : ",departure.date)
        // console.log("departure.goTime : ",departure.goTime)
        // console.log("departure.backTime : ",departure.backTime)
        /* DEBUG_DEPARTURE : For debug  */
      }
    }

    fetchdata()

  }, [departure.date, departure.goTime, departure.backTime, routeType, routeGo, carModelPrices])

  /**
  * An effect hook that fetches prices for car models based on the provided route information.
  * Updates the state with fetched car model prices and sets a flag to indicate the success of the fetch.
  */
  useEffect(() => {
    (async function () {
      // If there are no car models, return early
      if (!carModels.length) return

      // Set a flag to indicate that price fetching is in progress
      setIsFetchPriceSucceed(false)
      
      // Asynchronously fetches the prices for the given model ID and distance
      const fetchPrices = async (modelId: number, distance: number) => {
        try {
          // Fetch the price for the given model ID and distance
          const price = await getCarModelPrice(modelId, distance)
          return price
        } catch (error) {
          // If an error occurs, handle it and return 0 as the price
          console.error(`Error fetching price for model ${modelId}:`, error)
          return 0
        }
      }
  
      // Asynchronously fetches prices for car models and their respective distances
      const fetchCarModelPrices = async () => {
        // Initialize an array to store fetched car model prices
        const _carModelPrices: CarModelPrice[] = []

        // Create an array of promises by mapping over the carModels and fetching prices
        const promises = carModels.map(async (model) => {
          const goPrice = fetchPrices(model.id, (routeGo.distance ?? 0) / 1000)
          const backPrice = routeType.isOneWay
            ? 0
            : fetchPrices(model.id, (routeBack.distance ?? 0) / 1000)
          
          // Wait for both promises to resolve before continuing
          const [go, back] = await Promise.all([goPrice, backPrice])
  
          // Push the fetched prices for each car model to the _carModelPrices array
          _carModelPrices.push({
            id: model.id,
            go,
            back,
          })
        })
  
        // Wait for all promises to resolve before returning the fetched car model prices
        await Promise.all(promises)
       /* DEBUG_DEPARTURE : For debug  */
       // console.log("useEffect setCarModelPrices")
        /* DEBUG_DEPARTURE : For debug  */
        return _carModelPrices
      }
  
      fetchCarModelPrices()
        .then((prices) => {
          setIsFetchPriceSucceed(true)
          setCarModelPrices(prices)
        })
        .catch((error) => {
          console.error('Error fetching car model prices:', error)
          setIsFetchPriceSucceed(true)
          setCarModelPrices([])
        })
    })()
  }, [carModels, routeGo, routeBack, routeType])

  useEffect(() => {
    if (!isFetchingVehicles && isFetchPriceSucceed) {
      setIsCarAvailable(vehicles.length > 0)
      /* DEBUG_DEPARTURE : For debug  */
      // console.log("useEffect setIsCarAvailable")
      // console.log("isFetchingVehicles : ",isFetchingVehicles)
      // console.log("isFetchPriceSucceed : ",isFetchPriceSucceed)
      // console.log("vehicles : ",vehicles)
      /* DEBUG_DEPARTURE : For debug  */
    }
  }, [isFetchingVehicles, isFetchPriceSucceed, vehicles])

  async function FetchingGetStartStopCarServiceTime() {
    const res = await getStartStopCarServiceTime()
    setServiceTime(res)
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("asyncFn FetchingGetStartStopCarServiceTime")
    /* DEBUG_DEPARTURE : For debug  */
  }

  async function FetchingGetCutOffAdvanceBookingTime() {
    const res = await getCutOffAdvanceBookingTime()
    setCutOff(res)
    /** DEBUG_DEPARTURE : For debug  */
    // console.log("asyncFn FetchingGetCutOffAdvanceBookingTime")
    /** DEBUG_DEPARTURE : For debug  */
  }

  async function onRecheck(callBack: () => void) {
    const newServiceTime = await getStartStopCarServiceTime()
    const newCutOff = await getCutOffAdvanceBookingTime()
    if (
      JSON.stringify(newCutOff) === JSON.stringify(cutOff) &&
      JSON.stringify(newServiceTime) === JSON.stringify(serviceTime)
    ) {
      callBack()
    } else {
      FetchingGetStartStopCarServiceTime()
      FetchingGetCutOffAdvanceBookingTime()
      window.alert('Service time is now updated, please reselect the time')
    }
  }

  useEffect(() => {
    getRealtimeStatus(dayjstz().toDate()).then((res) => {
      setIsRealtimeToday(res)
    })
    getRealtimeStatus(dayjstz().add(1, 'day').toDate()).then((res) => {
      setIsRealtimeTmr(res)
    })
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useEffect getRealtimeStatus")
    /* DEBUG_DEPARTURE : For debug  */
  }, [departure.date])

  function setDate(departureDate: Date) {
    const newDeparture = { ...departure, date: departureDate }

    const parsedServiceTime = serviceTime.startCarServiceTime.split(':')
    const calculatedGoDepartureTime = isAfterStartTime
      ? dayjstz().add(BufferedRealTimeMinutes, 'minute')
      : dayjstz().hour(parseInt(parsedServiceTime[0])).minute(parseInt(parsedServiceTime[1]))
    const calculatedGoArriveTime = calculatedGoDepartureTime.add(BufferedTravelTime + RouteMinutes, 'minute')
    newDeparture.goTime = {
      departure: { hour: calculatedGoDepartureTime.hour(), minute: calculatedGoDepartureTime.minute() },
      arrival: { hour: calculatedGoArriveTime.hour(), minute: calculatedGoArriveTime.minute() },
    }
    const calculatedBackDepartureTime = dayjstz()
      .hour(calculatedGoArriveTime.hour())
      .minute(calculatedGoArriveTime.minute())
    const calculatedBackArriveTime = calculatedBackDepartureTime.add(BufferedTravelTime + RouteMinutes, 'minute')
    newDeparture.backTime = { //CHECK_TOR
      departure: { hour: calculatedBackDepartureTime.hour(), minute: calculatedBackDepartureTime.minute() },
      arrival: { hour: calculatedBackArriveTime.hour(), minute: calculatedBackArriveTime.minute() },
    }

    setDeparture(newDeparture)
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("fn setDate")
    /* DEBUG_DEPARTURE : For debug  */
  }

  function setGoTime(goTime: TimePackage) {
    setRouteGoTime(goTime)
    setDeparture({
      ...departure,
      goTime,
    })
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("fn setGoTime")
    /* DEBUG_DEPARTURE : For debug  */
  }

  function setBackTime(backTime: TimePackage) {
    setRouteBackTime(backTime)
    setDeparture({
      ...departure,
      backTime,
    })
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("fn setBackTime")
    /* DEBUG_DEPARTURE : For debug  */
    
  }

  useEffect(() => {
    const newDeparture = { ...departure }
    newDeparture.date = dayjstz()
      .locale('TH')
      .add(canSelectToday ? 0 : canSelectTomorrow ? 1 : 2, 'day')
      .toDate()
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("newDeparture.date : ",newDeparture.date)
    /* DEBUG_DEPARTURE : For debug  */
    const parsedServiceTime = serviceTime.startCarServiceTime.split(':')
    const calculatedGoDepartureTime = dayjstz(newDeparture.date).isToday()
      ? isAfterStartTime
        ? dayjstz().add(BufferedRealTimeMinutes, 'minute')
        : dayjstz().hour(parseInt(parsedServiceTime[0])).minute(parseInt(parsedServiceTime[1]))
      : dayjstz().hour(parseInt(parsedServiceTime[0])).minute(parseInt(parsedServiceTime[1]))
    const calculatedGoArriveTime = calculatedGoDepartureTime.add(BufferedTravelTime + RouteMinutes, 'minute')
    newDeparture.goTime = {
      departure: { hour: calculatedGoDepartureTime.hour(), minute: calculatedGoDepartureTime.minute() },
      arrival: { hour: calculatedGoArriveTime.hour(), minute: calculatedGoArriveTime.minute() },
    }
    if (!routeType.isOneWay) {
      const calculatedBackDepatureTime = dayjstz()
        .hour(calculatedGoArriveTime.hour())
        .minute(calculatedGoArriveTime.minute())
      const calculatedBackArriveTime = calculatedBackDepatureTime.add(BufferedTravelTime + RouteMinutes, 'minute')
      newDeparture.backTime = { 
        departure: { hour: calculatedBackDepatureTime.hour(), minute: calculatedBackDepatureTime.minute() },
        arrival: { hour: calculatedBackArriveTime.hour(), minute: calculatedBackArriveTime.minute() },
      }
    }
    setDeparture(newDeparture)
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useEffect600")
    // console.log("canSelectToborrow : ",canSelectTomorrow)
    // console.log("canSelectToday : ",canSelectToday)
    // console.log("serviceTime : ",serviceTime)
    /* DEBUG_DEPARTURE : For debug  */
  }, [canSelectTomorrow, canSelectToday, serviceTime])

  useEffect(() => {
    setRealtimeState(true)
    /* DEBUG_DEPARTURE : For debug  */
    // console.log("useEffect setRealtimeState")
    /* DEBUG_DEPARTURE : For debug  */
    
  }, []);

  return (
    <div className="flex flex-col justify-between h-screen bg-gray-200">
      <div>
        <ToastContainer position="top-center" hideProgressBar={false} pauseOnFocusLoss draggable pauseOnHover />
        <div className="bg-white">
          <Header
            icon={'back'}
            title={t('Route detail')}
            onBack={() => {
              if (edit.byConfirm || edit.byView) {
                setDeparture({ ...departure, vehicle: previousVehicle ?? undefined })
                navigate(editRouteCheck(edit, BookingRoute.Location))
              } else {
                navigate(BookingRoute.Summary)
              }
            }}
          />
        </div>
        {!edit.byView && (
          <div className="relative">
            {(isFetchingVehicles || !isFetchPriceSucceed) && <DateCardLoading />}
            <div className="card">
              <DateSelectCard
                title={t('Departure date and time')}
                date={departure.date}
                cutOffTime={cutOff}
                serviceTime={serviceTime}
                canSelectToday={canSelectToday}
                canSelectTomorrow={canSelectTomorrow}
                isCarAvailable={isCarAvailable}
                onChange={setDate}
              />
              <DateCardGo
                route={routeGo}
                date={departure.date}
                time={departure.goTime}
                serviceTime={serviceTime}
                cutOffTime={cutOff}
                hasNoCarOrVehicleActive={isNoAvailableVehicle}
                onChange={setGoTime}
                onValidate={(valid) => setIsValid((prev) => ({ ...prev, dateCardGo: valid }))}
              />
            </div>
            {!routeType.isOneWay && routeBack && departure.backTime !== undefined && (
              <DateCardBack
                route={routeBack}
                date={departure.date}
                time={departure.backTime}
                serviceTime={serviceTime}
                cutOffTime={cutOff}
                minTime={departure.goTime.arrival}
                goTime={departure.goTime}
                departureStateData={departure}
                hasNoCarOrVehicleActive={isNoAvailableVehicle}
                isOvernightBooking={isOvernightBooking}
                onChange={setBackTime}
                onValidate={(valid) => setIsValid((prev) => ({ ...prev, dateCardBack: valid }))}
              />
            )}
          </div>
        )}
        <BaggageCard
          baggages={baggages ?? departure.baggages}
          onChange={(baggages) => {
            if (edit.byConfirm) {
              setBaggages(baggages)
            } else {
              setDeparture({ ...departure, baggages })
            }
          }}
        />
        {!edit.byView &&
          (isFetchingVehicles || !isFetchPriceSucceed ? (
            <div className="mt-2 bg-white">
              <div className="flex justify-between border-b border-gray-200 card-title">
                <h3>{t('Vehicle')}</h3>
              </div>
              <div className="flex justify-around px-3 pb-4 my-3 space-x-3">
                <CarCardLoading />
                <CarCardLoading />
              </div>
            </div>
          ) : (
            <CarSelection
              vehicles={vehicles}
              serviceTime={serviceTime}
              selectedCar={previousVehicle ? previousVehicle.car : departure.vehicle?.car}
              tripType={routeType.isOneWay ? TripType.ONE_WAY : TripType.ROUND_TRIP}
              routeGo={routeGo}
              routeBack={routeBack}
              goDepartureTime={dayjstz(departure.date)
                .hour(goTimeDeparture.hour)
                .minute(goTimeDeparture.minute)
                .second(0)
                .toDate()}
              goArrivalTime={dayjstz(departure.date)
                .hour(goTimeArrival.hour)
                .minute(goTimeArrival.minute)
                .second(0)
                .toDate()}
              backDepartureTime={
                isValid && departure.backTime
                  ? dayjstz(departure.date)
                      .hour(departure.backTime.departure.hour)
                      .minute(departure.backTime.departure.minute)
                      .toDate()
                  : undefined
              }
              backArrivalTime={
                isValid && departure.backTime
                  ? dayjstz(departure.date)
                      .hour(departure.backTime.arrival.hour)
                      .minute(departure.backTime.arrival.minute)
                      .toDate()
                  : undefined
              }
              onChange={(vehicle, price) => {
                const dataVehicle = vehicles.find((v) => v.vehicle?.car?.id === vehicle.carId)
                if (edit.byConfirm) {
                  changeNewVehicle({ ...vehicle, isFree: dataVehicle?.isFree }, price)
                } else {
                  setPreviousVehicle(vehicle);
                  setDeparture({
                    ...departure,
                    vehicle,
                    isFree: dataVehicle?.isFree,
                    goPrice: price.go,
                    backPrice: price.back,
                  })
                }
              }}
              isCarDisabled={(s) => {
                setIsCarDisabled(!s)
              }}
            />
          ))}
        <div className="h-2 bg-gray-200" />
        <PassengerCard
          value={newFollower ?? departure.followers}
          maxFollower={departure.vehicle?.car?.seat || 0}
          alert={showAlert}
          isDisabled={edit.byView ? false : !isValid || departure.vehicle?.car?.id === 0 || isCarDisabled}
          onCalculate={(unit) => {
            if (edit.byConfirm) {
              setNewFollower(unit)
            } else {
              setDeparture({ ...departure, followers: unit })
            }
          }}
        />
        {!edit.byView && routeType.isOneWay && (
          <RouteCard
            isFree={departure.isFree}
            routeGo={routeGo}
            arrivalTime={routeGoTime.arrival}
            total={departure.goPrice}
          />
        )}
        {!edit.byView && !routeType.isOneWay && (
          <RouteCard
            isFree={departure.isFree}
            routeGo={routeGo}
            routeBack={routeBack}
            departureTime={routeGoTime.arrival}
            departureTimeTwo={routeBackTime?.arrival}
            total={departure.goPrice + (departure.backPrice ?? 0)}
          />
        )}
      </div>
      <div>
        {!edit.byView && <div className="h-2 bg-gray-200" />}
        <SingleButtonCard
          style="btn btn-primary"
          disabled={(edit.byView ? false : departure.vehicle === undefined || !isAllValid) || loading}
          onClick={() => onRecheck(onSave)}
        >
          {loading && <i className="mr-2 fa fa-circle-o-notch fa-spin" />}
          {t('Save')}
        </SingleButtonCard>
      </div>
    </div>
  )
}
