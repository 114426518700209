/////////// Add for Landing page ///////////
import UserDisplayBlock from 'src/components/Display/UserDisplayBlock'
import BookingCardDisplayBlock from 'src/components/Display/BookingCardDisplayBlock'
import RideSharingDisplayBlock from 'src/components/Display/RideSharingDisplayBlock'
import { HomeIcon, TruckIcon, CalendarIcon, UserIcon, PhoneIcon } from '@heroicons/react/outline'
/////////// From BookingList.tsx ///////////
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import loadingAnimation from 'src/assets/icons/loading.svg'
import CustomDatePicker from 'src/components/CustomDatePicker'
import Header from 'src/components/Header'
import { BookingRoute } from 'src/consts/route'
import { UserCNMIFetchingStateValue, BookingStatusNewCNMI } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { useCallApi } from 'src/hooks/useCallApi'
import BookingCard from 'src/pageviews/Booking/BookingCardCNMI'
import Loading from 'src/pageviews/Booking/Loading'
import PageEmpty from 'src/pageviews/Booking/PageEmptyCNMI'
import { lineState, pathState } from 'src/states'
import { Bookings, QueryBooking, BookingVehicle, Staff } from 'src/types'
import { Carousel } from 'src/components/CarouselCNMI'
import { domainState } from 'src/states'
import { cnmiCustomerState } from 'src/states'
import ToyotaRideSharing from '../../assets/images/ToyotaRideSharing.png'
import CNMIPoster from '../../assets/images/CNMIPoster.png'
import { USER_LANGUAGE } from 'src/consts'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import liff from '@line/liff/dist/lib'
import Modal from 'react-modal'

export default function Landingpage() {
  /////////// Add for Landing page ///////////
  /////////// From BookingList.tsx ///////////
  const { t } = useTranslation()
  const { getBookingList, getCustomerCNMI, getStaff, getDomain } = useCallApi()
  const [loading, setLoading] = useState(false)
  const [isLoadingBookings, setIsLoadingBookings] = useState(false)
  const setPath = useSetRecoilState(pathState)
  const setDomainState = useSetRecoilState(domainState)
  const navigate = useNavigate()
  const [bookingData, setBookingData] = useState<Bookings | null>()
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [cnmiCustomer, setCnmiCustomer] = useRecoilState(cnmiCustomerState)
  const { pictureUrl, userId } = useRecoilValue(lineState)
  const [, setLanguage] = useLocalStorage<string>(USER_LANGUAGE)
  const [openContact, setOpenContact] = useState(false)
  const [staff, setStaff] = useState<Staff | undefined>()
  const [showCNMIPoster, setShowCNMIPoster] = useState(false)

  // Show CNMIPoster Modal
  const handleMoreDetailButtonClick = () => {
    setShowCNMIPoster(true)
  }
  const handleMoreDetailButtonClose = () => {
    setShowCNMIPoster(false)
  }

  const isUpcoming = true // Please update !!

  const queryParams = new URLSearchParams(window.location.search)
  const domain = queryParams.get('domain')

  ///////////////////////////// Add for Landing page //////////////////////////////////
  /////////// From BookingList.tsx ///////////

  const inProgressStatuses = [
    BookingStatusNewCNMI.PENDING,
    BookingStatusNewCNMI.START,
    BookingStatusNewCNMI.DEPART,
    BookingStatusNewCNMI.CONFIRM_DRIVER,
    BookingStatusNewCNMI.CONFIRM_PICK_UP,
    BookingStatusNewCNMI.ARRIVE,
    BookingStatusNewCNMI.PICK_UP_POINT,
  ]

  async function fetchBookings(status: number) {
    setIsLoadingBookings(true)
    const queryStatuses = inProgressStatuses

    if (status === BookingStatusNewCNMI.PENDING) {
      queryStatuses.push(BookingStatusNewCNMI.START)
    }

    const query: QueryBooking = {
      historyBooking: true,
      statuses: queryStatuses,
      date: dayjstz(startDate).format('YYYY-MM-DD').toString(),
      startDate: dayjstz(startDate).format('YYYY-MM-DD').toString(),
      endDate: dayjstz(endDate).format('YYYY-MM-DD').toString(),
    }

    if (isUpcoming) delete query.historyBooking
    if (endDate && startDate) delete query.date
    if (!startDate) delete query.date
    if (!endDate) {
      delete query.startDate
      delete query.endDate
    }

    // const data = await getBookings(query)
    const data = await getBookingList()
    setBookingData(data)
    setIsLoadingBookings(false)
  }

  async function fetchStaff() {
    const domain = await getDomain()
    setStaff(await getStaff(domain))
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      if (userId) {
        await fetchStaff()
      }
      setLoading(false)
    })()
  }, [userId])

  useEffect(() => {
    fetchBookings(BookingStatusNewCNMI.PENDING)
  }, [BookingStatusNewCNMI.PENDING])

  useEffect(() => {
    setLoading(false)
  }, [bookingData])

  useEffect(() => {
    if (domain != undefined) {
      setDomainState(domain)
    }
  }, [domain])

  useEffect(() => {
    setLanguage('th')
    if (userId == undefined) {
      navigate('/signup', { state: { prevUrl: BookingRoute.LandingPageCNMI } })
    } else {
      setCnmiCustomer({ fetchState: UserCNMIFetchingStateValue.FETCHING })
      getCustomerCNMI({ lineId: userId }).then((res) => {
        if (res == null) {
          setCnmiCustomer({ lineId: userId, fetchState: UserCNMIFetchingStateValue.NOT_FOUND })
        } else {
          setCnmiCustomer({
            lineId: res.lineId ?? userId, // testing mock
            phone: res.phone,
            name: res.name,
            lat: res.lat,
            lng: res.lng,
            distance: res.distance,
            duration: res.duration,
            // ...res,  // actual line
            // fetchState: UserCNMIFetchingStateValue.NOT_FOUND, // testing mock
            fetchState: UserCNMIFetchingStateValue.FOUNDED, // actual line
          })
        }
      })
    }
  }, [])

  //////////// Menu Bar Template ///////////
  const BottomMenuBar = () => (
    <div className="bottom-menu-bar">
      <div onClick={() => navigate('/landingpage')} className="bottom-menu-item">
        <HomeIcon className="icon" />
        {t('Home')}
      </div>
      <div
        onClick={() => navigate('/signup', { state: { prevUrl: BookingRoute.LandingPageCNMI } })}
        className="bottom-menu-item"
      >
        <TruckIcon className="icon" />
        {t('Book a car')}
      </div>
      <div onClick={() => navigate('/bookinglist')} className="bottom-menu-item">
        <CalendarIcon className="icon" />
        {t('My booking')}
      </div>
      <div onClick={() => navigate('/paymentsummary')} className="bottom-menu-item">
        <UserIcon className="icon" />
        {t('Management')}
      </div>
      <div onClick={() => contactCustomer()} className="bottom-menu-item">
        <PhoneIcon className="icon" />
        {t('Contact us')}
      </div>
    </div>
  )
  //////////// End: Menu Bar Template ///////////

  //////////// Carousel ///////////
  const hasBookings =
    bookingData?.bookings && bookingData.bookings.some((booking) => inProgressStatuses.includes(booking.status))
  const carouselItems = hasBookings
    ? bookingData.bookings
        .filter((booking) => inProgressStatuses.includes(booking.status))
        .map((val, key) => (
          <div
            key={key}
            onClick={() => {
              setPath({ fromMenu: true, prvePath: BookingRoute.LandingPageCNMI })
              navigate(`${BookingRoute.View}/?bn=${val.bookingNumber}${domain ? `&&domain=${domain}` : ''}`)
            }}
          >
            {/* <BookingCard booking={val} /> */}
            <BookingCard booking={val as BookingVehicle} />
          </div>
        ))
    : [
        <PageEmpty
          key="empty-page"
          description={t('You have not booked yet')}
          buttonText={t('Book a car')}
          onButtonClick={() => navigate('/signup', { state: { prevUrl: BookingRoute.LandingPageCNMI } })}
        ></PageEmpty>,
      ]
  //////////// End: Carousel ///////////

  function contactCustomer() {
    if (liff.getOS() === 'ios') {
      const a = document.createElement('a')
      a.href = `tel:${staff?.phone}`
      a.click()
    } else {
      setOpenContact(true)
    }
  }

  return (
    <div className={`overflow-y-auto relative pb-50 ${true}`} style={{ height: 'calc(100vh - 50px)' }}>
      {' '}
      {/* For see all detail of page when scrolling down */}
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header title={t('Home page')} icon={'null'} bgColor="bg-white" reload={true} />
          {/******** Add display block *********/}
          <div className="ml-4 mr-4">
            {' '}
            {/* Add right and left margin */}
            <UserDisplayBlock
              imageUrl={pictureUrl}
              description1={`${t('Hello')} ${cnmiCustomer.name == undefined ? '' : cnmiCustomer.name}`} // Add user's LINE ID
              description2={t('Welcome to our ride service')}
              description3={t('Ride Sharing')}
              description4={t('We ride together')}
              className="mb-4" // Add bottom space
              onClickIcon={() => navigate('/userprofile')}
            />
            <BookingCardDisplayBlock description={t('Upcoming bookings...')} className="mb-4">
              <div className="p-0">
                {!isUpcoming && (
                  <div className="flex flex-row justify-between mx-auto my-auto">
                    <CustomDatePicker
                      clearDate={false}
                      onChangeStartDate={(value): void => setStartDate(value)}
                      onChangeEndDate={(value): void => setEndDate(value)}
                    />
                  </div>
                )}
                {isLoadingBookings ? (
                  <div className="flex items-center justify-center mx-auto my-auto">
                    <img src={loadingAnimation} width={'41px'} height={'41px'} />
                  </div>
                ) : (
                  <div className="flex justify-center w-full ">
                    <Carousel items={carouselItems} />
                  </div>
                )}
              </div>
            </BookingCardDisplayBlock>
            <RideSharingDisplayBlock
              description1={t('Ride Sharing')}
              description2={t('We ride together')}
              imageUrl={ToyotaRideSharing}
              className="mb-4" // Add bottom space
              description3={t('Receive at home, deliver safely, travel comfortably together by Toyota')}
              buttonText={t('More details')}
              onButtonClick={handleMoreDetailButtonClick}
            />
            <Modal
              isOpen={showCNMIPoster}
              onRequestClose={handleMoreDetailButtonClose}
              contentLabel="CNMI Poster"
              style={{
                overlay: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 40,
                },
                content: {
                  margin: 'auto',
                  maxWidth: '100%',
                  maxHeight: '55%', 
                  width: 'auto',
                  height: 'auto',
                },
              }}
            >
              <div className="text-right">
                <button onClick={handleMoreDetailButtonClose}>X</button>
              </div>
              <img src={CNMIPoster} alt="CNMI Poster" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </Modal>
            <ModalCard
              title={t('Are you want to contact customer service ?')}
              detail={''}
              status={openContact}
              duoButton={{
                left: { name: t('No'), type: 'cancel', style: 'btn-outline-primary' },
                right: { name: t('Yes'), type: 'confirm', style: 'btn-primary' },
              }}
              onClick={(val) => {
                setOpenContact(false)
                if (val) {
                  window.open(`tel:${staff?.phone}`)
                }
              }}
            />
          </div>

          {/******** Add Menu Bar at bottom screen *********/}
          <BottomMenuBar />
        </>
      )}
    </div>
  )
}
