import loading from 'src/assets/icons/loading.svg'

export default function Loading() {
  return (
    <div className="flex items-center w-screen h-screen">
      <div className="w-full">
        <div className="flex flex-col items-center my-auto bg-transparent">
          <img src={loading} width={'41px'} height={'41px'} />
        </div>
      </div>
    </div>
  )
}
