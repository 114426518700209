import React, { ReactNode } from 'react'
import { PencilAltIcon} from '@heroicons/react/outline'

interface UserDisplayBlockProps {
  imageUrl: string
  description1: string
  description2: string
  description3: string
  description4: string
  icon?: ReactNode
  onClickIcon?: () => void
}

const UserDisplayBlock: React.FC<UserDisplayBlockProps & { className?: string }> = ({ imageUrl, description1, description2, description3, description4, className, icon, onClickIcon }) => {
  return (
    <div className={`flex items-center p-4 bg-blue-200 shadow-md rounded-md ${className}`}>
      {/* Icon */}
      {icon && <div className="mr-4">{icon}</div>}
      {/* Image */}
      <img src={imageUrl} alt="" className="w-16 h-16 object-cover rounded-full mr-4" />
      {/* Description */}
      <div className="flex flex-col mt-3 mb-4 flex-grow">  {/* Add flex-grow */}
        <div className="flex">  {/* Make description1 and bookername inline */}
          <div className="text-xs font-normal">{description1}</div>
        </div>
        <div className="text-xs font-normal">{description2}</div>
        <div className="flex">  {/* Make description3 and description4 inline */}
          <div className="text-xs font-bold mr-1">{description3}</div>
          <div className="text-xs font-normal">{description4}</div>
        </div>
      </div>
      {/* Pencil Icon on the right */}
      <div className="ml-auto cursor-pointer grayscale-[90%]" onClick={onClickIcon}>
        <PencilAltIcon className="h-6 w-6" />
      </div>
    </div>
  )
}

export default UserDisplayBlock