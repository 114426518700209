import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { useRecoilState, useRecoilValue } from 'recoil'
import loadingLogo from 'src/assets/icons/loading.svg'
import Header from 'src/components/Header'
import { ERelation, STATUS_CHECKED } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { BookingStatus, BookingType, TripType } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { decoratePhoneNumber, editRouteCheck } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import i18n from 'src/i18n'
import InfoCard from 'src/pageviews/Booking/InfoCard'
import NoteCard from 'src/pageviews/Booking/NoteCard'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'
import {
  bookingCustomerState,
  bookingDepartureState,
  bookingLocationState,
  customerContacts,
  editState,
  lineState,
  viewCustomerState,
  viewDepartureState,
  viewLocationState,
} from 'src/states'
import { Booking, Person } from 'src/types'

enum TargetContact {
  Null = 0,
  Passenger = 1,
  EmergencyContact = 2,
}

export default function CustomerCard() {
  const { t } = useTranslation()
  const [edit, setEdit] = useRecoilState(editState)
  const [customer, setCustomer] = useRecoilState(edit.byView ? viewCustomerState : bookingCustomerState)
  const [contactList, setContactList] = useRecoilState(customerContacts)
  const { date, goTime, backTime, vehicle, baggages, followers, goPrice, backPrice } = useRecoilValue(
    edit.byView ? viewDepartureState : bookingDepartureState
  )
  const { routeGo, routeBack, routeType } = useRecoilValue(edit.byView ? viewLocationState : bookingLocationState)
  const { userId } = useRecoilValue(lineState)

  const [contacts, setContacts] = useState<Person[]>(contactList)
  const [deleteContacts, setDeleteContacts] = useState<Person[]>([])
  const [bookerValid, isBookerValid] = useState(false)
  const [passengerValid, isPassengerValid] = useState(false)
  const [emergencyValid, isEmergencyValid] = useState(false)
  const [relationValid, isRelationValid] = useState(false)
  const [selectContacts, setSelectContacts] = useState(false)
  const [loadingContacts, setLoadingContacts] = useState(false)
  const [showDeleteContacts, setShowDeleteContacts] = useState(false)
  const [errorMessagePassenger, setErrorMessagePassenger] = useState<string | undefined>()
  const [errorMessageEmergency, setErrorMessageEmergency] = useState<string | undefined>()
  const [alertName, isAlertName] = useState(false)
  const [alertPhone, isAlertPhone] = useState(false)
  const [dialogContact, setDialogContact] = useState({ status: false, target: TargetContact.Null })
  const [oldCustomer, setOldCustomer] = useState(customer)
  const [bookerIsEmergency, setBookerIsEmergency] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { updateBooking, updateCustomer, getCustomer } = useCallApi()

  function radioHandle(status: number) {
    if (status === STATUS_CHECKED.NOT_CHECK) {
      if (customer.checked === STATUS_CHECKED.PASSENGER) {
        setCustomer({
          ...customer,
          passenger: { name: '', phone: '' },
          checked: status,
        })
      } else if (bookerIsEmergency) {
        setCustomer({
          ...customer,
          emergencyContact: { name: '', phone: '', relation: customer.emergencyContact.relation },
          checked: status,
        })
      }
    } else if (status === STATUS_CHECKED.PASSENGER) {
      if (bookerIsEmergency) {
        setBookerIsEmergency(false)
        setCustomer({
          ...customer,
          passenger: customer.booker,
          emergencyContact: { name: '', phone: '', relation: customer.emergencyContact.relation },
          checked: status,
        })
      } else {
        setErrorMessageEmergency(undefined)
        setCustomer({
          ...customer,
          passenger: customer.booker,
          emergencyContact: customer.emergencyContact,
          checked: status,
        })
      }
    } else if (bookerIsEmergency) {
      if (customer.checked === STATUS_CHECKED.PASSENGER) {
        setCustomer({
          ...customer,
          passenger: { name: '', phone: '' },
          emergencyContact: { ...customer.booker, relation: customer.emergencyContact.relation },
          checked: status,
        })
      } else {
        setCustomer({
          ...customer,
          passenger: customer.passenger,
          emergencyContact: {
            name: customer.booker.name,
            phone: decoratePhoneNumber(customer.booker.phone),
            relation: customer.emergencyContact.relation,
          },
          checked: status,
        })
      }
    }
    isAlertName(false)
    isAlertPhone(false)
  }

  function handleContacts(contact: Person) {
    const index = _.findIndex(deleteContacts, (c) => {
      return c.phone === contact.phone && c.name === contact.name
    })
    if (index === -1) {
      deleteContacts.push(contact)
    } else {
      deleteContacts.splice(index, 1)
    }

    setShowDeleteContacts(deleteContacts.length > 0)
  }

  function handleEmergencyChecked() {
    const s = !bookerIsEmergency
    setBookerIsEmergency(s)
    if (s) {
      setCustomer({
        ...customer,
        emergencyContact: { ...customer.booker, relation: customer.emergencyContact.relation },
      })
    } else {
      setCustomer({
        ...customer,
        emergencyContact: { name: '', phone: '', relation: ERelation.Child.toString() },
      })
    }
  }

  async function updateContacts() {
    try {
      setLoadingContacts(true)
      await updateCustomer({
        lineId: userId,
        contacts: _.differenceWith(contacts, deleteContacts, _.isEqual),
      })

      const customer = await getCustomer({ lineId: userId })
      if (customer) {
        if (customer.contacts.length === 0) setDialogContact({ ...dialogContact, status: false })
        setContactList(customer.contacts)
      }
    } finally {
      setShowDeleteContacts(false)
      setSelectContacts(false)
      setDeleteContacts([])
      setLoadingContacts(false)
    }
  }

  function checkValidate(name: string, phone: string) {
    const length = phone.length
    const validName = !(name && customer.passenger.name === customer.emergencyContact.name)
    const validPhone = !(phone && customer.passenger.phone === customer.emergencyContact.phone)

    let mes = undefined
    let valid = false

    if (validName && validPhone) {
      valid = name !== '' && phone !== '' && length === 12
    } else {
      mes = t('Passenger information cannot be the same as emergency contact information.')
      valid = false
    }
    isAlertName(!validName)
    isAlertPhone(!validPhone)

    return { mes: mes, valid: valid }
  }

  function prepareBooking(): Booking {
    const booking: Booking = {
      bookingNumber: edit.bn ?? '',
      type: dayjstz(date).hour(0).minute(0).isToday() ? BookingType.REAL_TIME : BookingType.NORMAL,
      tripType: routeType.isOneWay ? TripType.ONE_WAY : TripType.ROUND_TRIP,
      trip: {
        origin: routeGo.origin,
        destination: routeGo.destination,
        distance: routeGo.distance || 0,
        duration: routeGo.duration || 0,
        price: goPrice ?? 0,
      },
      trip2: !routeType.isOneWay
        ? {
            origin: routeBack.origin,
            destination: routeBack.destination,
            distance: routeBack.distance || 0,
            duration: routeBack.duration || 0,
            price: backPrice ?? 0,
          }
        : undefined,

      scheduledDate: dayjstz(date).toDate(),
      departureTime: dayjstz(date).hour(goTime.departure.hour).minute(goTime.departure.minute).toDate(),
      arrivalTime: dayjstz(date).hour(goTime.arrival.hour).minute(goTime.arrival.minute).toDate(),
      departureTime2: backTime
        ? dayjstz(date).hour(backTime.departure.hour).minute(backTime.departure.minute).toDate()
        : undefined,
      arrivalTime2: backTime
        ? dayjstz(date).hour(backTime.arrival.hour).minute(backTime.arrival.minute).toDate()
        : undefined,
      vehicleId: vehicle ? vehicle.id : 0,
      carModelId: vehicle ? vehicle.carModelId : 0,
      followers,
      baggages,
      note: customer.note,
      price: 0,
      status: BookingStatus.NEW,

      bookerName: customer.booker.name,
      bookerPhone: customer.booker.phone,
      bookerLineId: customer.booker.lineId ?? '',
      passengerName: customer.passenger.name,
      passengerPhone: customer.passenger.phone,
      passengerLineId: customer.passenger.lineId ?? '',
      emergencyContactName: customer.emergencyContact.name,
      emergencyContactPhone: customer.emergencyContact.phone,
      emergencyContactRelation: customer.emergencyContact.relation || '',
    }
    return booking
  }

  async function update() {
    try {
      setLoading(true)
      const bookingUpdate: Booking = prepareBooking()
      const bu = await updateBooking(bookingUpdate)
      if (bu) {
        toast.success(t('Save successfully'))
        setTimeout(() => {
          setEdit({ ...edit, byView: false })
          setLoading(false)
          navigate(`${BookingRoute.View}/?bn=${edit.bn}`)
        }, 2000)
      } else {
        toast.error(t('Failed to save"'))
        setLoading(false)
      }
    } catch {
      toast.error(t('Failed to save'))
      setLoading(false)
    }
  }

  useEffect(() => {
    const { valid: passengerValid, mes } = checkValidate(customer.passenger.name, customer.passenger.phone)
    const { valid: emergencyValid } = checkValidate(customer.emergencyContact.name, customer.emergencyContact.phone)
    setErrorMessagePassenger(mes)
    isPassengerValid(passengerValid)
    isEmergencyValid(emergencyValid)
    if (customer.checked === STATUS_CHECKED.PASSENGER) {
      setErrorMessagePassenger(undefined)
      const { passenger, emergencyContact } = customer
      if (passenger.phone === emergencyContact.phone || passenger.name === emergencyContact.name) {
        setErrorMessageEmergency(t('Passenger information cannot be the same as emergency contact information.'))
      } else {
        setErrorMessageEmergency(undefined)
      }
    } else {
      setErrorMessageEmergency(undefined)
    }
  }, [customer.passenger, i18n.language])

  useEffect(() => {
    const result = checkValidate(customer.emergencyContact.name, customer.emergencyContact.phone)
    const _result = checkValidate(customer.passenger.name, customer.passenger.phone)
    setErrorMessageEmergency(result.mes)
    isPassengerValid(_result.valid)
    isEmergencyValid(result.valid)
    if (bookerIsEmergency) {
      setErrorMessageEmergency(undefined)
      const { passenger, emergencyContact } = customer
      if (passenger.phone === emergencyContact.phone || passenger.name === emergencyContact.name) {
        setErrorMessagePassenger(t('Passenger information cannot be the same as emergency contact information.'))
      } else {
        setErrorMessagePassenger(undefined)
      }
    } else {
      setErrorMessagePassenger(undefined)
    }
  }, [customer.emergencyContact, i18n.language])

  useEffect(() => {
    isBookerValid(customer.booker.name !== '' && customer.booker.phone.length === 12)
    if (customer.checked === STATUS_CHECKED.PASSENGER) {
      if (
        customer.booker.name !== customer.emergencyContact.name &&
        customer.booker.phone !== customer.emergencyContact.phone
      ) {
        setErrorMessageEmergency(undefined)
      }
      setCustomer({ ...customer, passenger: customer.booker })
    } else if (bookerIsEmergency) {
      if (customer.booker.name !== customer.passenger.name && customer.booker.phone !== customer.passenger.phone) {
        setErrorMessagePassenger(undefined)
      }
      setCustomer({
        ...customer,
        emergencyContact: {
          name: customer.booker.name,
          phone: decoratePhoneNumber(customer.booker.phone),
          relation: customer.emergencyContact.relation,
        },
      })
    }
  }, [customer.booker])

  useEffect(() => {
    if (edit.byConfirm) setOldCustomer(customer)
    if (customer.booker.name === '') return
    let check = STATUS_CHECKED.PASSENGER
    if (customer.booker.name === customer.passenger.name) {
      check = STATUS_CHECKED.PASSENGER
      setBookerIsEmergency(false)
    } else {
      check = STATUS_CHECKED.NOT_CHECK
      if (customer.booker.name === customer.emergencyContact.name) setBookerIsEmergency(true)
    }

    setCustomer({ ...customer, checked: check })
  }, [])

  useEffect(() => {
    setContacts(contactList)
  }, [contactList])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ToastContainer position="top-center" hideProgressBar={false} pauseOnFocusLoss draggable pauseOnHover />
      <Dialog
        onClose={async () => {
          setDialogContact({ ...dialogContact, status: false })
          setDeleteContacts([])
          setSelectContacts(false)
          setShowDeleteContacts(false)
        }}
        open={dialogContact.status}
      >
        <DialogTitle>
          <div className="flex justify-between text-lg">
            <div>{t('Contacts')}</div>
            {loadingContacts ? null : selectContacts ? (
              showDeleteContacts && (
                <div
                  className="text-red-500"
                  onClick={async () => {
                    await updateContacts()
                  }}
                >
                  {t('Delete')}
                </div>
              )
            ) : (
              <div className="w-4 text-left text-blue-500" onClick={() => setSelectContacts(true)}>
                <span className="text-2xl material-icons">delete_outlined</span>
              </div>
            )}
          </div>
        </DialogTitle>
        <DialogContent style={{ minWidth: '300px', maxHeight: '300px' }} dividers={true}>
          {loadingContacts ? (
            <div className="flex place-content-center">
              <img src={loadingLogo} width={'41px'} height={'41px'} />
            </div>
          ) : (
            <div className="block">
              {contacts.map((c) => {
                return (
                  <div key={c.phone + c.name} className="flex justify-between py-2 border-b last:border-0">
                    <div
                      className="block"
                      onClick={() => {
                        if (dialogContact.target === TargetContact.Passenger) {
                          setCustomer({
                            ...customer,
                            passenger: {
                              ...customer.passenger,
                              name: c.name,
                              phone: decoratePhoneNumber(c.phone),
                            },
                          })
                        } else if (dialogContact.target === TargetContact.EmergencyContact) {
                          setCustomer({
                            ...customer,
                            emergencyContact: {
                              ...customer.emergencyContact,
                              name: c.name,
                              phone: decoratePhoneNumber(c.phone),
                              relation: c.relation ?? ERelation.Child.toLocaleString(),
                            },
                          })
                        }
                        setShowDeleteContacts(false)
                        setSelectContacts(false)
                        setDeleteContacts([])
                        setDialogContact({ status: false, target: TargetContact.Null })
                      }}
                    >
                      <div className="font-bold">{c.name}</div>
                      <div className="opacity-80">{decoratePhoneNumber(c.phone)}</div>
                    </div>
                    {selectContacts && (
                      <input
                        type={'checkbox'}
                        value={JSON.stringify(c)}
                        onChange={(e) => handleContacts(JSON.parse(e.target.value))}
                        className="flex place-self-center"
                      />
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Header
        onBack={() => {
          if (edit.byConfirm || edit.byView) setCustomer(oldCustomer)
          navigate(editRouteCheck(edit, BookingRoute.Departure))
        }}
        icon="back"
        title={t('Customer Information')}
      />
      <div className="pt-1 bg-gray-200">
        <InfoCard
          title={t('Booker')}
          showRelation={false}
          onChange={(p) => setCustomer({ ...customer, booker: p })}
          person={customer.booker}
          checked={customer.checked}
          onSetRadioChecked={(status) => radioHandle(status)}
        />
        <InfoCard
          title={t('Passenger')}
          showRelation={false}
          showContacts={contacts.length !== 0 && !(customer.checked === STATUS_CHECKED.PASSENGER)}
          readonly={customer.checked === STATUS_CHECKED.PASSENGER}
          onClickContact={() => setDialogContact({ status: true, target: TargetContact.Passenger })}
          isNameError={errorMessagePassenger !== undefined && alertName}
          isPhoneError={errorMessagePassenger !== undefined && alertPhone}
          onChange={(p) => setCustomer({ ...customer, passenger: p })}
          person={customer.passenger}
          errorMessage={!passengerValid ? errorMessagePassenger : ''}
        />
        <InfoCard
          title={t('Emergency Contact')}
          showRelation={true}
          showContacts={contacts.length !== 0 && !bookerIsEmergency}
          onClickContact={() => setDialogContact({ status: true, target: TargetContact.EmergencyContact })}
          isNameError={errorMessageEmergency !== undefined && alertName}
          isPhoneError={errorMessageEmergency !== undefined && alertPhone}
          readonly={bookerIsEmergency}
          onChange={(p) => setCustomer({ ...customer, emergencyContact: p })}
          onValid={(r) => isRelationValid(r)}
          person={customer.emergencyContact}
          bookerIsEmergency={bookerIsEmergency}
          checked={customer.checked}
          onEmergencyChecked={handleEmergencyChecked}
          errorMessage={!emergencyValid ? errorMessageEmergency : ''}
        />
        <NoteCard
          title={t('Note (optional)')}
          placeHolder={t('Ex. Passengers with mobility disabilities and requesting special services.')}
          note={customer.note || ''}
          onChangeNote={(n) => setCustomer({ ...customer, note: n })}
        />
        <div className="mt-2">
          <SingleButtonCard
            disabled={
              !(
                passengerValid &&
                emergencyValid &&
                bookerValid &&
                relationValid &&
                !errorMessagePassenger &&
                !errorMessageEmergency
              ) || loading
            }
            onClick={() => {
              if (edit.byView) {
                update()
              } else {
                setEdit({ ...edit, byConfirm: false })
                navigate(BookingRoute.Confirm, { state: { editSuccess: edit.byConfirm } })
              }
            }}
          >
            {loading && <i className="mr-2 fa fa-circle-o-notch fa-spin" />}
            {t('Save')}
          </SingleButtonCard>
        </div>
      </div>
    </div>
  )
}
