import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckboxButton from 'src/components/Button/CheckboxButton'
import { BaggagesList } from 'src/consts'

type Props = {
  baggages: { items: number[]; note: string }
  onChange: (baggages: { items: number[]; note: string }) => void
}

export default function BaggageCard({ baggages, onChange }: Props) {
  const [selectedBaggages, setSelectedBaggages] = useState<number[]>(baggages.items)
  const { t } = useTranslation()

  function handleSelected(item: { key: number; value: string }) {
    const bgs = selectedBaggages.filter((b) => b !== item.key)
    if (selectedBaggages.includes(item.key)) {
      const items = [...bgs].sort()
      setSelectedBaggages(items)
      onChange({ items, note: baggages.note })
    } else {
      const items = [...bgs, item.key].sort()
      setSelectedBaggages(items)
      onChange({ items, note: baggages.note })
    }
  }

  return (
    <div className="card">
      <div className="border-b border-gray-200">
        <h3 className="card-title">{t('Carry-on item(s)')}</h3>
      </div>
      <div className="p-4">
        <div className="flex flex-wrap">
          {BaggagesList.map((item) => (
            <CheckboxButton
              key={item.key}
              title={t(`Baggage.${item.value}`)}
              isChecked={selectedBaggages.includes(item.key)}
              onCheck={() => handleSelected(item)}
            />
          ))}
        </div>
        <textarea
          placeholder={t('Ex. Luggage size 20 inches')}
          defaultValue={baggages.note ?? ''}
          onChange={(e) => onChange({ items: selectedBaggages, note: e.target.value })}
          className="w-full h-20 py-2 resize-none form-control"
        />
      </div>
    </div>
  )
}
