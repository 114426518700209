import { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useCallApi } from 'src/hooks/useCallApi'
import Loading from 'src/pageviews/Booking/Loading'
import PrivateView from 'src/pageviews/Booking/PrivateView'
import PublicView from 'src/pageviews/Booking/PublicView'
import PrivateViewCNMI from 'src/pageviews/Booking/PrivateViewCNMI'
import PublicViewCNMI from 'src/pageviews/Booking/PublicViewCNMI'
import { bookingState, lineState, publicBookingState } from 'src/states'

export default function View() {
  const { getPublicBooking,getDomain } = useCallApi()

  const { userId } = useRecoilValue(lineState)
  const setPublicBooking = useSetRecoilState(publicBookingState)
  const setBooking = useSetRecoilState(bookingState)
  const [domain, setDomain] = useState('')

  const [isAccessible, setIsAccessible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const queryParams = new URLSearchParams(window.location.search)
  const bn = queryParams.get('bn')
  let domainQ = queryParams.get('domain')

  useEffect(() => {
    ;(async () => {
      setDomain(await getDomain())
    })()
  }, [domain])

  useEffect(() => {
    setIsLoading(true)
    ;(async () => {
      if (domainQ == undefined && domain != undefined) 
          domainQ = domain

      if (!bn || !domainQ) return
      const data = await getPublicBooking(bn,domainQ)

      if (!data) {
        setIsAccessible(false)
      } else {
        if ([data?.booking.bookerLineId, data?.booking.passengerLineId].includes(userId)) {
          setBooking(data.booking)
          setIsAccessible(true)
        }
        setPublicBooking(data)
      }
      setIsLoading(false)
    })()
  }, [userId,domain])
  if (domain=='cnmi'){
    return isLoading ? <Loading /> : isAccessible ? <PrivateViewCNMI /> : <PublicViewCNMI />
  }else{
    return isLoading ? <Loading /> : isAccessible ? <PrivateView /> : <PublicView />}
  
}
