import { useEffect, useState } from 'react'
import { useDebounce } from './useDebounce'
import { useGoogleMaps } from './useGoogleMaps'

export function useLocationSearch(input: string) {
  const [locations, setLocations] = useState<google.maps.places.QueryAutocompletePrediction[]>([])
  const debounce = useDebounce(input, 300)
  const { searchLocation } = useGoogleMaps()

  useEffect(() => {
    if (input.length < 3) return
    if (debounce) {
      ;(async () => {
        setLocations(await searchLocation(debounce))
      })()
    } else {
      setLocations([])
    }
  }, [debounce])

  return locations
}
