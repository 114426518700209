import { useTranslation } from 'react-i18next'
import { TripType } from 'src/enums'
import { Booking } from 'src/types'
import TripCard from './TripCard'

type Props = {
  booking: Booking
  onClick: () => void
}

const BookingCard: React.FC<Props> = ({ booking, onClick }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col w-full bg-white rounded-lg shadow-md shadow-blue-600/20" onClick={onClick}>
      <div className="w-full p-2 border-b border-gray-200">
        <div className="flex flex-row justify-between">
          <p className="text-sm font-semibold text-gray-800">
            {booking.tripType === TripType.ONE_WAY ? t('One way') : t('Round trip')}
          </p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-sm font-semibold text-gray-800">{t('Booking Number')}</p>
          <p className="text-sm font-semibold text-gray-800">{booking.bookingNumber}</p>
        </div>
        <div className="flex flex-row justify-between ">
          <p className="text-sm font-semibold text-gray-800">{t('Passenger')}</p>
          <p className="text-sm font-semibold text-gray-800">{booking.passengerName}</p>
        </div>
      </div>
      <div className="p-2">
        {booking.tripType === TripType.ONE_WAY ? (
          <>
            <TripCard
              trip={booking.trip}
              date={booking.scheduledDate}
              time={booking.departureTime}
              status={booking.status}
              vehicleId={booking.vehicleId}
            ></TripCard>
          </>
        ) : booking.tripType === TripType.ROUND_TRIP ? (
          <>
            <TripCard trip={booking.trip} date={booking.scheduledDate} time={booking.departureTime}></TripCard>
            <TripCard
              trip={booking.trip2}
              date={booking.scheduledDate}
              time={booking.departureTime2}
              status={booking.status}
              vehicleId={booking.vehicleId}
            ></TripCard>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default BookingCard
