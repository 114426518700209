import { useState } from 'react'
import { Vote } from 'src/enums'
import i18n from 'src/i18n'
import { Question } from 'src/types'

type PropsAnswer = {
  id: number
  like: Vote | null
  note: string | null
  choice: string | null
}

type Props = {
  question: Question
  answer: ({ id, like, note, choice }: PropsAnswer) => void
}

const QuestionChoiceImage = ({ question, answer }: Props) => {
  const [check, setCheck] = useState(0)

  const handlerPickChoice = (index: number) => {
    setCheck(index + 1)
    const data = question.choices[index].titleTh
    answer({ id: question.id, like: null, note: null, choice: data })
  }
  return (
    <div>
      <div className="flex flex-row justify-between pb-2">
        <div className="text-base">{i18n.language === 'th' ? question.bodyTh : question.bodyEn}</div>
      </div>
      <div className="grid items-start w-full grid-cols-2 gap-5 pb-4">
        {question.choices.map((item, index) => (
          <div
            className="flex justify-between w-full p-2 rounded-lg h-52 border-[#CACACA] border-2 flex-col"
            key={index}
            onClick={() => {
              handlerPickChoice(index)
            }}
          >
            <div className="flex justify-start h-5">
              <input
                className="w-5 h-5 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                type="radio"
                value={item.titleTh}
                checked={check === index + 1}
                readOnly
              />
            </div>
            <div className="flex items-center justify-center my-2 h-36">
              <img className="object-contain h-32 " src={item.url} alt=""></img>
            </div>
            <div className="h-7">
              <p className="text-base text-center break-all">{i18n.language === 'th' ? item.titleTh : item.titleEn}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default QuestionChoiceImage
