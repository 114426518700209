import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { TimeUnit, Timeslot } from 'src/types'
import loadingIcon from 'src/assets/icons/loading.svg'
import { Dayjs } from 'dayjs'
import Button from '@mui/material/Button'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'
// import Typography from '@mui/material/Typography'
// import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import i18n from 'src/i18n'

import { bookingLocationState, lineState } from 'src/states'
import { useCallApi } from 'src/hooks/useCallApi'
import { TripType } from 'src/enums'
import { TimeSlotInterface } from 'src/pages/Booking/BookingCardCalendarCNMI'

type Props = {
  date: Dayjs
  onClose: () => void // Callback to close the modal
  onConfirm: (newTimeSlot: TimeSlotInterface) => void // Callback to send the selected time to the main component
  onUpdate: (SelectedTimeSlot: Timeslot, TripType: TripType, updateIndex: number) => void
  updateIndex?: number
  selectedTimeEdit: { goingTime: Timeslot | null; backTime: Timeslot | null }
  editTripType?: string
}

export default function TimeSlotCard({
  date,
  onClose,
  onConfirm,
  onUpdate,
  updateIndex,
  selectedTimeEdit,
  editTripType,
}: Props) {
  const [availableTimes, setAvailableTimes] = useState<Timeslot[]>([]) // State to store available times
  const [selectedTimes, setSelectedTimes] = useState<{ goingTime: Timeslot | null; backTime: Timeslot | null }>({
    goingTime: null,
    backTime: null,
  })
  const [isFetchingTimeslot, setIsFetchingTimeslot] = useState(true)
  const [selectedTripType, setSelectedTripType] = useState<TripType | null>(null)
  const [hadGo, setHadGo] = useState(false)
  const [hadBack, setHadBack] = useState(false)
  const { t } = useTranslation()

  //imu
  //Get recoil of currentBooking location from AddressAndPriceCNMI
  //Noted that current booking lat/long will be store in routeGo.origin as of CNMI prj.
  const { routeGo } = useRecoilValue(bookingLocationState)
  //Get customer line id to prevent duplicate booking
  const { userId } = useRecoilValue(lineState)
  // Destructure to get lat and lng from routeGo.origin
  const { lat, lng } = routeGo.origin
  // Construct the currentBookingLocation object
  const currentBookingLocation = {
    lat: lat !== undefined ? parseFloat(lat) : 0,
    lng: lng !== undefined ? parseFloat(lng) : 0,
    //lat: 13.526043068147734,
    //lng: 100.76001687442705,
  }
  const { checkSlotAvailability } = useCallApi()

  // Dummy data for arrival and departure times
  const arrivalTime = 8
  const departureTime = 14

  // Function to recommend booking time for Go trip
  /* const recommendGoTripTime = () => {
    if (arrivalTime < 9) {
      return { hour: 9, minute: 0 }
    } else {
      return { hour: 11, minute: 0 }
    }
  } */

  // Check if any slot is selected to enable the OK button
  const isOkButtonActive = selectedTimes.goingTime !== null || selectedTimes.backTime !== null

  // Function to recommend booking time for Back trip
  /* const recommendBackTripTime = () => {
    if (departureTime < 13) {
      return { hour: 13, minute: 0 };
    } else {
      return { hour: 15, minute: 0 };
    }
  }; */

  // Simulating the backend call for available time slots

  const fetchdata = async () => {
    setIsFetchingTimeslot(true)
    try {
      // Make a call to the backend to fetch the available time slots(To be implemented) <- Now moved to BE
      //const availableTimeSlot_init = await fetchAvailableTimeSlots()

      //Need to confirm how to get current location from recoil
      //const availableTimeSlot = await checkSlotAvailability(date,availableTimeSlot_init,currentBookingLocation)
      const year = date.year()
      const month = (date.month() + 1).toString().padStart(2, '0') // Adding 1 because months are zero-indexed
      const day = date.date().toString().padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}`

      const availableTimeSlot = await checkSlotAvailability(userId, formattedDate, currentBookingLocation)
      setAvailableTimes(availableTimeSlot)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsFetchingTimeslot(false)
    }
  }

  useEffect(() => {
    fetchdata()
  }, [arrivalTime, departureTime])

  // Function to fetch available time slots from the backend
  /* async function fetchAvailableTimeSlots(): Promise<Timeslot[]> {
    const _available: Timeslot[] = []
    _available.push({ isGoing: true, isAvailable: true, time: { hour: 9, minute: 0 } })
    _available.push({ isGoing: true, isAvailable: true, time: { hour: 11, minute: 0 } })
    _available.push({ isGoing: false, isAvailable: false, time: { hour: 13, minute: 0 } })
    _available.push({ isGoing: false, isAvailable: true, time: { hour: 15, minute: 0 } })
    return _available
  } */

  const handleConfirm = () => {
    setSelectedTimes({ goingTime: null, backTime: null })
    if (selectedTripType !== null) {
      if (updateIndex == undefined) {
        onConfirm(selectedTimes)
      } else {
        if (selectedTripType == TripType.GO_TRIP) {
          selectedTimes.goingTime != null && onUpdate(selectedTimes.goingTime, selectedTripType, updateIndex)
        } else {
          selectedTimes.backTime != null && onUpdate(selectedTimes.backTime, selectedTripType, updateIndex)
        }
      }
    }
  }

  const handleGoBack = () => {
    setSelectedTimes({ goingTime: null, backTime: null })
    onClose() // Close the modal without confirming
  }

  function TempTimeComparer(a?: TimeUnit, b?: TimeUnit) {
    if (a === undefined || b === undefined) {
      return false
    }
    const res = a.hour === b.hour && a.minute === b.minute
    return res
  }

  // const showTimeSlotStatus = useMemo(() => {
  //   if (selectedTimeEdit.goingTime == null && selectedTimeEdit.backTime == null) return ShowTimeSlotStatus.SHOW_BOTH
  //   if (selectedTimeEdit.backTime == null) return ShowTimeSlotStatus.SHOW_GO
  //   if (selectedTimeEdit.goingTime == null) return ShowTimeSlotStatus.SHOW_BACK
  // }, [selectedTimeEdit])

  return (
    <div>
      <CardHeader
        className="font-weight-bold text-nowrap d-none d-sm-block"
        title={t('Chosen date', {
          date: i18n.language === 'th' ? date.locale('th').format('DD MMMM BBBB') : date.format('DD MMM YYYY'),
        })}
        titleTypographyProps={{ sx: { fontSize: '1.25rem' } }}
      ></CardHeader>

      {isFetchingTimeslot ? (
        <div className="loading-overlay flex justify-center w-full">
          <img src={loadingIcon} alt="Loading" />
        </div>
      ) : (
        <>
          <CardContent>
            {(editTripType === undefined || editTripType === 'go') && (
              <>
                <div className="font-weight-bold text-nowrap d-none d-sm-block">
                  {/* โปรดเลือกรอบเวลารถขาไป */}
                  {t('Please choose time slot in go trip')}
                </div>
                {/* <Typography variant="h6" gutterBottom>
                  โปรดเลือกรอบเวลารถขาไป
                </Typography> */}
                <div className="flex flex-col card-body">
                  {availableTimes
                    .filter((slot) => slot.isGoing)
                    .map((slot, index) => {
                      /* const recommendedTimeGo = recommendGoTripTime()
                      const isRecommendedGo =
                        slot.time.hour === recommendedTimeGo.hour && slot.time.minute === recommendedTimeGo.minute */

                      return (
                        <Button
                          className="disabled:bg-slate-400 "
                          key={index}
                          variant="contained"
                          disabled={
                            !slot.isAvailable ||
                            (selectedTimeEdit.goingTime !== selectedTimeEdit.backTime &&
                              selectedTimeEdit.goingTime === null)
                          }
                          onClick={() => {
                            setSelectedTripType(TripType.GO_TRIP)
                            if (selectedTripType === TripType.GO_TRIP && selectedTimes.goingTime === slot) {
                              // If already selected, unselect it
                              setHadGo(false)
                              setSelectedTimes({ ...selectedTimes, goingTime: null }) // Reset or set to a default value
                            } else {
                              // If not selected, select it
                              setHadGo(true)
                              setSelectedTimes({ ...selectedTimes, goingTime: slot })
                            }
                          }}
                          style={{
                            marginBottom: '8px',
                            backgroundColor: !slot.isAvailable
                              ? '#a9a9a9'
                              : hadGo
                              ? TempTimeComparer(selectedTimes?.goingTime?.time, slot.time)
                                ? '#64b5f6'
                                : 'white'
                              : TempTimeComparer(selectedTimeEdit.goingTime?.time, slot.time)
                              ? '#FFD700'
                              : 'white',
                            color:
                              selectedTimes.goingTime === slot
                                ? 'white'
                                : slot.isAvailable
                                ? 'black' // Change text color to black for default state
                                : 'white', // Change text color to white for disabled state
                            border:
                              selectedTimes.goingTime === slot
                                ? '1px solid transparent'
                                : slot.isAvailable
                                ? '1px solid #a9a9a9'
                                : '1px solid transparent',
                          }}
                        >
                          {/* {
                            slot.isAvailable
                              ? formatTime(slot.time) + (isRecommendedGo ? ` (${t('RECOMMEND')})` : '')
                              : `${formatTime(slot.time)} (${t('FULL')})`
                          }{' '} */}
                          {slot.isAvailable ? formatTime(slot.time) : `${formatTime(slot.time)} (${t('FULL')})`}{' '}
                        </Button>
                      )
                    })}
                </div>
              </>
            )}
            {(editTripType === undefined || editTripType === 'back') && (
              <>
                <div className="font-weight-bold text-nowrap d-none d-sm-block">
                  {/* โปรดเลือกรอบเวลารถขากลับ */}
                  {t('Please choose time slot in back trip')}
                </div>
                {/* <Typography variant="h6" gutterBottom>
                  โปรดเลือกรอบเวลารถขากลับ
                </Typography> */}
                <div className="flex flex-col card-body">
                  {availableTimes
                    .filter((slot) => !slot.isGoing)
                    .map((slot, index) => {
                      // const recommendedTimeBack = recommendBackTripTime();
                      // const isRecommendedBack = slot.time.hour === recommendedTimeBack.hour && slot.time.minute === recommendedTimeBack.minute;
                      return (
                        <Button
                          key={index}
                          // variant="contained"
                          disabled={!slot.isAvailable}
                          // disabled={!slot.isAvailable}
                          onClick={() => {
                            setSelectedTripType(TripType.BACK_TRIP)
                            if (selectedTripType === TripType.BACK_TRIP && selectedTimes.backTime === slot) {
                              // If already selected, unselect it
                              setHadBack(false)
                              setSelectedTimes({ ...selectedTimes, backTime: null }) // Reset or set to a default value
                            } else {
                              // If not selected, select it
                              setHadBack(true)
                              setSelectedTimes({ ...selectedTimes, backTime: slot })
                            }
                          }}
                          style={{
                            marginBottom: '8px',
                            backgroundColor: !slot.isAvailable
                              ? '#a9a9a9'
                              : hadBack
                              ? TempTimeComparer(selectedTimes?.backTime?.time, slot.time)
                                ? '#64b5f6'
                                : 'white'
                              : TempTimeComparer(selectedTimeEdit.backTime?.time, slot.time)
                              ? '#FFD700'
                              : 'white',
                            color:
                              selectedTimes.backTime === slot
                                ? 'white'
                                : slot.isAvailable
                                ? 'black' // Change text color to black for default state
                                : 'white', // Change text color to white for disabled state
                            border:
                              selectedTimes.backTime === slot
                                ? '1px solid transparent'
                                : slot.isAvailable
                                ? '1px solid #a9a9a9'
                                : '1px solid transparent',
                          }}
                        >
                          {/*slot.isAvailable ? formatTime(slot.time) + (isRecommendedBack ? ' (แนะนำ)' : '') : formatTime(slot.time) + ' (เต็ม)'*/}
                          {slot.isAvailable ? formatTime(slot.time) : `${formatTime(slot.time)} (${t('FULL')})`}{' '}
                          {/* Display "เต็ม" when disabled */}
                        </Button>
                      )
                    })}
                </div>
              </>
            )}
          </CardContent>
          <CardActions>
            <SingleButtonCard style="btn btn-primary" onClick={handleGoBack}>
              {t('Cancel')}
            </SingleButtonCard>
            <SingleButtonCard
              style={`btn btn-primary ${!isOkButtonActive ? 'disabled' : ''}`}
              disabled={!isOkButtonActive}
              onClick={handleConfirm}
            >
              {t('OK')}
            </SingleButtonCard>
          </CardActions>
        </>
      )}
    </div>
  )
}

// Utility function to format time for display
const formatTime = (time: TimeUnit): string => {
  return `${time.hour}:${time.minute < 10 ? '0' : ''}${time.minute}`
}
// export default Card;
