import { useEffect, useMemo, useState } from 'react'
import ChevronLeftIcon from 'src/assets/icons/chevron-left-icon.svg'
import ChevronRightIcon from 'src/assets/icons/chevron-right-icon.svg'

interface CarouselCardProps {
  children: React.ReactNode
  selectedIndex: number
  itemIndex: number
  lastItemIndex: number
}

function CarouselCard({ children, selectedIndex, itemIndex, lastItemIndex }: CarouselCardProps) {
  const panelClass = 'scale-[0.8] opacity-70 z-0 pt-4'
  const panelLeftClass = `${panelClass} translate-x-[-33.33%]`
  const panelRightClass = `${panelClass} translate-x-[33.33%]`
  const cardClass = 'absolute w-2/3 h-full left-0 right-0 m-auto duration-500 '

  const itemClass =
    selectedIndex === itemIndex
      ? 'scale-1 z-10'
      : itemIndex === selectedIndex + 1 || (selectedIndex === lastItemIndex && itemIndex === 0)
      ? panelRightClass
      : itemIndex === selectedIndex - 1 || (selectedIndex === 0 && itemIndex === lastItemIndex)
      ? panelLeftClass
      : 'hidden'

  return <div className={`${cardClass} ${itemClass}`}>{children}</div>
}

interface CarouselIndicatorProps {
  items: JSX.Element[]
  selectedIndex: number
  onClick: (index: number) => void
  onClickIndicator?: (index: number) => void
}

function CarouselIndicator({ items, selectedIndex, onClick, onClickIndicator }: CarouselIndicatorProps) {
  function isActive(index: number) {
    if (items.length === 2) {
      return index === selectedIndex % 2
    }
    return index === selectedIndex
  }

  function getItemClass(index: number) {
    const inactiveClass = 'bg-stone-200 border border-stone-200'
    const activeClass = 'bg-blue-500 border border-blue-500'

    return isActive(index) ? activeClass : inactiveClass
  }

  return (
    <div className="flex items-center justify-center py-4 space-x-3">
      {items.map((slice, index) => (
        <div
          key={index}
          className={`w-3 h-3 rounded-full ${getItemClass(index)}`}
          onClick={() => {
            if (!isActive(index)) {
              if (onClickIndicator) {
                onClickIndicator(index)
              }
              onClick(index)
            }
          }}
        ></div>
      ))}
    </div>
  )
}

interface CarouselArrowProps {
  onClick: () => void
  direction: 'left' | 'right'
  icon: string
}

function CarouselArrow({ direction, onClick, icon }: CarouselArrowProps) {
  const arrowClass = direction === 'left' ? 'left-0 rounded-tr-lg rounded-br-lg' : 'right-0 rounded-tl-lg rounded-bl-lg'

  return (
    <div
      className={`absolute h-[80px] w-[40px] bg-blue-500 z-20 flex justify-center items-center ${arrowClass}`}
      onClick={onClick}
    >
      <img src={icon} />
    </div>
  )
}

export function Carousel({
  items,
  onClickIndicator,
}: {
  items: JSX.Element[]
  onClickIndicator?: (index: number) => void
}) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const carouselItems = useMemo(() => {
    return items.length === 2 ? [...items, ...items] : [...items]
  }, [items])

  useEffect(() => {
    if (onClickIndicator) {
      if (items.length === 2) {
        onClickIndicator(selectedIndex % 2)
      } else {
        onClickIndicator(selectedIndex)
      }
    }
  }, [selectedIndex])

  return (
    <div className="flex flex-col items-center justify-center w-full h-full max-w-sm">
      <CarouselArrow
        onClick={() => {
          setSelectedIndex((prev) => {
            const newValue = prev - 1
            return newValue < 0 ? carouselItems.length - 1 : newValue
          })
        }}
        direction="left"
        icon={ChevronLeftIcon}
      ></CarouselArrow>
      <CarouselArrow
        onClick={() => {
          setSelectedIndex((prev) => {
            const newValue = prev + 1
            return newValue > carouselItems.length - 1 ? 0 : newValue
          })
        }}
        direction="right"
        icon={ChevronRightIcon}
      ></CarouselArrow>
      <div className="relative w-full min-h-[330px] overflow-hidden">
        {carouselItems.map((item, index) => (
          <CarouselCard
            key={index}
            selectedIndex={selectedIndex}
            itemIndex={index}
            lastItemIndex={carouselItems.length - 1}
          >
            <div onClick={() => setSelectedIndex(index)}>{item}</div>
          </CarouselCard>
        ))}
      </div>
      <CarouselIndicator
        items={items}
        selectedIndex={selectedIndex}
        onClick={setSelectedIndex}
        onClickIndicator={onClickIndicator}
      ></CarouselIndicator>
    </div>
  )
}
