import { useTranslation } from 'react-i18next'
import { TripType } from 'src/enums'
import { BookingVehicle } from 'src/types'
import TripCard from './TripCardCNMI'

type Props = {
  booking: BookingVehicle
  onClick: () => void
}

const BookingCard: React.FC<Props> = ({ booking, onClick }: Props) => {
  const { t } = useTranslation()
  // console.log("booking: ", booking)

  return (
    <div className="flex flex-col w-full bg-white rounded-lg shadow-md shadow-blue-600/20" onClick={onClick}>
      <div className="w-full p-2 border-b border-gray-200">
        <div className="flex flex-row justify-between">
          <p className="text-sm font-semibold text-gray-800">
            {booking.tripType === TripType.GO_TRIP ? t('Go Trip') : t('Back Trip')}
          </p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-sm font-semibold text-gray-800">{t('Booking Number')}</p>
          <p className="text-sm font-semibold text-gray-800">{booking.bookingNumber}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-s font-semibold text-[#3E6ADF]">{t('Price (THB)')}</p>
          <p className="text-s font-bold text-[#3E6ADF]">
            {booking.payments[0].amount === 0 ? (
              <span>{t('Free')}</span>
            ) : (
              <>
                <span style={{ textDecoration: 'line-through' }}>{booking.payments[0].amount} </span>
                <span>{t('Free')}</span>
              </>
            )}
          </p>
        </div>
      </div>
      <div className="p-2">
        <TripCard
          trip={booking.trip}
          date={booking.scheduledDate}
          departureTime={booking.departureTime}
          arrivalTime={booking.arrivalTime}
        ></TripCard>
      </div>
    </div>
  )
}

export default BookingCard
