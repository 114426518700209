import { useTranslation } from 'react-i18next'
import destinationRouteBack from 'src/assets/icons/icon-destination-route-back.svg'
import destinationRouteGo from 'src/assets/icons/icon-destination-route-go.svg'
import originRouteBack from 'src/assets/icons/icon-origin-route-back.svg'
import driginRouteGo from 'src/assets/icons/icon-origin-route-go.svg'
import { Icons } from 'src/enums'

type Props = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  isReadOnly?: boolean
  icon: Icons
  value?: string
  placeholder?: string
  disabled?: boolean
}

const LocationInput: React.FC<Props> = ({
  onChange,
  isReadOnly,
  onClick,
  icon,
  value,
  placeholder,
  disabled,
}: Props) => {
  const { t } = useTranslation()
  isReadOnly !== undefined ? isReadOnly : false

  const _icon =
    icon === Icons.OriginRouteGo
      ? driginRouteGo
      : icon === Icons.OriginRouteBack
      ? originRouteBack
      : icon === Icons.DestinationRouteGo
      ? destinationRouteGo
      : destinationRouteBack

  const defaultValue = [Icons.OriginRouteGo, Icons.OriginRouteBack].includes(icon)
    ? t('LocationInput.Origin.Where to go ?')
    : t('LocationInput.Destination.Where to go ?')

  const inputClass = [Icons.OriginRouteGo, Icons.DestinationRouteGo].includes(icon)
    ? `${disabled ? 'bg-gray-200 focus:ring-0' : 'bg-sky-50 focus:border-sky-500 focus:ring-sky-500'} `
    : `${disabled ? 'bg-gray-200 focus:ring-0' : 'bg-sky-50 focus:border-red-500 focus:ring-red-500'} `

  return (
    <label className="relative block py-2" onClick={isReadOnly && !disabled ? onClick : () => {}}>
      <img className="absolute left-0 flex pl-2 inset-y-4" src={_icon} />
      <input
        name="input"
        autoFocus={!isReadOnly}
        readOnly={isReadOnly}
        className={`${inputClass} block w-full py-2 pr-3 border border-white rounded-md placeholder:text-slate-400 pl-9 focus:outline-none focus:ring-1 sm:text-sm`}
        value={value}
        placeholder={placeholder || defaultValue}
        type="text"
        onChange={onChange}
      />
    </label>
  )
}

export default LocationInput
