import { SwitchVerticalIcon } from '@heroicons/react/outline'
import liff from '@line/liff/dist/lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import AddressInput from 'src/components/AddressInput'
import Button from 'src/components/Button/Base'
import SwitchButton from 'src/components/Button/SwitchButton'
import Header from 'src/components/Header'
import ModalTermCondition from 'src/components/ModalTermCondition'
import { NOTE, PLACE_TYPE, SWAP } from 'src/consts'
import { BookingRoute } from 'src/consts/route'
import { Icons, InputType } from 'src/enums'
import { resolveAddressName } from 'src/helpers/utils'
import { useCallApi } from 'src/hooks/useCallApi'
import {  checkServicePostalCode, getCurrentLocation, useGoogleMaps } from 'src/hooks/useGoogleMaps'
import i18n from 'src/i18n'
import HospitalListInput from 'src/pageviews/Booking/HospitalListInput'
import Loading from 'src/pageviews/Booking/Loading'
import LocationInput from 'src/pageviews/Booking/LocationInput'
import ModalCard from 'src/pageviews/Booking/ModalCard'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'
import { bookingLocationState, hospitalOnBotState, lineState, otherLocationState } from 'src/states'
import { Place } from 'src/types'

export default function Location() {
  const [location, setLocation] = useRecoilState(bookingLocationState)
  const { routeGo, routeBack, routeType } = location
  const [swap, setSwap] = useRecoilState(hospitalOnBotState)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [isVisibleConsent, setIsVisibleConsent] = useState(false)
  const [hospitalList, setHospitalList] = useState<Place[] | undefined>(undefined)
  const navigate = useNavigate()
  const { userId } = useRecoilValue(lineState)
  const { getAcceptConsent, createAcceptConsent, getDestinationList ,getSupportPostcode} = useCallApi()
  const { getGeoLocation } = useGoogleMaps()
  const { t } = useTranslation()
  const [otherLocation, setOtherLocation] = useRecoilState(otherLocationState)
  const [loading, setLoading] = useState(false)

  async function handleSetSwap(click: SWAP) {
    if (!routeType.isOneWay) return

    await handleSwap(click)
    await setSwap(!swap)
  }

  async function handleSwap(swap: SWAP) {
    if (routeType.isOneWay) {
      const temp = routeGo.destination
      setLocation({
        ...location,
        routeGo: { destination: routeGo.origin, origin: temp },
        routeBack: { destination: temp, origin: routeGo.origin },
      })
    } else {
      if (swap === SWAP.ROUTE_GO) {
        const temp = routeGo.destination
        setLocation({ ...location, routeGo: { destination: routeGo.origin, origin: temp } })
      } else {
        const temp = routeBack.destination
        setLocation({ ...location, routeBack: { destination: routeBack.origin, origin: temp } })
      }
    }
  }

  function onSetNote(note: string, output: number) {
    if (output === NOTE.ORIGIN1) {
      setLocation({
        ...location,
        routeGo: { ...routeGo, origin: { ...routeGo.origin, note: note } },
      })
    } else if (output === NOTE.DESTINATION1) {
      setLocation({
        ...location,
        routeGo: { ...routeGo, destination: { ...routeGo.destination, note: note } },
      })
    } else if (output === NOTE.ORIGIN2) {
      setLocation({
        ...location,
        routeBack: { ...routeBack, origin: { ...routeBack.origin, note: note } },
      })
    } else if (output === NOTE.DESTINATION2) {
      setLocation({
        ...location,
        routeBack: { ...routeBack, destination: { ...routeBack.destination, note: note } },
      })
    }
  }

  async function setPlaceType(placeType: PLACE_TYPE) {
    await setLocation({ ...location, routeType: { ...routeType, placeType: placeType } })
  }

  async function setCurrentLocation() {
    const servicePostalCodes = await getSupportPostcode();
    const current = await getCurrentLocation()
    const result = await getGeoLocation({ location: current })
    const { address_components, place_id, formatted_address: _formatted_address } = result
    const { name, formatted_address } = resolveAddressName(address_components, _formatted_address)
    const origin = {
      id: place_id,
      name,
      address: formatted_address,
      lat: current.lat.toString(),
      lng: current.lng.toString(),
    }
    const addrlength = address_components.length - 1
    if (!checkServicePostalCode(address_components[addrlength].long_name ?? '',servicePostalCodes)) {
      return
    }

    setLocation({
      ...location,
      routeGo: { ...routeGo, origin: origin },
      routeBack: { ...routeBack, destination: origin },
    })
  }

  async function fetchIsAcceptConsent() {
    const result = await getAcceptConsent(userId)
    if (result) {
      setIsVisibleConsent(!result.consent.isAccepted)
    }
  }

  async function fetchHospitalList() {
    getDestinationList(i18n.language?.toUpperCase() || 'TH').then((res) => {
      let other = {} as Place
      const list = res.filter((r) => {
        if (r.id === '000000000000000000000000000') {
          other = r
          return false
        } else {
          return true
        }
      })
      setHospitalList([...list, other])
    })
  }

  async function acceptConsent() {
    const result = await createAcceptConsent(userId)
    if (result) {
      setIsVisibleConsent(false)
    }
  }

  useEffect(() => {
    if (routeGo.destination.id !== '') {
      setLocation({ ...location, routeType: { ...routeType, showRoute: true } })
    } else {
      setLocation({ ...location, routeType: { ...routeType, showRoute: false } })
    }
  }, [routeGo.destination.id])

  useEffect(() => {
    if (!routeType.isOneWay) {
      const originRouteGo = routeGo.origin.id ? routeGo.origin : routeGo.origin
      const destinationRouteGo = routeGo.origin.id ? routeGo.destination : routeGo.origin

      setLocation({
        ...location,
        routeGo: {
          ...routeGo,
          origin: { ...originRouteGo, disabled: false },
          destination: {
            ...destinationRouteGo,
            disabled: false,
          },
        },
        routeBack: {
          ...routeBack,
          origin: { ...destinationRouteGo, disabled: false, note: routeBack.origin.note },
          destination: routeBack.destination.id
            ? { ...routeBack.destination, disabled: false }
            : { ...originRouteGo, disabled: false },
        },
      })
    }
  }, [routeType.isOneWay])

  useEffect(() => {
    setLoading(true)
    fetchIsAcceptConsent()
    fetchHospitalList()
    window.scrollTo(0, 0)
    setOtherLocation(otherLocation)

    if (!routeGo.destination.id) {
      setLocation({
        ...location,
        routeGo: {
          ...routeGo,
        },
        routeBack: {
          ...routeBack,
        },
      })
    } else if (routeType.isOneWay) {
      setLocation({
        ...location,
        routeGo: routeGo.origin.id
          ? {
              ...routeGo,
              origin: {
                ...routeGo.origin,
              },
              destination: {
                ...routeGo.destination,
              },
            }
          : {
              ...routeGo,
              origin: {
                ...routeGo.origin,
                disabled: false,
              },
              destination: {
                ...routeGo.destination,
                disabled: false,
              },
            },
      })
    }

    if (!routeGo.origin.id) {
      ;(async () => {
        await setCurrentLocation()
      })()
    }
    i18n.changeLanguage(i18n.language)
    setLoading(false)

  }, [i18n.language])

  const tripTrophic = 'w-3/12 py-1 text-lg font-medium text-white rounded-r-xl bg-zinc-800 flex place-content-center'
  const queryParams = new URLSearchParams(window.location.search)
  const liffState = queryParams.get('liff.state')

  return (liffState && loading) ? (
    <div className="h-screen bg-powderblue">
      <Loading />
    </div>
  ) : (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <Header icon={'null'} title={t('Location.Route')} reload={true} />

        <div className="py-5">
          <SwitchButton
            leftToggle={t('One way')}
            rightToggle={t('Round trip')}
            onChange={(isOneWay) =>
              setLocation({ ...location, routeType: { isOneWay, placeType: PLACE_TYPE.ORIGIN1 } })
            }
            isOneWay={routeType.isOneWay}
          />
        </div>

        {!routeType.isOneWay && <div className={tripTrophic}>{t('Departure')}</div>}
        <div className="px-3 pt-5 pb-10">
          <label className="text-gray-400">{t('Pick-up')}</label>
          {routeType.isOneWay && !swap ? (
            <HospitalListInput
              icon={Icons.OriginRouteGo}
              value={routeGo.origin.name}
              options={hospitalList}
              otherEnd={location.routeGo.destination.id}
              onClick={async (d: Place) => {
                await setPlaceType(PLACE_TYPE.ORIGIN1)
                await setLocation({
                  ...location,
                  routeGo: { ...routeGo, origin: d },
                })
              }}
              onSetOtherPlace={async () => {
                await setPlaceType(PLACE_TYPE.ORIGIN1)
                await navigate(BookingRoute.Map)
              }}
              disabled={routeGo.origin.disabled}
            />
          ) : (
            <LocationInput
              isReadOnly={true}
              icon={Icons.OriginRouteGo}
              value={routeGo.origin.name}
              onClick={async () => {
                await setPlaceType(PLACE_TYPE.ORIGIN1)
                await navigate(BookingRoute.Map)
              }}
              disabled={routeGo.origin.disabled}
            />
          )}

          {routeGo.origin.name && (
            <AddressInput
              value={routeGo.origin.note ?? ''}
              type={InputType.ORIGIN}
              onChangeNote={(s) => onSetNote(s, NOTE.ORIGIN1)}
            />
          )}
          {routeType.isOneWay ? (
            <div className="flex justify-center py-4">
              <Button
                type="btn btn-outline-primary font-semibold shadow-lg shadow-blue-500/20"
                onClick={() => 
                  routeGo.origin.id && routeGo.destination.id
                    ? handleSetSwap(SWAP.ROUTE_GO)
                    : (setOpen(true), setMessage(t('Please complete the origin and destination information.')))
                }
              >
                <SwitchVerticalIcon className="inline-block w-5 h-5">sync_alt</SwitchVerticalIcon> {t('Switch')}
              </Button>
            </div>
          ) : (
            <div className="py-4" />
          )}

          <label className="text-gray-400">{t('Destination')}</label>
          {!routeType.isOneWay || swap ? (
            <HospitalListInput
              icon={Icons.DestinationRouteGo}
              value={routeGo.destination.name}
              options={hospitalList}
              otherEnd={location.routeGo.origin.id}
              onClick={async (d: Place) => {
                await setPlaceType(PLACE_TYPE.DESTINATION1)
                if (routeType.isOneWay) {
                  await setLocation({
                    ...location,
                    routeGo: { ...routeGo, destination: d },
                  })
                } else {
                  await setLocation({
                    ...location,
                    routeGo: { ...routeGo, destination: d },
                    routeBack: { ...routeBack, origin: d }
                  })
                }
              }}
              onSetOtherPlace={async () => {
                await setPlaceType(PLACE_TYPE.DESTINATION1)
                await navigate(BookingRoute.Map)
              }}
              disabled={routeGo.destination.disabled}
            />
          ) : (
            <LocationInput
              isReadOnly={true}
              icon={Icons.DestinationRouteGo}
              value={routeGo.destination.name}
              onClick={async () => {
                await setPlaceType(PLACE_TYPE.DESTINATION1)
                await navigate(BookingRoute.Map)
              }}
              disabled={routeGo.destination.disabled}
            />
          )}
          {routeGo.destination.name && (
            <AddressInput
              value={routeGo.destination.note ?? ''}
              type={InputType.DESTINATION}
              onChangeNote={(s) => onSetNote(s, NOTE.DESTINATION1)}
            />
          )}
        </div>
        {!routeType.isOneWay && (
          <>
            <div className={tripTrophic}>{t('Return')}</div>
            <div className="px-3 pt-5 mt-2">
              <label className="text-gray-400">{t('Pick-up')}</label>
              <HospitalListInput
                icon={Icons.OriginRouteBack}
                value={routeBack.origin.name}
                options={hospitalList}
                otherEnd={routeBack?.destination?.id}
                onClick={async (d: Place) => {
                  await setPlaceType(PLACE_TYPE.ORIGIN2)
                  await setLocation({
                    ...location,
                    routeBack: { ...routeBack, origin: d },
                    routeGo: { ...routeGo, destination: d },
                  })
                }}
                onSetOtherPlace={async () => {
                  await setPlaceType(PLACE_TYPE.ORIGIN2)
                  await navigate(BookingRoute.Map)
                }}
                disabled={routeGo.destination.disabled}
              />
              {routeBack?.origin?.name && (
                <AddressInput
                  value={routeBack.origin.note ?? ''}
                  type={InputType.ORIGIN}
                  onChangeNote={(s) => onSetNote(s, NOTE.ORIGIN2)}
                />
              )}
              {routeType.isOneWay ? (
                <div className="flex justify-center py-4">
                  <Button
                    type="btn btn-outline-primary font-semibold shadow-lg shadow-blue-500/20"
                    onClick={() =>
                      routeGo.origin.id && routeGo.destination.id
                        ? handleSetSwap(SWAP.ROUTE_BACK)
                        : (setOpen(true), setMessage(t('Please complete the origin and destination information.')))
                    }
                  >
                    <SwitchVerticalIcon className="inline-block w-5 h-5">sync_alt</SwitchVerticalIcon> {t('Switch')}
                  </Button>
                </div>
              ) : (
                <div className="py-4" />
              )}

              <label className="text-gray-400">{t('Destination')}</label>
              <LocationInput
                isReadOnly={true}
                icon={Icons.DestinationRouteBack}
                value={routeBack?.destination?.name}
                onClick={async () => {
                  if (routeGo.destination.id !== '') {
                    await setPlaceType(PLACE_TYPE.DESTINATION2)
                    await navigate(BookingRoute.Map)
                  } else {
                    setOpen(true), setMessage(t('Please select the outbound route first'))
                  }
                }}
                disabled={routeBack.destination.disabled}
              />

              {routeBack?.destination?.name && (
                <AddressInput
                  value={routeBack.destination.note ?? ''}
                  type={InputType.DESTINATION}
                  onChangeNote={(s) => onSetNote(s, NOTE.DESTINATION2)}
                />
              )}
            </div>
          </>
        )}
        <ModalCard
          title={t('Warning')}
          detail={message}
          status={open}
          singleButton={{ name: t('OK'), style: 'btn-primary', type: 'cancel' }}
          onClick={(s) => setOpen(s)}
        />
      </div>
      <div>
        {routeGo.origin.id && routeGo.destination.id && (routeType.isOneWay || routeBack?.destination.id !== '') && (
          <SingleButtonCard onClick={() => navigate(BookingRoute.Summary)}>{t('Save')}</SingleButtonCard>
        )}
        {process.env.REACT_APP_BUILD_TIME && (
          <div className="text-xs text-center text-gray-400">Build: {process.env.REACT_APP_BUILD_TIME}</div>
        )}
      </div>
      <ModalTermCondition visible={isVisibleConsent} onCancel={() => liff.closeWindow()} onOk={() => acceptConsent()} />
    </div>
  )
}

