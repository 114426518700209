import { useTranslation } from 'react-i18next'
import { Driver } from 'src/types'

type Props = {
  driver?: Driver
}

export default function DriverCard({ driver }: Props) {
  const { t } = useTranslation()

  return driver ? (
    <div>
      <div className="flex flex-col bg-white">
        <div className="border-b border-gray-200 card-title">
          <h3>{t('Driver Information')}</h3>
        </div>
        <div className="overflow-hidden sm:rounded-md md:mt-0">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="flex flex-col">
              <div className="flex flex-row py-2">
                <div className="flex-none">
                  <img alt="img" className="w-16 h-16 rounded-full" src={driver.imageUrl} />
                </div>
                <div className="flex-1 px-4">
                  <label className="block text-lg font-semibold text-gray-700"> {driver.name}</label>
                  <div className="flex flex-row py-2">
                    <span className="text-2xl text-blue-500 material-icons-outlined">call</span>
                    &nbsp;
                    <a href={`tel:${driver.phone}`} className="block text-lg font-semibold text-blue-500">
                      {driver.phone}
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap">
                {driver?.skills?.split(',').map((item) => (
                  <>
                    <div className="px-4 py-1 m-1 btn-pill">{item}</div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
