import { useTranslation } from 'react-i18next'
import { InputType } from 'src/enums'

type Props = {
  onChangeNote: (n: string) => void
  value?: string
  placeholder?: string
  type: InputType
}

export default function AddressInput({ value, placeholder, onChangeNote, type }: Props) {
  const { t } = useTranslation()
  const _placeholder =
    type === InputType.ORIGIN ? (placeholder != undefined ? placeholder : 'Pick-up detail') : 'Destination detail'
  const row = value ? Math.floor(value?.length / 65) + 1 : 1

  return (
    <textarea
      placeholder={t(_placeholder)}
      value={value}
      onChange={(e) => onChangeNote(e.target.value)}
      rows={row}
      className="w-full p-2 font-light border-b border-gray-200 resize-none focus:outline-none focus:ring-2 focus:rounded-lg focus:ring-blue-200 focus:border-white"
    />
  )
}
