type Props = {
  title: string
  placeHolder?: string
  note: string
  alert?: boolean
  onChangeNote: (n: string) => void
}

export default function NoteCard({ title, placeHolder, note, alert, onChangeNote }: Props) {
  const _alert = alert || false
  return (
    <div className={placeHolder && title && 'pt-2'}>
      <div className="flex flex-col bg-white">
        <div className={`px-4 ${placeHolder && title ? 'border-gray-200 border-b border-solid py-6' : 'pt-2'}`}>
          <h3 className="text-lg font-semibold leading-6 text-gray-900">{title}</h3>
        </div>
        <div className="px-4 overflow-hidden">
          <div className={`${placeHolder && 'pb-4'} pt-2`}>
            <textarea
              value={note}
              placeholder={placeHolder || 'เช่น หน้าบ้านปลูกต้นกุหลาบสีเหลือง'}
              onChange={(e) => onChangeNote(e.target.value)}
              className={`${
                _alert && 'focus:ring-red-500 focus:border-red-500 border-red-500 border-2'
              } w-full form-control h-20 resize-none`}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  )
}
