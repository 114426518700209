import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import Header from 'src/components/Header'
import NoteInput from 'src/components/Input/NoteInput'
import { FeedbackRoute } from 'src/consts/route'
import { HttpStatusCode, QuestionsAbout, RouteType, Vote } from 'src/enums'
import { dayjstz } from 'src/helpers/datetime'
import { useCallApi } from 'src/hooks/useCallApi'
import Loading from 'src/pageviews/Booking/Loading'
import Page404 from 'src/pageviews/Booking/Page404'
import SingleButtonCard from 'src/pageviews/Booking/SingleButtonCard'
import FeedBackCard from 'src/pageviews/Feedback/FeedBackCard'
import { lineState } from 'src/states'
import { FeedbackStatus, IFeedback, PublicBooking, TopicQuestion } from 'src/types'

type PropsAnswer = {
  id: number
  like: Vote | null
  note: string | null
  choice: string | null
}

type CheckAnswer = {
  id: number
  status: boolean
}

export default function Feedback() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getQuestions, createFeedback, getPublicBooking, getPublicFeedbacks, getDomain } = useCallApi()
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState<TopicQuestion[] | undefined>([])
  const [answers, setAnswers] = useState<FeedbackStatus[] | []>([])
  const [countQuestion, setCountQuestion] = useState(0)
  const [activeButton, setActiveButton] = useState(false)
  const [bookingNumber, setBookingNumber] = useState('')
  const [domain, setDomain] = useState('')
  const [routeType, setRouteType] = useState<RouteType>(RouteType.GO)
  const [booking, setBooking] = useState<PublicBooking | null>()
  const [comment, setComment] = useState('')
  const [driverImg, setDriverImg] = useState<string>()
  const [isBooker, setIsBooker] = useState(false)
  const [isPassenger, setIsPassenger] = useState(true)
  const [feedbacks, setFeedbacks] = useState<IFeedback[] | undefined>([])
  const [checkAnswer, setCheckAnswer] = useState<CheckAnswer[]>([])

  const [searchParams] = useSearchParams()

  const { userId } = useRecoilValue(lineState)

  const fetchFeedbacks = async (bn: string,dm: string) => {
    const data = await getPublicFeedbacks(dm,{ bookingNumber: bn })
    console.log(`getPublicFeedbacks ${data}`)
    setFeedbacks(data)
  }

  const fetchQuestions = async (dm: string) => {
    const data = await getQuestions(dm)
    setQuestions(data.questions)
  }

  const fetchBooking = async (dm: string,bn: string) => {
    const data = await getPublicBooking(bn, dm)
    console.log(`getPublicBooking ${data}`)
    setBooking(data)
    setDriverImg(data?.driver.imageUrl)
  }

  const handlerAnswer = ({ id, like, note, choice }: PropsAnswer) => {
    _.remove(answers, (i) => i.id === id)
    const items: FeedbackStatus[] = [...answers, { id, answer: { like, note, choice } }]
    setAnswers(items)
  }

  const handlerOnSubmit = async () => {
    const bookerComment = isBooker ? comment : ''
    const passengerComment = isPassenger && !isBooker ? comment : ''
    const data = { bookingNumber, routeType, lineId: userId, bookerComment, passengerComment, answers }
    const result = await createFeedback(data)
    if (result?.status === HttpStatusCode.OK) {
      navigate(FeedbackRoute.Success)
    }
  }

  //check booker or passenger have answered ?
  useEffect(() => {
    if (!isBooker && !isPassenger) return
    if (!feedbacks) return
    const _feedbacks = feedbacks.filter((f) => f.routeType === routeType)
    const _f = _.sortBy(_feedbacks, 'createdAt')[0]
    if (_feedbacks.length > 0 && _f?.createdAt && dayjstz().diff(dayjstz(_f.createdAt), 'hour') > 24) {
      navigate(FeedbackRoute.Success)
    }
  }, [routeType, feedbacks, isBooker, isPassenger])

  //check question vote is  notes ?
  useEffect(() => {
    const data = answers.map((i) => {
      _.remove(checkAnswer, (item) => item.id === i.id)
      let status = true
      if (
        (i.answer?.like !== null && i.answer?.note === null) ||
        (i.answer?.like === null && i.answer?.note !== null)
      ) {
        status = false
      }
      return { id: i.id, status }
    })
    setCheckAnswer(data)
  }, [answers])

  // check bookingNumber
  // check countQuestions and countAnswers
  // If all conditions ActiveButton Submit
  useEffect(() => {
    ;(async () => {
      const bn = searchParams.get('bn')
      const rt = searchParams.get('rt') ?? RouteType.GO
      setDomain(await getDomain())
      const answerVote = checkAnswer.every((i) => i.status === true)
      if (countQuestion > 0 && countQuestion === answers.length && bn?.length === 15 && answerVote && domain) {
        setActiveButton(true)
        setBookingNumber(bn)
        setRouteType(Number(rt))
      }
      if (!answerVote) {
        setActiveButton(false)
      }
    })()
  }, [answers, countQuestion, checkAnswer, domain])

  //checkout user is booker or passenger
  useEffect(() => {
    if (userId && booking?.booking.bookerLineId && userId === booking?.booking.bookerLineId) {
      setIsBooker(true)
    }
    if (userId && booking?.booking.passengerLineId && userId !== booking?.booking.passengerLineId) {
      setIsPassenger(false)
    }
    if (!booking?.booking.passengerLineId && userId === booking?.booking.bookerLineId) {
      setIsPassenger(false)
    }
  }, [userId, booking])

  // count question of booker, passenger
  useEffect(() => {
    let count = 0
    questions?.forEach((item, index) => {
      if (index === QuestionsAbout.BOOKING && isBooker) {
        count += item.questions?.count ? item.questions?.count : 0
      }
      if (index === QuestionsAbout.CAR && isPassenger) {
        count += item.questions?.count ? item.questions?.count : 0
      }
      if (index === QuestionsAbout.DRIVER && isPassenger) {
        count += item.questions?.count ? item.questions?.count : 0
      }
    })
    setCountQuestion(count)
  }, [userId, booking, questions, isBooker, isPassenger])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const dm = await getDomain()
        const bn = searchParams.get('bn')
        if (bn?.length === 15) {
          fetchBooking(dm,bn)
          fetchFeedbacks(dm,bn)
        }
        fetchQuestions(dm)
      } catch {
        setQuestions([])
      } finally {
          setLoading(false)
      }
    })()
  }, [countQuestion])

  return (
    <div className="h-screen">
      {loading || booking === undefined ? (
        <Loading />
      ) : //ไม่มี questions, booking, และ ไม่เป็นทั้ง isBooker, isPassenger
      !questions || !booking || (!isBooker && !isPassenger) ? (
        <Page404
          description={t('The system is not working properly, please try again.')}
          textButton={t('Try again')}
          onClick={() => navigate(0)}
        />
      ) : (
        <>
          <Header title={t('Feedback form')} titleLeft icon={'null'} />
          <div className="flex flex-col py-2">
            {questions.map((item, index) =>
              index === QuestionsAbout.BOOKING && isBooker ? (
                <FeedBackCard data={item} onClick={(answer) => handlerAnswer(answer)} key={index} active={answers} />
              ) : index === QuestionsAbout.CAR && isPassenger ? (
                <FeedBackCard data={item} onClick={(answer) => handlerAnswer(answer)} key={index} active={answers} />
              ) : (index === QuestionsAbout.DRIVER && isPassenger) || (isPassenger && isBooker) ? (
                <FeedBackCard
                  data={item}
                  onClick={(answer) => handlerAnswer(answer)}
                  driverImg={driverImg}
                  key={index}
                  active={answers}
                />
              ) : (
                <div key={index}></div>
              )
            )}
            <div className="px-4 pt-2">
              <NoteInput onChange={(v) => setComment(v)} maxlength={125} placeholder={t('Feedback.Note (optional)')} />
            </div>
            <SingleButtonCard style="btn btn-primary" onClick={() => handlerOnSubmit()} disabled={!activeButton}>
              {t('Feedback.Submit')}
            </SingleButtonCard>
          </div>
        </>
      )}
    </div>
  )
}
