import { useTranslation } from 'react-i18next'
import { BaggageItems } from 'src/enums'
import { Baggage } from 'src/types'

type Props = {
  baggages: Baggage
  passenger: number
  showTitle?: boolean
  isEdit?: boolean
  onEdit?: () => void
}

export default function ShowBaggageAndPassenger({ showTitle, baggages, passenger, onEdit, isEdit }: Props) {
  const { t } = useTranslation()
  const itemsList = baggages.items.map((item) => t(`Baggage.${BaggageItems[item]}`))
  const summaryBaggage = `${itemsList.join(', ')}${
    itemsList.length > 0 && baggages.note.trim() ? ', ' : ''
  }${baggages.note.trim()}`
  return (
    <div className="flex flex-col pt-2 bg-white">
      {showTitle && (
        <div className="flex justify-between px-4 py-6 text-lg font-semibold leading-6 text-gray-900 border-b sm:px-0">
          <h3>{t('Baggage and Companion information')}</h3>
          {isEdit && (
            <h3 className="underline text-primary" onClick={onEdit}>
              {t('Edit')}
            </h3>
          )}
        </div>
      )}
      <div className="p-4 space-y-2 font-semibold bg-white border-b border-gray-200">
        <div className="opacity-60">{t('Carry-on item(s)')}</div>
        <div className="leading-6 text-gray-900 break-words">{summaryBaggage.length !== 0 ? summaryBaggage : '-'}</div>
      </div>
      <div className="flex justify-between p-4 font-semibold bg-white border-b border-gray-200">
        <div className="opacity-60">{t('Number of companion(s)')}</div>
        <div>{passenger || t('Do not have')}</div>
      </div>
    </div>
  )
}
