import { Dialog } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import Button from 'src/components/Button/Base'
import Header from 'src/components/Header'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import i18n from 'src/i18n'
import { domainState } from 'src/states'

type Props = {
  visible: boolean
  onCancel: () => void
  onOk: () => void
}

export default function ModalTermCondition({ visible, onCancel, onOk }: Props) {
  const { t } = useTranslation()
  const domain_state = useRecoilValue(domainState)
  const [localDomain,] = useLocalStorage('domain_state')

  return (
    <Dialog fullScreen open={visible}>
      <div className="bg-white">
        <Header icon={'null'} title={t('Terms and Conditions')} reload={true} />
        <div className="w-full h-screen px-4 py-2">
          <div className="rounded-sm bg-[#F7F7F7] h-5/6 p-4 mb-6">
            <iframe
              // src={`${process.env.REACT_APP_AWS_S3_PUBLIC_URL}/consent/consent_${i18n.language}.html`}
              src={`${process.env.REACT_APP_AWS_S3_PUBLIC_URL}/consent/${domain_state?domain_state:localDomain}/consent_${i18n.language}.html`}
              height="100%"
              width="100%"
            />
          </div>
          <div className="flex justify-between row">
            <Button type="btn btn-outline-danger w-2/5" onClick={() => onCancel()}>
              {t('Reject')}
            </Button>
            <Button type="btn btn-primary w-2/5" onClick={() => onOk()}>
              {t('Accept')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
