import { CheckIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ERelation, STATUS_CHECKED } from 'src/consts'
import { formatInputPhoneText } from 'src/helpers/utils'
import { Person } from 'src/types/index'
import InputPhone from '../../components/Input/PhoneNumber'
import NoteCard from './NoteCard'

type Props = {
  title: string
  person: Person
  showRelation?: boolean
  errorMessage?: string
  checked?: number
  bookerIsEmergency?: boolean
  onChange: (p: Person) => void
  onValid?: (v: boolean) => void
  onSetRadioChecked?: (n: number) => void
  onEmergencyChecked?: (value: boolean) => void
  onClickContact?: () => void
  isNameError?: boolean
  isPhoneError?: boolean
  isValidatePhone?: boolean
  showContacts?: boolean
  readonly?: boolean
}

export default function InfoCard({
  title,
  person,
  showRelation,
  errorMessage,
  checked,
  bookerIsEmergency = false,
  onChange,
  onValid,
  onSetRadioChecked,
  onClickContact,
  onEmergencyChecked,
  isPhoneError,
  isNameError,
  showContacts,
  readonly,
}: Props) {
  const [inPerson, setInPerson] = useState(person)
  const [relationValid, isRelationValid] = useState(checkRelationValid(person.relation || ERelation.Child.toString()))
  const [showNote, setShowNote] = useState(false)
  const showSelect = showNote ? checkRelation(inPerson.relation ?? '') : inPerson?.relation

  const { t } = useTranslation()

  function checkRelation(relation: string) {
    return checkShow(relation) ? ERelation.Other.toString() : relation
  }

  function relationChange(r: string) {
    setShowNote(checkShow(r))
    setInPerson((p) => ({ ...p, relation: r }))
    isRelationValid(checkRelationValid(r))
  }

  function checkRelationValid(r: string) {
    return showRelation && (r === ERelation.Other.toString() || r.length === 0) ? false : true
  }

  function onSetName(e: string) {
    setInPerson((p) => ({ ...p, name: e }))
  }

  function onSetPhone(e: string) {
    setInPerson((p) => ({ ...p, phone: formatInputPhoneText(e) }))
  }

  //จำเป็นต้องลูปเช็คเพราะค่าอื่นๆไม่ตายตัว ไม่ใช่แค่แสดงtextarea แต่รวมถึงการเลือกความสัมพันธ์ปัจจุบัน
  function checkShow(r: string) {
    for (let i = 0; i < ERelation.Other; i++) {
      if (i.toString() === r) return false
    }
    return true
  }

  function handleCheckPhone(x: string) {
    const last = x.slice(-1)
    if (x && x[0] !== '0') return
    if (x.split('').every((val) => /[0-9,-]/.test(val))) onSetPhone(x)
    if ((x.length === 4 || x.length === 8) && last === '-') onSetPhone(x)
    if (!x) onSetPhone('')
  }

  function radioToCheck(x: number) {
    return (
      <div
        className={`${
          checked === x ? 'bg-blue-500 border-blue-500' : 'bg-white border-gray-400'
        } text-white border rounded`}
      >
        <CheckIcon className="w-4 h-4" />
      </div>
    )
  }

  useEffect(() => {
    onChange(inPerson)
  }, [inPerson])

  useEffect(() => {
    if (onValid) onValid(relationValid)
  }, [relationValid])

  useEffect(() => {
    if (person) setInPerson(person)
    if (person?.relation) setShowNote(checkShow(person?.relation))
  }, [person])

  return (
    <>
      <div className="card">
        <div className="flex flex-row items-center justify-between border-b border-gray-200">
          <h3 className="card-title">{title}</h3>
          {showContacts && (
            <button
              className="content-center px-2 py-1 mr-3 text-lg font-bold text-blue-500 underline bg-white rounded-lg"
              onClick={onClickContact}
            >
              {t('Contact History')}
            </button>
          )}
        </div>
        <div className="flex flex-col px-1 pb-4 space-y-2 radio-buttons">
          {onEmergencyChecked && (
            <div className="flex pt-4 pl-4 space-x-2 place-items-center">
              {checked !== STATUS_CHECKED.PASSENGER ? (
                <div
                  className={`${
                    bookerIsEmergency ? 'bg-blue-500 border-blue-500' : 'bg-white border-gray-400'
                  } text-white border rounded`}
                >
                  <CheckIcon className="w-4 h-4" />
                </div>
              ) : (
                <div className={'bg-gray-300 border-gray-400 text-gray-300 border rounded'}>
                  <CheckIcon className="w-4 h-4" />
                </div>
              )}
              <input
                id="passenger"
                className="absolute w-4 h-4 opacity-0 left-3"
                type="checkbox"
                onChange={() => {
                  if (checked !== STATUS_CHECKED.PASSENGER) {
                    onEmergencyChecked(bookerIsEmergency)
                  }
                }}
                checked={checked === STATUS_CHECKED.EMERGENCY}
              />
              <label>{t('Use your information')}</label>
            </div>
          )}
        </div>
        <div className="px-4">
          <div className="flex flex-col">
            <label className="block text-lg font-light text-gray-700">{t('Name - Surname')}</label>
            <input
              type="text"
              placeholder={t('Ex. John Doe')}
              value={inPerson.name}
              onChange={(e) => onSetName(e.target.value)}
              readOnly={readonly}
              className={`${
                isNameError && 'border-2 border-red-500'
              } read-only:bg-gray-300 h-10 form-control focus:outline-none`}
            />
            <label className="text-alert">{isNameError ? errorMessage : ''}</label>

            <label className="block text-lg font-light text-gray-700">{t('Mobile Phone')}</label>
            <InputPhone
              value={inPerson.phone}
              readOnly={readonly}
              isPhoneError={isPhoneError}
              isMessageError={errorMessage}
              onChange={(v) => handleCheckPhone(v)}
            />
            {onSetRadioChecked && (
              <div className="pb-4 space-y-2">
                <div className="flex space-x-2 place-items-center">
                  {radioToCheck(STATUS_CHECKED.NOT_CHECK)}
                  <input
                    id="clear"
                    className="absolute w-4 h-4 opacity-0 left-3"
                    type="radio"
                    onChange={() => onSetRadioChecked(STATUS_CHECKED.NOT_CHECK)}
                    checked={checked === STATUS_CHECKED.NOT_CHECK}
                  />
                  <label htmlFor="passenger">{t('Booking for the other')}</label>
                </div>
                <div className="flex space-x-2 place-items-center">
                  {radioToCheck(STATUS_CHECKED.PASSENGER)}
                  <input
                    id="passenger"
                    className="absolute w-4 h-4 opacity-0 left-3"
                    type="radio"
                    onChange={() => onSetRadioChecked(STATUS_CHECKED.PASSENGER)}
                    checked={checked === STATUS_CHECKED.PASSENGER}
                  />
                  <label htmlFor="clear">{t('Booking for yourself')}</label>
                </div>
              </div>
            )}
            {showRelation && (
              <>
                <label className="block text-lg font-light text-gray-700">{t('Relationship')}</label>
                <select
                  name="relationship"
                  aria-label="relationship"
                  value={showSelect}
                  onChange={(e) => typeof e.target.value === 'string' && relationChange(e.target.value)}
                  className="h-10 form-control"
                >
                  {Object.values(ERelation)
                    .filter((r) => typeof r === 'string')
                    .map((val, key) => (
                      <option key={val} value={key + 1}>
                        {t(`Relation.${val}`)}
                      </option>
                    ))}
                </select>
                <div className="mb-2"></div>
              </>
            )}
          </div>
        </div>
      </div>
      {showNote && (
        <NoteCard
          title={''}
          placeHolder={t('Ex. Co Worker')}
          alert={inPerson.relation === ERelation.Other.toString() || inPerson.relation === ''}
          note={
            inPerson.relation
              ? inPerson.relation === ERelation.Other.toString()
                ? ''
                : inPerson.relation
              : inPerson.relation || ''
          }
          onChangeNote={(n) => relationChange(n)}
        />
      )}
    </>
  )
}
