import { useEffect, useMemo, useState } from 'react'


interface CarouselCardProps {
    children: React.ReactNode
    selectedIndex: number
    itemIndex: number
    lastItemIndex: number
}

function CarouselCard({ children, selectedIndex, itemIndex, dragPosition }: CarouselCardProps & { dragPosition: number }) {
    /////////////// Hide panelClass (include both left and right) ////////////////////
    // const panelClass = 'scale-[0.8] opacity-70 z-0 pt-4'
    // const panelLeftClass = `${panelClass} translate-x-[-33.33%]`
    // const panelRightClass = `${panelClass} translate-x-[33.33%]`
    const cardClass = 'w-full h-full left-0 right-0 m-auto duration-500 transition-transform'
    const itemClass =
        selectedIndex === itemIndex
        ? 'scale-1 z-10'
        // : itemIndex === selectedIndex + 1 || (selectedIndex === lastItemIndex && itemIndex === 0)
        // ? panelRightClass
        // : itemIndex === selectedIndex - 1 || (selectedIndex === 0 && itemIndex === lastItemIndex)
        // ? panelLeftClass
        : 'hidden'

    // Swipe //
    const isCurrent = selectedIndex === itemIndex
    let style = {}

    if (isCurrent) {
        style = { transform: `translateX(${dragPosition}px)` }
    }

    return <div className={`${cardClass} ${itemClass}`} style={style}>{children}</div>
}

{/****************************** Slice button ********************************/}
interface CarouselIndicatorProps {
    items: JSX.Element[]
    selectedIndex: number
    onClick: (index: number) => void
    onClickIndicator?: (index: number) => void
}

function CarouselIndicator({ items, selectedIndex, onClick, onClickIndicator }: CarouselIndicatorProps) {
    function isActive(index: number) {
        if (items.length === 2) {
        return index === selectedIndex % 2
        }
        return index === selectedIndex
    }

    function getItemClass(index: number) {
        const inactiveClass = 'bg-white border border-white cursor-pointer'        // Old  'bg-stone-200 border border-stone-200'
        const activeClass = 'bg-blue-500 border border-blue-500 cursor-pointer'

        return isActive(index) ? activeClass : inactiveClass
    }

    return (
        <div className={`flex items-center justify-center space-x-3 space-y-1 px-0 w-full mt-2 flex-wrap z-30`}>     {/* effect when >1 carouselitems */}
            {items.map((slice, index) => (
                <div
                key={index}
                className={`w-5 h-3 rounded-full ${getItemClass(index)}`}
                onClick={() => {
                    if (!isActive(index)) {
                    if (onClickIndicator) {
                        onClickIndicator(index)
                    }
                    onClick(index)
                    }
                }}
                ></div>
            ))}
        </div>
    )
}
{/****************************** End: Slice button **********************************/}

{/********************* Left and Right Arrow Button for sliding -> Position, Rounded side, Height, Width, Color ******************/}


{/********************* End: Left and Right Button for sliding ******************/}

export function Carousel({
    items,
    onClickIndicator,
}: {
    items: JSX.Element[]
    onClickIndicator?: (index: number) => void
}) {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const carouselItems = useMemo(() => {
        return items.length === 2 ? [...items, ...items] : [...items]
    }, [items])

    useEffect(() => {
        if (onClickIndicator) {
            if (items.length === 2) {
                onClickIndicator(selectedIndex % 2)
            } else {
                onClickIndicator(selectedIndex)
            }
        }
    }, [selectedIndex])

    ////////////////////// Swipe ////////////////////////
    const [dragStart, setDragStart] = useState(0)
    const [dragging, setDragging] = useState(false)
    const DRAG_THRESHOLD = 50 // Unit: Pixels adjust as needed for sensitivity

    // Swipe Animation //
    const [dragPosition, setDragPosition] = useState(0)

    const onDragStart = (event: React.MouseEvent | React.TouchEvent) => {
        setDragging(true)
        setDragStart(event.type.includes('mouse') ? (event as React.MouseEvent).pageX : (event as React.TouchEvent).touches[0].clientX)
    }
    
    const onDragEnd = (event: React.MouseEvent | React.TouchEvent) => {
        if (!dragging) return
        setDragging(false)

        const currentX = event.type.includes('mouse') ? (event as React.MouseEvent).pageX : (event as React.TouchEvent).changedTouches[0].clientX
        const dragDistance = currentX - dragStart

        if (dragDistance > DRAG_THRESHOLD) {            // Dragged enough to trigger going to the previous item
            setSelectedIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : items.length - 1)
        } else if (dragDistance < -DRAG_THRESHOLD) {    // Dragged enough to trigger going to the next item
            setSelectedIndex(prevIndex => prevIndex < items.length - 1 ? prevIndex + 1 : 0)
        }
        setDragPosition(0)
    }

    const onDragMove = (event: React.MouseEvent | React.TouchEvent) => {
        if (!dragging) return
        const currentX = event.type.includes('mouse') ? (event as React.MouseEvent).pageX : (event as React.TouchEvent).touches[0].clientX
        const newDragPosition = currentX - dragStart
        setDragPosition(newDragPosition)
    }
    ////////////////////// End: Swipe //////////////////////

    // Add condition for when have 1 carouselitems only
    // Check items for show the CarouselIndicator -> 1 item won't be shown arrows and indicator, >1 will be shown arrows and indicator
  
    const showCarouselIndicator = carouselItems.length > 1

    return (
        <div
            className="flex flex-col items-center justify-center w-full h-full max-w-xl"
            onMouseDown={onDragStart}
            onTouchStart={onDragStart}
            onMouseMove={onDragMove}
            onTouchMove={onDragMove}
            onMouseUp={onDragEnd}
            onTouchEnd={onDragEnd}
            onMouseLeave={onDragEnd}    // In case the mouse leaves the container while dragging
            style={{ cursor: dragging ? 'grabbing' : 'grab' }}>

            {/* Remove comment if want to show left arrow */}
            {/* {showCarouselArrow && (
                <CarouselArrow
                    onClick={() => {
                        setSelectedIndex((prev) => {
                            const newValue = prev - 1
                            return newValue < 0 ? carouselItems.length - 1 : newValue
                        })
                    }}
                    direction="left"
                    icon={ChevronLeftIcon}
                ></CarouselArrow>
            )} */}
                
            <div className={`flex-grow relative w-full overflow-hidden`}>
                {carouselItems.map((item, index) => (
                    <CarouselCard
                        key={index}
                        selectedIndex={selectedIndex}
                        itemIndex={index}
                        lastItemIndex={carouselItems.length - 1}
                        dragPosition={dragPosition}     // Swipe
                    >
                        <div onClick={() => setSelectedIndex(index)}>{item}</div>
                    </CarouselCard>
                ))}
            </div>
            
            {/* Remove comment if want to show right arrow */}
            {/* {showCarouselArrow && (
                <CarouselArrow
                    onClick={() => {
                        setSelectedIndex((prev) => {
                            const newValue = prev + 1
                            return newValue > carouselItems.length - 1 ? 0 : newValue
                        })
                    }}
                    direction="right"
                    icon={ChevronRightIcon}
                ></CarouselArrow>
            )} */}
    
            {showCarouselIndicator && (
                <CarouselIndicator
                    items={items}
                    selectedIndex={selectedIndex}
                    onClick={setSelectedIndex}
                    onClickIndicator={onClickIndicator}
                />
            )}
        </div>
    )
}